/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams,  } from 'react-router-dom';
import _ from 'lodash';
import InputKoopers from '../../../components/molecules/InputKoopers';
import Button from '../../../components/atoms/Button/Button';
import { 
    getUserInformationRequest,
    updateUserInformationRequest,
} from '../../../redux/actions/userActions';
import ProfileCard from '../../../components/organisms/ProfileCard';

// src & styles
import '../ProfileModule/Profile.scss';
import carImg from '../../../assets/images/koopers/car-a.png';

const ViewCarCard = (props) => {
    const { carId } = useParams();
    const car = useSelector(state => state.car.cars.data).find(car => car.id == carId);
    const dispatch = useDispatch();
    const history = useHistory();

    const carInformation = {
        carBrand: car.carBrand.displayText.toUpperCase(),
        carModel: car.carModel.displayText.toUpperCase(),
        setUpOption: car.carInstanceSetups[0].carSetupOption.displayText,
        carCost: car.baseCost,
        images: car.carInstanceDocuments,
        files: car.carInstanceDocuments,
    };

    return (
        <div className='profileCardContainer'>
            <ProfileCard
                docsTitle='DOCUMENTACIÓN'
                photo={carImg}
                carsProfile
                gallery
                brand={carInformation.carBrand}
                model={carInformation.carModel}
                images={carInformation.images}
                files={carInformation.files}
                docContainerClassName={'carsDocsGeneralContainer'}
            >
                <InputKoopers 
                    mode={'car'} 
                    value={`MARCA: ${carInformation.carBrand}`}
                    // onChange={(e) => setCarInformation({...carInformation, name: e.target.value})}
                />
                <InputKoopers 
                    mode={'car'} 
                    value={`MODELO: ${carInformation.carModel}`}
                    // onChange={(e) => setCarInformation({...carInformation, lastName: e.target.value})}
                />
                <InputKoopers 
                    mode={'configuration'} 
                    value={`CONFIGURACIÓN: ${carInformation.setUpOption}`}
                    // onChange={(e) => setCarInformation({...carInformation, email: e.target.value})}
                />
                <InputKoopers
                    mode={'cost'}
                    value={`COSTO: ${carInformation.carCost}`}
                    // onChange={(e) => setCarInformation({...carInformation, phone: e.target.value})}
                />
                <Button
                    onClick={() => history.goBack()}
                >
                    ACEPTAR
                </Button>
            </ProfileCard>             
        </div>
    )
}

ViewCarCard.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    userRegister: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    user: PropTypes.object,
    addNotification: PropTypes.func,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        lessors: _.get(state, 'lessor.lessors', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    getUserInformationRequest: params => dispatch(getUserInformationRequest(params)),
    updateUserInformationRequest: params => dispatch(updateUserInformationRequest(params)),
    // updateLessor: params => dispatch(updateUserInformationRequest(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(ViewCarCard);
