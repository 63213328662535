import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
	upsertLessorDriverAction,
	getAllLessorDriversAction,
} from '../../../../redux/actions/lessorAction';
import { addNotification } from '../../../../redux/actions/notificationAction';
import InputKoopers from '../../../../components/molecules/InputKoopers';

import Button from '../../../../components/atoms/Button/Button';

const NewRegisterAdmin = (props) => {
	const { mode, lessorId, driverId  } = useParams();
    const history = useHistory();
	const [ formData, setFormData ] = useState({});

	useEffect(() => {
		if (mode === 'editar' &&  _.isEmpty(formData)) {
			props.getAllLessorDriversAction({ where: { lessorId: parseInt(lessorId) }});
		}
	}, [mode]);

	
	useEffect(() => {
		if (mode === 'editar' && !_.isEmpty(props.getAllLessorDrivers.data) && _.isEmpty(formData)) {
			let driver = props.getAllLessorDrivers.data.find(d => d.id == driverId);
			setFormData(_.get(driver, 'person', {}));
		}
	}, [props.getAllLessorDrivers]);

	const updateFormData = (inputName, isFile=false) => (e) => {
		let value = isFile ? e.target.files[0] : e.target.value;
		let formDataCopy = _.clone(formData);
		setFormData({
			...formDataCopy,
			[inputName]: value,
		});
	};

    const onSubmitForm = () => {
		const requestData = {
			lessorId,
			person: {
				"firstName": _.get(formData, 'firstName', ''),
				"secondName": _.get(formData, 'secondName', ''),
				"firstLastName": _.get(formData, 'firstLastName', ''),
				"secondLastName": _.get(formData, 'secondLastName', ''),
				"dob": _.get(formData, 'dob', '2000-01-01'),
				"gender": _.get(formData, 'gender', 'male'),
				"primaryPhone": _.get(formData, 'primaryPhone', ''),
				"primaryEmail": _.get(formData, 'primaryEmail', ''),
				"secondaryEmail": _.get(formData, 'secondaryEmail', ''),
			},
			driver:{
		
			},
			user: {

			},
		};
		if (mode === 'editar') {
			requestData['driverId'] = driverId;
		}
		props.upsertLessorDriverAction(requestData, { isFileUpload: false });
        history.goBack();
    }

	const title = "Holas";
	return (
		<div className='newRegisterContainer'>
			{
				title &&
				<h1 className='registerTitle'>{title}</h1>
			}
		
			<div className='profileGeneralContainer'>
				<div className='profileCard'>
						{/* <input 
							id='inputPhoto' 
							type={'file'} 
							accept='image/*'
							onChange={(e) => handleChangeImage(e)}
							// {...register('photoProfile')}
						/> */}
					<div className='form'>
						{
							/*
							props.carsProfile ?
							<div className='profileNameContainer'>
								<h2>{props.brand}</h2>
								<h3>{props.model}</h3>
							</div>
							:
							<>
								<ProfileImage
									photo={props.photo}
									onChangePhoto={props.onChangePhoto}
									allowOpenSelectFile={!!props.children}
								/>
								<div className='profileNameContainer'>
									<h2>{name}</h2>
								</div>
							</>
							*/
						}
						{
							<div className='formContainer'>
								<InputKoopers 
									mode={'user'}
									placeholder={'Primer nombre'}
									key={'firstName'}
									onChange={updateFormData('firstName')}
									value={formData.firstName}
								/>
								<InputKoopers 
									mode={'user'}
									placeholder={'Segundo nombre'}
									key={'firstName'}
									onChange={updateFormData('secondName')}
									value={formData.secondName}
								/>
								<InputKoopers 
									mode={'user'}
									placeholder={'Primer apellido'}
									key={'firstLastName'}
									onChange={updateFormData('firstLastName')}
									value={formData.firstLastName}
								/>
								<InputKoopers 
									mode={'user'}
									placeholder={'Segundo apellido'}
									key={'firstLastName'}
									onChange={updateFormData('secondLastName')}
									value={formData.secondLastName}
								/>
								<InputKoopers 
									mode={'phone'}
									placeholder={'Teléfono'}
									key={'primaryPhone'}
									onChange={updateFormData('primaryPhone')}
									value={formData.primaryPhone}
								/>
								<InputKoopers 
									mode={'mail'}
									placeholder={'Correo electrónico'}
									key={'3m4il'}
									autocomplete={'new-email'}
									onChange={updateFormData('primaryEmail')}
									value={formData.primaryEmail}
								/>
								<Button onClick={onSubmitForm}>
									GUARDAR
								</Button>
							</div>
						}
					</div>
				</div>
			
			</div>

		</div>
	);
}

NewRegisterAdmin.propTypes = {
    upsertLessorDriverAction: PropTypes.any,
    addNotification: PropTypes.func,
	getAllLessorDrivers: PropTypes.array,
	getAllLessorDriversAction: PropTypes.any,
}

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
		getAllLessorDrivers: _.get(state, 'lessor.getAllLessorDrivers', {}),
    };
}

const mapDispatchToProps = dispatch => ({
	getAllLessorDriversAction: params => dispatch(getAllLessorDriversAction(params)),
    upsertLessorDriverAction: (params, requestParams) => dispatch(upsertLessorDriverAction(params, requestParams)),
    addNotification: params => dispatch(addNotification(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(NewRegisterAdmin);
