import React, { useEffect, useState } from "react";
import "./tabsStyle.scss";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import * as Yup from "yup";
import Modal from "react-modal";
import {
  getAllCiudadesAction,
  getAllContriesAction,
  getAllEstadoAction,
} from "../../../../redux/actions/countryAction";
import { useDispatch, useSelector } from "react-redux";
import { upsertAgenciesAdmin } from "../../../../redux/actions/agenciesAction";

const validationSchema = Yup.object().shape({
  nombre: Yup.string().required("Este campo es obligatorio"),
  telefono: Yup.string().required("Este campo es obligatorio"),
  curp: Yup.string().required("Este campo es obligatorio"),
  estado: Yup.string().required("Este campo es obligatorio"),
  direccion: Yup.string().required("Este campo es obligatorio"),
  apellidos: Yup.string().required("Este campo es obligatorio"),
  correo: Yup.string().required("Este campo es obligatorio"),
  pais: Yup.string().required("Este campo es obligatorio"),
  ciudad: Yup.string().required("Este campo es obligatorio"),
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  diplay: "flex",
  flexDirection: "column",
  textAlign: "center",
  height: "20rem",
};
function RepresentanteLegal({
  type,
  idGeneral,
  tipoPersona,
  representanteLegal,
}) {
  const dispatch = useDispatch();
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [curp, setCurp] = useState("");
  const [state, setState] = useState("");
  const [direccion, setDireccion] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [correo, setCorreo] = useState("");
  const [country, setCountry] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const countriesGlobal = useSelector((state) => state.countries.countries);
  const ciudadesGlobal = useSelector((state) => state.cities.cities);
  const EstadosGlobal = useSelector((state) => state.states.states);
  const [countries, setCountries] = React.useState({});
  const [states, setStates] = React.useState({});
  const [ciudades, setCiudades] = React.useState({});
  const [selectedState, setSelectedState] = useState("");
  useEffect(() => {
    console.log("representanteLegal", representanteLegal);
    if (_.get(representanteLegal, "person.firstLastName")) {
      setApellidos(representanteLegal.person.firstLastName);
    }
    if (_.get(representanteLegal, "person.firstName")) {
      setNombre(representanteLegal.person.firstName);
    }
    if (_.get(representanteLegal, "person.primaryEmail")) {
      setCorreo(representanteLegal.person.primaryEmail);
    }
    if (_.get(representanteLegal, "person.primaryEmail")) {
      setTelefono(representanteLegal.person.primaryPhone);
    }
    if (_.get(representanteLegal, "person.curp")) {
      setCurp(representanteLegal.person.curp);
    }



    if (_.get(representanteLegal, "address")) {
      setCountry(representanteLegal.address.countryId);
      const data = {
        where: {
          countryId: representanteLegal.address.countryId,
        },
      };
      dispatch(getAllEstadoAction(data));
      
      
      setDireccion(representanteLegal.address.street1)
    }
  }, [representanteLegal]);

  useEffect(() => {
    if (_.get(representanteLegal, "address")) {
      setSelectedState(representanteLegal.address.stateId);
      
      const data = {
        where: {
          stateId: representanteLegal.address.stateId,
        },
      };
      dispatch(getAllCiudadesAction(data));
      setCiudad(representanteLegal.address.cityId);
    }
  }, [country]);
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    const data = {
      where: {
        countryId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllEstadoAction(data));
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
    const data = {
      where: {
        stateId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllCiudadesAction(data));
  };

  const handleCiudadChange = (event) => {
    setCiudad(event.target.value);
  };
  const handleGuardarCambios = () => {
    validationSchema
      .validate({
        nombre: nombre,
        telefono: telefono,
        curp: curp,
        estado: selectedState,
        direccion: direccion,
        apellidos: apellidos,
        correo: correo,
        pais: country,
        ciudad: ciudad,
      })
      .then(() => {
        const data = {
          data: {
            id: idGeneral,
            taxData: {
              representativeDetails: {
                person: {
                  firstName:nombre,
                  firstLastName: apellidos,
                  primaryPhone: telefono,
                  primaryEmail: correo,
                  curp:curp
                },
                address: {
                  countryId: country,
                  stateId: selectedState,
                  cityId: ciudad,
                  street1: direccion,
                },
              }

            }
          }
        }
        console.log("Cambios guardados");
        dispatch(upsertAgenciesAdmin(data));

        console.log("Cambios guardados",data);
      })
      .catch((errors) => {
        setValidationErrors(errors);
        setModalOpen(true);
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  React.useEffect(() => {
    dispatch(getAllContriesAction({}));
  }, []);
  React.useEffect(() => {
    if (_.get(countriesGlobal, "data.rows[0]")) {
      setCountries(countriesGlobal.data.rows);
    }
  }, [countriesGlobal]);

  React.useEffect(() => {
    if (_.get(ciudadesGlobal, "data.rows[0]")) {
      setCiudades(ciudadesGlobal.data.rows);
    }
  }, [ciudadesGlobal]);

  React.useEffect(() => {
    if (_.get(EstadosGlobal, "data.rows[0]")) {
      setStates(EstadosGlobal.data.rows);
    }
  }, [EstadosGlobal]);

  return (
    <div className="main-tabs">
      <div className="scrollable-container">
        <div className="main-separete-container">
          <div className="separete-container">
            <TextField
              className="textfield-stepper"
              id="nombre"
              margin="dense"
              name="nombre"
              label="Nombre(s) *"
              variant="filled"
              placeholder="Agregar..."
              value={nombre}
              onChange={(event) => setNombre(event.target.value)}
              error={Boolean(validationErrors.nombre)}
              helperText={validationErrors.nombre}
            />
            <TextField
              className="textfield-stepper"
              id="telefono"
              margin="dense"
              name="telefono"
              label="Teléfono *"
              variant="filled"
              placeholder="Agregar..."
              value={telefono}
              onChange={(event) => setTelefono(event.target.value)}
              error={Boolean(validationErrors.telefono)}
              helperText={validationErrors.telefono}
            />
            <TextField
              className="textfield-stepper"
              id="curp"
              margin="dense"
              name="curp"
              label="CURP *"
              variant="filled"
              placeholder="Agregar..."
              value={curp}
              onChange={(event) => setCurp(event.target.value)}
              error={Boolean(validationErrors.curp)}
              helperText={validationErrors.curp}
            />
            <TextField
              id="estado"
              className="textfield-stepper"
              label="Estado *"
              variant="filled"
              name="estado"
              margin="dense"
              placeholder="Seleccionar"
              select
              value={selectedState}
              onChange={handleStateChange}
              error={Boolean(validationErrors.estado)}
              helperText={validationErrors.estado}
            >
              {states &&
                states.length > 0 &&
                states.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              className="textfield-stepper"
              id="direccion"
              margin="dense"
              name="direccion"
              label="Dirección *"
              variant="filled"
              placeholder="Agregar..."
              value={direccion}
              onChange={(event) => setDireccion(event.target.value)}
              error={Boolean(validationErrors.direccion)}
              helperText={validationErrors.direccion}
            />
          </div>

          <div className="separete-container">
            <TextField
              className="textfield-stepper"
              id="apellidos"
              margin="dense"
              name="apellidos"
              label="Apellido(s) *"
              variant="filled"
              placeholder="Agregar..."
              value={apellidos}
              onChange={(event) => setApellidos(event.target.value)}
              error={Boolean(validationErrors.apellidos)}
              helperText={validationErrors.apellidos}
            />
            <TextField
              className="textfield-stepper"
              id="correo"
              margin="dense"
              name="correo"
              label="Correo *"
              variant="filled"
              placeholder="Agregar..."
              value={correo}
              onChange={(event) => setCorreo(event.target.value)}
              error={Boolean(validationErrors.correo)}
              helperText={validationErrors.correo}
            />
            <TextField
              className="textfield-stepper"
              id="pais"
              name="pais"
              label="País *"
              variant="filled"
              margin="dense"
              placeholder="Seleccionar"
              select
              value={country}
              onChange={handleCountryChange}
              error={Boolean(validationErrors.pais)}
              helperText={validationErrors.pais}
            >
              {countries &&
                countries.length > 0 &&
                countries.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              id="ciudad"
              className="textfield-stepper"
              label="Ciudad/Municipio *"
              name="ciudad"
              margin="dense"
              variant="filled"
              placeholder="Seleccionar"
              select
              value={ciudad}
              onChange={handleCiudadChange}
              error={Boolean(validationErrors.ciudad)}
              helperText={validationErrors.ciudad}
            >
              {ciudades &&
                ciudades.length > 0 &&
                ciudades.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </div>
      </div>
      <div className="container-button">
        <Button className="btn-continuar" onClick={handleGuardarCambios}>
          GUARDAR
        </Button>
      </div>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="container-btns"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <p
              className="text-modal"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              No se han guardado los campos editados
            </p>
            <p
              className="text-modal"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {" "}
              ¿Seguro de querer salir?
            </p>
            <Button
              onClick={closeModal}
              className="btn-salir-sin-guardar"
              style={{
                color: "#3c3c3b",
                opacity: "1",
                textAlign: "center",
                backgroundColor: "#d6c103",
                boxShadow: "0px 3px 12px #3C3C3B33",
                borderRadius: "8px",
                fontSize: "bold",
              }}
            >
              SALIR SIN GUARDAR
            </Button>
            <Button
              onClick={closeModal}
              style={{
                color: "#3c3c3b",
                opacity: "1",
                textAlign: "center",
                backgroundColor: "white",
                boxShadow: "0px 3px 12px #3C3C3B33",
                borderRadius: "8px",
                fontSize: "bold",
                border: "1px solid #d6c103",
              }}
            >
              REGRESAR
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default RepresentanteLegal;
