import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./EditarAgencias.scss";
import EditarFotos from "../../../components/EditarAgenciasComponentes/EditarFoto/EditarFotos";
import TabsEditables from "../../../components/EditarAgenciasComponentes/TabsEditables/TabsEditables";
import btnBack from "../../../assets/images/maranello/Icon_Atras.png";
import _ from "lodash";

function EditarAgencias() {
  const location = useLocation();
  const [dataAgencie, setdataAgencie] = useState()
  const [logoChanged, setLogoChanged] = useState(false);

  const [logo, setLogo] = useState();
  useEffect(() => {
    console.log("selectedData", location);
    if(_.get(location.state,"item")){
      console.log("location.state.item", location.state.item);
      if(_.get(location.state,"item.agencyData.logo")){
        setLogo(location.state.item.agencyData.logo)

      }


      setdataAgencie(location.state.item)
    }
  }, [location]);

  return (
    <div className="container-editar-agencia">
      <div className="header-gestion-agencias">
        <Link to="/dashboard/inicio">
          <img src={btnBack} className="img-back" />
        </Link>
        <h1 className="title-gestion-agencias">
          Gestión de Agencias / Detalle de agencia
        </h1>
      </div>

      <div className="container-info-editable-agencia">
        <div className="container-editar-foto">
          <EditarFotos dataAgencie={dataAgencie} setLogo={setLogo} logoChanged={logoChanged} setLogoChanged={setLogoChanged}/>
        </div>
        <div className="container-tabs-editables">
          <TabsEditables dataAgencie={dataAgencie} logo={logo} logoChanged={logoChanged} setLogoChanged={setLogoChanged}/>
        </div>
      </div>

      {/* <h1>Editar Agencia</h1>
      <p>ID: {selectedData.id}</p>
      <p>Nombre Comercial: {selectedData.nombreComercial}</p>
      <p>Teléfono: {selectedData.telefono}</p> */}
    </div>
  );
}

export default EditarAgencias;
