import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
    LESSEE_MODULE_GET_ALL_LESSEE_BEGIN,
    LESSEE_MODULE_GET_ALL_LESSEE_SUCCESS,
    LESSEE_MODULE_GET_ALL_LESSEE_FAILURE,
    LESSEE_MODULE_UPSERT_LESSEE_BEGIN,
    LESSEE_MODULE_UPSERT_LESSEE_SUCCESS,
    LESSEE_MODULE_UPSERT_LESSEE_FAILURE,
    LESSEE_MODULE_UPDATE_LESSEE_BEGIN,
    LESSEE_MODULE_UPDATE_LESSEE_SUCCESS,
    LESSEE_MODULE_UPDATE_LESSEE_FAILURE,
    LESSEE_MODULE_DELETE_LESSEE_BEGIN,
    LESSEE_MODULE_DELETE_LESSEE_SUCCESS,
    LESSEE_MODULE_DELETE_LESSEE_FAILURE,
} from "../actionTypes";

// ACTIONS TO REDUCER


export const getAllLesseesBegin = () => ({
    type: LESSEE_MODULE_GET_ALL_LESSEE_BEGIN,
});

export const getAllLesseesSuccess = ({totalRecords, data}) => ({
    type: LESSEE_MODULE_GET_ALL_LESSEE_SUCCESS,
    totalRecords,
    data,
});

export const getAllLesseesFailure = (err) => ({
    type: LESSEE_MODULE_GET_ALL_LESSEE_FAILURE,
    err,
});

export const upsertLesseeBegin = () => ({
    type: LESSEE_MODULE_UPSERT_LESSEE_BEGIN,
});

export const upsertLesseeSuccess = ({totalRecords, data}) => ({
    type: LESSEE_MODULE_UPSERT_LESSEE_SUCCESS,
    totalRecords,
    data,
});

export const upsertLesseeFailure = (err) => ({
    type: LESSEE_MODULE_UPSERT_LESSEE_FAILURE,
    err,
});

export const updateLesseeBegin = () => ({
    type: LESSEE_MODULE_UPDATE_LESSEE_BEGIN,
});

export const updateLesseeSuccess = ({totalRecords, data}) => ({
    type: LESSEE_MODULE_UPDATE_LESSEE_SUCCESS,
    totalRecords,
    data,
});

export const updateLesseeFailure = (err) => ({
    type: LESSEE_MODULE_UPDATE_LESSEE_FAILURE,
    err,
});

export const deleteLesseeBegin = () => ({
    type: LESSEE_MODULE_DELETE_LESSEE_BEGIN,
});

export const deleteLesseeSuccess = () => ({
    type: LESSEE_MODULE_DELETE_LESSEE_SUCCESS,
});

export const deleteLesseeFailure = (err) => ({
    type: LESSEE_MODULE_DELETE_LESSEE_FAILURE,
    err,
});

// DISPATCH TO ACTIONS


export function getAllLesseesAction(data){
    return (dispatch, getState) => {
        dispatch(getAllLesseesBegin());
        return request({
            partialUrl: `/api/v1/lessee/get-all-lessees-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getAllLesseesSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            // dispatch(addNotification({
            //     toastType: 'error',
            //     toastTitle: 'Se ha generado un error al obtener el listado, favor de intentarlo de nuevo',
            // }));
            return dispatch(getAllLesseesFailure(err));
        });
    };
}

export function updateLesseeAction(data) {
    return (dispatch, getState) => {
        dispatch(updateLesseeBegin());
        return request({
            partialUrl: `/api/v1/lessee/update-lessee-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
            isFileUpload: true,
            headers: {},
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(updateLesseeSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(updateLesseeFailure(err));
        });
    };
}

export function upsertLesseeAction(data) {
    return (dispatch, getState) => {
        dispatch(upsertLesseeBegin());
        return request({
            partialUrl: `/api/v1/lessee/auth/signup-local-lessee-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
            headers: {},
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertLesseeSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al crear al arrendatario, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertLesseeFailure(err));
        });
    };
}

export function deleteLesseeAction(data) {
    return (dispatch, getState) => {
        dispatch(deleteLesseeBegin());
        return request({
            partialUrl: `/api/v1/lessee/delete-lessee-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
            headers: {},
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(deleteLesseeSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al eliminar al arrendatario, favor de intentarlo de nuevo',
            }));
            return dispatch(deleteLesseeFailure(err));
        });
    };
}
