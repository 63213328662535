import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Phone.scss';

// eslint-disable-next-line react/prop-types
const Phone = ({
	number,
}) => {
    return (
		<a href={`tel:${number}`}>
			<span>{number}</span>
		</a>
    );
}

Phone.protoTypes = {
    props: PropTypes.any,
    number: PropTypes.any,
};

export default Phone;
