import React, { useEffect, useState } from "react";
import { Modal, Backdrop, Fade, Button, Box } from "@mui/material";
import "./ColorModal.scss";
import page from "../../../../assets/images/Grupo.png";
import ImagenPrevia from "../../../../assets/images/maranello/Card_UploadIMG.png";
import ImagenPreviaCarta from "../../../../assets/images/maranello/Card_Amarillo_Archivo.png";
import { upsertAgenciesAdmin } from "../../../../redux/actions/agenciesAction";
import { useDispatch } from "react-redux";

const styleGlobal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

const ColorModal = ({
  open,
  handleClose,
  handleColorChange,
  colorSelected,
  dataAgencie,
  setColorSelected,
}) => {
  const [style, setStyle] = useState("cardChangeContainerYellow");
  const [btn, setBtn] = useState("btnChange");
  const [idGeneral, setIdGeneral] = useState();
  const [tipoPersona, setTipoPersona] = useState();
  const [nombreProveedor, setNombreProveedor] = useState();
  const [descripcionProveedor, setDescripcionProveedor] = useState();
  const [country, setCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [ciudad, setCiudad] = useState();
  const [direccion, setDireccion] = useState();
  const [isStripeSelected, setIsStripeSelected] = useState();
  const [isPayPalSelected, setIsPayPalSelected] = useState();
  const [colorSeleccionado, setColorSeleccionado] = useState();
  const [nombrePersona, setNombrePersona] = useState();

  const dispatch = useDispatch();

  const handledDataSubmit = () => {
    const agencyData = {
      data: {
        id: idGeneral,
        agencyTypeId: tipoPersona,
        agencyData: {
          colorCode: colorSeleccionado,
          businessName: nombreProveedor,
          description: descripcionProveedor,
          personFisicName: nombrePersona,
          address: {
            countryId: country,
            stateId: selectedState,
            cityId: ciudad,
            street1: direccion,
          },
          gatewayPayments: {
            useStripe: isStripeSelected ? 1 : 0,
            usePaypal: isPayPalSelected ? 1 : 0,
          },
        },
      },
    };
    console.log("agencyData", agencyData);
    dispatch(upsertAgenciesAdmin(agencyData));
    setColorSelected(colorSeleccionado);
    handleClose()
  };

  useEffect(() => {
    if (!_.isNil(dataAgencie)) {
      setIdGeneral(dataAgencie.id);
      setTipoPersona(dataAgencie.agencyTypeId);
    }

    if (_.get(dataAgencie, "agencyData")) {
      console.log("dataAgencie", dataAgencie.agencyData);
      setNombreProveedor(dataAgencie.agencyData.businessName);
      setDescripcionProveedor(dataAgencie.agencyData.description);
      setCountry(dataAgencie.agencyData.address.countryId);
      setSelectedState(dataAgencie.agencyData.address.stateId);
      setCiudad(dataAgencie.agencyData.address.cityId);
      setDireccion(dataAgencie.agencyData.address.street1);
      setIsStripeSelected(dataAgencie.agencyData.gatewayPayments.useStripe);
      setIsPayPalSelected(dataAgencie.agencyData.gatewayPayments.usePaypal);
      setNombrePersona(dataAgencie.agencyData.personFisicName)
    }

    console.log("colorSelected", colorSelected);
    if (colorSelected === "#d8d8d8") {
      setStyle("cardChangeContainerGray");
      /*  "#d8d8d8", "gray", */
    } else if (colorSelected === "#fced60") {
      setStyle("cardChangeContainerYellow");
      /*  "#fced60", "yellow", */
    } else if (colorSelected === "#c3002f") {
      setStyle("cardChangeContainer");
      /*  "#c3002f", "red", */
    } else if (colorSelected === "#b381d8") {
      setStyle("cardChangeContainerPurple");
      /*  "#b381d8","purple", */
    } else if (colorSelected === "#81d88b") {
      setStyle("cardChangeContainerGreen");
      /* "#81d88b","green", */
    } else if (colorSelected === "#ffc60a") {
      setStyle("cardChangeContainerOrange");
      /* "#ffc60a","orange" */
    } else if (colorSelected === "#0078c3") {
      setStyle("cardChangeContainerBlue");
      /* "#0078c3","blue" */
    }
  }, [colorSelected]);

  function changeStyle(id) {
    console.warn(id);
    setStyle(id);
    setBtn(id);
  }
  function changeStyle2(id) {
    setBtn(id);
  }
  const handleOnClick = (styleOne, styleTwo, color) => {
    changeStyle(styleOne);
    changeStyle2(styleTwo);
    setColorSeleccionado(color);
    console.log("color", color);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box in={open} sx={styleGlobal}>
        <div className="MiddleContainer">
          <div className={style}>
            <img src={page} className="imgPage" />
          </div>
          <div className="sectionColor">
            <p>Color del contraste</p>
            <div className="colorContainer">
              <Button
                className="btnBlue"
                id="blue"
                onClick={() => {
                  handleOnClick(
                    "cardChangeContainerBlue",
                    "btnChangeBlue",
                    "#0078c3"
                  );
                }}
              />
              <Button
                className="btnOrange"
                id="blue"
                onClick={() => {
                  handleOnClick(
                    "cardChangeContainerOrange",
                    "btnChangeOrange",
                    "#ffc60a"
                  );
                }}
              />
              <Button
                className="btnGreen"
                id="blue"
                onClick={() => {
                  handleOnClick(
                    "cardChangeContainerGreen",
                    "btnChangeGreen",
                    "#81d88b"
                  );
                }}
              />
              <Button
                className="btnPurple"
                id="blue"
                onClick={() => {
                  handleOnClick(
                    "cardChangeContainerPurple",
                    "btnChangePurple",
                    "#b381d8"
                  );
                }}
              />
              <Button
                className="btnRed"
                id="blue"
                onClick={() => {
                  handleOnClick("cardChangeContainer", "btnChange", "#c3002f");
                }}
              />
              <Button
                className="btnYellow"
                id="blue"
                onClick={() => {
                  handleOnClick(
                    "cardChangeContainerYellow",
                    "btnChangeYellow",
                    "#fced60"
                  );
                }}
              />
              <Button
                className="btnGray"
                id="blue"
                onClick={() => {
                  handleOnClick(
                    "cardChangeContainerGray",
                    "btnChangeGray",
                    "#d8d8d8"
                  );
                }}
              />
            </div>
          </div>
          <Button className="btn-guardar-modal" onClick={handledDataSubmit}>
            Guardar
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ColorModal;
