import React from "react";
import { Modal, Typography, Button, Box } from "@mui/material";
import "./EliminarModal.scss";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "20rem",
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
};
function EliminarModal({ open, onClose, rowData, onConfirmDelete }) {
  /*   console.log("rowData", rowData); */

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ textAlign: "center" }}>
          <div className="container-modal">
            <div className="container-head-modal">
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                ¿Seguro que desea eliminar el taller?
              </p>
              <p style={{ fontSize: "15px" }}>
                Al ejecutar esta acción se eliminará la información
                correspondiente al taller.
              </p>
            </div>

            <div className="modal-buttons">
              <Button
                onClick={onConfirmDelete}
                color="error"
                className="btn-eliminar"
              >
                Eliminar
              </Button>
              <Button
                onClick={onClose}
                color="primary"
                className="btn-cancelar"
              >
                Cancelar
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default EliminarModal;
