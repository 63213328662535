import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import notificationReducer from "./notificationReducer";
import userInterfaceReducer from "./userInterfaceReducer";
import userReducer from "./usersReducer";
import companyReducer from "./companyReducer";
import adminReducer from "./adminReducer";
import lessorsReducer from "./lessorsReducer";
import lesseesReducer from "./LesseesReducer";
import carReducer from "./carReducer";
import transactionReducer from "./transactionReducer";
import cardBrandReducer from "./carBrandReducer";
import documentCategory from "./documentCategoryReducer";
import countryReducer from "./countryReducer";
import cityReducer from "./cityReducer";
import stateReducer from "./stateReducer";
import countriesFiscales from "./countryFiscalReducer";
import citiesFiscales from "./cityFiscalesReducer";
import statesFiscales from "./stateFiscalReducer";
import countriesRepresentante from "./countryRepresentanteReducer"
import citiesRepresentante from "./cityRepresentanteReducer"
import statesRepresentante from "./stateRepresentanteReducer"
import agencyReducer from './agencyReducer'
import talleresReducer from './tallerReducer'
import profileReducer from './profileReducer'
const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    notification: notificationReducer,
    userInterface: userInterfaceReducer,
    user: userReducer,
    company: companyReducer,
    admin: adminReducer,
    lessor: lessorsReducer,
    lessee: lesseesReducer,
    car: carReducer,
    transaction: transactionReducer,
    cardBrand: cardBrandReducer,
    documentCategory: documentCategory,
    countries: countryReducer,
    cities: cityReducer,
    states: stateReducer,
    countriesFiscales: countriesFiscales,
    citiesFiscales: citiesFiscales,
    statesFiscales: statesFiscales,
    countriesRepresentante: countriesRepresentante,
    citiesRepresentante: citiesRepresentante,
    statesRepresentante: statesRepresentante,
    agencias: agencyReducer,
    talleres:talleresReducer,
    profile:profileReducer
  });

export default rootReducer;
