import request from "../../utils/request";
import { addNotification } from "./notificationAction";

import {
  ADMIN_MODULE_UPSERT_WORKSHOP_BEGIN,
  ADMIN_MODULE_UPSERT_WORKSHOP_SUCCESS,
  ADMIN_MODULE_UPSERT_WORKSHOP_FAILURE,
  ADMIN_MODULE_GET_ALL_WORKSHOP_BEGIN,
  ADMIN_MODULE_GET_ALL_WORKSHOP_SUCCESS,
  ADMIN_MODULE_GET_ALL_WORKSHOP_FAILURE,
  ADMIN_MODULE_DELETE_WORKSHOP_BEGIN,
  ADMIN_MODULE_DELETE_WORKSHOP_SUCCESS,
  ADMIN_MODULE_DELETE_WORKSHOP_FAILURE,
  ADMIN_MODULE_EDIT_WORKSHOP_BEGIN,
  ADMIN_MODULE_EDIT_WORKSHOP_SUCCESS,
  ADMIN_MODULE_EDIT_WORKSHOP_FAILURE,
} from "./../actionTypes";

export const upsertWorkShopsBegin = () => ({
  type: ADMIN_MODULE_UPSERT_WORKSHOP_BEGIN,
});
export const upsertWorkShopsSuccess = (data) => ({
  type: ADMIN_MODULE_UPSERT_WORKSHOP_SUCCESS,
  data,
});
export const upsertWorkShopsFailure = (err) => ({
  type: ADMIN_MODULE_UPSERT_WORKSHOP_FAILURE,
  err,
});

export const editWorkShopsBegin = () => ({
  type: ADMIN_MODULE_EDIT_WORKSHOP_BEGIN,
});
export const editWorkShopsSuccess = (data) => ({
  type: ADMIN_MODULE_EDIT_WORKSHOP_SUCCESS,
  data,
});
export const editWorkShopsFailure = (err) => ({
  type: ADMIN_MODULE_EDIT_WORKSHOP_FAILURE,
  err,
});

export const getAllWorkShopsBegin = () => ({
  type: ADMIN_MODULE_GET_ALL_WORKSHOP_BEGIN,
});
export const getAllWorkShopsSuccess = (data) => ({
  type: ADMIN_MODULE_GET_ALL_WORKSHOP_SUCCESS,
  data,
});
export const getAllWorkShopsFailure = (err) => ({
  type: ADMIN_MODULE_GET_ALL_WORKSHOP_FAILURE,
  err,
});

export const deleteWorkShopsBegin = () => ({
  type: ADMIN_MODULE_DELETE_WORKSHOP_BEGIN,
});
export const deleteWorkShopsSuccess = (data) => ({
  type: ADMIN_MODULE_DELETE_WORKSHOP_SUCCESS,
  data,
});
export const deleteWorkShopsFailure = (err) => ({
  type: ADMIN_MODULE_DELETE_WORKSHOP_FAILURE,
  err,
});

export function upsertWorkshopsAdmin(data = {}) {
  /* debugger */
  return (dispatch, getState) => {
    dispatch(upsertWorkShopsBegin());
    return request({
      partialUrl: `/workshop/insert-workshop`,
      method: "POST",
      state: getState().user.request,
      body: { ...data },
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(upsertWorkShopsSuccess(data));
        dispatch(
          addNotification({
            toastType: "success",
            toastTitle: "Registro realizado con exito",
          })
        );
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
      });
  };
}

export function editWorkshopsAdmin(data = {}) {
  return (dispatch, getState) => {
    dispatch(editWorkShopsBegin());
    return request({
      partialUrl: `/workshop/update-workshop`,
      method: "POST",
      state: getState().user.request,
      body: { ...data },
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(editWorkShopsSuccess(data));
        dispatch(
          addNotification({
            toastType: "success",
            toastTitle: "Taller editado con exito",
          })
        );
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
      });
  };
}




export function getAllWorkshopsAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getAllWorkShopsBegin());
    return request({
      partialUrl: `/workshop/getAll-workshop-action`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getAllWorkShopsSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getAllWorkShopsFailure(err));
      });
  };
}

export function deleteWorkshopsAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(deleteWorkShopsBegin());
    console.log("Dispatch");
    return request({
      partialUrl: `/workshop/delete-workshop-action`,
      method: "POST",
      state: getState().user.request,
      body: { ...data },
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(deleteWorkShopsSuccess(data));
        dispatch(
          addNotification({
            toastType: "success",
            toastTitle: "Eliminado con exito",
          })
        );
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
