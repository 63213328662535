/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Button from "../../../components/atoms/Button/Button";
import GlobalFilter from "../../../components/atoms/GlobalFilter/GlobalFilter";
import Searcher from "../../../components/atoms/Searcher/Searcher";
import DataTable from "../../../components/atoms/DataTable/DataTable";
import ActionMenu from "../../../components/atoms/ActionMenu/ActionMenu";
import CustomModal from "../../../components/organisms/Modal/Modal";
import DeleteModal from "../../../components/organisms/Modal/DeleteModal";
import {
  LESSEE_REGISTER,
  LESSEE_VIEW_REGISTER,
  TRANSACTIONS_MANAGEMENT,
} from "../../../routes/Paths";
import {
  getAllLesseesAction,
  deleteLesseeAction,
} from "../../../redux/actions/lesseeActions";

import { updatePasswordRequestAction } from "../../../redux/actions/userActions";

// src & styles
import "./LesseeManagement.scss";
import photo from "../../../assets/images/koopers/profilePhoto.png";
import add from "../../../assets/images/koopers/add.png";

const LesseeManagement = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [searchCondition, setSearchCondition] = useState({});
  const [itemSelected, setItemSelected] = useState(null);
  const [searchObj, setSearchObj] = useState({});

  const dispatch = useDispatch();
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const history = useHistory();

  const reloadInfo = () => {
    dispatch(getAllLesseesAction(searchCondition));
  };

  // useEffect(()=>{
  //     props.getAllLesseesAction(searchCondition);
  // },[/* props.isSuccess, */ searchCondition]);

  const handleDelete = (item) => {
    const dataRequest = {
      userId: item,
    };
    props.deleteLesseeAction(dataRequest);
    toggleModal();
    reloadInfo();
  };

  const actionMenuItems = [
    {
      displayText: "Editar registro",
      keyName: "EDITAR_REGISTRO",
      icon: "edit",
    },
    {
      displayText: "Recuperar contraseña",
      keyName: "RECUPERAR_CONTRASEÑA",
      icon: null,
    },
    {
      displayText: "Eliminar registro",
      keyName: "ELIMINAR_REGISTRO",
      icon: "trash",
    },
    {
      displayText: "Ver transacciones",
      keyName: "VER_TRANSACCIONES",
      icon: "eye",
    },
  ];

  const LesseesInfo = React.useMemo(() => [
    {
      id: "id",
      Header: "ID",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "20%",
      accessor: (d) => ({
        id: _.get(d, "id", ""),
      }),
      Cell: function cell({ value: { id }, row }) {
        return <span>{`${id}`}</span>;
      },
    },
    {
      id: "name",
      Header: "Nombre(s)",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "20%",
      accessor: (d) => ({
        firstName: _.get(d, "person.firstName", ""),
      }),
      Cell: function cell({ value: { firstName }, row }) {
        return (
          <span>
            {!_.isEmpty(firstName) &&
              `${firstName[0].toUpperCase()}${firstName.substring(1)}`}
          </span>
        );
      },
    },
    {
      id: "lastname",
      Header: "Apellido(s)",
      disableSortBy: true,
      disableFilters: true,
      width: "20%",
      accessor: (d) => ({
        firstLastName: _.get(d, "person.firstLastName", ""),
      }),
      Cell: function cell({ value: { firstLastName: firstLastName }, row }) {
        return (
          <span>
            {!_.isEmpty(firstLastName) &&
              `${firstLastName[0].toUpperCase()}${firstLastName.substring(1)}`}
          </span>
        );
      },
    },
    {
      id: "email",
      Header: "Correo",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "25%",
      accessor: (d) => ({
        primaryEmail: _.get(d, "email", _.get(d, "person.primaryEmail", "")),
      }),
      Cell: function cell({ value: { primaryEmail }, row }) {
        return <span>{`${primaryEmail}`}</span>;
      },
    },
    {
      id: "phoneNumber",
      Header: "Teléfono",
      disableSortBy: true,
      disableFilters: true,
      filter: "text",
      width: "20%",
      accessor: (d) => ({
        primaryPhone: _.get(d, "person.primaryPhone", ""),
      }),
      Cell: function cell({ value: { primaryPhone }, row }) {
        return <span>{`${primaryPhone}`}</span>;
      },
    },
    {
      id: "options.name",
      Header: "",
      filter: "text",
      disableFilters: true,
      width: "3%",
      accessor: (d) => ({
        item: d,
      }),
      Cell: function cell({ value: { item }, row }) {
        return (
          <ActionMenu
            actionMenuItems={actionMenuItems}
            client={true}
            callback={(selected) => {
              if (selected === "EDITAR_REGISTRO") {
                history.push(
                  LESSEE_VIEW_REGISTER.replace(":lesseeId", item.id)
                );
              } else if (selected === "VER_TRANSACCIONES") {
                history.push(
                  TRANSACTIONS_MANAGEMENT.replace(
                    ":mode",
                    "arrendatario"
                  ).replace(":id", item.id)
                );
              } else if (selected === "ELIMINAR_REGISTRO") {
                toggleModal();
                setItemSelected(item.user.id);
              } else if (selected === "RECUPERAR_CONTRASEÑA") {
                props.updatePasswordRequestAction({
                  email: _.get(item, "person.primaryEmail", ""),
                });
              }
            }}
          />
        );
      },
    },
  ]);

  const getNewData = (condition) => {
    setSearchCondition(condition);
    dispatch(getAllLesseesAction(searchCondition));
  };

  return (
    <div className="LessorManagementGeneralContainer">
      <div className="LessorManagementHeaderContainer">
        <div className="titleContainer">
          <h1>GESTIÓN DE ARRENDATARIOS</h1>
        </div>
        <div className="searcherContainer">
          {/* <Searcher placeholderSearch={'Busca nombre o correo arrendatario'} /> */}
          {/* <GlobalFilter 
                        placeholderSearch={'Busca nombre o correo arrendatario'}
                        filter={searchObj}
                        setFilter={setSearchObj}
                    /> */}
        </div>
      </div>
      <div className="buttonContainer">
        <Button srcicon={add} onClick={() => history.push(LESSEE_REGISTER)}>
          NUEVO
        </Button>
      </div>
      <div className="tableContainer">
        <DataTable
          columns={LesseesInfo}
          data={{
            // ...props.lessees,
            data: _.get(props.lessees, "data", []),
            totalRecords: _.get(props.lessees, "totalRecords", 0),
          }}
          getData={getNewData}
          searchObj={searchObj}
        />
      </div>
      {openModal && (
        <CustomModal isOpen={openModal} toggle={toggleModal}>
          <DeleteModal
            OnClickCancel={toggleModal}
            onClickAgree={() => handleDelete(itemSelected)}
            reloadInfo={reloadInfo}
            toggle={toggleModal}
          />
        </CustomModal>
      )}
    </div>
  );
};

LesseeManagement.propTypes = {
  isAuthenticated: PropTypes.any,
  history: PropTypes.any,
  role: PropTypes.any,
  dispatch: PropTypes.any,
  getAllLesseesAction: PropTypes.any,
  deleteLesseeAction: PropTypes.any,
  lessees: PropTypes.any,
  value: PropTypes.any,
  row: PropTypes.any,
  data: PropTypes.any,
  totalRecords: PropTypes.any,
  isSuccess: PropTypes.any,
  updatePasswordRequestAction: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.isAuthenticated,
    role: state.user.role,
    error: state.user.error,
    data: _.get(state, "data.data", {}),
    lessees: _.get(state, "lessee.lessees", {}),
    isSuccess: _.get(state, "lessee.lesseeRecord.isSuccess", {}),
  };
}

const mapDispatchToProps = (dispatch) => ({
  getAllLesseesAction: (params) => dispatch(getAllLesseesAction(params)),
  deleteLesseeAction: (params) => dispatch(deleteLesseeAction(params)),
  updatePasswordRequestAction: (params) =>
    dispatch(updatePasswordRequestAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LesseeManagement);
