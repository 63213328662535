/* eslint-disable no-unused-vars */
import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { PrivateRoute, PublicRoute } from "../../../utils/routeAccessHelper";

// STYLES
import "./BaseLayout.scss";

// BASE LAYOUT
import Footer from "./Footer/Footer";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import MainContainer from "./MainContainer/MainContainer";

// PATHS
import {
  HOME,
  PROFILE,
  ADMINS_MANAGEMENT,
  ADMIN_REGISTER,
  ADMIN_VIEW_REGISTER,
  LESSOR_MANAGEMENT,
  LESSOR_MANAGEMENT_REGISTER,
  LESSOR_VIEW_REGISTER,
  LESSEE_MANAGEMENT,
  LESSEE_VIEW_REGISTER,
  LESSEE_REGISTER,
  CAR_MANAGEMENT,
  CAR_VIEW_REGISTER,
  CAR_REGISTER,
  INTERN_MANAGEMENT,
  COLLECTION_MANAGEMENT,
  TRANSACTIONS_MANAGEMENT,
  VIEW_TRANSACTION,
  CAR_BRAND_MANAGEMENT,
  CAR_BRAND_FORM,
  CAR_BRAND_MODEL_MANAGEMENT,
  CAR_BRAND_VIEW_MODELS_FORM,
  CAR_VIEW_DOCUMENTS,
  CAR_VIEW_CREATE_DOCUMENT,
  CAR_COMMENTS_MANAGEMENT,
  LESSOR_DRIVERS_MANAGEMENT,
  LESSOR_DRIVERS_FORM,
  AGENCIES_MANAGEMENT,
  AGENCIES_ADD,
  AGENCIES_REGISTRY,
  EDIT_AGENCIES,
  TALLER_ADD,
  EDIT_TALLER,
  TALER_REGISTRY,
  MI_PERFIL_MARANELLO,
} from "../../../routes/Paths";

// COMPONENTS
import {
  home,
  profile,
  admins,
  lessors,
  lessees,
  cars,
  inters,
  collection,
  transactions,
  carBrand,
  adminMaranello,
} from "../../../routes/Components";

// NOT FOUND
import NotFoundPage from "../NotFound/NotFoundPage";

const BaseLayout = (props) => {
  return (
    <>
      <Header />
      <Sidebar />
      <MainContainer>
        <Switch>
          <Route
            exact
            path="/dashboard"
            render={() => <Redirect to={HOME} />}
          />
          <PrivateRoute
            exact
            path={HOME}
            component={home.Home}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={PROFILE}
            component={profile.Profile}
            isAllowed={props.isAuthenticated}
          />
          {/* ADMIN MODULE */}
          <PrivateRoute
            exact
            path={ADMINS_MANAGEMENT}
            component={admins.AdminsManagement}
            isAllowed={props.isAuthenticated}
          />

          <PrivateRoute
            exact
            path={MI_PERFIL_MARANELLO}
            component={adminMaranello.miPerfilMaranello}
            isAllowed={props.isAuthenticated}
          />

          <PrivateRoute
            exact
            path={AGENCIES_MANAGEMENT}
            component={adminMaranello.ManagmentAgencies}
            isAllowed={props.isAuthenticated}
          />

          <PrivateRoute
            exact
            path={AGENCIES_ADD}
            component={adminMaranello.AddAgencies}
            isAllowed={props.isAuthenticated}
          />

          <PrivateRoute
            exact
            path={AGENCIES_REGISTRY}
            component={adminMaranello.StepperAgencies}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={TALLER_ADD}
            component={adminMaranello.AddWorkshop}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={TALER_REGISTRY}
            component={adminMaranello.StepperWorkshop}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={EDIT_AGENCIES}
            component={adminMaranello.EditarAgencia}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={EDIT_TALLER}
            component={adminMaranello.EditarTaller}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ADMIN_REGISTER}
            component={admins.NewRegisterAdmin}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={ADMIN_VIEW_REGISTER}
            component={admins.ViewAdminProfile}
            isAllowed={props.isAuthenticated}
          />
          {/* LESSOR MODULE */}
          <PrivateRoute
            exact
            path={LESSOR_MANAGEMENT}
            component={lessors.LessorManagementHome}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={LESSOR_VIEW_REGISTER}
            component={lessors.ViewLessorProfile}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={LESSOR_DRIVERS_MANAGEMENT}
            component={lessors.LessorDriversManagement}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={LESSOR_DRIVERS_FORM}
            component={lessors.LessorDriversForm}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={LESSOR_MANAGEMENT_REGISTER}
            component={lessors.NewRegisterLessor}
            isAllowed={props.isAuthenticated}
          />
          {/* LESSEE MODULE */}
          <PrivateRoute
            exact
            path={LESSEE_MANAGEMENT}
            component={lessees.LesseeManagement}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={LESSEE_VIEW_REGISTER}
            component={lessees.ViewLesseeProfile}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={LESSEE_REGISTER}
            component={lessees.NewRegisterLessee}
            isAllowed={props.isAuthenticated}
          />
          {/* INTERN MODULE */}
          <PrivateRoute
            exact
            path={INTERN_MANAGEMENT}
            component={inters.InternManagement}
            isAllowed={props.isAuthenticated}
          />
          {/* CAR MODULE */}
          <PrivateRoute
            exact
            path={CAR_MANAGEMENT}
            component={cars.CarsManagement}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={CAR_VIEW_REGISTER}
            component={cars.ViewCardCar}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={CAR_REGISTER}
            component={cars.newRegisterCar}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={CAR_VIEW_DOCUMENTS}
            component={cars.CarsDocumentManagement}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={CAR_VIEW_CREATE_DOCUMENT}
            component={cars.CarsDocumentForm}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={CAR_COMMENTS_MANAGEMENT}
            component={cars.CarsCommentManagement}
            isAllowed={props.isAuthenticated}
          />
          {/* CAR BRAND MODULE */}
          <PrivateRoute
            exact
            path={CAR_BRAND_MANAGEMENT}
            component={carBrand.CarBrandManagement}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={CAR_BRAND_FORM}
            component={carBrand.CarBrandFrom}
            isAllowed={props.isAuthenticated}
          />
          {/* CAR MODELS MODULE */}
          <PrivateRoute
            exact
            path={CAR_BRAND_MODEL_MANAGEMENT}
            component={carBrand.carBrandModel.CarBrandModelManagement}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={CAR_BRAND_VIEW_MODELS_FORM}
            component={carBrand.carBrandModel.CarBrandModelForm}
            isAllowed={props.isAuthenticated}
          />
          {/*
                    <PrivateRoute
                        exact
                        path={CAR_BRAND_REGISTER}
                        component={carBrand.newRegisterCarBrand}
                        isAllowed={props.isAuthenticated}
                    />
*/}
          {/* TRANSACTION MODULE */}
          <PrivateRoute
            exact
            path={VIEW_TRANSACTION}
            component={transactions.ViewTransactionDetails}
            isAllowed={props.isAuthenticated}
          />
          <PrivateRoute
            exact
            path={TRANSACTIONS_MANAGEMENT}
            component={transactions.TransactionsManagement}
            isAllowed={props.isAuthenticated}
          />
          {/* COLLECTION MODULE */}
          <PrivateRoute
            exact
            path={COLLECTION_MANAGEMENT}
            component={collection.CollectionManagement}
            isAllowed={props.isAuthenticated}
          />
          <PublicRoute component={NotFoundPage} />
        </Switch>
      </MainContainer>
      <Footer />
    </>
  );
};

BaseLayout.propTypes = {
  classNameMain: PropTypes.string,
  isSidebarOpened: PropTypes.any,
  isAuthenticated: PropTypes.any,
  role: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isSidebarOpened: _.get(state.userInterface, "isSidebarOpened", false),
    isAuthenticated: state.user.isAuthenticated || false,
    role:
      _.get(state.user, "user.roles[0]", false) === "administrator"
        ? true
        : false,
  };
};

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseLayout)
);
