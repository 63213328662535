import React from "react";
import "./TypePersonWorkshop.scss";
import btnBack from "../../../assets/images/maranello/Icon_Atras.png";
import { Link, useHistory } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Button } from "@material-ui/core";

function TypePersonWorkshop() {
  const [typeAgencie, setTypeAgencie] = React.useState("");
  const history = useHistory();

  const handleChange = (event) => {
    setTypeAgencie(event.target.value);
  };

  const handleNext = () => {
    if (typeAgencie) {
      history.push({
        pathname: "/dashboard/nuevo-taller-registro",
        state: { tipoPersona: typeAgencie },
      });
    }
  };

  const isNextButtonDisabled = !typeAgencie; // Deshabilitar el botón si no se ha seleccionado el tipo de persona

  return (
    <div className="main-container-nueva-agencia">
      <div className="header-gestion-agencias">
        <Link to="/dashboard/gestion-agencias">
          <img src={btnBack} className="img-back" />
        </Link>
        <h1 className="title-gestion-agencias">
        Gestión de Talleres / Nueva Taller
        </h1>
      </div>

      <div className="main-container-select-tipo-agencia">
        <div className="contenedor-select-center">
          <div className="header-tipo-agencia-select">
            <p className="title-select-agencia">Tipo de Taller</p>
          </div>
          <div className="container-select-tipo-agencia">
            <FormControl variant="standard" className="select-tipo">
              <InputLabel id="demo-simple-select-label">
                Tipo de Taller:
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={typeAgencie}
                label="Tipo de Taller"
                onChange={handleChange}
              >
                <MenuItem value={"1"}>Persona Física</MenuItem>
                <MenuItem value={"2"}>Persona Moral</MenuItem>
              </Select>
            </FormControl>

            <Button
              className="btn-siguiente-tipo-agencia"
              onClick={handleNext}
              disabled={isNextButtonDisabled}
            >
              SIGUIENTE
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TypePersonWorkshop;
