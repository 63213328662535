import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// src & styles
import './InputKoopers.scss';
import userImg from '../../assets/images/koopers/inputUser.png';
import atImg from '../../assets/images/koopers/icon-at.png';
import keyImg from '../../assets/images/koopers/key.png';
import phoneImg from '../../assets/images/koopers/phone.png';
import car from '../../assets/images/koopers/car.png';
import money from '../../assets/images/koopers/money.png';
import personIcon from '../../assets/images/koopers/user-icon.png';
import config from '../../assets/images/koopers/services.png';

const UploadFileKoopers = ({
    autocomplete,
    mode,
    type = 'text',
    value,
    onChange,
    placeholder,
    id,
    name,
    readOnly = false,
}) => {

	const [ currentDocument, setCurrentDocument ] = useState();

    let img = '';

    if (mode == 'user') img = userImg;
    else if (mode == 'mail') {
        img = atImg;
        type = 'email';
    }
    else if (mode == 'password') {
        img = keyImg;
        type = 'password';
    }
    else if (mode == 'phone') {
        img = phoneImg;
    }
    else if (mode == 'car') {
        img = car;
        readOnly = true;
    }
    else if (mode == 'cost') {
        img = money;
        readOnly = true;
    }
    else if (mode == 'person') {
        img = personIcon;
        readOnly = true;
    }
    else if (mode == 'configuration') {
        img = config;
        readOnly = true;
    } else if (mode == 'carBrand') {
        img = car;
        readOnly = false;
    }

	const onClickInputPhoto = () => {
		const inputPhoto = document.getElementById('inputPhoto');
		inputPhoto.click();
    }

	const handleChangeImage = (event) => {
        //setCurrentDocument(URL.createObjectURL(event.target.files[0]))
		setCurrentDocument(event.target.files[0]);
		onChange(event);
        // dispatch(changeSidebarLogoRequest(image));
        //onChangePhoto(event);
		console.log(event.target.files[0]);
    }



	return (
		<>
			<input 
				id='inputPhoto' 
				type={'file'} 
				accept='image/*'
				onChange={(e) => handleChangeImage(e)}
				// {...register('photoProfile')}
            />
			<input 
				onClick={onClickInputPhoto}
				className='inputDefault'
				style={{ backgroundImage: `url(${img})`}}
				type={type}
				value={_.get(currentDocument, 'name', '')}
				placeholder={placeholder}
				onChange={onChange}
				readOnly={readOnly}
				id={id}
				autoComplete={autocomplete}
				name={name}
			/>
		</>
	);


	/*
    return (
        <input
            className='inputDefault'
            style={{ backgroundImage: `url(${img})`}}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            readOnly={readOnly}
            id={id}
            autoComplete={autocomplete}
            name={name}
        />
    ),
	*/
}

UploadFileKoopers.propTypes = {
    mode: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string,
    autocomplete: PropTypes.any,
    name: PropTypes.string,
    readOnly: PropTypes.bool,
}

export default UploadFileKoopers;
