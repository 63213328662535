import React, { useEffect, useState } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  IconButton,
  TextField,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./ModalEditar.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileAction,
  updateDocumentationProfileAction,
} from "../../../redux/actions/profileAction";
import { url_api } from "../../../config";

const ModalComponent = ({ open, handleClose }) => {
  const userLoggeado = useSelector((state) => state.user.user);
  const profileLogge = useSelector((state) => state.profile.profile);
  const [discount, setDiscount] = useState("18%");
  const [files, setFiles] = useState(Array(2).fill(null));
  const [base64Files, setBase64Files] = useState(Array(2).fill(null));
  const [filesIds, setFilesIds] = useState(Array(2).fill(null));
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [idGeneral, setIdGeneral] = useState("");
  const dispatch = useDispatch();
  const [addedDocuments, setAddedDocuments] = useState([]);
  const [editedDiscount, setEditedDiscount] = useState(null);
  const allowedDocumentsForPerson = [
    "Términos & Condiciones",
    "Aviso de privacidad",
  ];
  const titles = ["Términos & Condiciones", "Aviso de privacidad"];

  const handleFileChange = async (event, index) => {
    console.log("selecciona archivo documentacion");
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const updatedFiles = [...files];
      const updatedBase64Files = [...base64Files];
      const base64Data = await convertBase64(selectedFile);
      updatedBase64Files[index] = base64Data;
      updatedFiles[index] = URL.createObjectURL(selectedFile);
      setBase64Files(updatedBase64Files);
      setFiles(updatedFiles);
      setSelectedIndex(-1);
    }


  };

 

  const handleDragOver = (event, index) => {
    event.preventDefault();
  };

  const handleDragLeave = (event, index) => {
    event.preventDefault();
  };

  const handleFileRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles[index] = null;
    setFiles(updatedFiles);
  };

  const handleViewDocument = (index) => {
    const selectedFile = files[index];
    if (selectedFile) {
      window.open(URL.createObjectURL(selectedFile), "_blank");
    }
  };

  const handleClickMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedIndex(-1);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        return resolve({
          file: fileReader.result,
          title: file.name,
        });
      };
      fileReader.onerror = (error) => reject(error);
    });
  };

  const handleFileInputClick = (index) => {
    const fileInput = document.querySelector(`#fileInput${index}`);
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleSave = () => {
    console.log("Documentos agregados:", addedDocuments);
    console.log("Porcentaje editado:", discount || "Sin cambios");
    const filesToSend = [
      files[0] === null && filesIds[0] === null
        ? null
        : files[0] === null && filesIds[0] !== null
        ? { privaceNotice: { fileId: filesIds[0] } }
        : base64Files[0] !== null
        ? { privaceNotice: { ...base64Files[0], fileId: filesIds[0] } }
        : null,

      files[1] === null && filesIds[1] === null
        ? null
        : files[1] === null && filesIds[1] !== null
        ? { termsConditions: { fileId: filesIds[1] } }
        : base64Files[1] !== null
        ? { termsConditions: { ...base64Files[1], fileId: filesIds[1] } }
        : null,

      
    ].filter(Boolean);




    const data = {
      data: {
        superAdminId: idGeneral,
        discountPercentage: discount,
        files: filesToSend,
      },
    };

    dispatch(updateDocumentationProfileAction(data));
    console.log("data", data);
  };

  useEffect(() => {
    if (_.get(userLoggeado, "person")) {
      setIdGeneral(userLoggeado.id);
      const data = {
        userId: userLoggeado.id,
      };
      dispatch(getProfileAction(data));
    }
  }, [userLoggeado]);

  useEffect(() => {
    if (_.get(profileLogge, "data")) {
      console.log("Modal editar", profileLogge.data);
      if (!_.isNil(profileLogge.data.config)) {
        console.log("Modal editar", profileLogge.data.config);
        if (_.get(profileLogge, "data.config.privacyNotice")) {
          console.log(
            "Modal editar",
            profileLogge.data.config.privacyNotice.fileName
          );
          files.splice(
            1,
            1,
            `${url_api}${profileLogge.data.config.privacyNotice.fileName}.pdf`
          );
          filesIds.splice(1, 1, profileLogge.data.config.privacyNotice.id);
        }
        if (_.get(profileLogge, "data.config.termsConditions")) {
          console.log(
            "Modal editar",
            profileLogge.data.config.termsConditions.fileName
          );
          files.splice(
            0,
            1,
            `${url_api}${profileLogge.data.config.termsConditions.fileName}.pdf`
          );
          filesIds.splice(0, 1, profileLogge.data.config.termsConditions.id);
        }
      }
    }
  }, [profileLogge]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modal-content-contraseña">
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <div className="container-nueva-contrasena">
            <p
              className="title-cambiar-contrasena-2"
              style={{ fontFamily: "'Pathway Gothic One', sans-serif" }}
            >
              Configuración
            </p>

            <p
              style={{
                fontFamily: "'Pathway Gothic One', sans-serif",
                fontWeight: "bold",
                fontSize: "25px",
                marginBottom: "1rem",
              }}
            >
              Valuación
            </p>
            <p>
              Como administrador en Maranello puedes modificar el % de descuento
              en el precio promedio de compra de un vehículo. (Por default es de
              18%):
            </p>

            <TextField
              id="filled-basic"
              label="% de descuento:"
              variant="filled"
              value={discount}
              InputProps={{
                style: { backgroundColor: "white", marginTop: "1rem" },
              }}
              onChange={(event) => setDiscount(event.target.value)}
            />

            <div>
              <div className="document-container">
                {titles.map((title, index) => (
                  <React.Fragment key={index}>
                    {allowedDocumentsForPerson.includes(title) && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "85%",
                        }}
                      >
                        <div style={{ height: "3rem" }}>
                          <p
                            className="document-title"
                            style={{
                              fontFamily: "'Pathway Gothic One', sans-serif",
                              fontSize: "18px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            {title}
                          </p>
                        </div>
                        <div
                          className="document-item"
                          onDragOver={(event) => handleDragOver(event, index)}
                          onDragLeave={(event) => handleDragLeave(event, index)}
                        >
                          {!files[index] && (
                            <div className="upload-container">
                              <button
                                className="upload-placeholder"
                                onClick={() => handleFileInputClick(index)}
                              >
                                <div className="container-subir-archivo">
                                  <p className="text-subir-archivo">
                                    Subir archivo
                                  </p>
                                  <p className="tipo-archivo">
                                    El archivo debe ser PDF, JPG, PNG
                                  </p>
                                </div>
                                <div className="container-deje-aqui">
                                  <p className="deje-arhcivo">
                                    Deje su archivo aquí
                                  </p>
                                </div>
                              </button>
                              <input
                                style={{ visibility: "hidden" }}
                                id={`fileInput${index}`}
                                type="file"
                                accept=".pdf, .doc, .docx"
                                className="input-file"
                                onChange={(event) =>
                                  handleFileChange(event, index)
                                }
                                onClick={(event) => (event.target.value = null)}
                              />
                            </div>
                          )}

                          {files[index] && (
                            <div className="container-file">
                              <embed
                                src={
                                  /* URL.createObjectURL( */ files[
                                    index
                                  ] /* ) */
                                }
                                type="application/pdf"
                                width="100%"
                                height="80%"
                                style={{
                                  zIndex: 0,
                                  borderColor: "none",
                                  borderRadius: "15px 15px 0 0",
                                }}
                              />
                              <div
                                className="sub-container"
                                style={{
                                  display: "flex",
                                  flexDirection: "row-reverse",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className="menu-container"
                                  style={{ display: "flex", width: "20%" }}
                                >
                                  <MoreVertIcon
                                    onClick={(event) =>
                                      handleClickMenu(event, index)
                                    }
                                  />
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={selectedIndex === index}
                                    onClose={handleCloseMenu}
                                  >
                                    <MenuItem
                                      onClick={() => handleViewDocument(index)}
                                    >
                                      Ver
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => handleFileRemove(index)}
                                    >
                                      Eliminar
                                    </MenuItem>
                                  </Menu>
                                </div>
                                <div
                                  className="file-info"
                                  style={{ display: "flex", width: "80%" }}
                                >
                                  <p
                                    style={{
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {files[index].name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>

            <Button
              className="btn-guardar"
              variant="contained"
              color="primary"
              sx={{ marginTop: 10 }}
              onClick={handleSave}
              style={{ fontFamily: "'Pathway Gothic One', sans-serif" }}
            >
              GUARDAR
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalComponent;
