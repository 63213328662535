import React from 'react';
import PropTypes from 'prop-types';

const Link = (props) => {
    
    return (
        
        props && props.hrefLink &&
        <a
            {...props}
            className={props.classNameUrl}
            href={props.hrefLink}
            target={props.target}
        >
            {props.children}
        </a>

    );
}

Link.propTypes = {
    hrefLink: PropTypes.string,
    classNameUrl: PropTypes.string,
    children: PropTypes.any,
    target: PropTypes.string,
};

export default Link;