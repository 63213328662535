export default {
    global: {
        accept: 'accept',
        cancel: 'cancel',
    },
    components: {
        atoms: {

        },
        molecules: {

        },
        organisms: {

        },
        templates: {

        },
    },
    authModule: {
        login: {
            title: "Sign in to platform",
            loginButtonText: "Sign in",
        },
    },
};
