import React from 'react';
import RegisterTemplate from '../../../components/templates/Register/RegisterTemplate';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { upsertAdministratorAction } from '../../../redux/actions/adminAction';
import { addNotification } from '../../../redux/actions/notificationAction';
import { useHistory } from 'react-router-dom';

const NewRegisterAdmin = (props) => {

    const history = useHistory();
    const onSubmitCreateAdmin = (formData) => {
        const {
            firstName,
            firstLastName,
            primaryEmail,
            primaryPhone,
            password,
            // passwordConfirmation,
        } = formData;
        if(!_.isEmpty(firstName) && !_.isEmpty(firstLastName) && !_.isEmpty(primaryEmail) && !_.isEmpty(password) && !_.isEmpty(primaryPhone) /* && password === passwordConfirmation  */) {
            const requestData = {
                person: {
                    firstName,
                    firstLastName,
                    primaryPhone,
                    primaryEmail,
                },
                user: {
                    email: primaryEmail,
                    password, 
                },
            };
            props.upsertAdministratorAction(requestData);
            history.goBack();
        }
    }

    return (
        <RegisterTemplate 
            upsertFunction={onSubmitCreateAdmin}
            /* title={'REGISTRAR NUEVO ADMINISTRADOR'} */ />
    );
}

NewRegisterAdmin.propTypes = {
    upsertAdministratorAction: PropTypes.any,
    addNotification: PropTypes.func,
}

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        admins: _.get(state, 'admin.admins', {}),
        lessors: _.get(state, 'lessor.lessors', {})
    };
}

const mapDispatchToProps = dispatch => ({
    upsertAdministratorAction: params => dispatch(upsertAdministratorAction(params)),
    addNotification: params => dispatch(addNotification(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(NewRegisterAdmin);
