import { Button, Menu, MenuItem, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
/* import "./Style/Documentacion.scss"; */
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function Documentacion({
  setFieldValue,
  handleNext,
  handleBack,
  isMoral,
  handleFormSubmit,
  handleBackDocumentacion,
}) {
  const [files, setFiles] = useState(Array(5).fill(null));
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const allowedDocumentsForPerson = [
    "Comprobante de domicilio",
    "INE",
    "Constancia de situación fiscal",
  ];

  const handleFileChange = async (event, index) => {
    console.log("selecciona archivo documentacion");
    const selectedFile = event.target.files[0];
    const base64Data = await convertBase64(selectedFile);
    setFieldValue(`file${index}`, base64Data);
    const updatedFiles = [...files];
    updatedFiles[index] = selectedFile;
    setFiles(updatedFiles);
    setSelectedIndex(-1);
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
  };

  const handleDragLeave = (event, index) => {
    event.preventDefault();
  };

  const handleFileRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles[index] = null;
    setFiles(updatedFiles);
  };

  const handleViewDocument = (index) => {
    const selectedFile = files[index];
    if (selectedFile) {
      window.open(URL.createObjectURL(selectedFile), "_blank");
    }
  };

  const handleClickMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedIndex(-1);
  };

  const handleSave = () => {
    files.forEach((file, index) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Data = reader.result;
          console.log(`Título: ${titles[index]}`);
          console.log(`Base64: ${base64Data}`);
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const titles = [
    "Comprobante de domicilio",
    "INE",
    "Constancia de situación fiscal",
    "Acta constitutiva",
    "Poder notarial",
  ];

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        return resolve({
          file: fileReader.result,
          title: file.name,
        });
      };
      fileReader.onerror = (error) => reject(error);
    });
  };

  const backButtonFunction = isMoral ? handleBack : handleBackDocumentacion;

  const handleFileInputClick = (index) => {
    const fileInput = document.querySelector(`#fileInput${index}`);
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <div className="main-container-datos-principales">
      <div className="head-container-registro">
        <p className="title-head">Documentación</p>
      </div>
      <div className="document-container">
        {titles.map((title, index) => (
          <React.Fragment key={index}>
            {(isMoral || allowedDocumentsForPerson.includes(title)) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "85%",
                }}
              >
                <div style={{ height: "3rem" }}>
                  <p className="document-title">{title}</p>
                </div>
                <div
                  className="document-item"
                  onDragOver={(event) => handleDragOver(event, index)}
                  onDragLeave={(event) => handleDragLeave(event, index)}
                >
                  {!files[index] && (
                    <div className="upload-container">
                      <button
                        className="upload-placeholder"
                        onClick={() => handleFileInputClick(index)}
                      >
                        <div className="container-subir-archivo">
                          <p className="text-subir-archivo">Subir archivo</p>
                          <p className="tipo-archivo">
                            El archivo debe ser PDF, JPG, PNG
                          </p>
                        </div>
                        <div className="container-deje-aqui">
                          <p className="deje-arhcivo">Deje su archivo aquí</p>
                        </div>
                      </button>
                      <input
                        style={{visibility:"hidden"}}
                        id={`fileInput${index}`}
                        type="file"
                        accept=".pdf, .doc, .docx"
                        className="input-file"
                        onChange={(event) => handleFileChange(event, index)}
                        onClick={(event) => (event.target.value = null)}
                      />
                    </div>
                  )}

                  {files[index] && (
                    <div className="container-file">
                      <embed
                        src={URL.createObjectURL(files[index])}
                        type="application/pdf"
                        width="100%"
                        height="80%"
                        style={{
                          zIndex: 0,
                          borderColor: "none",
                          borderRadius: "15px 15px 0 0",
                        }}
                      />
                      <div className="sub-container">
                        <div className="menu-container">
                          <MoreVertIcon
                            onClick={(event) => handleClickMenu(event, index)}
                          />
                          <Menu
                            anchorEl={anchorEl}
                            open={selectedIndex === index}
                            onClose={handleCloseMenu}
                          >
                            <MenuItem onClick={() => handleViewDocument(index)}>
                              Ver
                            </MenuItem>
                            <MenuItem onClick={() => handleFileRemove(index)}>
                              Eliminar
                            </MenuItem>
                          </Menu>
                        </div>
                        <div className="file-info">
                          <p>{files[index].name}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="container-button-stepper">
        <Button className="btn-continuar" onClick={backButtonFunction}>
          ATRAS
        </Button>
        <Button className="btn-continuar" onClick={handleNext}>
          REGISTRAR AGENCIA
        </Button>
      </div>
    </div>
  );
}

Documentacion.initialValues = {
  proofAddress: "",
  ine: "",
  fiscalConstancy: "",
  charterNumber: "",
  proxyNumber: "",
};

Documentacion.label = "Documentación";
