import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Modal,
  Fade,
  IconButton,
  Backdrop,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./CambiarContrasenaModal.scss";
import { updatePasswordAction } from "../../../redux/actions/profileAction";
import { useDispatch, useSelector } from "react-redux";

export default function CambiarContrasenaModal({ open, handleClose }) {
  const [password, setPassword] = useState("");
  const [idGerente, setIdGerente] = useState("");
  const userLoggeado = useSelector((state) => state.user.user);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const dispatch = useDispatch();
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    // Validar si las contraseñas coinciden
    setPasswordsMatch(event.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    // Validar si las contraseñas coinciden
    setPasswordsMatch(event.target.value === password);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (_.get(userLoggeado, "person")) {
      setIdGerente(userLoggeado.id);
    }
  }, [userLoggeado]);

  const handleSave = () => {
    if (password === confirmPassword) {
      console.log("Contraseña guardada:", password);
      const data = {
        id: idGerente,
        password: password,
      };
      console.log("data",data)

      dispatch(updatePasswordAction(data));
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} className="modal-content-contraseña">
        <div className="modal-content-contraseña">
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <div className="container-nueva-contrasena">
            <p
              className="title-cambiar-contrasena"
              style={{ fontFamily: "'Pathway Gothic One', sans-serif" }}
            >
              INGRESE SU NUEVA CONTRASEÑA
            </p>
            <TextField
              label="Nueva Contraseña"
              variant="filled"
              margin="dense"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              error={!passwordsMatch}
              helperText={!passwordsMatch && "Las contraseñas no coinciden"}
              InputProps={{
                style: { backgroundColor: "white", marginBottom: "2rem" },
                endAdornment: (
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                ),
              }}
              placeholder="Ingrese la nueva contraseña"
              defaultValue="" // Agrega este valor por defecto
            />
            <TextField
              label="Confirmar Contraseña"
              variant="filled"
              margin="dense"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={!passwordsMatch}
              InputProps={{
                style: { backgroundColor: "white" },
                endAdornment: (
                  <IconButton
                    onClick={handleToggleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                ),
              }}
              placeholder="Confirme la contraseña"
              defaultValue="" // Agrega este valor por defecto
            />
            <Button
              className="btn-guardar"
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={!passwordsMatch}
              sx={{ marginTop: 10 }}
              style={{ fontFamily: "'Pathway Gothic One', sans-serif" }}
            >
              ACEPTAR
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
