import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
	upsertCarDocumentAction,
} from '../../../../redux/actions/carActions';
import {
	getAllDocumentCategoriesAction
} from '../../../../redux/actions/documentCategoryAction';
import { addNotification } from '../../../../redux/actions/notificationAction';
import UploadFileKoopers from '../../../../components/molecules/UploadFileKoopers';
import SelectKoopers from '../../../../components/molecules/SelectKoopers';

import Button from '../../../../components/atoms/Button/Button';



const NewRegisterAdmin = (props) => {
	const { mode, carInstanceId } = useParams();
    const history = useHistory();
	const [ formData, setFormData ] = useState({
        firstName: '',
        firstLastName: '',
    });

	useEffect(() => {
		props.getAllDocumentCategoriesAction();
	},[]);

	const updateFormData = (inputName, isFile=false) => (e) => {
		let value = isFile ? e.target.files[0] : e.target.value;
		let formDataCopy = _.clone(formData);
		setFormData({
			...formDataCopy,
			[inputName]: value,
		});
	};

    const onSubmitForm = () => {
		/*
        const {
            firstName,
            firstLastName,
            primaryEmail,
            primaryPhone,
            password,
            // passwordConfirmation,
        } = formData;
        if(!_.isEmpty(firstName) && !_.isEmpty(firstLastName) && !_.isEmpty(primaryEmail) && !_.isEmpty(password) && !_.isEmpty(primaryPhone) ) {
            const requestData = {
                person: {
                    firstName,
                    firstLastName,
                    primaryPhone,
                    primaryEmail,
                },
                user: {
                    email: primaryEmail,
                    password, 
                },
            };
            props.upsertAdministratorAction(requestData);
            history.goBack();
        }
		*/
		const requestData = {
			documentType: formData.documentType,
			carInstanceId,
			documentFile: formData.documentFile,
		};
		console.log('requestData: ', requestData);
		const formData_ = new FormData();
		if (mode === 'editar') {
			//	formData_.append('id', requestData.id);
		}
        formData_.append('documentType', requestData.documentType);
        formData_.append('carInstanceId', requestData.carInstanceId);
        formData_.append('documentFile', requestData.documentFile);
		props.upsertCarDocumentAction(formData_, { isFileUpload:true });
        history.goBack();

    }

	const formatSelectOptions = () => {
		const docType = _.find(props.allDocumentTypes,{'keyName': "lessorAddCar"});
		return _.get(docType, 'documentTypes', []);
	}

	const title = "Holas";
	return (
		<div className='newRegisterContainer'>
			{
				title &&
				<h1 className='registerTitle'>{title}</h1>
			}
		
			<div className='profileGeneralContainer'>
				<div className='profileCard'>
						{/* <input 
							id='inputPhoto' 
							type={'file'} 
							accept='image/*'
							onChange={(e) => handleChangeImage(e)}
							// {...register('photoProfile')}
						/> */}
					<div className='form'>
						{
							/*
							props.carsProfile ?
							<div className='profileNameContainer'>
								<h2>{props.brand}</h2>
								<h3>{props.model}</h3>
							</div>
							:
							<>
								<ProfileImage
									photo={props.photo}
									onChangePhoto={props.onChangePhoto}
									allowOpenSelectFile={!!props.children}
								/>
								<div className='profileNameContainer'>
									<h2>{name}</h2>
								</div>
							</>
							*/
						}
						{
							<div className='formContainer'>
								<SelectKoopers 
									mode={'user'}
									placeholder={'Tipo de documento'}
									key={'documentType'}
									options={formatSelectOptions()}
									onChange={updateFormData('documentType')}
								/>
								<UploadFileKoopers 
									mode={'user'}
									placeholder={'Documento'}
									key={'documentFile'}
									onChange={updateFormData('documentFile', true)}
								/>
								<Button onClick={onSubmitForm}>
									GUARDAR
								</Button>
							</div>
						}
					</div>
				</div>
			
			</div>

		</div>
	);
	
	/*
    return (
        <RegisterTemplate 
            upsertFunction={onSubmitCreateAdmin}
            title={'REGISTRAR NUEVO ADMINISTRADOR'}  />
    );
	*/
}

NewRegisterAdmin.propTypes = {
    upsertCarDocumentAction: PropTypes.any,
    addNotification: PropTypes.func,
	getAllDocumentCategoriesAction: PropTypes.func,
	allDocumentTypes: PropTypes.array,
}

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        admins: _.get(state, 'admin.admins', {}),
        lessors: _.get(state, 'lessor.lessors', {}),
		allDocumentTypes: _.get(state,'documentCategory.getAllDocumentTypes.data', []),
    };
}

const mapDispatchToProps = dispatch => ({
    upsertCarDocumentAction: (params, requestParams) => dispatch(upsertCarDocumentAction(params, requestParams)),
    addNotification: params => dispatch(addNotification(params)),
	getAllDocumentCategoriesAction: params => dispatch(getAllDocumentCategoriesAction(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(NewRegisterAdmin);
