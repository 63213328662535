import {
	DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_BEGIN,
	DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_SUCCESS,
	DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_FAILURE,
 } from '../actionTypes';
 
 import initialState from '../initialState';
 
 export default function userInterfaceReducer (state = initialState.documentCategory, action) {
	switch (action.type) {
		case DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_BEGIN:
			return {
				...state,
				getAllDocumentTypes: {
                    ...state.getAllDocumentTypes,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
				},
			}
		case DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_SUCCESS:
			return {
				...state,
				getAllDocumentTypes: {
                    ...state.getAllDocumentTypes,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: action.data,
				},
			}
		case DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_FAILURE:
			return {
				...state,
				getAllDocumentTypes: {
                    ...state.getAllDocumentTypes,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
				},
			}
		default:
			return state;
	}
 }