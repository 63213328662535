import React from 'react';
import './HomeClient.scss';
import { GlobalFilter } from '../../components/atoms';

const ClientHomeModule = () => {


    return (
        <>
            <div className='cardsContainer'>
                <div className='clientHome__cardsFilterContainer'>
                    <GlobalFilter
                        showDateRangeSearch
                        showTextSearch={false}
                        classNameDate1='cardsContainerDate1'
                        classNameDate2='cardsContainerDate2'
                    />
                </div>
            </div>
            
        </>
    );
}

export default ClientHomeModule;
