import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import RegisterTemplate from '../../../components/templates/Register/RegisterTemplate';
import { upsertLesseeAction } from '../../../redux/actions/lesseeActions';
import { addNotification } from '../../../redux/actions/notificationAction';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const NewRegisterLessee = (props) => {

    const history = useHistory();

    const onSubmitCreateLessee = (formData) => {
        const {
            firstName,
            firstLastName,
            primaryEmail,
            primaryPhone,
            password,
            // passwordConfirmation,
        } = formData;
        if(!_.isEmpty(firstName) && !_.isEmpty(firstLastName) && !_.isEmpty(primaryEmail) && !_.isEmpty(password) && !_.isEmpty(primaryPhone) /* && password === passwordConfirmation  */) {
            const requestData = {
                // lessorId: 1,
                user: {
                    email: primaryEmail,
                    password
                },
                person: {
                    firstName,
                    firstLastName,
                    primaryPhone,
                    primaryEmail,
                },
            };
            props.upsertLesseeAction(requestData);
            history.goBack();
        } else {
            return props.addNotification({
                toastType: 'error',
                toastTitle: 'Ha ocurrido un error, rellene los datos correctamente',
            });
        }
    }

    return (
        <RegisterTemplate 
            upsertFunction={onSubmitCreateLessee}
        /* title={'REGISTRAR NUEVO ARRENDATARIO'} */ 
        />
    );
}

NewRegisterLessee.propTypes = {
    upsertLesseeAction: PropTypes.any,
    addNotification: PropTypes.any, 
}

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        lessors: _.get(state, 'lessor.lessors', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    upsertLesseeAction: params => dispatch(upsertLesseeAction(params)),
    addNotification: params => dispatch(addNotification(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(NewRegisterLessee);
