/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import InputKoopers from '../../../components/molecules/InputKoopers';
import Button from '../../../components/atoms/Button/Button';
import { 
    getUserInformationRequest,
    updateUserInformationRequest,
} from '../../../redux/actions/userActions';
import ProfileCard from '../../../components/organisms/ProfileCard';
import { upsertAdministratorAction } from '../../../redux/actions/adminAction';

// src & styles
import '../ProfileModule/Profile.scss';

const AdminProfile = (props) => {

    const { adminId } = useParams();

    const admin = useSelector(state => state.admin.admins.data).find(admin => admin.id == adminId); 
    console.log(admin);
    const dispatch = useDispatch();

    let name = ''; 
    if (!_.isEmpty(admin.person.firstName)) name = admin.person.firstName.toUpperCase();

    let lastName = ''; 
    if (!_.isEmpty(admin.person.firstLastName)) lastName = admin.person.firstLastName.toUpperCase();

    const [ userInformation, setUserInformation ] = useState({
        firstName: `${name[0]}${name.substring(1).toLowerCase()}`,
        firstLastName: `${lastName[0]}${lastName.substring(1).toLowerCase()}`,
        email: admin.person.primaryEmail,
        primaryPhone: admin.person.primaryPhone,
        id: admin.user.id,
        // profilePhoto: admin.photo,
    });

    let profilePhoto;

    if (!_.isNil(admin.person.profileImageMedia) && admin.person.profileImageMedia.publicURL) {
        //profilePhoto = lessee.person.profileImageMedia.publicURL.replace('http://localhost:1337/', 'https://koopersapi.beesoftware.dev/')
        profilePhoto = admin.person.profileImageMedia.publicURL;
    }

    const history = useHistory();

    const onSubmitCreateAdmin = (formData) => {
        const requestData = {
            id: formData.id,
            user: {
                email: formData.email
            },
            person: {
                firstName: formData.firstName,
                firstLastName: formData.firstLastName,
                primaryPhone: formData.primaryPhone,
                primaryEmail: formData.email
            },
            profileImageMediaFile: formData.profileImageMediaFile,
        };
        console.log(requestData, "soy el request")

        const formData_ = new FormData();
        formData_.append('id', requestData.id);
        formData_.append('user', JSON.stringify(requestData.user));
        formData_.append('person', JSON.stringify(requestData.person));
        formData_.append('role', '');
        formData_.append('administrator', JSON.stringify({}));
        formData_.append('profileImageMediaFile', requestData.profileImageMediaFile);

        props.upsertAdministratorAction(formData_, { isFileUpload:true });
        history.goBack();
    }

    // useEffect(() => {
    //     dispatch(getUserInformationRequest(userInformation));
    // }, [])

    return (
        <div className='profileCardContainer'>
            <ProfileCard
                docsTitle='DOCUMENTACIÓN'
                title={`${userInformation.firstName} ${userInformation.firstLastName}`}
                // location={location}
                photo={!_.isNil(profilePhoto) && profilePhoto}
                onChangePhoto={(e) => {
                    setUserInformation({...userInformation, profileImageMediaFile: e.target.files[0]})
                }}
            >
                <InputKoopers 
                    mode={'user'} 
                    value={userInformation.firstName}
                    onChange={(e) => setUserInformation({...userInformation, firstName: e.target.value.toLowerCase()})}
                />
                <InputKoopers 
                    mode={'user'} 
                    value={userInformation.firstLastName}
                    onChange={(e) => setUserInformation({...userInformation, firstLastName: e.target.value.toLowerCase()})}
                />
                <InputKoopers 
                    mode={'mail'} 
                    value={userInformation.email}
                    onChange={(e) => setUserInformation({...userInformation, email: e.target.value.toLowerCase()})}
                />
                <InputKoopers
                    mode={'phone'}
                    value={userInformation.primaryPhone}
                    onChange={(e) => setUserInformation({...userInformation, primaryPhone: e.target.value})}
                />
                <Button
                    onClick={() => onSubmitCreateAdmin({...userInformation})}
                >
                    GUARDAR
                </Button>
            </ProfileCard>             
        </div>
    )
}

AdminProfile.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    userRegister: PropTypes.any,
    companies: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    user: PropTypes.object,
    upsertAdministratorAction: PropTypes.func,
    addNotification: PropTypes.any,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        admin: _.get(state, 'admin.userInformation', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    upsertAdministratorAction: (params, requestOptions={}) => dispatch(upsertAdministratorAction(params, requestOptions)),
});

export default connect(mapStateToProps,mapDispatchToProps)(AdminProfile);
