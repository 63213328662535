// HOME
export const HOME = '/dashboard/inicio';

// PERFIL
export const PROFILE = '/dashboard/perfil';

// GESTIÓN ADMINISTRADOR
export const ADMINS_MANAGEMENT = '/dashboard/gestion-administradores';
// - registro ver/nuevo:
export const ADMIN_VIEW_REGISTER = '/dashboard/gestion-administradores/ver-registro/:adminId';
export const ADMIN_REGISTER = '/dashboard/gestion-administradores/registro';

// GESTIÓN ARRENDADOR
export const LESSOR_MANAGEMENT = '/dashboard/gestion-talleres';
// - registro ver/nuevo:
export const LESSOR_VIEW_REGISTER = '/dashboard/gestion-arrendador/ver-registro/:lessorId';
export const LESSOR_DRIVERS_MANAGEMENT = '/dashboard/gestion-arrendador/:lessorId/conductores';
export const LESSOR_DRIVERS_FORM = '/dashboard/gestion-arrendador/:lessorId/conductores/:mode/:driverId?'; // {mode: registrar: driverId:null}
export const LESSOR_MANAGEMENT_REGISTER = '/dashboard/gestion-arrendador/:mode';

// GESTIÓN ARRENDATARIO
export const LESSEE_MANAGEMENT = '/dashboard/gestion-arrendatario';
// - registro ver/nuevo:
export const LESSEE_VIEW_REGISTER = '/dashboard/gestion-arrendatario/ver-registro/:lesseeId';
export const LESSEE_REGISTER = '/dashboard/gestion-arrendatario/registro';

// GESTIÓN INTERNOS
export const INTERN_MANAGEMENT = '/dashboard/gestion-internos';

// GESTIÓN VEHÍCULOS
export const CAR_MANAGEMENT = '/dashboard/gestion-vehiculos/:mode';
export const CAR_VIEW_REGISTER = '/dashboard/gestion-vehiculos/ver-registro/:carId';
export const CAR_VIEW_DOCUMENTS = '/dashboard/gestion-vehiculos/documentos/:carInstanceId';
export const CAR_VIEW_CREATE_DOCUMENT = '/dashboard/gestion-vehiculos/:carInstanceId/documentos/:mode';
export const CAR_COMMENTS_MANAGEMENT = '/dashboard/gestion-vehiculos/:carInstanceId/comentarios';
export const CAR_REGISTER = '/dashboard/gestion-vehiculos/:mode/registro';

// GESTIÓN DE MARCAS VEHÍCULOS
export const CAR_BRAND_MANAGEMENT = '/dashboard/vehiculos/';
export const CAR_BRAND_FORM = '/dashboard/vehiculos/:accessMode/:carId?';

//GESTION DE MODELOS VEHÍCULOS
export const CAR_BRAND_MODEL_MANAGEMENT = '/dashboard/vehiculos-modelos/:carId/modelos';
export const CAR_BRAND_VIEW_MODELS_FORM = '/dashboard/vehiculos-modelos/:carId/modelos/:accessMode/:modelId?';



// GESTIÓN TRANSACCIONES
export const TRANSACTIONS_MANAGEMENT = '/dashboard/transacciones/:mode/:id?';
export const VIEW_TRANSACTION = '/dashboard/transacciones/ver-transaccion/:transactionId';

// GESTIÓN COBRANZA
export const COLLECTION_MANAGEMENT = '/dashboard/gestion-cobranza';


//Mi perfil
export const MI_PERFIL_MARANELLO = '/dashboard/mi-perfil';





//Maranello 
//gestion agencias 
export const AGENCIES_MANAGEMENT = '/dashboard/gestion-agencias';
export const AGENCIES_ADD = '/dashboard/nueva-agencia';
export const AGENCIES_REGISTRY = '/dashboard/nueva-agencia-registro';
export const EDIT_AGENCIES = '/dashboard/Editar-Agencia/:id';


//gestion Talleres
export const TALLER_ADD = '/dashboard/nuevo-taller';
export const TALER_REGISTRY = '/dashboard/nuevo-taller-registro';
export const EDIT_TALLER = '/dashboard/Editar-Taller/:id'


