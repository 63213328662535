import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
    ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_BEGIN,
    ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_SUCCESS,
    ADMINISTATOR_MODULE_GET_ONE_ADMINISTRATOR_FAILURE,
    ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_BEGIN,
    ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_SUCCESS,
    ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_FAILURE,
    ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_BEGIN,
    ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_SUCCESS,
    ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_FAILURE,
    ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_BEGIN,
    ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_SUCCESS,
    ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_FAILURE,
    ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_BEGIN,
    ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_SUCCESS,
    ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_FAILURE,
} from './../actionTypes';

// ACTIONS TO REDUCER

export const getOneAdministratorRecordBegin = () => ({
    type: ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_BEGIN,
});

export const getOneAdministratorRecordSuccess = (adminRecord) => ({
    type: ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_SUCCESS,
    adminRecord,
});

export const getOneAdministratorRecordFailure = () => ({
    type: ADMINISTATOR_MODULE_GET_ONE_ADMINISTRATOR_FAILURE,
});

export const getAllAdministratorsBegin = () => ({
    type: ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_BEGIN,
});

export const getAllAdministratorsSuccess = ({totalRecords, data}) => ({
    type: ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_SUCCESS,
    totalRecords,
    data,
});

export const getAllAdministratorsFailure = () => ({
    type: ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_FAILURE,
});

export const upsertAdministratorBegin = () => ({
    type: ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_BEGIN,
});

export const upsertAdministratorSuccess = () => ({
    type: ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_SUCCESS,
});

export const upsertAdministratorFailure = () => ({
    type: ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_FAILURE,
});

export const deleteAdministratorBegin = () => ({
    type: ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_BEGIN,
});

export const deleteAdministratorSuccess = () => ({
    type: ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_SUCCESS,
});

export const deleteAdministratorFailure = (err) => ({
    type: ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_FAILURE,
    err,
});

export const updateAdministratorBegin = () => ({
    type: ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_BEGIN,
});

export const updateAdministratorSuccess = () => ({
    type: ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_SUCCESS,
});

export const updateAdministratorFailure = () => ({
    type: ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_FAILURE,
});

// DISPATCH TO ACTIONSv

export function getOneAdministratorRecordAction (data){
    return (dispatch, getState) => {
        dispatch(getOneAdministratorRecordBegin());
        return request({
            partialUrl: `/api/v1/administrator/get-one-administrator-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getOneAdministratorRecordSuccess(data.data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(getOneAdministratorRecordFailure(err));
        });
    };
}

export function getAllAdministratorsAction(data){
    return (dispatch, getState) => {
        dispatch(getAllAdministratorsBegin());
        return request({
            partialUrl: `/user/get-one-user`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getAllAdministratorsSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllAdministratorsFailure(err));
        });
    };
}

// export const getAllAdministrators = data => {
//     return async (dispatch) => {
//         const administrators = await getAllAdministratorsAction(data)
//         dispatch({
//             type: ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_BEGIN,
//             payload: administrators,
//         });
// }}

export function upsertAdministratorAction(data, requestParams={}){
    return (dispatch, getState) => {
        dispatch(upsertAdministratorBegin());
        return request({
            partialUrl: `/api/v1/administrator/upsert-administrator-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
            headers: {},
            ...requestParams
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertAdministratorSuccess(data.data));
            dispatch(upsertAdministratorBegin());
            
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertAdministratorFailure(err));
        });
    };
}

export function deleteAdministratorAction(data){
    return (dispatch, getState) => {
        dispatch(deleteAdministratorBegin());
        return request({
            partialUrl: `/api/v1/administrator/delete-administrator-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            // dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha eliminado el administrador con éxito',
            }));
            dispatch(deleteAdministratorSuccess(data.data));
            // dispatch(deleteAdministratorBegin());
            // return true;
        })
        .catch(err => {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(deleteAdministratorFailure(err));
        });
    };
}
