/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import Link from "../../../components/atoms/Link1/Link1";
import InputPassword from "../../../components/atoms/InputPassword/InputPassword";
import { userLogin } from "../../../redux/actions/userActions";
import "./FormLogIn.scss";
import Button from "../../../components/atoms/Button/Button";
import logo from "../../../assets/images/maranello/LOGO_MARANELLO_NGR.png";
import { TextField } from "@material-ui/core";

const FormLogIn = (props) => {
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const onSubmitLoginForm = async (formData) => {
    console.warn("formData",formData);
    await props.userLogin(formData);
    return history.push("/dashboard");
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitLoginForm)} className="form-logIn">
        <div className={"log-in"}>
          <div className="form-logo-container">
            <img className="logo-forms" src={logo} alt="" />
          </div>
          <h2 className="header-form-auth">INICIAR SESION</h2>
          <h4 className="sub-header-form-auth">
            Bienvenido <strong>BAMBINO!</strong>
          </h4>

          <div className="inputs-container-auth">
            <TextField
              type="email"
              label="Correo"
              margin="dense"
              className="input-generic"
              {...register("email")}
              placeholder={"Introduce tu correo"}
              variant="standard"
              color="warning"
            />
            <TextField
              type="password"
              label="Contraseña"
              margin="dense"
              className="input-generic"
              {...register("password")}
              placeholder={"Introduce tu contraseña"}
              variant="standard"
              color="warning"
            />
            <Link className={"link"} to={"/recuperar-password"}>
              ¿Has olvidado tu contraseña?
            </Link>
          </div>
          <div className="container-button">
            <Button className="btn-login-form">
              Iniciar sesión para continuar
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

FormLogIn.propTypes = {
  to: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  userLogin: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: _.get(state.user, "isAuthenticated", false),
    typeUser: _.get(state.user, "user.roles[0]", false),
  };
}

const mapDispatchToProps = (dispatch) => ({
  userLogin: (params) => dispatch(userLogin(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormLogIn);
