import React, { useEffect, useState } from "react";
import "./tabsStyle.scss";
import { Button, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { url_api } from "../../../../config";
import { upsertAgenciesAdmin } from "../../../../redux/actions/agenciesAction";
import { useDispatch } from "react-redux";
import { editWorkshopsAdmin } from "../../../../redux/actions/workshopsAction";

function Documentacion({ agencyMedia, type, idGeneral, tipoPersona }) {
  const [files, setFiles] = useState(Array(5).fill(null));
  const [filesIds, setFilesIds] = useState(Array(5).fill(null));

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isForBack, setIsForBack] = useState(false);

  const [ine, setIne] = useState(null);
  const [proofAddress, setProofAddress] = useState(null);
  const [fiscalConstancy, setFiscalConstancy] = useState(null);
  const [charterNumber, setCharterNumber] = useState(null);
  const [proxyNumber, setProxyNumber] = useState(null);
  const [base64Files, setBase64Files] = useState(Array(5).fill(null));

  useEffect(() => {
    console.log("agencyMedia", agencyMedia);
    if (_.get(agencyMedia, "ine")) {
      console.log("agencyMedia.ine", agencyMedia.ine);
      setIne(agencyMedia.ine.fileName);
      filesIds.splice(0, 1, agencyMedia.ine.id);
      files.splice(0, 1, `${url_api}${agencyMedia.ine.fileName}.pdf`);
      console.log("Ine ", `${url_api}${agencyMedia.ine.fileName}.pdf`);
      //0
    }
    if (_.get(agencyMedia, "proofAddress")) {
      setProofAddress(agencyMedia.proofAddress.fileName);
      filesIds.splice(1, 1, agencyMedia.proofAddress.id);
      files.splice(1, 1, `${url_api}${agencyMedia.proofAddress.fileName}.pdf`);
      console.log(
        "proofAddress",
        `${url_api}${agencyMedia.proofAddress.fileName}.pdf`
      );
      //1
    }
    if (_.get(agencyMedia, "fiscalConstancy")) {
      setFiscalConstancy(agencyMedia.fiscalConstancy.fileName);
      filesIds.splice(2, 1, agencyMedia.fiscalConstancy.id);
      files.splice(
        2,
        1,
        `${url_api}${agencyMedia.fiscalConstancy.fileName}.pdf`
      );
      //2
    }
    if (_.get(agencyMedia, "charterNumber")) {
      setCharterNumber(agencyMedia.charterNumber.fileName);
      filesIds.splice(3, 1, agencyMedia.charterNumber.id);
      files.splice(3, 1, `${url_api}${agencyMedia.charterNumber.fileName}.pdf`);
      //3
    }
    if (_.get(agencyMedia, "proxyNumber")) {
      setProxyNumber(agencyMedia.proxyNumber.fileName);
      filesIds.splice(4, 1, agencyMedia.proxyNumber.id);
      files.splice(4, 1, `${url_api}${agencyMedia.proxyNumber.fileName}.pdf`);
      //4
    }
  }, [agencyMedia]);

  useEffect(() => {
    console.log("files", files);
  }, [files]);

  useEffect(() => {
    console.log("base64Files", base64Files);
  }, [base64Files]);

  const handleFileChange = async (event, index) => {
    console.log("selecciona archivo documentacion");
    setIsForBack(true);
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const updatedFiles = [...files];
      const updatedBase64Files = [...base64Files];
      const base64Data = await convertBase64(selectedFile);
      updatedBase64Files[index] = base64Data;
      updatedFiles[index] = URL.createObjectURL(selectedFile);
      setBase64Files(updatedBase64Files);
      setFiles(updatedFiles);
      setSelectedIndex(-1);
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        return resolve({
          file: fileReader.result,
          title: file.name,
        });
      };
      fileReader.onerror = (error) => reject(error);
    });
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
  };

  const handleDragLeave = (event, index) => {
    event.preventDefault();
  };

  const handleFileRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles[index] = null;
    setFiles(updatedFiles);
  };

  const handleViewDocument = (index) => {
    const selectedFile = files[index];
    if (selectedFile) {
      /* window.open(URL.createObjectURL(selectedFile), "_blank"); */
      window.open(selectedFile, "_blank");
    }
  };

  const handleClickMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedIndex(-1);
  };
  const dispatch = useDispatch();
  const handleSave = () => {
    console.log("base64Files", base64Files);
    console.log("base64Files", filesIds);

    const filesToSend = [
      files[0] === null && filesIds[0] === null
        ? null
        : files[0] === null && filesIds[0] !== null
        ? { ine: { fileId: filesIds[0] } }
        : base64Files[0] !== null
        ? { ine: { ...base64Files[0], fileId: filesIds[0] } }
        : null,

      files[1] === null && filesIds[1] === null
        ? null
        : files[1] === null && filesIds[1] !== null
        ? { proofAddress: { fileId: filesIds[1] } }
        : base64Files[1] !== null
        ? { proofAddress: { ...base64Files[1], fileId: filesIds[1] } }
        : null,

      files[2] === null && filesIds[2] === null
        ? null
        : files[2] === null && filesIds[1] !== null
        ? { fiscalConstancy: { fileId: filesIds[2] } }
        : base64Files[2] !== null
        ? { fiscalConstancy: { ...base64Files[2], fileId: filesIds[2] } }
        : null,

      files[3] === null && filesIds[3] === null
        ? null
        : files[3] === null && filesIds[3] !== null
        ? { charterNumber: { fileId: filesIds[1] } }
        : base64Files[3] !== null
        ? { charterNumber: { ...base64Files[3], fileId: filesIds[3] } }
        : null,

      files[4] === null && filesIds[4] === null
        ? null
        : files[4] === null && filesIds[4] !== null
        ? { proxyNumber: { fileId: filesIds[4] } }
        : base64Files[4] !== null
        ? { proxyNumber: { ...base64Files[4], fileId: filesIds[4] } }
        : null,
    ].filter(Boolean);

    const data = {
      data: {
        id: idGeneral,
        files: filesToSend,
      },
    };
    console.log("data", data);
    dispatch(editWorkshopsAdmin(data));
  };

  const titles = type
    ? [
        "INE",
        "Comprobante de domicilio",
        "Constancia de situación fiscal",
        "Acta constitutiva",
        "Poder notarial",
      ]
    : ["INE", "Comprobante de domicilio", "Constancia de situación fiscal"];

  return (
    <div className="main-tabs">
      <p className="title-seccion">Documentación</p>
      <div className="document-container">
        {titles.map((title, index) => (
          <React.Fragment key={index}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ height: "3rem" }}>
                <p className="document-title">{title}</p>
              </div>
              <div
                className="document-item"
                onDragOver={(event) => handleDragOver(event, index)}
                onDragLeave={(event) => handleDragLeave(event, index)}
              >
                {!files[index] && (
                  <div className="upload-container">
                    <input
                      type="file"
                      accept=".pdf"
                      style={{ display: "none" }}
                      onChange={(event) => handleFileChange(event, index)}
                      onClick={(event) => (event.target.value = null)}
                    />
                    <div
                      className="upload-placeholder"
                      onClick={() =>
                        document.querySelector('input[type="file"]').click()
                      }
                    >
                      <div className="container-subir-archivo">
                        <p className="text-subir-archivo">Subir archivo</p>
                        <p className="tipo-archivo">
                          El archivo debe ser PDF, JPG, PNG
                        </p>
                      </div>
                      <div className="container-deje-aqui">
                        <p className="deje-arhcivo">Deje su archivo aquí</p>
                      </div>
                    </div>
                  </div>
                )}

                {files[index] && (
                  <div className="container-file">
                    <embed
                      src={
                        files[index] /* URL.createObjectURL( files[index]/* ) */
                      }
                      type="application/pdf"
                      width="100%"
                      height="80%"
                      style={{
                        zIndex: 0,
                        borderColor: "none",
                        borderRadius: "15px 15px 0 0",
                      }}
                    />
                    <div className="sub-container">
                      <div className="menu-container">
                        <MoreVertIcon
                          onClick={(event) => handleClickMenu(event, index)}
                        />
                        <Menu
                          anchorEl={anchorEl}
                          open={selectedIndex === index}
                          onClose={handleCloseMenu}
                        >
                          <MenuItem onClick={() => handleViewDocument(index)}>
                            Ver
                          </MenuItem>
                          <MenuItem onClick={() => handleFileRemove(index)}>
                            Eliminar
                          </MenuItem>
                        </Menu>
                      </div>
                      <div className="file-info">
                        <p>{files[index].name}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>

      <div className="container-button">
        <Button className="btn-continuar" onClick={handleSave}>
          GUARDAR
        </Button>
      </div>
    </div>
  );
}

export default Documentacion;
