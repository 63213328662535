import {
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import "./Style/DatosPrincipales.scss";
import ImagenPrevia from "../../../assets/images/maranello/Card_UploadIMG.png";
import ImagenPreviaCarta from "../../../assets/images/maranello/Card_Amarillo_Archivo.png";
import * as yup from "yup";
import { useFormikContext } from "formik";
import page from "../../../assets/images/Grupo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCiudadesAction,
  getAllContriesAction,
  getAllEstadoAction,
} from "../../../redux/actions/countryAction";
import { getAllAgenciesAction } from "../../../redux/actions/agenciesAction";
import { addNotification } from "../../../redux/actions/notificationAction";

export default function DatosPrincipales({
  setFieldValue,
  handleNext,
  handleBack,
  isMoral,
}) {
  const dispatch = useDispatch();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [style, setStyle] = useState("cardChangeContainerYellow");
  const [btn, setBtn] = useState("btnChange");
  const { values, errors, validateForm } = useFormikContext(); // Añadido validateForm
  const [country, setCountry] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [state, setState] = useState("");
  const countriesGlobal = useSelector((state) => state.countries.countries);
  const ciudadesGlobal = useSelector((state) => state.cities.cities);
  const EstadosGlobal = useSelector((state) => state.states.states);
  const agenciasGlobales = useSelector((state) => state.agencias.agencias);
  const [countries, setCountries] = React.useState({});
  const [states, setStates] = React.useState({});
  const [ciudades, setCiudades] = React.useState({});
  const [agenciaSelect, setAgenciaSelect] = React.useState({});
  const [isStripeSelected, setIsStripeSelected] = useState(false);
  const [isPayPalSelected, setIsPayPalSelected] = useState(false);
  const [isEfectivoSelected, setisEfectivoSelected] = useState(false);
  const [belongsAgencyBool, setbelongsAgencyBool] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleStripeChange = (event) => {
    console.log("stripe", event.target.checked);
    setIsStripeSelected(event.target.checked);
    if (event.target.checked === true) {
      console.log("soy 1");
      setFieldValue("stripe", "1");
    }
  };

  const handleEfectivoChange = (event) => {
    console.log("efectivo", event.target.checked);
    setisEfectivoSelected(event.target.checked);
    if (event.target.checked === true) {
      setFieldValue("efectivo", "3");
    }
  };

  const handlePayPalChange = (event) => {
    setIsPayPalSelected(event.target.checked);
    if (event.target.checked === true) {
      console.log("soy 1");
      setFieldValue("paypal", "1");
    }
  };
  const handleAgencyBelongs = (event) => {
    setbelongsAgencyBool(event.target.checked);
    setFieldValue("paypal", event.target.checked);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    const data = {
      where: {
        countryId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllEstadoAction(data));
    setFieldValue("Pais", event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
    setFieldValue("Estado", event.target.value);
    const data = {
      where: {
        stateId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllCiudadesAction(data));
  };

  const hadleAgencie = (event) => {
    setAgenciaSelect(event.target.value);
    setFieldValue("agenciId", event.target.value);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        return resolve({
          file: fileReader.result,
          title: file.name,
        });
        /* resolve(fileReader.result); */
      };
      fileReader.onerror = (error) => reject(error);
    });
  };

  const handleDocumentUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedDocument(file);
      const base64 = await convertBase64(file);
      console.log(base64);
      setFieldValue("letterHead", base64);
    }
  };

  const handleCiudadChange = (event) => {
    setCiudad(event.target.value);
    setFieldValue("Ciudad", event.target.value);
  };

  const handleImageUpload = async (event) => {
    console.log("soy la imagen");
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      const base64Logo = await convertBase64(file);
      console.log(base64Logo);
      setFieldValue("logo", base64Logo);
    }
  };

  function changeStyle(id) {
    console.warn(id);
    setStyle(id);
    setBtn(id);
  }
  function changeStyle2(id) {
    setBtn(id);
  }
  const handleOnClick = (styleOne, styleTwo, color) => {
    changeStyle(styleOne);
    changeStyle2(styleTwo);
    console.log("color", color);
    setFieldValue("ColorContraste", color);
  };

  const handleContinue = () => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        if (!values.logo || !values.letterHead) {
          dispatch(
            addNotification({
              toastType: "error",
              toastTitle:
                "Para avanzar por favor selecciona un logo o una hoja membretada",
            })
          );
        } else {
          handleNext();
        }
      }
    });
  };

  React.useEffect(() => {
    dispatch(getAllContriesAction({}));
    dispatch(getAllAgenciesAction());
  }, []);

  React.useEffect(() => {
    console.log("countriesGlobal", countriesGlobal);
    if (_.get(countriesGlobal, "data.rows[0]")) {
      setCountries(countriesGlobal.data.rows);
      console.log("countriesGlobal", countriesGlobal.data.rows);
    }
  }, [countriesGlobal]);

  React.useEffect(() => {
    console.log("ciudadesGlobal", ciudadesGlobal);
    if (_.get(ciudadesGlobal, "data.rows[0]")) {
      setCiudades(ciudadesGlobal.data.rows);
      console.log("ciudadesGlobal", ciudadesGlobal.data.rows);
    }
  }, [ciudadesGlobal]);

  React.useEffect(() => {
    console.log("EstadosGlobal", EstadosGlobal);
    if (_.get(EstadosGlobal, "data.rows[0]")) {
      setStates(EstadosGlobal.data.rows);
    }
  }, [EstadosGlobal]);

  React.useEffect(() => {
    console.log("agenciasGlobales", agenciasGlobales);
    if (_.get(agenciasGlobales, "data.rows[0]")) {
      setAgenciaSelect(agenciasGlobales.data.rows);
    }
  }, [agenciasGlobales]);

  return (
    <div className="main-container-datos-principales">
      <div className="head-container-registro">
        <p className="title-head">Datos principales</p>
      </div>
      <div className="scrollable-container">
        <div className="main-separete-container">
          <div className="separete-container">
            {isMoral && (
              <TextField
                className="textfield-stepper"
                id="filled-basic"
                margin="dense"
                name="nombreComercial"
                label="Nombre comercial *"
                variant="filled"
                placeholder="Agregar..."
                InputLabelProps={{ shrink: true }}
                value={values.nombreComercial || ""}
                error={Boolean(errors.nombreComercial)}
                helperText={errors.nombreComercial}
                onChange={(e) => {
                  setFieldValue("nombreComercial", e.target.value);
                }}
              />
            )}

            {!isMoral && (
              <>
                <TextField
                  className="textfield-stepper"
                  id="filled-basic"
                  margin="dense"
                  name="nombreComercial"
                  label="Nombre comercial *"
                  variant="filled"
                  placeholder="Agregar..."
                  InputLabelProps={{ shrink: true }}
                  value={values.nombreComercial || ""}
                  error={Boolean(errors.nombreComercial)}
                  helperText={errors.nombreComercial}
                  onChange={(e) => {
                    setFieldValue("nombreComercial", e.target.value);
                  }}
                />

                <TextField
                  className="textfield-stepper"
                  id="filled-basic"
                  margin="dense"
                  name="NombrePersonaFisica"
                  label="Nombre persona física *"
                  variant="filled"
                  placeholder="Agregar..."
                  InputLabelProps={{ shrink: true }}
                  value={values.NombrePersonaFisica || ""}
                  error={Boolean(errors.NombrePersonaFisica)}
                  helperText={errors.NombrePersonaFisica}
                  onChange={(e) => {
                    setFieldValue("NombrePersonaFisica", e.target.value);
                  }}
                />
              </>
            )}

            <TextField
              className="textfield-stepper"
              id="filled-basic"
              name="Pais"
              label="País *"
              variant="filled"
              margin="dense"
              placeholder="Seleccionar"
              select
              onChange={handleCountryChange}
              InputLabelProps={{ shrink: Boolean(country) }}
              value={values.Pais}
              error={Boolean(errors.Pais)}
              helperText={errors.Pais}
            >
              {countries &&
                countries.length > 0 &&
                countries.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              id="filled-basic"
              className="textfield-stepper"
              label="Ciudad/Municipio *"
              name="Ciudad"
              margin="dense"
              variant="filled"
              placeholder="Seleccionar"
              select
              onChange={handleCiudadChange}
              InputLabelProps={{ shrink: Boolean(ciudad) }}
              value={values.Ciudad}
              error={Boolean(errors.Ciudad)}
              helperText={errors.Ciudad}
            >
              {ciudades &&
                ciudades.length > 0 &&
                ciudades.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>

            <div className="container-checkbox-main">
              <p className="title-pasarela">Pasarela de pagos</p>
              <div className="container-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isStripeSelected}
                      onChange={handleStripeChange}
                      name="stripe"
                      className="titlecheck"
                    />
                  }
                  label="Stripe"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPayPalSelected}
                      onChange={handlePayPalChange}
                      name="paypal"
                      className="titlecheck"
                    />
                  }
                  label="PayPal"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isEfectivoSelected}
                      onChange={handleEfectivoChange}
                      name="efectivo"
                      className="titlecheck"
                    />
                  }
                  label="Efectivo"
                />
              </div>
            </div>

            <div className="container-checkbox-main-taller-tiene">
              <div className="container-title-agencia-pertenece">
                <p className="title-pasarela">
                  El Taller ¿pertenece a una agencia?
                </p>
              </div>

              <div className="container-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={belongsAgencyBool}
                      onChange={handleAgencyBelongs}
                      name="belongsAgency"
                      className="titlecheck"
                    />
                  }
                  label=""
                />
              </div>
            </div>
          </div>
          <div className="separete-container">
            <TextField
              className="textfield-stepper"
              id="filled-basic"
              margin="dense"
              name="Descripcion"
              label="Descripción del taller"
              variant="filled"
              placeholder="Agregar..."
              multiline
              rows={4} // Aumenta el número de filas visibles aquí
              InputLabelProps={{ shrink: true }}
              value={values.Descripcion}
              error={Boolean(errors.Descripcion)}
              helperText={errors.Descripcion}
              onChange={(e) => {
                setFieldValue("Descripcion", e.target.value);
              }}
            />

            <TextField
              id="filled-basic"
              className="textfield-stepper"
              label="Estado *"
              variant="filled"
              name="Estado"
              margin="dense"
              placeholder="Seleccionar"
              select
              onChange={handleStateChange}
              InputLabelProps={{ shrink: Boolean(state) }}
              value={values.Estado}
              error={Boolean(errors.Estado)}
              helperText={errors.Estado}
            >
              {states &&
                states.length > 0 &&
                states.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              className="textfield-stepper"
              id="filled-basic"
              name="Direccion"
              label="Dirección *"
              margin="dense"
              variant="filled"
              placeholder="Agregar..."
              InputLabelProps={{ shrink: true }}
              value={values.Direccion}
              error={Boolean(errors.Direccion)}
              helperText={errors.Direccion}
              onChange={(e) => {
                setFieldValue("Direccion", e.target.value);
              }}
            />
            <TextField
              id="filled-basic"
              label="Outlined"
              variant="filled"
              style={{ visibility: "hidden" }}
            />

            <TextField
              id="filled-basic"
              className="textfield-stepper"
              label="Selecciona la agencia a la que pertenece"
              variant="filled"
              name="agenciaPertenece"
              margin="dense"
              placeholder="Seleccionar"
              select
              onChange={hadleAgencie}
              InputLabelProps={{ shrink: Boolean(state) }}
              value={values.agenciId}
              error={Boolean(errors.agenciId)}
              helperText={errors.agenciId}
              disabled={!belongsAgencyBool}
            >
              {agenciaSelect &&
                agenciaSelect.length > 0 &&
                agenciaSelect.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.agencyData.businessName}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </div>

        <div className="container-logo-color-carta">
          <div className="container-subir-logo">
            <div className="container-prev-img">
              {selectedImage ? (
                <div className="placeholder-text">
                  <img
                    src={selectedImage}
                    alt="Logo"
                    className="preview-image"
                  />
                </div>
              ) : (
                <div className="placeholder-text">
                  <img src={ImagenPrevia} className="preview-image" />
                </div>
              )}
            </div>
            <input
              type="file"
              accept="image/jpeg, image/png"
              id="logo-upload"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            <label htmlFor="logo-upload">
              <Button className="btn-logo" component="span">
                SUBIR LOGO
              </Button>
            </label>
          </div>

          <div className="container-subir-logo">
            <div className="container-prev-img">
              {selectedDocument ? (
                <div className="placeholder-text">
                  {/* Aquí se muestra la vista previa del documento */}
                  {selectedDocument.type === "application/pdf" ? (
                    <iframe
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        height: "80%",
                        marginTop: "1rem",
                      }}
                      src={URL.createObjectURL(selectedDocument)}
                      title="Documento PDF"
                      className="preview-pdf"
                    />
                  ) : (
                    <embed
                      src={URL.createObjectURL(selectedDocument)}
                      type="application/msword"
                      className="preview-word"
                    />
                  )}
                </div>
              ) : (
                <div className="placeholder-text">
                  <img src={ImagenPreviaCarta} className="preview-image" />
                </div>
              )}
            </div>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              id="document-upload"
              style={{ display: "none" }}
              onChange={handleDocumentUpload}
            />
            <label htmlFor="document-upload">
              <Button className="btn-logo" component="span">
                SUBIR HOJA MEMBRETADA
              </Button>
            </label>
          </div>

          <div className="MiddleContainer">
            <div className={style}>
              <img src={page} className="imgPage" />
            </div>
            <div className="sectionColor">
              <p>Color del contraste</p>
              <div className="colorContainer">
                <Button
                  className="btnBlue"
                  id="blue"
                  onClick={() => {
                    handleOnClick(
                      "cardChangeContainerBlue",
                      "btnChangeBlue",
                      "#0078c3"
                    );
                  }}
                />
                <Button
                  className="btnOrange"
                  id="blue"
                  onClick={() => {
                    handleOnClick(
                      "cardChangeContainerOrange",
                      "btnChangeOrange",
                      "#ffc60a"
                    );
                  }}
                />
                <Button
                  className="btnGreen"
                  id="blue"
                  onClick={() => {
                    handleOnClick(
                      "cardChangeContainerGreen",
                      "btnChangeGreen",
                      "#81d88b"
                    );
                  }}
                />
                <Button
                  className="btnPurple"
                  id="blue"
                  onClick={() => {
                    handleOnClick(
                      "cardChangeContainerPurple",
                      "btnChangePurple",
                      "#b381d8"
                    );
                  }}
                />
                <Button
                  className="btnRed"
                  id="blue"
                  onClick={() => {
                    handleOnClick(
                      "cardChangeContainer",
                      "btnChange",
                      "#c3002f"
                    );
                  }}
                />
                <Button
                  className="btnYellow"
                  id="blue"
                  onClick={() => {
                    handleOnClick(
                      "cardChangeContainerYellow",
                      "btnChangeYellow",
                      "#fced60"
                    );
                  }}
                />
                <Button
                  className="btnGray"
                  id="blue"
                  onClick={() => {
                    handleOnClick(
                      "cardChangeContainerGray",
                      "btnChangeGray",
                      "#d8d8d8"
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-button-stepper">
        <Button className="btn-continuar" onClick={handleContinue}>
          SIGUIENTE
        </Button>
      </div>
    </div>
  );
}

DatosPrincipales.initialValues = {
  nombreComercial: "",
  NombreProvedor: "",
  NombrePersonaFisica: "",
  Pais: "",
  Ciudad: "",
  Descripcion: "",
  Estado: "",
  Direccion: "",
  stripe: "0",
  paypal: "0",
  efectivo: "0",
  logo: "",
  letterHead: "",
  ColorContraste: "#fced60",
  belongsAgency: false,
  agenciaPertenece: "",
  agenciId: "",
};

DatosPrincipales.validationSchema = yup.object().shape({
  nombreComercial: yup.string().when("esMoral", {
    is: true,
    then: yup
      .string()
      .required("Campo requerido")
      .min(2, "Mínimo dos caracteres"),
  }),
  NombreProvedor: yup.string().when("esMoral", {
    is: false,
    then: yup
      .string()
      .required("Campo requerido")
      .min(2, "Mínimo dos caracteres"),
  }),
  NombrePersonaFisica: yup.string().when("esMoral", {
    is: false,
    then: yup
      .string()
      .required("Campo requerido")
      .min(2, "Mínimo dos caracteres"),
  }),
  Pais: yup.string().required("requerido"),
  Ciudad: yup.string().required("requerido").min(2, "minimo dos caracteres"),
  Descripcion: yup
    .string()
    .required("requerido")
    .min(2, "minimo dos caracteres"),
  Estado: yup.string().required("requerido").min(2, "minimo dos caracteres"),
  Direccion: yup.string().required("requerido").min(2, "minimo dos caracteres"),
});

DatosPrincipales.label = "Datos principales";
