import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
    CAR_MODULE_GET_ALL_CARS_BEGIN,
    CAR_MODULE_GET_ALL_CARS_SUCCESS,
    CAR_MODULE_GET_ALL_CARS_FAILURE,
    CAR_MODULE_UPSERT_CARS_BEGIN,
    CAR_MODULE_UPSERT_CARS_SUCCESS,
    CAR_MODULE_UPSERT_CARS_FAILURE,
    CAR_MODULE_GET_ALL_DOCUMENTS_BEGIN,
    CAR_MODULE_GET_ALL_DOCUMENTS_SUCCESS,
    CAR_MODULE_GET_ALL_DOCUMENTS_FAILURE,
    CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_BEGIN,
    CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_SUCCESS,
    CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_FAILURE,
    CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_BEGIN,
    CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_SUCCESS,
    CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_FAILURE,
    CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_BEGIN,
    CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_SUCCESS,
    CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_FAILURE,
} from "../actionTypes";

// ACTIONS TO REDUCER

export const getAllCarsBegin = () => ({
    type: CAR_MODULE_GET_ALL_CARS_BEGIN,
});

export const getAllCarsSuccess = ({totalRecords, data}) => ({
    type: CAR_MODULE_GET_ALL_CARS_SUCCESS,
    totalRecords,
    data,
});

export const getAllCarsFailure = (err) => ({
    type: CAR_MODULE_GET_ALL_CARS_FAILURE,
    err,
});

export const upsertCarBegin = () => ({
    type: CAR_MODULE_UPSERT_CARS_BEGIN,
});

export const upsertCarSuccess = ({totalRecords, data}) => ({
    type: CAR_MODULE_UPSERT_CARS_SUCCESS,
    totalRecords,
    data,
});

export const upsertCarFailure = (err) => ({
    type: CAR_MODULE_UPSERT_CARS_FAILURE,
    err,
});

// Mover a carinstance

export const getAllCarDocumentsBegin = () => ({
    type: CAR_MODULE_GET_ALL_DOCUMENTS_BEGIN,
});

export const getAllCarDocumentsSuccess = ({totalRecords, data}) => ({
    type: CAR_MODULE_GET_ALL_DOCUMENTS_SUCCESS,
    totalRecords,
    data,
});

export const getAllCarDocumentsFailure = (err) => ({
    type: CAR_MODULE_GET_ALL_DOCUMENTS_FAILURE,
    err,
});

export const deleteOneCarDocumentBegin = () => ({
    type: CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_BEGIN,
});

export const deleteOneCarDocumentSuccess = ({totalRecords, data}) => ({
    type: CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_SUCCESS,
    totalRecords,
    data,
});

export const deleteOneCarDocumentFailure = (err) => ({
    type: CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_FAILURE,
    err,
});

export const upsertCarDocumentBegin = () => ({
    type: CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_BEGIN,
});

export const upsertCarDocumentSuccess = ({totalRecords, data}) => ({
    type: CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_SUCCESS,
    totalRecords,
    data,
});

export const upsertCarDocumentFailure = (err) => ({
    type: CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_FAILURE,
    err,
});

export const getAllTransactionRatesBegin = () => ({
    type: CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_BEGIN,
});

export const getAllTransactionRatesSuccess = ({data, totalRecords}) => ({
    type: CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_SUCCESS,
    totalRecords,
    data,
});

export const getAllTransactionRatesFailure = (error) => ({
    type: CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_FAILURE,
    error,
});


// DISPATCH TO ACTIONS


export function getAllCarsAction(data){
    return (dispatch, getState) => {
        dispatch(getAllCarsBegin());
        return request({
            partialUrl: `/api/v1/car/car-availables/get-all-cars-available`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getAllCarsSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllCarsFailure(err));
        });
    };
}

export function upsertCarsAction(data) {
    return (dispatch, getState) => {
        dispatch(upsertCarBegin());
        return request({
            partialUrl: `/api/v1/lessee/auth/signup-local-lessee-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(upsertCarSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertCarFailure(err));
        });
    };
}

export function getAllCarDocumentsAction(data){ // Mover a carinstance
    return (dispatch, getState) => {
        dispatch(getAllCarDocumentsBegin());
        return request({
            partialUrl: `/api/v1/car/car-documents/get-all-car-documents-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getAllCarDocumentsSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllCarDocumentsFailure(err));
        });
    };
}

export function deleteOneCarDocumentAction(data) { // Mover a carinstance
    return (dispatch, getState) => {
        dispatch(deleteOneCarDocumentBegin());
        return request({
            partialUrl: `/api/v1/car/car-documents/delete-one-car-document-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(deleteOneCarDocumentSuccess(data.data));
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha eliminado el registro con éxito',
            }));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado, favor de intentarlo de nuevo',
            }));
            return dispatch(deleteOneCarDocumentFailure(err));
        });
    };
}

export function upsertCarDocumentAction(data, requestParams={}){
    return (dispatch, getState) => {
        dispatch(upsertCarDocumentBegin());
        return request({
            partialUrl: `/api/v1/lessor/car/upload-car-instance-document-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
            headers: {},
            ...requestParams
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertCarDocumentSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertCarDocumentFailure(err));
        });
    };
}

export function getAllTransactionRatesAction(data) {
    return (dispatch, getState) => {
        dispatch(getAllTransactionRatesBegin());
        return request({
            partialUrl: `/api/v1/lessor/car/get-all-comments-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha recuperado la información con éxito',
            }));
            dispatch(getAllTransactionRatesSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha podido realizar la acción, intentelo de nuevo',
            }));
            return dispatch(getAllTransactionRatesFailure(err));
        });
    };
}
