import React from "react";
import Footer from "../../../components/templates/BaseLayout/Footer/Footer";
import FullScreenLayout from "../../../components/templates/FullScreenLayout/FullScreenLayout";
import bambu1 from "./../../../assets/images/bambu1.png";
import "./ChangePassword.scss";
import RecoverPasswordForm from "./ChangePasswordForm";
import backButton from "./../../../assets/images/maranello/Icon_Atras.png";
import { useHistory } from "react-router-dom";

const ChangePassword = () => {
    const history = useHistory();
  const onClickGoBack = () => {
    history.goBack();
  };
  return (
    <>
      <div className="login-main">
        <div className="login-container">
         <div style={{display:"flex",height:"100%",justifyContent:"flex-start", flexDirection:"column" }}>
            <img
                className="backButton"
                src={backButton}
                onClick={onClickGoBack}
                style={{width:"3rem"}}
            />
         </div>
          
          <RecoverPasswordForm></RecoverPasswordForm>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
