import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import RegisterTemplate from '../../../components/templates/Register/RegisterTemplate';
import { upsertLessorAction } from '../../../redux/actions/lessorAction';
import { addNotification } from '../../../redux/actions/notificationAction';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const NewRegisterLessor = (props) => {

    const history = useHistory();
    
    const onSubmitCreateLessor = (formData) => {
        const {
            firstName,
            firstLastName,
            primaryEmail,
            primaryPhone,
            password,
            // passwordConfirmation,
        } = formData;
        if(!_.isEmpty(firstName) && !_.isEmpty(firstLastName) && !_.isEmpty(primaryEmail) && !_.isEmpty(password) && !_.isEmpty(primaryPhone) /* && password === passwordConfirmation  */) {
            const requestData = {
                // lessorId: 1,
                user: {
                    email: primaryEmail,
                    password
                },
                person: {
                    firstName,
                    firstLastName,
                    primaryPhone,
                    primaryEmail,
                },
            };
            props.upsertLessorAction(requestData);
            history.goBack();
        }
        // } else {
        //     return props.addNotification({
        //         toastType: 'error',
        //         toastTitle: 'Ha ocurrido un error, rellene los datos correctamente',
        //     });
        // }
    }

    return (
        <RegisterTemplate 
            upsertFunction={onSubmitCreateLessor}
        /* title={'REGISTRAR NUEVO ARRENDATARIO'} */ 
        />
    );
}

NewRegisterLessor.propTypes = {
    upsertLessorAction: PropTypes.any,
    addNotification: PropTypes.any, 
}

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        lessors: _.get(state, 'lessor.lessors', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    upsertLessorAction: params => dispatch(upsertLessorAction(params)),
    addNotification: params => dispatch(addNotification(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(NewRegisterLessor);
