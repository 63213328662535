import React, { useEffect, useState } from "react";
import "./LessorManagementHome.scss";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import btnBack from "../../../assets/images/maranello/Icon_Atras.png";
import { Link, useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { DataGrid } from "@material-ui/data-grid";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem, Typography } from "@material-ui/core";
import UsuariosInternosModal from "../../../components/ModalesTalleres/UsuariosInternosModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgenciesAction } from "../../../redux/actions/agenciesAction";
import ActionsMenuTaller from "./ActionMenuTaller";
import { getAllWorkshopsAction } from "../../../redux/actions/workshopsAction";

function GestionTalleres() {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [usuariosInternosModalOpen, setUsuariosInternosModalOpen] =
    useState(false);

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };
  const talleresGlobales = useSelector((state) => state.talleres.talleres);
  const [agencias, setAgencias] = React.useState([]);
  const dispatch = useDispatch();
  const [registroActual, setRegistroActual] = useState({});

  useEffect(() => {
    dispatch(getAllWorkshopsAction());
  }, []);

  useEffect(() => {
    if (_.get(talleresGlobales, "data.rows[0]")) {
      /* console.log(talleresGlobales.data.rows[0]); */
      setAgencias([]);
      talleresGlobales.data.rows.map((seccion) => {
       /*  console.log("seccion", seccion); */
        let sectionCopy = JSON.parse(JSON.stringify(seccion));
        sectionCopy.id = sectionCopy.id;
        sectionCopy.nombreComercial = sectionCopy.workshopData.businessName;
        sectionCopy.telefono = sectionCopy.workshopContactDetails.primaryPhone;
        sectionCopy.correo = sectionCopy.workshopContactDetails.primaryEmail;
        sectionCopy.direccion = sectionCopy.workshopData.address.street1;
        sectionCopy.ciudad = sectionCopy.workshopData.address.city.name;
        setAgencias((d) => [...d, sectionCopy]);
      });
    }

    /* console.log("talleresGlobales", talleresGlobales); */
  }, [talleresGlobales]);

  const obtenerGerente = () => {
    let inputValue = document.getElementById("BusquedaGerente").value;
    console.log("inputValue", inputValue);
    const data = {
      where: {
        businessName: inputValue,
      },
    };
    dispatch(getAllWorkshopsAction(data));
  };

  const handleUsuariosInternosModalClose = () => {
    setUsuariosInternosModalOpen(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    { field: "nombreComercial", headerName: "NOMBRE COMERCIAL", width: 250 },
    { field: "telefono", headerName: "TELÉFONO", width: 200 },
    { field: "correo", headerName: "CORREO", width: 200 },
    { field: "direccion", headerName: "DIRECCIÓN", width: 250 },
    { field: "ciudad", headerName: "CIUDAD", type: "TELÉFONO", width: 150 },
    {
      field: "actions",
      headerName: "ACCIONES",
      width: 180,
      renderCell: (params) => (
        <ActionsMenuTaller
          {...params}
          onEdit={handleEdit}
          setUsuariosInternosModalOpen={setUsuariosInternosModalOpen}
        />
      ),
    },
  ];

  const handleEdit = (registro) => {
    setRegistroActual(registro);
  };

  return (
    <div className="main-container-gestion-agencias">
      <div className="header-gestion-agencias">
        <Link to="/dashboard/inicio">
          <img src={btnBack} className="img-back" />
        </Link>
        <h1 className="title-gestion-agencias">
          Gestión de Talleres / Listado
        </h1>
      </div>
      <div className="sub-header-gestion-agencias">
        <TextField
          id="BusquedaGerente"
          className="input-busqueda"
          placeholder="Buscar por nombre"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  type="submit"
                  sx={{
                    p: "10px",

                    borderRadius: "40px",
                  }}
                  aria-label="search"
                  onClick={obtenerGerente}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Link to="/dashboard/nuevo-taller">
          <Button className="btn-crear-agencia">+ CREAR NUEVA</Button>
        </Link>
      </div>

      <div
        className="container-datagrid-gestion-agencias" /* style={{ height: 400, width: "100%" }} */
      >
        <div className="sub-container-datagrid-gestion-agencias">
          <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
            Tabla de Agencias
          </Typography>
          <DataGrid
            className="data-grid-gestion-agencias"
            rows={agencias}
            columns={columns}
            pageSize={10}
          />
          <UsuariosInternosModal
            open={usuariosInternosModalOpen}
            onClose={handleUsuariosInternosModalClose}
            rowData={selectedRow}
          />
        </div>
      </div>
    </div>
  );
}

export default GestionTalleres;
