/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import { toggleSidebar } from "../../../../redux/actions/userInterfaceAction";
import SidebarOptionItem from "./SidebarOptionItem";
import {
  LESSOR_MANAGEMENT,
  HOME,
  ADMINS_MANAGEMENT,
  LESSEE_MANAGEMENT,
  CAR_MANAGEMENT,
  TRANSACTIONS_MANAGEMENT,
  COLLECTION_MANAGEMENT,
  INTERN_MANAGEMENT,
  CAR_BRAND_MANAGEMENT,
  AGENCIES_MANAGEMENT,
  MI_PERFIL_MARANELLO,
} from "../../../../routes/Paths";

// SRC & STYLES
import "./Sidebar.scss";

import logo from "../../../../assets/images/maranello/LOGO_MARANELLO_BCO.png";
import home from "../../../../assets/images/maranello/Icon_Inicio_Bco.png";
import agencias from "../../../../assets/images/maranello/Icon_Agencia_Bco.png";
import talleres from "../../../../assets/images/maranello/Icon_Taller_Bco.png";
import user from "../../../../assets/images/maranello/Icon_Perfil_Bco.png";
import salir from "../../../../assets/images/maranello/Icon_CerrarSesión_Bco.png";
import { Link, useHistory } from "react-router-dom";
import { userLogout } from "../../../../redux/actions/userActions";
import { Button } from "@mui/material";

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onClickToogle = () => {
    props.toggleSidebar();
  };

  const openMenu = () => {
    const openButton = document.getElementById("menu");
    openButton.click();
  };

  const handleClick = () => {
    console.log("salir");
    userLogout();
    /* history.push('/iniciar-sesion'); */
  };

  console.log("props menu", props);

  const sidebarOptions = [
    {
      img: home,
      optionName: "Inicio",
      link: HOME,
    },
    {
      img: agencias,
      optionName: "Gestión de Agencias",
      link: AGENCIES_MANAGEMENT,
    },
    {
      img: talleres,
      optionName: "Gestión de Talleres",
      link: LESSOR_MANAGEMENT,
    },
    {
      img: user,
      optionName: "Mi Perfil",
      link: MI_PERFIL_MARANELLO,
    },
  ];

  return (
    <aside>
      <nav className="sidebar">
        <div className="header-logo">
          <div className="logo-container">
            <img src={logo} alt="Koopers logo" />
          </div>
          <div className="toogle-button-container">
            {/* <button
              className="header-toggle-btn pull-right"
              onClick={onClickToogle}
              id="menu"
            /> */}
            {/* <img className="menuButton" src={menu} onClick={openMenu} /> */}
          </div>
        </div>
        <div className="activePanel">
          {sidebarOptions.map((option) => (
            <SidebarOptionItem
              img={option.img}
              optionName={option.optionName}
              link={option.link}
              sidebarState={props.isSidebarOpened}
              onclick={onClickToogle}
            />
          ))}
        </div>
        <div className="salir-container">
          <Button
            className="container-img"
            onClick={() => {
              dispatch(userLogout());
            }}
          >
            <img src={salir} className="img-salir" />
            <p className="text-salir">Salir</p>
          </Button>
        </div>
      </nav>
    </aside>
  );
};

Sidebar.propTypes = {
  isSidebarOpened: PropTypes.any,
  toggleSidebar: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    isSidebarOpened: _.get(state.userInterface, "isSidebarOpened", false),
  };
}

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
