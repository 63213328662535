import React , {useState} from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';



// src & styles
import './ProfileImage.scss';
import edit from '../../../assets/images/koopers/edit-icon.png';
import profilePhoto from '../../../assets/images/koopers/defaultPhoto.png';


const ProfileImage = ({
    photo,
    onChangePhoto,
    allowOpenSelectFile,
}) => {

    console.log('allowOpenSelectFile: ', allowOpenSelectFile);

    const [ currentProfilePhoto, setCurrentProfilePhoto ] = useState(photo ? photo : profilePhoto);

    const onClickInputPhoto = () => {
        if (allowOpenSelectFile) {
            const inputPhoto = document.getElementById('inputPhoto');
            inputPhoto.click();
        }
    }

    const handleChangeImage = (event) => {
        setCurrentProfilePhoto(URL.createObjectURL(event.target.files[0]))
        // dispatch(changeSidebarLogoRequest(image));
        onChangePhoto(event);
    }

    return (
        <div>
            <input 
                id='inputPhoto' 
                type={'file'} 
                accept='image/*'
                onChange={(e) => handleChangeImage(e)}
                // {...register('photoProfile')}
            />
            <div className='form'>
                <div 
                    onClick={onClickInputPhoto}
                    className='profilePhotoContainer'
                    style={{position: 'relative'}}
                >
                    <img
                        className='profilePhotoImg' 
                        src={currentProfilePhoto}
                        style={{display: 'block'}}
                    />
                </div>
            </div>
            {
                allowOpenSelectFile && (
                    <button
                        onClick={onClickInputPhoto}
                        style={{position: 'relative', bottom: 0, left: 0, marginBottom: '30px'}}
                        className={'button-default'}
                    >
                        Cambiar Imagen
                    </button>
                )
            }
    </div>
    );


};

ProfileImage.propTypes = {
    isAuthenticated: PropTypes.any,
    children: PropTypes.any,
    onSubmit: PropTypes.func,
    docsTitle: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    photo: PropTypes.any,
    upsertFunction: PropTypes.func,
    addNotification: PropTypes.any,
    carsProfile: PropTypes.bool,
    brand: PropTypes.string,
    model: PropTypes.string,
    images: PropTypes.any,
    files: PropTypes.any,
    button: PropTypes.string,
    buttonsOnClick: PropTypes.func,
    gallery: PropTypes.bool,
    docContainerClassName: PropTypes.string,
    name: PropTypes.string,
    onChangePhoto: PropTypes.func,
    allowOpenSelectFile: PropTypes.bool,
}

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        admins: _.get(state, 'admin.admins', {}),
        lessors: _.get(state, 'lessor.lessors', {})
    };
}

const mapDispatchToProps = dispatch => ({
	
});

export default connect(mapStateToProps,mapDispatchToProps)(ProfileImage);
