import _ from 'lodash';
import {
  USER_MODULE_USER_LOGIN_BEGIN,
  USER_MODULE_USER_LOGIN_SUCCESS,
  USER_MODULE_USER_LOGIN_FAILURE,
  USER_MODULE_USER_LOGOUT_BEGIN,
  USER_MODULE_USER_LOGOUT_SUCCESS,
  USER_MODULE_USER_LOGOUT_FAILURE,
  USER_MODULE_USER_REGISTER_BEGIN,
  USER_MODULE_USER_REGISTER_SUCCESS,
  USER_MODULE_USER_REGISTER_FAILURE,
  USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_BEGIN,
  USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_SUCCESS,
  USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_FAILURE,
  USER_MODULE_USER_SET_NEW_PASSWORD_BEGIN,
  USER_MODULE_USER_SET_NEW_PASSWORD_SUCCESS,
  USER_MODULE_USER_SET_NEW_PASSWORD_FAILURE,
  USER_MODULE_USER_GET_INFORMATION_BEGIN,
  USER_MODULE_USER_GET_INFORMATION_SUCCESS,
  USER_MODULE_USER_GET_INFORMATION_FAILURE,
  USER_MODULE_USER_UPDATE_INFORMATION_BEGIN,
  USER_MODULE_USER_UPDATE_INFORMATION_SUCCESS,
  USER_MODULE_USER_UPDATE_INFORMATION_FAILURE,
} from '../actionTypes';

import initialState from '../initialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case USER_MODULE_USER_LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
        user: null,
      };
    case USER_MODULE_USER_LOGIN_SUCCESS:{
      return {
        ...state,
        request: {
          ...state.request,
          headers:{
            ...state.request.headers,
            Authorization: `Bearer ${action.user.token}`,
          },
        },
        loading: false,
        isAuthenticated: true,
        user: action.user,
      };
    }
    case USER_MODULE_USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error || "algun error",
        isAuthenticated: false,
        user: null,
      };
    case USER_MODULE_USER_LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_MODULE_USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        role: "",
        user: {},
        token: '',
      };
    case USER_MODULE_USER_LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error || 'Se ha generado algun error'
      };
    case USER_MODULE_USER_REGISTER_BEGIN:
      return {
        ...state,
      };
    case USER_MODULE_USER_REGISTER_SUCCESS:
      return {
        ...state,
      };
    case USER_MODULE_USER_REGISTER_FAILURE:
      return {
        ...state,
      };
    case USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_BEGIN:
      return {
        ...state,
        updatePasswordRequest: {
          ...state.updatePasswordRequest,
          loading: true,
          isSuccess: false,
          error: null,
        }
      };
    case USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        updatePasswordRequest: {
          ...state.updatePasswordRequest,
          loading: false,
          isSuccess: true,
          error: null,
        }
      };
    case USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        updatePasswordRequest: {
          ...state.updatePasswordRequest,
          loading: false,
          isSuccess: false,
          error: _.get(state , 'error' , false),
        }
      };
    case USER_MODULE_USER_SET_NEW_PASSWORD_BEGIN:
      return {
        ...state,
        setNewPassword: {
          ...state.setNewPassword,
          loading: true,
          isSuccess: false,
          error: null,
        }
      };
    case USER_MODULE_USER_SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        setNewPassword: {
          ...state.setNewPassword,
          loading: false,
          isSuccess: true,
          error: null,
        }
      };
    case USER_MODULE_USER_SET_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        setNewPassword: {
          ...state.setNewPassword,
          loading: false,
          isSuccess: false,
          error: _.get(state , 'error' , false),
        }
      };
      case USER_MODULE_USER_GET_INFORMATION_BEGIN:
        return {
          ...state,
          loading: true,
        }
      case USER_MODULE_USER_GET_INFORMATION_SUCCESS:
        return {
          ...state,
          loading: false,
          userInformation: action.data,
      }
      case USER_MODULE_USER_GET_INFORMATION_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error || 'Error en la petición',
        }
      case USER_MODULE_USER_UPDATE_INFORMATION_BEGIN:
        return {
          ...state,
          loading: true,
      }
      case USER_MODULE_USER_UPDATE_INFORMATION_SUCCESS:
        return {
          ...state,
          loading: false,
          userInformation: action.userInformation,
      }
      case USER_MODULE_USER_UPDATE_INFORMATION_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error || 'Error en la petición',
        }
    default:
      return state;
  }
}
