import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import searchicon from '../../../assets/images/searchIcon.png';
import './GlobalFilter.scss';

export default function GlobalFilter ({
    filter={},
    setFilter,
    showTextSearch=true,
    showDateRangeSearch=false,
    classNameSearch,
    classNameDate1 = 'inputDate first',
    classNameDate2 = 'inputDate second',
    placeholderSearch,
    placeholderDate,
    oneDateFilter,
}) {
    const onChange = (key,term) => {
        let filterCopy = _.cloneDeep(filter);
        filterCopy[key] = term;
        setFilter(filterCopy);
    };

    const renderDateRangeSearch = () => {
        return (
            <>
                <input
                    name={'dateRangeSearchBegin'}
                    value={filter['dateRangeSearchBegin'] || ''}
                    onChange={e => onChange('dateRangeSearchBegin', e.target.value)}
                    className={classNameDate1}
                    placeholder={placeholderDate}
                    type='date'
                />
                {
                    !oneDateFilter ? 
                    <input
                        name={'dateRangeSearchEnd'}
                        value={filter['dateRangeSearchEnd'] || ''}
                        onChange={e => onChange('dateRangeSearchEnd', e.target.value)}
                        className={classNameDate2}
                        placeholder={placeholderDate}
                        type='date'
                    /> 
                    : ''
                }
            </>
        );
    };

    return (
        <>
            {
                showTextSearch && (
                <input
                    name={'textSearch'}
                    value={filter['textSearch'] || ''}
                    onChange={e => onChange('textSearch', e.target.value)}
                    className={classNameSearch ? classNameSearch : 'inputSearch'}
                    style={{backgroundImage: `url(${searchicon})`}}
                    placeholder={placeholderSearch}
                />
                )
            }
            {
                showDateRangeSearch && renderDateRangeSearch()
            }
         
        </>
    );
}

GlobalFilter.propTypes = {
    filter: PropTypes.any,
    setFilter: PropTypes.any,
    showTextSearch: PropTypes.any,
    showDateRangeSearch: PropTypes.any,
    classNameSearch: PropTypes.any,
    classNameDate1: PropTypes.any,
    classNameDate2: PropTypes.any,
    placeholderSearch: PropTypes.any,
    placeholderDate: PropTypes.any,
    oneDateFilter: PropTypes.any,
}
