import { userLogout } from '../redux/actions/userActions';

export default async function ({
    partialUrl,
    method,
    body,
    headers=null,
    state, 
    dispatch=null,
    isFileUpload=false,
}) {
    let headersCreated = headers ? headers : state.headers
    if (isFileUpload && false) {
        headersCreated = {
            ...headersCreated,
            'Content-Type': 'multipart/form-data',
        }
    } else {
        //headersCreated = headers ? headers : state.headers,
    }
    // Aca hay que validar que el token no este vencido
    //return fetch(`https://koopersapi.beesoftware.dev${partialUrl}`, 
    /* return fetch(`http://localhost:1337${partialUrl}`, */
    return fetch(`https://meranelloapi.beesoftware.dev${partialUrl}`,
    {
        method,
       // headers: headers ? headers : state.headers,
        headers: headersCreated,
        // headers:new Headers({ ...(headers ? headers : state.headers) }) ,
      
        /*  headers: new Headers({ 
            'Content-Type': 'application/json',
            //'Access-Controll-Allow-Origin': '*',
        }),
        */

        body: method !== "GET" ? 
            (
                isFileUpload ? body : JSON.stringify({
                ...state.body,
                ...body,
                })
            ) : null,
    }).then(response => {
        if (!dispatch) {
            return response;
        }
        if (!response.ok) {
            if(response.status === 401) {
                dispatch(userLogout());
            }
            throw Error (response.statusText); // testiar
        }
        return response;
    });
}
