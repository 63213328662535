import React from "react";
import { Link, useLocation } from "react-router-dom";
import btnBack from "../../../assets/images/maranello/Icon_Atras.png";
import "./NewWorkshop.scss";
import StepperTaller from "../../../components/NewWorkshopStepper/NewWorkshopStepper";
import _ from "lodash";

function NewWorkshopPage() {
  const location = useLocation();
  const tipoPersona = _.get(location, "state.tipoPersona", "");
  return (
    <div className="main-container-stepper-nueva-agencia">
      <div className="header-gestion-agencias">
        <Link to="/dashboard/gestion-talleres">
          <img src={btnBack} className="img-back" alt="Volver" />
        </Link>
        <h1 className="title-gestion-agencias">
          Gestión de Talleres / Nuevo Taller
        </h1>
      </div>
      <div className="container-stepper-agencia">
        <div className="container-center-stepper">
          <StepperTaller tipoPersona={tipoPersona} />
        </div>
      </div>
    </div>
  );
}

export default NewWorkshopPage;
