import React, { useEffect, useState } from "react";
import "./EditarDatosPerfil.scss";
import {
  Button,
  TextField,
  Modal,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import CambiarContrasenaModal from "../CambiarContrasena/CambiarContrasenaModal";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCiudadesAction,
  getAllContriesAction,
  getAllEstadoAction,
} from "../../../redux/actions/countryAction";
import {
  getProfileAction,
  upsertProfileAction,
} from "../../../redux/actions/profileAction";
export default function EditarDatosPerfil({
  imagenBack,
  setImagenBack,
  urlimagenBack,
  setUrlImagenBack,
}) {
  const countriesGlobal = useSelector((state) => state.countries.countries);
  const ciudadesGlobal = useSelector((state) => state.cities.cities);
  const EstadosGlobal = useSelector((state) => state.states.states);
  const userLoggeado = useSelector((state) => state.user.user);
  const profileLogge = useSelector((state) => state.profile.profile);

  const [countries, setCountries] = React.useState({});
  const [states, setStates] = React.useState({});
  const [ciudades, setCiudades] = React.useState({});
  const [selectedState, setSelectedState] = useState("");
  const [userId, setUserId] = useState("");
  const [addressId, setAddressId] = useState("");
  const [country, setCountry] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    nombre: "Nombre",
    apellidos: "Apellidos",
    telefono: "123456789",
    correo: "correo@example.com",
    pais: "Pais",
    estado: "Estado",
    ciudad: "Ciudad/Municipio",
  });
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleFieldChange = (field, value) => {
    setUserData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    const data = {
      where: {
        countryId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllEstadoAction(data));
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    const data = {
      where: {
        stateId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllCiudadesAction(data));
  };

  const handleCiudadChange = (event) => {
    setCiudad(event.target.value);
  };

  React.useEffect(() => {
    dispatch(getAllContriesAction({}));
  }, []);

  useEffect(() => {
    if (_.get(userLoggeado, "person")) {
      console.log(userLoggeado.person);
      console.log(userLoggeado.id);
      setUserId(userLoggeado.person.id);
      const data = {
        userId: userLoggeado.id,
      };
      dispatch(getProfileAction(data));
      setUserData({
        nombre: userLoggeado.person.firstName,
        apellidos: userLoggeado.person.firstLastName,
        telefono: userLoggeado.person.primaryPhone,
        correo: userLoggeado.email,
      });
    }
  }, [userLoggeado]);

  useEffect(() => {
    console.log("profileLogge", profileLogge);
    if (_.get(profileLogge, "data.address")) {
      console.log(profileLogge.data.address);
      setAddressId(profileLogge.data.address.id);
      setCountry(profileLogge.data.address.countryId);
      const data = {
        where: {
          countryId: profileLogge.data.address.countryId,
        },
      };
      dispatch(getAllEstadoAction(data));
    }
  }, [profileLogge]);

  useEffect(() => {
    console.log("profileLogge", profileLogge);
    if (_.get(profileLogge, "data.address")) {
      console.log(profileLogge.data.address);
      const data = {
        where: {
          stateId: profileLogge.data.address.stateId,
        },
      };

      dispatch(getAllCiudadesAction(data));
      setSelectedState(profileLogge.data.address.stateId);
      setCiudad(profileLogge.data.address.cityId);
    }
  }, [country]);
  React.useEffect(() => {
    if (_.get(countriesGlobal, "data.rows[0]")) {
      setCountries(countriesGlobal.data.rows);
    }
  }, [countriesGlobal]);

  React.useEffect(() => {
    if (_.get(ciudadesGlobal, "data.rows[0]")) {
      setCiudades(ciudadesGlobal.data.rows);
    }
  }, [ciudadesGlobal]);

  React.useEffect(() => {
    if (_.get(EstadosGlobal, "data.rows[0]")) {
      setStates(EstadosGlobal.data.rows);
    }
  }, [EstadosGlobal]);

  const handleGuardarClick = () => {
    console.log("Datos guardados:", userData);

    const data = {
      person: {
        id: userId,
        firstName: userData.nombre,
        firstLastName: userData.apellidos,
        primaryPhone: userData.telefono,
      },
      address: {
        id: addressId,
        countryId: country,
        stateId: selectedState,
        cityId: ciudad,
      },
    };
    const dataLogo = {
      person: {
        id: userId,
        firstName: userData.nombre,
        firstLastName: userData.apellidos,
        primaryPhone: userData.telefono,
        logo: urlimagenBack,
      },
      address: {
        id: addressId,
        countryId: country,
        stateId: selectedState,
        cityId: ciudad,
      },
    };
    console.log("data", data);
    if (imagenBack) {
      dispatch(upsertProfileAction(dataLogo));
    } else {
      dispatch(upsertProfileAction(data));
    }
  };

  return (
    <div className="main-container-datos-mi-perfil">
      <div className="container-inputs-mi-perfil">
        <p className="title-informacion-personal">INFORMACION PERSONAL</p>
        <div className="container-flex-input">
          <div className="container-inputs">
            <div className="container-inputs-field">
              <TextField
                className="white-background"
                label="Nombre"
                margin="dense"
                variant="filled"
                value={userData.nombre}
                onChange={(e) => handleFieldChange("nombre", e.target.value)}
              />
            </div>
            <div className="container-inputs-field">
              <TextField
                className="white-background"
                label="Apellidos"
                margin="dense"
                variant="filled"
                value={userData.apellidos}
                onChange={(e) => handleFieldChange("apellidos", e.target.value)}
              />
            </div>
            <div className="container-inputs-field">
              <TextField
                className="white-background"
                label="Teléfono"
                margin="dense"
                variant="filled"
                value={userData.telefono}
                onChange={(e) => handleFieldChange("telefono", e.target.value)}
              />
            </div>
            <div className="container-inputs-field">
              <TextField
                className="white-background"
                label="Correo"
                margin="dense"
                disabled
                variant="filled"
                value={userData.correo}
                onChange={(e) => handleFieldChange("correo", e.target.value)}
                InputProps={{
                  style: { backgroundColor: "white" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="container-inputs-field">
              <TextField
                className="textfield-stepper"
                id="pais"
                name="pais"
                style={{ width: "90%" }}
                label="País *"
                variant="filled"
                margin="dense"
                placeholder="Seleccionar"
                select
                value={country}
                onChange={handleCountryChange}
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
              >
                {countries &&
                  countries.length > 0 &&
                  countries.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="container-inputs-field">
              <TextField
                id="estado"
                className="textfield-stepper"
                label="Estado *"
                variant="filled"
                name="estado"
                margin="dense"
                placeholder="Seleccionar"
                style={{ width: "90%" }}
                select
                value={selectedState}
                onChange={handleStateChange}
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
              >
                {states &&
                  states.length > 0 &&
                  states.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="container-inputs-field">
              <TextField
                id="ciudad"
                className="textfield-stepper"
                label="Ciudad/Municipio *"
                name="ciudad"
                margin="dense"
                variant="filled"
                placeholder="Seleccionar"
                select
                value={ciudad}
                onChange={handleCiudadChange}
                style={{ width: "90%" }}
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
              >
                {ciudades &&
                  ciudades.length > 0 &&
                  ciudades.map((elemento) => (
                    <MenuItem key={elemento.id} value={elemento.id}>
                      {elemento.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>

            <div className="container-inputs-field">
              <p className="cambiar-contrasena-text" onClick={handleModalOpen}>
                Cambiar contraseña
              </p>
            </div>
          </div>
        </div>
        <div className="container-button">
          <Button onClick={() => handleGuardarClick()} className="btn-guardar">
            Guardar
          </Button>
        </div>
      </div>

      <CambiarContrasenaModal open={modalOpen} handleClose={handleModalClose} />
    </div>
  );
}
