import request from '../../utils/request';
import { addNotification } from './notificationAction';


import {
    TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_BEGIN,
    TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_SUCCESS,
    TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_FAILURE,
} from '../actionTypes';

// ACTIONS TO REDUCER

export const getAllTransactionsBegin = () => ({
    type: TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_BEGIN,
});

export const getAllTransactionsSuccess = (data) => ({
    type: TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_SUCCESS,
    data,
});

export const getAllTransactionsFailure = (error) => ({
    type: TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_FAILURE,
    error,
});

// DISPATCH TO ACTIONS

export function getAllTransactionsAction(data) {
    return (dispatch, getState) => {
        dispatch(getAllTransactionsBegin());
        return request({
            partialUrl: `/api/v1/transaction/get-all-transactions-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha recuperado la información con éxito',
            }));
            localStorage.setItem('user', JSON.stringify(data.data.data));
            dispatch(getAllTransactionsSuccess(data.data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha podido realizar la acción, intentelo de nuevo',
            }));
            return dispatch(getAllTransactionsFailure(err));
        });
    };
}
