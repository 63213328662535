import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Link from '../../../atoms/Link1/Link1';
import './SidebarOptionItem.scss';

const SidebarOptionItem = ({
  icon = 'sidebarItemIconContainer',
  optionName,
  text = 'link',
  link,
  img,
  sidebarState,
  onclick,
}) => {
  const history = useHistory();
  const location = useLocation();

  const isSidebarOpened = () => {
    if (sidebarState) {
      onclick();
    }
  };

  const isActive = location.pathname === link;

  return (
    <div
      className={`optionItemContainer ${isActive ? 'active' : ''} ${
        sidebarState === false ? '' : 'open'
      }`}
      key={optionName}
      onClick={() => {
        history.push(link);
        isSidebarOpened();
      }}
    >
      <div className={icon}>
        <img className="iconImg" src={img} alt={optionName} />
      </div>
      <div
        className={`${
          sidebarState ? 'openOptionItem' : 'closeOptionItem'
        } ${isActive ? 'activeOptionItem' : ''}`}
      >
        <Link to={link} className={text} id={optionName}>
          {optionName}
        </Link>
      </div>
    </div>
  );
};

SidebarOptionItem.propTypes = {
  span: PropTypes.any,
  icon: PropTypes.any,
  img: PropTypes.any,
  optionName: PropTypes.any,
  text: PropTypes.any,
  link: PropTypes.string,
  activeIconClassName: PropTypes.any,
  sidebarState: PropTypes.any,
  onclick: PropTypes.func,
};

export default SidebarOptionItem;
