/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams,  } from 'react-router-dom';
import _ from 'lodash';
import InputKoopers from '../../../components/molecules/InputKoopers';
import Button from '../../../components/atoms/Button/Button';
import { 
    addNotification
} from '../../../redux/actions/notificationAction';
import {
    updateLessorAction,
} from '../../../redux/actions/lessorAction';
import {
    getUserInformationRequest
} from '../../../redux/actions/userActions';

// src & styles
import '../ProfileModule/Profile.scss';
import ProfileCard from '../../../components/organisms/ProfileCard';

const LessorProfile = (props) => {
    const { lessorId } = useParams();
    const lessor = useSelector(state => state.lessor.lessors.data).find(lessor => lessor.id == lessorId);
    const history = useHistory();

    let name = ''; 
    if (!_.isEmpty(lessor.person.firstName)) name = lessor.person.firstName.toUpperCase();

    let lastName = ''; 
    if (!_.isEmpty(lessor.person.firstLastName)) lastName = lessor.person.firstLastName.toUpperCase();

    const [ userInformation, setUserInformation ] = useState({
        userId: lessor.user.id,
        firstName: `${name[0]}${name.substring(1).toLowerCase()}`,
        firstLastName: `${lastName[0]}${lastName.substring(1).toLowerCase()}`,
        email: lessor.person.primaryEmail,
        primaryPhone: lessor.person.primaryPhone,
        profilePhoto: lessor.person.profileImageMedia
        // .publicURL.replace('http://localhost:1337/', 'https://koopersapi.beesoftware.dev/'),
    });

    const onSubmitUpdateLessor = (data) => {

        const formData_ = new FormData();
            formData_.append("userId", data.userId);
            formData_.append("user", `{"email": "${data.email}"}`);
            formData_.append("person", `{"firstName": "${data.firstName.toLowerCase()}", "firstLastName": "${data.firstLastName.toLowerCase()}", "primaryPhone": "${data.primaryPhone}", "primaryEmail": "${data.email}" }`);
            formData_.append("lessor", "{}");
            formData_.append('profileImageMediaFile', data.profileImageMediaFile);

        props.updateLessorAction(formData_);
        history.goBack();
    }

    let profilePhoto;
    const publicURL = _.get(userInformation, 'profilePhoto.publicURL', null);
    if (!_.isNil(publicURL)) {
    //     debugger
        profilePhoto = publicURL/* .replace('http://localhost:1337/', 'https://koopersapi.beesoftware.dev/')*/ 
    } 
    console.log('¨Phooto: ', profilePhoto);
    return (
        // <div className='profileCardContainer'>
            <ProfileCard
                docsTitle='DOCUMENTACIÓN'
                title={`${userInformation.firstName} ${userInformation.firstLastName}`}
                photo={!_.isEmpty(profilePhoto) && profilePhoto}
                // upsertFunction={() => onSubmitUpdateLessor({...userInformation})}
                button={'GUARDAR'}
                buttonsOnClick={() => onSubmitUpdateLessor({...userInformation})}
                onChangePhoto={(e) => {
                    setUserInformation({...userInformation, profileImageMediaFile: e.target.files[0]})
                }}
            >
                <InputKoopers 
                    mode={'user'} 
                    value={userInformation.firstName}
                    onChange={(e) => setUserInformation({...userInformation, firstName: e.target.value})}
                />
                <InputKoopers 
                    mode={'user'} 
                    value={userInformation.firstLastName}
                    onChange={(e) => setUserInformation({...userInformation, firstLastName: e.target.value})}
                />
                <InputKoopers 
                    mode={'mail'} 
                    value={userInformation.email}
                    onChange={(e) => setUserInformation({...userInformation, email: e.target.value})}
                />
                <InputKoopers
                    mode={'phone'}
                    value={userInformation.primaryPhone}
                    onChange={(e) => setUserInformation({...userInformation, primaryPhone: e.target.value})}
                />
            </ProfileCard>  
        // </div>
    )
}

LessorProfile.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    userRegister: PropTypes.any,
    companies: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    user: PropTypes.object,
    updateLessorAction: PropTypes.func,
    addNotification: PropTypes.func,
    updateLessor: PropTypes.any,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        lessors: _.get(state, 'lessor.lessors', {}),
        updateLessor: _.get(state, 'lessor.updateLessor', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    getUserInformationRequest: params => dispatch(getUserInformationRequest(params)),
    // updateUserInformationRequest: params => dispatch(updateUserInformationRequest(params)),
    updateLessorAction: params => dispatch(updateLessorAction(params)),
    addNotification: params => dispatch(addNotification(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(LessorProfile);
