/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import InputKoopers from '../molecules/InputKoopers';
import Button from '../atoms/Button/Button';
import Modal from './Modal/Modal';

// src & styles
import './ProfileCard.scss';
import profilePhoto from '../../assets/images/koopers/defaultPhoto.png';
import plusDocs from '../../assets/images/koopers/iconCirclePlus.png'
import fileImg from '../../assets/images/koopers/document.png';

const GenericCardContainer = (
    {
        photo,
        title,
        children,
    }
) => {
    const [ currentProfilePhoto, setCurrentProfilePhoto ] = useState(photo ? photo : profilePhoto);
    const [ uploadedFiles, setUploadedFiles ] = useState([]);
    const [ isOpen, setIsOpen ] = useState(false);

    const { register, handleSubmit } = useForm();

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    let name = title ? title.toUpperCase() : '';

    const onClickInputPhoto = () => {
        const inputPhoto = document.getElementById('inputPhoto');
        inputPhoto.click();
    }

    const handleChangeImage = (event) => {
        setCurrentProfilePhoto(URL.createObjectURL(event.target.files[0]))
        // dispatch(changeSidebarLogoRequest(image));
    }

    const onClickInputDocument = () => {
        const inputDocs = document.getElementById('docsProfileInput');
        inputDocs.click();
    }

    const handleAddDocument = (event) => {
        setUploadedFiles(uploadedFiles.push((event.target.files[0])));
    }

    return (
        <div className='profileGeneralContainer'>
            <div className='profileCard'>
                    {/* <input 
                        id='inputPhoto' 
                        type={'file'} 
                        accept='image/*'
                        onChange={(e) => handleChangeImage(e)}
                        // {...register('photoProfile')}
                    /> */}
                <div className='form'>
                    {
                        <>
                            <div 
                                onClick={onClickInputPhoto}
                                className='profilePhotoContainer'
                            >
                                <img className='profilePhotoImg' src={currentProfilePhoto} />
                            </div> 
                            <div className='profileNameContainer'>
                                <h2>{name}</h2>
                            </div>
                        </>
                    }
                    <div className='formContainer'>
                        {children}
                    </div>
                </div>
            </div>
        
        </div>
    )
}

GenericCardContainer.propTypes = {
    isAuthenticated: PropTypes.any,
    children: PropTypes.any,
    onSubmit: PropTypes.func,
    docsTitle: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    photo: PropTypes.any,
    upsertFunction: PropTypes.func,
    addNotification: PropTypes.any,
    carsProfile: PropTypes.bool,
    brand: PropTypes.string,
    model: PropTypes.string,
    images: PropTypes.any,
    files: PropTypes.any,
    button: PropTypes.string,
    buttonsOnClick: PropTypes.func,
    gallery: PropTypes.bool,
    docContainerClassName: PropTypes.string,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        admins: _.get(state, 'admin.admins', {}),
        lessors: _.get(state, 'lessor.lessors', {})
    };
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(GenericCardContainer);
