/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory,  } from 'react-router-dom';
import _ from 'lodash';
import './Home.scss';
import logo from '../../../assets/images/maranello/LOGO_MARANELLO_BCO.png';

const Home = () => {
    return (
        <>
            <div className='home-main'>
                <img src={logo} />
            </div>
        </>
    )
}

Home.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    userRegister: PropTypes.any,
    getAllCompaniesAction: PropTypes.any,
    companies: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        companies: _.get(state, 'company.companies', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    // getAllCompaniesAction: params => dispatch(getAllCompaniesAction(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(Home);
