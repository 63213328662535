/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import InputKoopers from '../../../components/molecules/InputKoopers';
import Button from '../../../components/atoms/Button/Button';
import { 
    getUserInformationRequest,
    updateUserInformationRequest,
} from '../../../redux/actions/userActions';
import { updateLesseeAction } from '../../../redux/actions/lesseeActions';

// src & styles
import '../ProfileModule/Profile.scss';
import ProfileCard from '../../../components/organisms/ProfileCard';

const LesseeProfile = (props) => {

    const { lesseeId } = useParams();

    const lessee = useSelector(state => state.lessee.lessees.data).find(lessee => lessee.id == lesseeId); 
    const history = useHistory();
    const dispatch = useDispatch();

    let name = ''; 
    if (!_.isEmpty(lessee.person.firstName)) name = lessee.person.firstName.toUpperCase();

    let lastName = ''; 
    if (!_.isEmpty(lessee.person.firstLastName)) lastName = lessee.person.firstLastName.toUpperCase();

    const [ userInformation, setUserInformation ] = useState({
        userId: lessee.user.id,
        firstName: `${name[0]}${name.substring(1).toLowerCase()}`,
        firstLastName: `${lastName[0]}${lastName.substring(1).toLowerCase()}`,
        email: lessee.person.primaryEmail,
        primaryPhone: lessee.person.primaryPhone,
        // profilePhoto: lessee.person.profileImageMedia.publicURL.replace('http://localhost:1337/', 'https://koopersapi.beesoftware.dev/'),
    });
    let profilePhoto;
    if (!_.isNil(lessee.person.profileImageMedia) && lessee.person.profileImageMedia.publicURL) {
        //profilePhoto = lessee.person.profileImageMedia.publicURL.replace('http://localhost:1337/', 'https://koopersapi.beesoftware.dev/')
        profilePhoto = lessee.person.profileImageMedia.publicURL;
    }

    // useEffect(() => {
    //     dispatch(getUserInformationRequest(userInformation));
    // }, [])

    const onSubmitUpdateLessee = (data) => {

        const formData_ = new FormData();
            formData_.append("userId", data.userId);
            formData_.append("user", `{"email": "${data.email}"}`);
            formData_.append("person", `{"firstName": "${data.firstName.toLowerCase()}", "firstLastName": "${data.firstLastName.toLowerCase()}", "primaryPhone": "${data.primaryPhone}", "primaryEmail": "${data.email}" }`);
            formData_.append("lessee", "{}");
            formData_.append('profileImageMediaFile', data.profileImageMediaFile);

        props.updateLesseeAction(formData_);
        history.goBack();
    }

    return (
        <div className='profileCardContainer'>
            <ProfileCard
                docsTitle='DOCUMENTACIÓN'
                title={`${userInformation.firstName} ${userInformation.firstLastName}`}
                // location={location}
                photo={!_.isNil(profilePhoto) && profilePhoto}
                // button={'GUARDAR'}
                // buttonsOnClick={() => onSubmitUpdateLessee({...userInformation})}
                onChangePhoto={(e) => {
                    setUserInformation({...userInformation, profileImageMediaFile: e.target.files[0]})
                }}
            >
                <InputKoopers 
                    mode={'user'} 
                    value={userInformation.firstName}
                    onChange={(e) => setUserInformation({...userInformation, firstName: e.target.value})}
                />
                <InputKoopers 
                    mode={'user'} 
                    value={userInformation.firstLastName}
                    onChange={(e) => setUserInformation({...userInformation, firstLastName: e.target.value})}
                />
                <InputKoopers 
                    mode={'mail'} 
                    value={userInformation.email}
                    onChange={(e) => setUserInformation({...userInformation, email: e.target.value})}
                />
                <InputKoopers
                    mode={'phone'}
                    value={userInformation.primaryPhone}
                    onChange={(e) => setUserInformation({...userInformation, primaryPhone: e.target.value})}
                />
                <Button onClick={() => onSubmitUpdateLessee(userInformation)}>GUARDAR</Button>
            </ProfileCard>             
        </div>
    )
}

LesseeProfile.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    userRegister: PropTypes.any,
    companies: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    user: PropTypes.object,
    updateLessee: PropTypes.any,
    updateLesseeAction: PropTypes.any,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        lessee: _.get(state, 'lessee.lessees', {}),
        updateLessee: _.get(state, 'lesse.updateLessee', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    updateLesseeAction: params => dispatch(updateLesseeAction(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(LesseeProfile);
