import React, { useEffect, useState } from "react";
import "./NewWorkshopStepper.scss";
import { Box, Button, Modal, Step, StepButton, Stepper } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import DatosPrincipales from "./Steps/DatosPrincipales";
import DatosContacto from "./Steps/DatosContacto";
import DatosFiscales from "./Steps/DatosFiscales";
import DatosRepresentante from "./Steps/DatosRepresentante";
import Documentacion from "./Steps/Documentacion";
import { Typography } from "@material-ui/core";
import { values } from "lodash";
import {
  getAllWorkshopsAction,
  upsertWorkshopsAdmin,
} from "../../redux/actions/workshopsAction";

function NewWorkshopStepper({ tipoPersona }) {
  const [isMoral, setIsMoral] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [valores, setValores] = React.useState();
  const [completed, setCompleted] = React.useState({});
  const [datosPersonalData, setDatosPersonalData] = React.useState("367");
  const dispatch = useDispatch();
  const history = useHistory();
  const steps = [
    /*  */
    DatosPrincipales,
    DatosContacto,
    DatosFiscales,
    DatosRepresentante,
    Documentacion,
  ];
  const ActiveStep = steps[activeStep];
  const validationSchema = ActiveStep.validationSchema;

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  useEffect(() => {
    console.log(tipoPersona);
    console.log("isMoral", isMoral);
    if (tipoPersona == 1) {
      console.log("soy 1");
      setIsMoral(false); // Si tipoPersona es igual a 1, deshabilitamos isMoral (representante legal)
    } else {
      setIsMoral(true); // Si tipoPersona es diferente de 1, habilitamos isMoral (representante legal)
      console.log("soy 2");
    }
  }, [tipoPersona]);

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const isPreLastStep = () => {
    return activeStep === steps.length - 2;
  };

  const allStepsCompleted = () => {
    return completedSteps() === steps.length;
  };

  const handleNext = async (values, formikBag) => {
    const dataEnvioPersonaMoral = {
      data: {
        workshopType: tipoPersona,
        agencyId: values.agenciId,
        workshopData: {
          businessName: values.nombreComercial,
          description: values.Descripcion,
          colorCode: values.ColorContraste,
          address: {
            countryId: values.Pais,
            stateId: values.Estado,
            cityId: values.Ciudad,
            street1: values.Direccion,
          },
          gatewayPayments: {
            stripe: values.stripe,
            paypal: values.paypal,
          },
          logo: values.logo,
          letterHead: values.letterHead,
        },
        workshopContactDetails: {
          primaryPhone: values.telefono,
          whatsApp: values.whatsApp,
          primaryEmail: values.correo,
          website: values.PaginaWeb,
        },
        taxData: {
          companyName: values.razonSocial,
          rfc: values.rfc,
          proxyNumber: values.numeroPoder,
          charterNumber: values.numeroActa,
          address: {
            countryId: values.PaisFiscal,
            stateId: values.EstadoFiscal,
            cityId: values.CiudadFiscal,
            street1: values.DireccionFiscal,
          },
          representativeDetails: {
            person: {
              firstName: values.nombreRepresentante,
              firstLastName: values.apellidoRepresentante,
              primaryPhone: values.telefonoRepresentante,
              primaryEmail: values.corrreoRepresentante,
              curp:values.curpRepresentante
            },
            address: {
              countryId: values.PaisRepresentante,
              stateId: values.EstadoRepresentante,
              cityId: values.CiudadRepresentante,
              street1: values.direccionRepresentate,
            },
          },
        },
        files: {
          proofAddress: values.file0,
          ine: values.file1,
          fiscalConstancy: values.file2,
          charterNumber: values.file3,
          proxyNumber: values.file4,
        },
      },
    };
    const dataEnvioPersonaFisica = {
      data: {
        workshopType: tipoPersona,
        agencyId: values.agenciId,
        workshopData: {
          businessName: values.nombreComercial,
          description: values.Descripcion,
          colorCode: values.ColorContraste,
          address: {
            countryId: values.Pais,
            stateId: values.Estado,
            cityId: values.Ciudad,
            street1: values.Direccion,
          },
          personFisicName: values.NombrePersonaFisica,
          gatewayPayments: {
            stripe: values.stripe,
            paypal: values.paypal,
          },
          logo: values.logo,
          letterHead: values.letterHead,
        },
        workshopContactDetails: {
          primaryPhone: values.telefono,
          whatsApp: values.whatsApp,
          primaryEmail: values.correo,
          website: values.PaginaWeb,
        },
        taxData: {
          companyName: values.razonSocial,
          rfc: values.rfc,
          address: {
            countryId: values.PaisFiscal,
            stateId: values.EstadoFiscal,
            cityId: values.CiudadFiscal,
            street1: values.DireccionFiscal,
          },
        },
        files: {
          proofAddress: values.file0,
          ine: values.file1,
          fiscalConstancy: values.file2,
          /* charterNumber: values.file3,
          proxyNumber: values.file4, */
        },
      },
    };
    if (!isLastStep()) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;

      if (!isMoral && newActiveStep === 3) {
        setActiveStep(newActiveStep + 1);
      } else {
        setActiveStep(newActiveStep);
      }
    } else {
      if (tipoPersona == 1) {
        console.log("values", values);
        console.log("dataEnvioPersonaFisica", dataEnvioPersonaFisica);
        dispatch(upsertWorkshopsAdmin(dataEnvioPersonaFisica));
        dispatch(getAllWorkshopsAction());
        history.push("/dashboard/gestion-talleres");
      } else {
        console.log("dataEnvioPersonaMoral", dataEnvioPersonaMoral);
        dispatch(upsertWorkshopsAdmin(dataEnvioPersonaMoral));
        dispatch(getAllWorkshopsAction());
        history.push("/dashboard/gestion-talleres");
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      history.goBack();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleBackDocumentacion = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };

  const handleFormSubmit = async (values, formikBag) => {
    if (isLastStep()) {
      console.log("Último paso alcanzado. Imprimiendo datos...");
      console.log("Tipo de persona:", tipoPersona);
      console.log("Valores:", values);
      if (tipoPersona === 1) {
        const dataEnvioPersonaFisica = {
          // Construir el objeto de envío con los datos necesarios
        };
        console.log(
          "Enviando datos de persona física:",
          dataEnvioPersonaFisica
        );
        /* dispatch(upsertAgenciesAdmin(dataEnvioPersonaFisica)); */
      } else {
        const dataEnvioPersonaMoral = {
          // Construir el objeto de envío con los datos necesarios
        };
        console.log("Enviando datos de persona moral:", dataEnvioPersonaMoral);
        /* dispatch(upsertAgenciesAdmin(dataEnvioPersonaMoral)); */
      }
    }
  };

  //igual
  const initialValues = steps.reduce(
    (values, { initialValues }) => ({
      ...values,
      ...initialValues,
    }),
    {}
  );
  const totalSteps = steps.length;

  useEffect(() => {
    console.log("values", values);
  }, [values]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, touched, values, setFieldValue }) => (
          <Form>
            <Stepper nonLinear activeStep={activeStep}>
              {/* Mostrar solo los pasos de DatosRepresentante si isMoral es verdadero */}
              {steps.map((StepComponent, index) => {
                if (index === 3 && !isMoral) {
                  return null;
                }
                return (
                  <Step key={index} completed={completed[index]}>
                    <StepButton color="inherit">
                      {StepComponent.label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              <div style={{}}>
                <SwipeableViews index={activeStep}>
                  {/* Mostrar solo los pasos de DatosRepresentante si isMoral es verdadero */}
                  {steps.map((StepComponent, index) => {
                    if (index === 3 && !isMoral) {
                      return null;
                    }
                    return (
                      <StepComponent
                        key={index}
                        setFieldValue={setFieldValue.bind(this)}
                        values={values}
                        handleNext={() => handleNext(values, setFieldValue)}
                        handleBack={handleBack}
                        handleFormSubmit={handleFormSubmit}
                        isMoral={isMoral}
                        handleBackDocumentacion={handleBackDocumentacion}
                      />
                    );
                  })}
                </SwipeableViews>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
export default NewWorkshopStepper;
