import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';

// src & styles
import './RenderStatus.scss';

const RenderStatus = ({
	status=[],
	showOnlyActives=true,
}) => {

	const getStatusToShow = () => {
		if (_.isNil(status)) return [];
		const data = status.filter( s => {
			if (showOnlyActives) {
				return _.isNil(s.deletedAt);
			}
			return false;
		});
		return data;
	};

	return (
		<>
			<ul>
				{
					// eslint-disable-next-line react/jsx-key
					getStatusToShow().map( item => <li>{item.status.displayText}</li>)
				}
			</ul>
		</>
	);
};

RenderStatus.propTypes = {
    status: PropTypes.any,
	showOnlyActives: PropTypes.any,
};

export default RenderStatus;

