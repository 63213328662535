import React from 'react';
import PropTypes from 'prop-types';

// src & styles
import './SpanKoopers.scss';
import userImg from '../../assets/images/koopers/inputUser.png';
import atImg from '../../assets/images/koopers/icon-at.png';
import keyImg from '../../assets/images/koopers/key.png';
import phoneImg from '../../assets/images/koopers/phone.png';
import car from '../../assets/images/koopers/car.png';
import money from '../../assets/images/koopers/money.png';
import personIcon from '../../assets/images/koopers/user-icon.png';
import config from '../../assets/images/koopers/services.png';

const InputKoopers = ({
    tag,
    info,
    mode,
    id,
}) => {

    let img = '';

    if (mode == 'user') img = userImg;
    else if (mode == 'mail') {
        img = atImg;
    }
    else if (mode == 'password') {
        img = keyImg;
    }
    else if (mode == 'phone') {
        img = phoneImg;
    }
    else if (mode == 'car') {
        img = car;
    }
    else if (mode == 'cost') {
        img = money;
    }
    else if (mode == 'person') {
        img = personIcon;
    }
    else if (mode == 'configuration') {
        img = config;
    }


    return (
        <div
            className='infoTagContainer'
            id={id}
            >
            <div className='tagNameContainer'
                style={{ backgroundImage: `url(${img})`}}
            >
                {tag}
            </div>
            <div className='infoTextContainer'>{info}</div>
        </div>
    )
}

InputKoopers.propTypes = {
    mode: PropTypes.string,
    tag: PropTypes.string,
    info: PropTypes.string,
    id: PropTypes.string,
}

export default InputKoopers;
