import { NOTIFICATION_ADD } from '../actionTypes';

//https://fkhadra.github.io/react-toastify/api/toast
export function addNotification({toastType, toastTitle, toastConfig}){
    return (dispatch) => {
        return dispatch({
            type: NOTIFICATION_ADD,
            toastType,
            toastTitle,
            toastConfig,
        });
    }
}
