import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Email.scss';

// eslint-disable-next-line react/prop-types
const Email = ({
	email,
}) => {
    return (
		<a href={`tel:${email}`}>
			<span>{email}</span>
		</a>
    );
}

Email.protoTypes = {
    props: PropTypes.any,
    email: PropTypes.any,
};

export default Email;
