import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import Button from "../../../components/atoms/Button/Button";
import "./../../../components/templates/FullScreenLayout/FullScreenLayout.scss";
import { setNewPasswordAction } from "../../../redux/actions/userActions";
import logo from "../../../assets/images/maranello/LOGO_MARANELLO_NGR.png";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import "./ChangePasswordKoala.scss";

const SetNewPasswordForm = (props) => {
  const history = useHistory();
  let { token } = useParams();
  const { register, handleSubmit } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    if (
      !_.isNil(props.setNewPassword.isSuccess) &&
      props.setNewPassword.isSuccess
    ) {
       history.push("/");
    }
  }, [props.setNewPassword.isSuccess]);

  const onSubmitForm = (formData) => {
    if (formData.password !== formData.repeatPassword) {
      setPasswordError(true);
      return; // Detener el envío del formulario si las contraseñas no coinciden
    }
    const data = {
      password: formData.password,
      token: token,
    };

    props.setNewPasswordAction(data);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className="form-logIn">
      <div className={"log-in"}>
        <div className="form-logo-container">
          <img className="logo-forms" src={logo} alt="" />
        </div>
        <h2
          className="header-form-auth"
          style={{ fontSize: "26px", padding: "1rem" }}
        >
          INGRESE SU NUEVA CONTRASEÑA
        </h2>
        <div className="inputs-container-auth">
          <TextField
            type={showPassword ? "text" : "password"}
            label="Nueva contraseña"
            className="input-generic"
            margin="dense"
            {...register("password")}
            placeholder="Introduzca nueva contraseña"
            error={passwordError}
            helperText={passwordError ? "Las contraseñas no coinciden" : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            type={showRepeatPassword ? "text" : "password"}
            label="Repetir nueva contraseña"
            className="input-generic"
            margin="dense"
            {...register("repeatPassword")}
            placeholder="Repita nueva contraseña"
            error={passwordError}
            helperText={passwordError ? "Las contraseñas no coinciden" : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle repeat password visibility"
                    onClick={handleToggleRepeatPasswordVisibility}
                  >
                    {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="container-button">
          <Button className="btn-login-form">Aceptar</Button>
        </div>
      </div>
    </form>
  );
};

SetNewPasswordForm.propTypes = {
  setNewPasswordAction: PropTypes.func,
  setNewPassword: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    setNewPassword: _.get(state.user, "setNewPassword", {}),
  };
}

const mapDispatchToProps = (dispatch) => ({
  setNewPasswordAction: (params) => dispatch(setNewPasswordAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetNewPasswordForm);
