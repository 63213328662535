import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./EditWorkshop.scss";
import EditarFotos from "../../../components/EditarTalleresComponentes/EditarFoto/EditarFotos";
import TabsEditables from "../../../components/EditarTalleresComponentes/TabsEditables/TabsEditables";
import btnBack from "../../../assets/images/maranello/Icon_Atras.png";

function EditWorkshop() {
  const location = useLocation();
  const [dataAgencie, setdataAgencie] = useState()
  const [logoChanged, setLogoChanged] = useState(false);
  const [logo, setLogo] = useState();

  /* const { selectedData } = location.state;
  console.log("selectedData", selectedData); */

  useEffect(() => {
    console.log("selectedData", location);
    if(_.get(location.state,"item")){
      console.log("location.state.item", location.state.item);
      if(_.get(location.state,"item.workshopData.logo")){
        setLogo(location.state.item.workshopData.logo)
      }


      setdataAgencie(location.state.item)
    }
  }, [location]);




  return (
    <div className="container-editar-agencia">
      <div className="header-gestion-agencias">
        <Link to="/dashboard/inicio">
          <img src={btnBack} className="img-back" />
        </Link>
        <h1 className="title-gestion-agencias">
        Gestión de Talleres / Detalle de Talleres
        </h1>
      </div>

      <div className="container-info-editable-agencia"> 
        <div className="container-editar-foto">
          <EditarFotos dataAgencie={dataAgencie} setLogo={setLogo} logoChanged={logoChanged} setLogoChanged={setLogoChanged}/>
        </div>
        <div className="container-tabs-editables">
          <TabsEditables dataAgencie={dataAgencie} logo={logo} logoChanged={logoChanged} setLogoChanged={setLogoChanged}/>
        </div>

        </div>
      
      {/* <h1>Editar Agencia</h1>
      <p>ID: {selectedData.id}</p>
      <p>Nombre Comercial: {selectedData.nombreComercial}</p>
      <p>Teléfono: {selectedData.telefono}</p> */}
      
    </div>
  );
}

export default EditWorkshop;
