import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './BreadCrumb.scss';

// eslint-disable-next-line react/prop-types
const BreadCrumb = ({items=[]}) => {
    return (
        <ul className='breadcrumb'>
            {
                // eslint-disable-next-line react/jsx-key
                items && items.map(i => { 
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <li>
                            {
                                !_.isNil(i.to) && <Link to={i.to}>{i.name}</Link>
                            }
                            {
                                _.isNil(i.to) && <span>{i.name}</span>
                            }
                        </li>
                    );
                })
            }
        </ul>
    );
}

BreadCrumb.protoTypes = {
    props: PropTypes.any,
    items: PropTypes.any,
};

export default BreadCrumb;
