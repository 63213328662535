import {
	CAR_BRAND_MODULE_GET_ALL_CARS_BEGIN,
	CAR_BRAND_MODULE_GET_ALL_CARS_SUCCESS,
	CAR_BRAND_MODULE_GET_ALL_CARS_FAILURE,
	CAR_BRAND_MODULE_UPSERT_CAR_BEGIN,
	CAR_BRAND_MODULE_UPSERT_CAR_SUCCESS,
	CAR_BRAND_MODULE_UPSERT_CAR_FAILURE,
	CAR_BRAND_MODULE_DELETE_CAR_BEGIN,
	CAR_BRAND_MODULE_DELETE_CAR_SUCCESS,
	CAR_BRAND_MODULE_DELETE_CAR_FAILURE,

	CAR_BRAND_MODULE_UPSERT_CAR_MODEL_BEGIN,
	CAR_BRAND_MODULE_UPSERT_CAR_MODEL_SUCCESS,
	CAR_BRAND_MODULE_UPSERT_CAR_MODEL_FAILURE,
	CAR_BRAND_MODULE_DELETE_CAR_MODEL_BEGIN,
	CAR_BRAND_MODULE_DELETE_CAR_MODEL_SUCCESS,
	CAR_BRAND_MODULE_DELETE_CAR_MODEL_FAILURE,
} from '../actionTypes';

import initialState from '../initialState';

export default function carBrandReducer(state=initialState.carBrand, action) {
    switch(action.type) {
        case CAR_BRAND_MODULE_GET_ALL_CARS_BEGIN:
            return {
                ...state,
                carsBrand: {
                    ...state.carsBrand,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case CAR_BRAND_MODULE_GET_ALL_CARS_SUCCESS:
            return {
                ...state,
                carsBrand: {
                    ...state.carsBrand,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.totalRecords,
                    data: action.data,
                },
            };
        case CAR_BRAND_MODULE_GET_ALL_CARS_FAILURE:
            return {
                ...state,
                carsBrand: {
                    ...state.carsBrand,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
		case CAR_BRAND_MODULE_UPSERT_CAR_BEGIN:
			return {
				...state,
				upsertCarBrand: {
					...state.upsertCarBrand,
					isLoading: true,
					isSuccess: false,
					err: null,
					data: {},
				},
			};
		case CAR_BRAND_MODULE_UPSERT_CAR_SUCCESS:
			return {
				...state,
				upsertCarBrand: {
					...state.upsertCarBrand,
					isLoading: false,
					isSuccess: true,
					err: null,
					data: action.record,
				},
			};
		case CAR_BRAND_MODULE_UPSERT_CAR_FAILURE:
			return {
				...state,
				upsertCarBrand: {
					...state.upsertCarBrand,
					isLoading: false,
					isSuccess: false,
					err: action.err,
					data: {},
				},
			};
		case CAR_BRAND_MODULE_DELETE_CAR_BEGIN:
			return {
				...state,
				deleteCarBrand: {
					...state.deleteCarBrand,
					isLoading: true,
					isSuccess: false,
					err: null,
					data: {},
				},
			};
		case CAR_BRAND_MODULE_DELETE_CAR_SUCCESS:
			return {
				...state,
				deleteCarBrand: {
					...state.deleteCarBrand,
					isLoading: false,
					isSuccess: true,
					err: null,
					data: action.record,
				},
			};
		case CAR_BRAND_MODULE_DELETE_CAR_FAILURE:
			return {
				...state,
				deleteCarBrand: {
					...state.deleteCarBrand,
					isLoading: false,
					isSuccess: false,
					err: action.err,
					data: {},
				},
			};



		case CAR_BRAND_MODULE_UPSERT_CAR_MODEL_BEGIN:
			return {
				...state,
				upsertCarModel: {
					...state.upsertCarModel,
					isLoading: true,
					isSuccess: false,
					err: null,
					data: {},
				},
			};
		case CAR_BRAND_MODULE_UPSERT_CAR_MODEL_SUCCESS:
			return {
				...state,
				upsertCarModel: {
					...state.upsertCarModel,
					isLoading: false,
					isSuccess: true,
					err: null,
					data: action.record,
				},
			};
		case CAR_BRAND_MODULE_UPSERT_CAR_MODEL_FAILURE:
			return {
				...state,
				upsertCarModel: {
					...state.upsertCarModel,
					isLoading: false,
					isSuccess: false,
					err: action.err,
					data: {},
				},
			};
		case CAR_BRAND_MODULE_DELETE_CAR_MODEL_BEGIN:
			return {
				...state,
				deleteCarModel: {
					...state.deleteCarModel,
					isLoading: true,
					isSuccess: false,
					err: null,
					data: {},
				},
			};
		case CAR_BRAND_MODULE_DELETE_CAR_MODEL_SUCCESS:
			return {
				...state,
				deleteCarModel: {
					...state.deleteCarModel,
					isLoading: false,
					isSuccess: true,
					err: null,
					data: action.record,
				},
			};
		case CAR_BRAND_MODULE_DELETE_CAR_MODEL_FAILURE:
			return {
				...state,
				deleteCarModel: {
					...state.deleteCarModel,
					isLoading: false,
					isSuccess: false,
					err: action.err,
					data: {},
				},
			};


        default:
            return state;
    }
}