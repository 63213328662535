import request from '../../utils/request';
import { addNotification } from './notificationAction';


import {
    USER_MODULE_USER_LOGIN_BEGIN,
    USER_MODULE_USER_LOGIN_SUCCESS,
    USER_MODULE_USER_LOGIN_FAILURE,
    USER_MODULE_USER_LOGOUT_BEGIN,
    USER_MODULE_USER_LOGOUT_SUCCESS,
    USER_MODULE_USER_LOGOUT_FAILURE,
    USER_MODULE_USER_REGISTER_BEGIN,
    USER_MODULE_USER_REGISTER_SUCCESS,
    USER_MODULE_USER_REGISTER_FAILURE,
    USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_BEGIN,
    USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_SUCCESS,
    USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_FAILURE,
    USER_MODULE_USER_SET_NEW_PASSWORD_BEGIN,
    USER_MODULE_USER_SET_NEW_PASSWORD_SUCCESS,
    USER_MODULE_USER_SET_NEW_PASSWORD_FAILURE,
    USER_MODULE_USER_GET_INFORMATION_BEGIN,
    USER_MODULE_USER_GET_INFORMATION_SUCCESS,
    USER_MODULE_USER_GET_INFORMATION_FAILURE,
    USER_MODULE_USER_UPDATE_INFORMATION_BEGIN,
    USER_MODULE_USER_UPDATE_INFORMATION_SUCCESS,
    USER_MODULE_USER_UPDATE_INFORMATION_FAILURE,

} from '../actionTypes';

// ACTIONS TO REDUCER

export const userLoginBegin = () => ({
type: USER_MODULE_USER_LOGIN_BEGIN,
});

export const userLoginSuccess = (user) => ({
    type: USER_MODULE_USER_LOGIN_SUCCESS,
    user,
});

export const userLoginFailure = (error) => ({
    type: USER_MODULE_USER_LOGIN_FAILURE,
    error,
});

export const userLogoutBegin = () => ({
    type: USER_MODULE_USER_LOGOUT_BEGIN,
});

export const userLogoutSuccess = () => ({
    type: USER_MODULE_USER_LOGOUT_SUCCESS,
});

export const userLogoutFailure = () => ({
    type: USER_MODULE_USER_LOGOUT_FAILURE,
});

export const userRegisterBegin = () => ({
    type: USER_MODULE_USER_REGISTER_BEGIN,
});

export const userRegisterSuccess = () => ({
    type: USER_MODULE_USER_REGISTER_SUCCESS,
});

export const userRegisterFailure = () => ({
    type: USER_MODULE_USER_REGISTER_FAILURE,
});

export const updatePasswordRequestBegin = () => ({
    type: USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_BEGIN,
});

export const updatePasswordRequestSuccess = () => ({
    type: USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_SUCCESS,
});

export const updatePasswordRequestFailure = () => ({
    type: USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_FAILURE,
});

export const setNewPasswordBegin = () => ({
    type: USER_MODULE_USER_SET_NEW_PASSWORD_BEGIN,
});

export const setNewPasswordSuccess = () => ({
    type: USER_MODULE_USER_SET_NEW_PASSWORD_SUCCESS,
});

export const setNewPasswordFailure = () => ({
    type: USER_MODULE_USER_SET_NEW_PASSWORD_FAILURE,
});

export const getUserInformationBegin = () => ({
    type: USER_MODULE_USER_GET_INFORMATION_BEGIN,
});

export const getUserInformationSuccess = (data) => ({
    type: USER_MODULE_USER_GET_INFORMATION_SUCCESS,
    data
});

export const getUserInformationFailure = (err) => ({
    type: USER_MODULE_USER_GET_INFORMATION_FAILURE,
    err
});


export const updateUserInformationBegin = () => ({
    type: USER_MODULE_USER_UPDATE_INFORMATION_BEGIN,
});

export const updateUserInformationSuccess = () => ({
    type: USER_MODULE_USER_UPDATE_INFORMATION_SUCCESS,
});

export const updateUserInformationFailure = () => ({
    type: USER_MODULE_USER_UPDATE_INFORMATION_FAILURE,
});

// DISPATCH TO ACTIONS


export function userLogin({email,password}){
return (dispatch, getState) => {
    dispatch(userLoginBegin());
    return request({
        partialUrl: `/signin/signin-local-admin-action`,
        method: 'POST',
        state: getState().user.request,
        body: {
            email,
            password
        },
        dispatch,
    })
    .then( request => request.json())
    .then( data => {
        console.log("data",data);
        dispatch(addNotification({
            toastType: 'success',
            toastTitle: 'Se ha iniciado sesión con éxito',
        }));
        localStorage.setItem('user', JSON.stringify(data));
        dispatch(userLoginSuccess(data));
    })
    .catch(err => {
        console.log(err);
        dispatch(addNotification({
            toastType: 'error',
            toastTitle: 'No se ha podido iniciar sesión, intentelo de nuevo',
        }));
        return dispatch(userLoginFailure(err));
    });
};
}

export function userLogout(){
    console.log("aqui ando")
    return (dispatch, getState) => {
        console.log("si lo borro")
        console.log(getState());
        localStorage.removeItem('user');
        dispatch(userLogoutBegin());
        return dispatch(userLogoutSuccess());
    }
}

export function userRegister(data){ // TODO: Cambiar nombre a userMemberRegister
    return (dispatch, getState) => {
        dispatch(userRegisterBegin());
        return request({
            partialUrl: `/api/v1/users/create-member-user`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification('success', 'Se ha registrado con éxito'));
            dispatch(userRegisterSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            return dispatch(userRegisterFailure(err));
        });
    };
}

export function updatePasswordRequestAction (data) {
    return (dispatch, getState) => {
        dispatch(updatePasswordRequestBegin());
        console.log('Data: ', data)
        return request({
            partialUrl: `/api/v1/auth/recover-password/token/send-token-to-recover-password-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se han envido instrucciones para concluir la acción a su email',
            }));
            dispatch(updatePasswordRequestSuccess(data.data));
            dispatch(updatePasswordRequestBegin());
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha podido terminar la acción, intentelo de nuevo',
            }));
            return dispatch(updatePasswordRequestFailure(err));
        });
    }
}




export function setNewPasswordAction (data) {
    return (dispatch, getState) => {
        dispatch(setNewPasswordBegin());
        return request({
            partialUrl: `/recover-password/token/update-password-by-token-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(setNewPasswordSuccess(data.data));
            dispatch(setNewPasswordBegin());
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha podido terminar la acción, intentelo de nuevo',
            }));
            return dispatch(setNewPasswordSuccess(err));
        });
    }
}

export function getUserInformationRequest (data) {
    return (dispatch, getState) => {
        dispatch(getUserInformationBegin());
        return request({
            partialUrl: `/api/v1/administrator/get-one-administrator-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(getUserInformationSuccess(data.data));
            return data.data
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha podido terminar la acción, intentelo de nuevo',
            }));
            return dispatch(getUserInformationFailure(err));
        });
    }
}


export function updateUserInformationRequest (data) {
    return (dispatch, getState) => {
        dispatch(updateUserInformationBegin());
        return request({
            // partialUrl: `/api/v1/administrator/upsert-administrator-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
            isFileUpload: true,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(updateUserInformationSuccess(data.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha podido terminar la acción, intentelo de nuevo',
            }));
            return dispatch(updateUserInformationFailure(err));
        });
    }
}

