import React from "react";
import * as yup from "yup";
import { useFormikContext } from "formik";
import "./Style/DatosContacto.scss";
import { TextField } from "@material-ui/core";
import { Button } from "@mui/material";

export default function DatosContacto({
  setFieldValue,
  handleNext,
  handleBack,
}) {
  const { values, errors, validateForm } = useFormikContext();

  const handleContinue = () => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        handleNext();
      }
    });
  };

  return (
    <div className="main-container-datos-contacto">
      <div className="head-container-registro">
        <p className="title-head">Datos de Contacto</p>
      </div>
      <div className="container-datos-contacto">
        <div className="separete-container">
          <TextField
            className="textfield-stepper"
            id="filled-basic"
            margin="dense"
            name="telefono"
            label="Teléfono *"
            variant="filled"
            placeholder="Agregar..."
            InputLabelProps={{ shrink: true }}
            value={values.telefono || ""}
            error={Boolean(errors.telefono)}
            helperText={errors.telefono}
            onChange={(e) => {
              setFieldValue("telefono", e.target.value);
            }}
          />
          <TextField
            className="textfield-stepper"
            id="filled-basic"
            margin="dense"
            name="whatsApp"
            label="WhatsApp"
            variant="filled"
            placeholder="Agregar..."
            InputLabelProps={{ shrink: true }}
            value={values.whatsApp || ""}
            error={Boolean(errors.whatsApp)}
            helperText={errors.whatsApp}
            onChange={(e) => {
              setFieldValue("whatsApp", e.target.value);
            }}
          />
        </div>
        <div className="separete-container">
          <TextField
            className="textfield-stepper"
            id="filled-basic"
            margin="dense"
            name="correo"
            label="Correo *"
            variant="filled"
            placeholder="Agregar..."
            InputLabelProps={{ shrink: true }}
            value={values.correo || ""}
            error={Boolean(errors.correo)}
            helperText={errors.correo}
            onChange={(e) => {
              setFieldValue("correo", e.target.value);
            }}
          />
          <TextField
            className="textfield-stepper"
            id="filled-basic"
            margin="dense"
            name="PaginaWeb"
            label="Página web"
            variant="filled"
            placeholder="Agregar..."
            InputLabelProps={{ shrink: true }}
            value={values.PaginaWeb || ""}
            error={Boolean(errors.PaginaWeb)}
            helperText={errors.PaginaWeb}
            onChange={(e) => {
              setFieldValue("PaginaWeb", e.target.value);
            }}
          />
        </div>
      </div>
      <div className="container-button-stepper">
        <Button className="btn-continuar" onClick={handleBack}>
          ATRAS
        </Button>
        <Button className="btn-continuar" onClick={handleContinue}>
          SIGUIENTE
        </Button>
      </div>
    </div>
  );
}

DatosContacto.initialValues = {
  telefono: "",
  correo: "",
  PaginaWeb: "",
  whatsApp: "",
};

DatosContacto.validationSchema = yup.object().shape({
  telefono: yup
    .string()
    .matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      "Ingresa un número de teléfono válido"
    )
    .required("Teléfono es requerido")
    .min(2, "Mínimo dos caracteres"),
  correo: yup
    .string()
    .email("Ingresa una dirección de correo válida")
    .required("Ingresa una dirección de correo válida"),
  PaginaWeb: yup
    .string()
    .matches(
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
      "Ingresa una URL válida"
    ),
  whatsApp: yup
    .string()
    .matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      "Ingresa un número de teléfono válido"
    )
});

DatosContacto.label = "Datos de Contacto";
