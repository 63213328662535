import React from "react";

const InternManagement = () => {
    return (
        <div>

        </div>
    )
}

export default InternManagement;
