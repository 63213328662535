import _ from 'lodash';
import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
    LESSOR_MODULE_GET_ONE_LESSOR_BEGIN,
    LESSOR_MODULE_GET_ONE_LESSOR_SUCCESS,
    LESSOR_MODULE_GET_ONE_LESSOR_FAILURE,
    LESSOR_MODULE_GET_ALL_LESSOR_BEGIN,
    LESSOR_MODULE_GET_ALL_LESSOR_SUCCESS,
    LESSOR_MODULE_GET_ALL_LESSOR_FAILURE,
    LESSOR_MODULE_UPSERT_LESSOR_BEGIN,
    LESSOR_MODULE_UPSERT_LESSOR_SUCCESS,
    LESSOR_MODULE_UPSERT_LESSOR_FAILURE,
    LESSOR_MODULE_DELETE_LESSOR_BEGIN,
    LESSOR_MODULE_DELETE_LESSOR_SUCCESS,
    LESSOR_MODULE_DELETE_LESSOR_FAILURE,
    LESSOR_MODULE_UPDATE_LESSOR_BEGIN,
    LESSOR_MODULE_UPDATE_LESSOR_SUCCESS,
    LESSOR_MODULE_UPDATE_LESSOR_FAILURE,
    LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_BEGIN,
    LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_SUCCESS,
    LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_FAILURE,
    LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_BEGIN,
    LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_SUCCESS,
    LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_FAILURE,
    LESSOR_MODULE_UPSERT_LESSOR_DRIVER_BEGIN,
    LESSOR_MODULE_UPSERT_LESSOR_DRIVER_SUCCESS,
    LESSOR_MODULE_UPSERT_LESSOR_DRIVER_FAILURE,
} from './../actionTypes';

// ACTIONS TO REDUCER

export const getOneLessorRecordBegin = () => ({
    type: LESSOR_MODULE_GET_ONE_LESSOR_BEGIN,
});

export const getOneLessorRecordSuccess = (adminRecord) => ({
    type: LESSOR_MODULE_GET_ONE_LESSOR_SUCCESS,
    adminRecord,
});

export const getOneLessorRecordFailure = (err) => ({
    type: LESSOR_MODULE_GET_ONE_LESSOR_FAILURE,
    err,
});

export const getAllLessorsBegin = () => ({
    type: LESSOR_MODULE_GET_ALL_LESSOR_BEGIN,
});

export const getAllLessorsSuccess = ({totalRecords, data}) => ({
    type: LESSOR_MODULE_GET_ALL_LESSOR_SUCCESS,
    totalRecords,
    data,
});

export const getAllLessorsFailure = (err) => ({
    type: LESSOR_MODULE_GET_ALL_LESSOR_FAILURE,
    err,
});

export const upsertLessorBegin = () => ({
    type: LESSOR_MODULE_UPSERT_LESSOR_BEGIN,
});

export const upsertLessorSuccess = () => ({
    type: LESSOR_MODULE_UPSERT_LESSOR_SUCCESS,
});

export const upsertLessorFailure = (err) => ({
    type: LESSOR_MODULE_UPSERT_LESSOR_FAILURE,
    err,
});

export const deleteLessorBegin = () => ({
    type: LESSOR_MODULE_DELETE_LESSOR_BEGIN,
});

export const deleteLessorSuccess = () => ({
    type: LESSOR_MODULE_DELETE_LESSOR_SUCCESS,
});

export const deleteLessorFailure = (err) => ({
    type: LESSOR_MODULE_DELETE_LESSOR_FAILURE,
    err,
});

export const updateLessorBegin = () => ({
    type: LESSOR_MODULE_UPDATE_LESSOR_BEGIN,
});

export const updateLessorSuccess = () => ({
    type: LESSOR_MODULE_UPDATE_LESSOR_SUCCESS,
});

export const updateLessorFailure = (err) => ({
    type: LESSOR_MODULE_UPDATE_LESSOR_FAILURE,
    err,
});

export const getAllLessorDriversBegin = () => ({
    type: LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_BEGIN,
});

export const getAllLessorDriversSuccess = ({totalRecords, data}) => {
    console.log('getAllLessorDriversSuccess: ' , totalRecords, data,)
    return(
    {
    type: LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_SUCCESS,
    totalRecords: _.isNil(totalRecords) ? 0 : totalRecords,
    data: _.isNil(data) ? [] : data,
})
};

export const getAllLessorDriversFailure = (err) => ({
    type: LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_FAILURE,
    err,
});

export const deleteOneCarDriverBegin = () => ({
    type: LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_BEGIN,
});

export const deleteOneCarDriverSuccess = ({totalRecords, data}) => ({
    type: LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_SUCCESS,
    totalRecords: _.isNil(totalRecords) ? 0 : totalRecords,
    data: _.isNil(data) ? [] : data,
});

export const deleteOneCarDriverFailure = (err) => ({
    type: LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_FAILURE,
    err,
});

export const upsertLessorDriverBegin = () => ({
    type: LESSOR_MODULE_UPSERT_LESSOR_DRIVER_BEGIN,
});

export const upsertLessorDriverSuccess = () => ({
    type: LESSOR_MODULE_UPSERT_LESSOR_DRIVER_SUCCESS,
});

export const upsertLessorDriverFailure = (err) => ({
    type: LESSOR_MODULE_UPSERT_LESSOR_DRIVER_FAILURE,
    err,
});

// DISPATCH TO ACTIONSv

// export function getOneLessorRecordAction (data){
//     return (dispatch, getState) => {
//         dispatch(getOneLessorRecordBegin());
//         return request({
//             partialUrl: `/api/v1/administrator/get-one-administrator-action`,
//             method: 'POST',
//             state: getState().user.request,
//             body: data,
//             dispatch,
//         })
//         .then( request => request.json())
//         .then( data => {
//             console.log(data);
//             dispatch(getOneLessorRecordSuccess(data.data.data));
//         })
//         .catch(err => {
//             console.log(err);
//             dispatch(addNotification({
//                 toastType: 'error',
//                 toastTitle: 'No se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
//             }));
//             return dispatch(getOneLessorRecordFailure(err));
//         });
//     };
// }

export function getAllLessorsAction(data){
    return (dispatch, getState) => {
        dispatch(getAllLessorsBegin());
        return request({
            partialUrl: `/api/v1/lessor/get-all-lessors-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getAllLessorsSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            // dispatch(addNotification({
            //     toastType: 'error',
            //     toastTitle: 'No se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            // }));
            return dispatch(getAllLessorsFailure(err));
        });
    };
}

export function upsertLessorAction(data){
    return (dispatch, getState) => {
        dispatch(upsertLessorBegin());
        return request({ 
            partialUrl: `/api/v1/lessor/auth/signup-local-lessor-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
            isFileUpload: false,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertLessorSuccess(data.data));
            dispatch(upsertLessorBegin());
            
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertLessorFailure(err));
        });
    };
}

export function deleteLessorAction(data){
    return (dispatch, getState) => {
        dispatch(deleteLessorBegin());
        return request({
            partialUrl: `/api/v1/lessor/delete-lessor-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(deleteLessorSuccess(data.data));
            dispatch(deleteLessorBegin());
            return true;
        })
        .catch(err => {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(deleteLessorFailure(err));
        });
    };
}

export function updateLessorAction(data) {
    return (dispatch, getState) => {
        dispatch(updateLessorBegin());
        return request({
            partialUrl: `/api/v1/lessor/update-lessor-action`,
            method: 'POST',
            state: getState().lessor.request,
            body: data,
            headers: {},
            dispatch,
            isFileUpload: true,
        })
        .then( request => request.json())
        .then( data => {
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(updateLessorSuccess(data.data));
            dispatch(updateLessorBegin());
            return true;
        })
        .catch(err => {
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(updateLessorFailure(err));
        });
    };
}

export function getAllLessorDriversAction(data){
    return (dispatch, getState) => {
        dispatch(getAllLessorDriversBegin());
        return request({
            partialUrl: `/api/v1/lessor/driver/get-all-drivers-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log('data.data: ' , data.data);
            dispatch(getAllLessorDriversSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            // dispatch(addNotification({
            //     toastType: 'error',
            //     toastTitle: 'No se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            // }));
            return dispatch(getAllLessorDriversFailure(err));
        });
    };
}

export function deleteOneCarDriverAction(data){
    return (dispatch, getState) => {
        dispatch(deleteOneCarDriverBegin());
        return request({
            partialUrl: `/api/v1/lessor/driver/delete-one-driver-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(deleteOneCarDriverSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            // dispatch(addNotification({
            //     toastType: 'error',
            //     toastTitle: 'No se ha generado un error al obtener el listado de cliente, favor de intentarlo de nuevo',
            // }));
            return dispatch(deleteOneCarDriverFailure(err));
        });
    };
}


export function upsertLessorDriverAction(data){
    return (dispatch, getState) => {
        dispatch(upsertLessorDriverBegin());
        return request({ 
            partialUrl: `/api/v1/lessor/driver/upsert-driver-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
            isFileUpload: false,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(addNotification({
                toastType: 'success',
                toastTitle: 'Se ha ejecutado la acción con éxito',
            }));
            dispatch(upsertLessorDriverSuccess(data.data));
            dispatch(upsertLessorDriverBegin());
        })
        .catch(err => {
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'No se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertLessorDriverFailure(err));
        });
    };
}