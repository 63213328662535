import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
	CAR_BRAND_MODULE_GET_ALL_CARS_BEGIN,
	CAR_BRAND_MODULE_GET_ALL_CARS_SUCCESS,
	CAR_BRAND_MODULE_GET_ALL_CARS_FAILURE,
	CAR_BRAND_MODULE_UPSERT_CAR_BEGIN,
	CAR_BRAND_MODULE_UPSERT_CAR_SUCCESS,
	CAR_BRAND_MODULE_UPSERT_CAR_FAILURE,
    CAR_BRAND_MODULE_DELETE_CAR_BEGIN,
    CAR_BRAND_MODULE_DELETE_CAR_SUCCESS,
    CAR_BRAND_MODULE_DELETE_CAR_FAILURE,

	CAR_BRAND_MODULE_UPSERT_CAR_MODEL_BEGIN,
	CAR_BRAND_MODULE_UPSERT_CAR_MODEL_SUCCESS,
	CAR_BRAND_MODULE_UPSERT_CAR_MODEL_FAILURE,
    CAR_BRAND_MODULE_DELETE_CAR_MODEL_BEGIN,
    CAR_BRAND_MODULE_DELETE_CAR_MODEL_SUCCESS,
    CAR_BRAND_MODULE_DELETE_CAR_MODEL_FAILURE
} from "../actionTypes";

// ACTIONS TO REDUCER

export const getAllCarsBrandBegin = () => ({
    type: CAR_BRAND_MODULE_GET_ALL_CARS_BEGIN,
});

export const getAllCarsBrandSuccess = ({totalRecords, data}) => ({
    type: CAR_BRAND_MODULE_GET_ALL_CARS_SUCCESS,
    totalRecords,
    data,
});

export const getAllCarsBrandFailure = (err) => ({
    type: CAR_BRAND_MODULE_GET_ALL_CARS_FAILURE,
    err,
});

export const upsertCarBrandBegin = () => ({
    type: CAR_BRAND_MODULE_UPSERT_CAR_BEGIN,
});

export const upsertCarBrandSuccess = ({totalRecords, data}) => ({
    type: CAR_BRAND_MODULE_UPSERT_CAR_SUCCESS,
    totalRecords,
    data,
});

export const upsertCarBrandFailure = (err) => ({
    type: CAR_BRAND_MODULE_DELETE_CAR_BEGIN,
    err,
});

export const deleteCarBrandBegin = () => ({
    type: CAR_BRAND_MODULE_DELETE_CAR_SUCCESS,
});

export const deleteCarBrandSuccess = ({totalRecords, data}) => ({
    type: CAR_BRAND_MODULE_DELETE_CAR_FAILURE,
    totalRecords,
    data,
});

export const deleteCarBrandFailure = (err) => ({
    type: CAR_BRAND_MODULE_UPSERT_CAR_FAILURE,
    err,
});




export const upsertCarModelBegin = () => ({
    type: CAR_BRAND_MODULE_UPSERT_CAR_MODEL_BEGIN,
});

export const upsertCarModelSuccess = ({totalRecords, data}) => ({
    type: CAR_BRAND_MODULE_UPSERT_CAR_MODEL_SUCCESS,
    totalRecords,
    data,
});

export const upsertCarModelFailure = (err) => ({
    type: CAR_BRAND_MODULE_UPSERT_CAR_MODEL_FAILURE,
    err,
});

export const deleteCarModelBegin = () => ({
    type: CAR_BRAND_MODULE_DELETE_CAR_MODEL_BEGIN,
});

export const deleteCarModelSuccess = ({totalRecords, data}) => ({
    type: CAR_BRAND_MODULE_DELETE_CAR_MODEL_SUCCESS,
    totalRecords,
    data,
});

export const deleteCarModelFailure = (err) => ({
    type: CAR_BRAND_MODULE_DELETE_CAR_MODEL_FAILURE,
    err,
});

// DISPATCH TO ACTIONS


export function getAllCarsBrandAction(data){
    return (dispatch, getState) => {
        dispatch(getAllCarsBrandBegin());
        return request({
            partialUrl: `/api/v1/car/car-brand/get-all-car-brands-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getAllCarsBrandSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllCarsBrandFailure(err));
        });
    };
}

export function upsertCarBrandAction(data) {
    return (dispatch, getState) => {
        dispatch(upsertCarBrandBegin());
        return request({
            partialUrl: `/api/v1/car/car-brand/upsert-car-brand-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(upsertCarBrandSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al intenar realizar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertCarBrandFailure(err));
        });
    };
}

export function deleteCarBrandAction(data) {
    return (dispatch, getState) => {
        dispatch(deleteCarBrandBegin());
        return request({
            partialUrl: `/api/v1/car/car-brand/delete-car-brand-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(deleteCarBrandSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al intenar realizar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(deleteCarBrandFailure(err));
        });
    };
}





export function upsertCarModelAction(data) {
    return (dispatch, getState) => {
        dispatch(upsertCarModelBegin());
        return request({
            partialUrl: `/api/v1/car/car-model/upsert-car-model-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(upsertCarModelSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al intenar realizar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(upsertCarModelFailure(err));
        });
    };
}


export function deleteCarModelAction(data) {
    return (dispatch, getState) => {
        dispatch(deleteCarModelBegin());
        return request({
            partialUrl: `/api/v1/car/car-model/delete-car-model-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(deleteCarModelSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al intenar realizar la acción, favor de intentarlo de nuevo',
            }));
            return dispatch(deleteCarModelFailure(err));
        });
    };
}
