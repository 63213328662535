import React, { useEffect, useState } from "react";
import "./MiPerfil.scss";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import btnBack from "../../../assets/images/maranello/Icon_Atras.png";
import { Link, useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import SettingsIcon from "@mui/icons-material/Settings"; // Importa el icono de engranaje
import ModalComponent from "../../../components/MiPerfilComponents/ModalEditar/ModalEditar"; // Ajusta la ruta a tu archivo ModalComponent
import EditarFoto from "../../../components/MiPerfilComponents/EditarFoto/EditarFoto";
import EditarDatosPerfil from "../../../components/MiPerfilComponents/EditarDatosPerfil/EditarDatosPerfil";
import btnConfing from "../../../assets/images/maranello/BTN_COG.png";
import { useSelector } from "react-redux";

export default function MiPerfilAdmin() {
  const [modalOpen, setModalOpen] = useState(false);
  const userLoggeado = useSelector((state) => state.user.user);
  const [imagenBack, setImagenBack] = useState(false);
  const [urlimagenBack, setUrlImagenBack] = useState("");

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    console.log("userLoggeado", userLoggeado);
  }, []);

  useEffect(() => {
    console.log("urlimagenBack", urlimagenBack);
  }, [urlimagenBack]);

  return (
    <div className="main-mi-perfil-container">
      <div className="header-gestion-agencias">
        <div className="container-left">
          <Link to="/dashboard/inicio">
            <img src={btnBack} className="img-back" />
          </Link>
          <h1 className="title-gestion-agencias">Mi perfil</h1>
        </div>

        {/* <IconButton
          edge="end"
          color="inherit"
          aria-label="settings"
          onClick={handleModalOpen}
          sx={{ marginLeft: "auto" }}
        > */}
        <img
          src={btnConfing}
          className="btn-configuracion"
          onClick={handleModalOpen}
        />
        {/* </IconButton> */}
      </div>

      <div className="container-photo-perfil">
        <div className="left-container-photo">
          <EditarFoto
            imagenBack={imagenBack}
            setImagenBack={setImagenBack}
            urlimagenBack={urlimagenBack}
            setUrlImagenBack={setUrlImagenBack}
          />
        </div>

        <div className="right-container-photo">
          <EditarDatosPerfil
            imagenBack={imagenBack}
            setImagenBack={setImagenBack}
            urlimagenBack={urlimagenBack}
            setUrlImagenBack={setUrlImagenBack}
          />
        </div>
      </div>

      <ModalComponent open={modalOpen} handleClose={handleModalClose} />
    </div>
  );
}
