import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// src & styles
import './InputKoopers.scss';
import userImg from '../../assets/images/koopers/inputUser.png';
import atImg from '../../assets/images/koopers/icon-at.png';
import keyImg from '../../assets/images/koopers/key.png';
import phoneImg from '../../assets/images/koopers/phone.png';
import car from '../../assets/images/koopers/car.png';
import money from '../../assets/images/koopers/money.png';
import personIcon from '../../assets/images/koopers/user-icon.png';
import config from '../../assets/images/koopers/services.png';

const SelectKoopers = ({
    autocomplete,
    mode,
    type = 'text',
    value,
    onChange,
    placeholder,
    id,
    name,
    readOnly = false,
	options = [],
}) => {

    const [selectedOption, setSelectedOption] = useState();

    let img = '';

    if (mode == 'user') img = userImg;
    else if (mode == 'mail') {
        img = atImg;
        type = 'email';
    }
    else if (mode == 'password') {
        img = keyImg;
        type = 'password';
    }
    else if (mode == 'phone') {
        img = phoneImg;
    }
    else if (mode == 'car') {
        img = car;
        readOnly = true;
    }
    else if (mode == 'cost') {
        img = money;
        readOnly = true;
    }
    else if (mode == 'person') {
        img = personIcon;
        readOnly = true;
    }
    else if (mode == 'configuration') {
        img = config;
        readOnly = true;
    } else if (mode == 'carBrand') {
        img = car;
        readOnly = false;
    }

	const BuildOptions = () => {
		if (_.isNil(options) || _.isEmpty(options)) {
			return (
				// eslint-disable-next-line react/jsx-key
				<option value={''}>No hay opciones para seleccionar</option>
			);
		}
		return options.map((option) => {
			return (
				// eslint-disable-next-line react/jsx-key
				<option value={_.get(option, 'keyName', 'No text')}>{_.get(option, 'displayText', 'No text').toUpperCase()}</option>
			);
		});
	};

    const selectOptionOnChange = (e) => {
        setSelectedOption(e.target.value);
        onChange(e);
    }

	return (
		<select
			className='inputDefault'
			style={{ backgroundImage: `url(${img})`}}
			placeholder={placeholder}
			readOnly={readOnly}
			id={id}
			name={name}
			onChange={selectOptionOnChange}
            value={selectedOption}
		>
			<BuildOptions />
		</select>
	);

}

SelectKoopers.propTypes = {
    mode: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string,
    autocomplete: PropTypes.any,
    name: PropTypes.string,
    readOnly: PropTypes.bool,
	options: PropTypes.array,
}

export default SelectKoopers;
