import request from "../../utils/request";
import { addNotification } from "./notificationAction";
import {
  ADMIN_MODULE_ADMIN_GET_PROFILE_BEGIN,
  ADMIN_MODULE_ADMIN_GET_PROFILE_SUCCESS,
  ADMIN_MODULE_ADMIN_GET_PROFILE_FAILURE,
  ADMIN_MODULE_ADMIN_UPDATE_PROFILE_BEGIN,
  ADMIN_MODULE_ADMIN_UPDATE_PROFILE_SUCCESS,
  ADMIN_MODULE_ADMIN_UPDATE_PROFILE_FAILURE,
  ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_BEGIN,
  ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_SUCCESS,
  ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_FAILURE,
  ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_BEGIN,
  ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_SUCCESS,
  ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_FAILURE,
} from "../actionTypes";

export const getProfileBegin = () => ({
  type: ADMIN_MODULE_ADMIN_GET_PROFILE_BEGIN,
});

export const getProfiledSuccess = (data) => ({
  type: ADMIN_MODULE_ADMIN_GET_PROFILE_SUCCESS,
  data,
});

export const getProfileFailure = () => ({
  type: ADMIN_MODULE_ADMIN_GET_PROFILE_FAILURE,
});

export const upsertProfileBegin = () => ({
  type: ADMIN_MODULE_ADMIN_UPDATE_PROFILE_BEGIN,
});

export const upsertProfileSuccess = () => ({
  type: ADMIN_MODULE_ADMIN_UPDATE_PROFILE_SUCCESS,
});

export const upsertProfileFailure = () => ({
  type: ADMIN_MODULE_ADMIN_UPDATE_PROFILE_FAILURE,
});

export const updatePasswordBegin = () => ({
  type: ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_BEGIN,
});

export const updatePasswordSuccess = () => ({
  type: ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_SUCCESS,
});

export const updatePasswordFailure = () => ({
  type: ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_FAILURE,
});

export const updateDocumentationBegin = () => ({
  type: ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_BEGIN,
});

export const updateDocumentationSuccess = () => ({
  type: ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_SUCCESS,
});

export const updateDocumentationFailure = () => ({
  type: ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_FAILURE,
});

export function upsertProfileAction(data = {}) {
  /* debugger */
  return (dispatch, getState) => {
    dispatch(upsertProfileBegin());
    return request({
      partialUrl: `/update-data-admin/update-data-admin-action`,
      method: "POST",
      state: getState().user.request,
      body: { ...data },
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(upsertProfileSuccess(data));
        dispatch(
          addNotification({
            toastType: "success",
            toastTitle: "Registro realizado con exito",
          })
        );
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
      });
  };
}

export function updateDocumentationProfileAction(data = {}) {
  /* debugger */
  return (dispatch, getState) => {
    dispatch(updateDocumentationBegin());
    return request({
      partialUrl: `/configSuperAdmin/insert-configSuperAdmin-action`,
      method: "POST",
      state: getState().user.request,
      body: { ...data },
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(updateDocumentationSuccess(data));
        dispatch(
          addNotification({
            toastType: "success",
            toastTitle: "Registro realizado con exito",
          })
        );
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
      });
  };
}

export function updatePasswordAction(data = {}) {
  /* debugger */
  return (dispatch, getState) => {
    dispatch(updatePasswordBegin());
    return request({
      partialUrl: `/recover-password/update-password-super-admin-action`,
      method: "POST",
      state: getState().user.request,
      body: { ...data },
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(updatePasswordSuccess(data));
        dispatch(
          addNotification({
            toastType: "success",
            toastTitle: "Registro realizado con exito",
          })
        );
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
      });
  };
}

export function getProfileAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getProfileBegin());
    return request({
      partialUrl: `/user/get-one-user`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getProfiledSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getProfileFailure(err));
      });
  };
}
