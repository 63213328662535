import {
    TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_BEGIN,
    TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_SUCCESS,
    TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_FAILURE,
} from '../actionTypes';

import initialState from '../initialState';

export default function transactionReducer(state=initialState.transaction, action) {
    switch(action.type) {
        case TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_BEGIN:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: [],
                },
            };
        case TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.totalRecords,
                    data: action.data,
                },
            };
        case TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_FAILURE:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
        default:
            return state;
    }
}