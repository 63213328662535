import React from 'react';
import PropTypes from 'prop-types';

const PillTag = ({children}) => {

    return  (
        <span 
        style={{margin: '10px', padding: '0.5rem', 
        color: 'white', background: '#0A0A0A', 
        border: '1px solid #fff', borderRadius: '10px', 
        fontWeight: 'bold', marginBottom: '32px', display: 'inline-block'}}
        >
            {children}
        </span>
    );
    
}

export default PillTag;

PillTag.propType = {
    children: PropTypes.any,
}
