/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import Searcher from '../../../../components/atoms/Searcher/Searcher';
import DataTable from '../../../../components/atoms/DataTable/DataTable';
import ActionMenu from '../../../../components/atoms/ActionMenu/ActionMenu';
import CustomModal from '../../../../components/organisms/Modal/Modal';
import DeleteModal from '../../../../components/organisms/Modal/DeleteModal';
import {
    CAR_VIEW_CREATE_DOCUMENT,
} from '../../../../routes/Paths';
import {
	getAllCarsAction,
	getAllCarDocumentsAction,
    deleteOneCarDocumentAction,
    deleteOneCarDocumentBegin, // Para limpiar la bandera de eliminado
} from '../../../../redux/actions/carActions';
import Button from '../../../../components/atoms/Button/Button';

// src & styles
import './CarsDocumentManagement.scss';
import add from '../../../../assets/images/koopers/add.png';

const CarsDocumentManagement = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ openModal, setOpenModal ] = useState(false);
    const [ searchCondition, setSearchCondition ] = useState({});
    const [ itemSelected, setItemSelected ] = useState(null);
    const [searchObj,  setSearchObj] = useState({});

    const { mode, carInstanceId } = useParams();


    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    
    const reloadInfo = () => {
        dispatch(getAllCarDocumentsAction(searchCondition));
    };

    const handleDelete = (item) => {
        const dataRequest = {
            carInstanceDocumentId: item
        }
        props.deleteOneCarDocumentAction(dataRequest);
        toggleModal();
        
    }

    useEffect(()=>{
        if (props.deleteOneCarDocumentSuccess) {
            reloadInfo();
            props.deleteOneCarDocumentBegin();
        }
    },[props.deleteOneCarDocumentSuccess]);

	const actionMenuItems = [
        {
            displayText: 'Eliminar registro',
            keyName: 'ELIMINAR_REGISTRO',
            icon: 'trash',
        },
    ];

    const columns = React.useMemo(
        () => [
            {
                id: 'id',
                Header: 'ID',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '5%', 
                accessor: d => ({
                    id: _.get(d, 'id', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        id,
                    },
                    row
                }) {
                    return(<span>{`${id}`}</span>)
                }
            },
            {
                id: 'documentType',
                Header: 'Tipo de documento',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '20%', 
                accessor: d => ({
                    documentType: _.get(d, 'document.documentType.displayText', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        documentType,
                    },
                    row
                }) {
                    return(<span>{`${documentType.toUpperCase()}`}</span>)
                }
            },
            {
                id: 'document',
                Header: 'Documento',
                disableSortBy: true,
                disableFilters: true,
                width: '20%',
                accessor: d => ({
                    publicURL: _.get(d, 'document.documentMedia.publicURL', ''),
					fileName: _.get(d, 'document.documentMedia.fileName', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        publicURL,
						fileName,
                    },
                    row
                }) {
                    return(<a href={`${publicURL}`} target={'_blank'} >{`${fileName}`}</a>)
                }
            },
             {
                 id: 'options.name',
                 Header: '',
                 filter: "text",
                 disableFilters: true,
                 width: '10%',
                 accessor: d => ({
                     item: d,
                 }),
                 Cell: function cell ({ value: { item }, row }) {
                     return (
                         <ActionMenu
                            actionMenuItems={actionMenuItems}
                            client={true}
                            callback={(selected) => {
                             if (selected === 'ELIMINAR_REGISTRO') {
                                toggleModal();
                                setItemSelected(item.id);
                             }}}
                         />
                     ); 
                 }
             },
    ]);

    const getNewData = (condition) => {
        setSearchCondition(condition);
        // Pendiente implementar el SEARCH
		dispatch(getAllCarDocumentsAction({ where: { carInstanceId: parseInt(carInstanceId) }}));
        //dispatch(getAllCarDocumentsAction(mode != 'todos' ? { where: { lessorId: parseInt(mode) }} : {}));
    };

    return (
        <div className='LessorManagementGeneralContainer'>
            <div className='LessorManagementHeaderContainer'>
                <div className='titleContainer'>
                    <h1>GESTIÓN DE DOCUMENTOS</h1>
                </div> 
                <div className='searcherContainer'>
                    <Searcher placeholderSearch={'Busca documento'} />
                </div>
            </div>
            <div className='buttonContainer'>
                    <Button
                        srcicon={add}
                        onClick={() => history.push(CAR_VIEW_CREATE_DOCUMENT.replace(":carInstanceId", carInstanceId).replace(":mode", 'registro'))}
                    >
                        NUEVO
                    </Button>
                </div>
            <div className='tableContainer'>
                <DataTable
                    columns={columns}
                    data={{...props.carDocuments}}
                    getData={getNewData}
                    searchObj={searchObj}
                />
            </div>
            {
                openModal &&
                <CustomModal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <DeleteModal
                        OnClickCancel={toggleModal}
                        onClickAgree={() => handleDelete(itemSelected)}
                        reloadInfo={reloadInfo}
                        toggle={toggleModal}
                    />
                </CustomModal>
            }
        </div>
    )
}

CarsDocumentManagement.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    getAllCarsAction: PropTypes.any,
    carDocuments: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    data: PropTypes.any,
    totalRecords: PropTypes.any,
    deleteOneCarDocumentAction: PropTypes.func,
    deleteOneCarDocumentSuccess: PropTypes.bool,
    deleteOneCarDocumentBegin: PropTypes.func,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        // data: _.get(state, 'data.data', {}),
        carDocuments: _.get(state, 'car.getAllDocuments', {}),
        deleteOneCarDocumentSuccess: _.get(state, 'car.deleteOneCarDocument.isSuccess', false),
    };
}

const mapDispatchToProps = dispatch => ({
    getAllCarsAction: params => dispatch(getAllCarsAction(params)),
    deleteOneCarDocumentAction: params => dispatch(deleteOneCarDocumentAction(params)),
    deleteOneCarDocumentBegin: params => dispatch(deleteOneCarDocumentBegin(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(CarsDocumentManagement);
