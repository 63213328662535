import request from "../../utils/request";
import { addNotification } from "./notificationAction";
import {
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_BEGIN,
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_SUCCESS,
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FAILURE,
  ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_BEGIN,
  ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_SUCCESS,
  ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FAILURE,
  ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_BEGIN,
  ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_SUCCESS,
  ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FAILURE,
} from "./../actionTypes";

export const getAllCountriesBegin = () => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_BEGIN,
});

export const getAllCountriesSuccess = (data) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_SUCCESS,
  data,
});

export const getAllCountriesFailure = (err) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FAILURE,
  err,
});

export const getAllCiudadBegin = () => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_BEGIN,
});

export const getAllCiudadSuccess = (data) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_SUCCESS,
  data,
});

export const getAllCiudadFailure = (err) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FAILURE,
  err,
});

export const getAllEstadosBegin = () => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_BEGIN,
});

export const getAllEstadosSuccess = (data) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_SUCCESS,
  data,
});

export const getAllEstadosFailure = (err) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FAILURE,
  err,
});




export function getAllContriesAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getAllCountriesBegin());
    return request({
      partialUrl: `/country/get-all-records`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(getAllCountriesSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getAllCountriesFailure(err));
      });
  };
}

export function getAllCiudadesAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getAllCiudadBegin());
    return request({
      partialUrl: `/city/get-all-records`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(getAllCiudadSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getAllCiudadFailure(err));
      });
  };
}

export function getAllEstadoAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getAllEstadosBegin());
    return request({
      partialUrl: `/state/get-all-records`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(getAllEstadosSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getAllEstadosFailure(err));
      });
  };
}
