/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import {connect, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams,  } from 'react-router-dom';
import _ from 'lodash';
import Searcher from '../../../components/atoms/Searcher/Searcher';
import DataTable from '../../../components/atoms/DataTable/DataTable';
import ActionMenu from '../../../components/atoms/ActionMenu/ActionMenu';
import { getAllTransactionsAction } from '../../../redux/actions/transactionActions';
import { VIEW_TRANSACTION } from '../../../routes/Paths';
import RenderStatus from '../../../components/molecules/renderStatus/RenderStatus';

// src & styles
// import './TransactionsManagement.scss';

const TransactionsManagement = (props) => {

    const { mode, id } = useParams();
    const [ searchCondition, setSearchCondition ] = useState({});
    // const [ ItemSelected, setItemSelected ] = useState(null);
    const [searchObj,  setSearchObj] = useState({});

    const history = useHistory();
    const dispatch = useDispatch();

    const reloadInfo = (searchCondition) => {
        // props.getAllAdministratorsAction(searchCondition);
        //props.getAllTransactionsAction({offset: 0,limit: 5});
    };

    console.warn(mode);
    /* if (mode !== 'todas') {
        props.getAllTransactionsAction({
            where: {
                lessorId: mode
            }
        }); 
    } */

    /* useEffect(()=>{
        props.getAllTransactionsAction(searchCondition);
    },[searchCondition]); */

    // dispatch(getAllTransactionsAction(mode!='todas' ? { where: {
    //     lessorId: parseInt(mode)}} : mode))
    //     // console.log(params); dispatch(getAllTransactionsAction(where))))

    const transactionsInfo = useMemo(
        () => [
            {
                id: 'id',
                Header: 'ID',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '5%', 
                accessor: d => ({
                    id: _.get(d, 'id', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        id,
                    },
                    row
                }) {
                    return(<span>{`${id}`}</span>)
                }
            },
            {
                id: 'carBrandAndModel',
                Header: 'Marca/Modelo',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '20%', 
                accessor: d => ({
                    carBrand: _.get(d, 'carInstance.carBrand.displayText', ''),
                    carModel: _.get(d, 'carInstance.carModel.displayText', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        carBrand,
                        carModel,
                    },
                    row
                }) {
                    return(<span>{`${carBrand}/${carModel}`}</span>)
                }
            },
            {
                id: 'lessee',
                Header: 'Arrendatario',
                disableSortBy: true,
                disableFilters: true,
                width: '20%',
                accessor: d => ({
                    firstName: _.get(d, 'lessee.person.firstName', ''),
                    firstLastName: _.get(d, 'lessee.person.firstLastName', ''),

                }),
                Cell: function cell ({ 
                    value: {
                        firstName,
                        firstLastName,
                    },
                    row
                }) {
                    return(<span>{`${firstName} ${firstLastName}`}</span>)
                }
            },
            {
                id: 'lessor',
                Header: 'Arrendador',
                disableSortBy: true,
                disableFilters: true,
                filter: "text",
                width: '25%',
                accessor: d => ({
                    lessorFirstName: _.get(d, 'lessor.person.firstName', ''),
                    lessorFirstLastName: _.get(d, 'lessor.person.firstLastName', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        lessorFirstName,
                        lessorFirstLastName,
                    },
                    row
                }) {
                    return(<span>{`${lessorFirstName} ${lessorFirstLastName}`}</span>)
                }
            },
            {
                id: 'carCost',
                Header: 'Costo',
                disableSortBy: true,
                disableFilters: true,
                filter: "text",
                width: '20%',
                accessor: d => ({
                    // baseCost: _.get(d, 'baseCost') && !_.isNil(d.baseCost) ? d.baseCost : '-',
                    baseCost: _.get(d, 'baseCost', ''),
                }),
                Cell: function cell ({ value: { baseCost }, row }) {
                    return(<span>{`${baseCost}`}</span>)
                },
             },
             {
                id: 'status',
                Header: 'Status',
                disableSortBy: true,
                disableFilters: true,
                filter: "text",
                width: '20%',
                accessor: d => ({
                    // baseCost: _.get(d, 'baseCost') && !_.isNil(d.baseCost) ? d.baseCost : '-',
                    transactionStatus: _.get(d, 'transactionStatus', []),
                }),
                Cell: function cell ({ value: { transactionStatus }, row }) {
                    return(<RenderStatus status={transactionStatus} />)
                },
             },
             {
                 id: 'options.name',
                 Header: '',
                 filter: "text",
                 disableFilters: true,
                 width: '5%',
                 accessor: d => ({
                     item: d,
                 }),
                 Cell: function cell ({ value: { item }, row }) {
                     return (
                         <ActionMenu
                             justView
                             client={true}
                             callback={(number) => {
                             if (number === 1) {
                                console.log('ver');
                                history.push(VIEW_TRANSACTION.replace(":transactionId", item.id));
                             }}}
                         />
                     ); 
                 }
             },
    ]);

    // const table = useMemo(() =>
    //     <DataTable
    //         columns={transactionsInfo}
    //         data={{...props.transactions}}
    //         getData={() => getNewData()}
    //         searchObj={searchObj}
    //     />
    // );

    const getNewData = () => {
       let where = {};
       if (mode === 'todas') {
        where = {}
       } else if (mode === 'arrendatario') {
          where = { where: { lesseeId: parseInt(id) }}
       } else if (mode === 'arrendador') {
          where = { where: { lessorId: parseInt(id) }};
       }
       dispatch(getAllTransactionsAction(where));
    };


    return (
        <div className='LessorManagementGeneralContainer'>
            <div className='LessorManagementHeaderContainer'>
                <div className='titleContainer'>
                    <h1>TRANSACCIONES</h1>
                </div> 
                <div className='searcherContainer'>
                    <Searcher placeholderSearch={'Buscar'} />
                </div>
            </div>
            <div className='tableContainer'>
                {/* {table} */}
                <DataTable
                    columns={transactionsInfo}
                    data={{...props.transactions}}
                    getData={() => getNewData()}
                    searchObj={searchObj}
                />
            </div>
        </div>
    )
}

TransactionsManagement.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    getAllTransactionsAction: PropTypes.any,
    transactions: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    data: PropTypes.any,
    totalRecords: PropTypes.any,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        // data: _.get(state, 'data.data', {}),
        transactions: _.get(state, 'transaction.transactions', {}),
        
    };
}

const mapDispatchToProps = dispatch => ({
    getAllTransactionsAction: params => dispatch(getAllTransactionsAction(params))
    // {let where = params!='todas' ? {where: {
    //     lessorId: parseInt(params)}} : params;
    //     console.log(params); dispatch(getAllTransactionsAction(where))},
});

export default connect(mapStateToProps,mapDispatchToProps)(TransactionsManagement);
