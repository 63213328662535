/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
    MenuItem,
    Menu,
    IconButton,
}from '@material-ui/core';
import { MoreVert as MoreIcon } from "@material-ui/icons";
import _ from 'lodash';

import './ActionMenu.scss';

import edit from '../../../assets/images/koopers/edit-icon.png';
import trash from '../../../assets/images/koopers/garbage.png';
import eye from '../../../assets/images/koopers/icon-eye.png';

export default function ActionMenu({
    callback,
    justView,
    defaultOptions,
    cardBrand,
    cardBrandModel,
    actionMenuItems,
}) {
    const iconsAvailables = {
        edit,
        trash,
        eye,
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null); 
    };

    const RenderDynamicMenuOption = () => {
        let options = _.isNil(actionMenuItems) ? [] : actionMenuItems;

        const getIcon = (iconName) => {
            let icon = iconsAvailables[iconName];
            if (_.isNil(icon)) {
                return edit;
            }
            return icon;
        };

        return (
            <div className='menuOptions'>
                {
                    options.map((option, index) => {
                        return (
                            <MenuItem key={index} onClick={ () => callback(_.get(option, 'keyName', index))}>
                                <img 
                                    src={getIcon(_.get(option, 'icon', 'edit'))} 
                                    style={{
                                        marginRight: '10px', 
                                        filter: 'invert(59%) sepia(82%) saturate(3904%) hue-rotate(141deg) brightness(94%) contrast(98%)'
                                    }}
                                />
                                <p className='menuTitle'>
                                    {
                                        _.get(option, 'displayText', `Opcion ${index + 1}`)
                                    }
                                </p>
                            </MenuItem>
                        );
                    })
                }
        </div>  
        );
        /*
        const menuOptions = [
            {
                callback: (t) => console.log('T: ', t),
                icon: iconsAvailables['eye'],
                displayText: 'Editar Dinamico',
            },
        ];

        return (
            <div className='menuOptions'>
                {
                    menuOptions.map(option => <ActionMenuOptions {...option} />)
                }
            </div>
        );
        */
    };

    const RenderOptions = () => {
        return (
            <>
                {
                    justView &&
                    <div style={{backgroundColor: '#969595', borderRadious: '10px'}}>
                        <MenuItem onClick={ () => callback(1)} className='menuOptions'>
                            <img 
                                src={eye} 
                                style={{marginRight: '10px', 
                                filter: 'invert(59%) sepia(82%) saturate(3904%) hue-rotate(141deg) brightness(94%) contrast(98%)' }}
                            /> 
                                <p 
                                    className='menuTitle'
                                >
                                    Ver registro
                                </p>
                        </MenuItem>
                    </div>  
                }
                {
                    defaultOptions &&
                    <div className='menuOptions'>
                        <MenuItem onClick={ () => callback(1)}>
                            <img 
                                src={edit} 
                                style={{marginRight: '10px', 
                                filter: 'invert(59%) sepia(82%) saturate(3904%) hue-rotate(141deg) brightness(94%) contrast(98%)' }}
                            /> 
                                <p 
                                    className='menuTitle'
                                >
                                    Editar registro
                                </p>
                        </MenuItem>
                        <MenuItem onClick={ () => callback(2)}>
                            <img 
                                src={trash} 
                                style={{marginRight: '10px', 
                                filter: 'invert(59%) sepia(82%) saturate(3904%) hue-rotate(141deg) brightness(94%) contrast(98%)' }}
                            /> 
                                <p 
                                    className='menuTitle'
                                >
                                    Eliminar registro
                                </p>
                        </MenuItem>
                    </div>              
                }
                {
                    cardBrand &&
                    <div className='menuOptions'>
                        <MenuItem onClick={ () => callback("EDITAR_REGISTRO")}>
                            <img 
                                src={edit} 
                                style={{marginRight: '10px', 
                                filter: 'invert(59%) sepia(82%) saturate(3904%) hue-rotate(141deg) brightness(94%) contrast(98%)' }}
                            /> 
                                <p 
                                    className='menuTitle'
                                >
                                    Editar registro
                                </p>
                        </MenuItem>
                        <MenuItem onClick={ () => callback("VER_MODELOS")}>
                            <img 
                                src={eye} 
                                style={{marginRight: '10px', 
                                filter: 'invert(59%) sepia(82%) saturate(3904%) hue-rotate(141deg) brightness(94%) contrast(98%)' }}
                            /> 
                                <p 
                                    className='menuTitle'
                                >
                                    Ver modelos
                                </p>
                        </MenuItem>
                        <MenuItem onClick={ () => callback("ELIMINAR_REGISTRO")}>
                            <img 
                                src={trash} 
                                style={{marginRight: '10px', 
                                filter: 'invert(59%) sepia(82%) saturate(3904%) hue-rotate(141deg) brightness(94%) contrast(98%)' }}
                            /> 
                                <p 
                                    className='menuTitle'
                                >
                                    Eliminar registro
                                </p>
                        </MenuItem>
                    </div>
                }
                {
                    cardBrandModel &&
                    <div className='menuOptions'>
                        <MenuItem onClick={ () => callback("EDITAR_REGISTRO")}>
                            <img 
                                src={edit} 
                                style={{marginRight: '10px', 
                                filter: 'invert(59%) sepia(82%) saturate(3904%) hue-rotate(141deg) brightness(94%) contrast(98%)' }}
                            /> 
                                <p 
                                    className='menuTitle'
                                >
                                    Editar registro
                                </p>
                        </MenuItem>
                        <MenuItem onClick={ () => callback("ELIMINAR_REGISTRO")}>
                            <img 
                                src={trash} 
                                style={{marginRight: '10px', 
                                filter: 'invert(59%) sepia(82%) saturate(3904%) hue-rotate(141deg) brightness(94%) contrast(98%)' }}
                            /> 
                                <p 
                                    className='menuTitle'
                                >
                                    Eliminar registro
                                </p>
                        </MenuItem>
                    </div>
                }
                {
                    <RenderDynamicMenuOption />
                }
            </>
        );
    }

    return (
        <div>
        <IconButton
        aria-label="more"
        id="basic-button"
        aria-controls="basic-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        >
        <MoreIcon />
        </IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            <RenderOptions />
        </Menu>
        </div>
    );
}

ActionMenu.propTypes = {
    callback: PropTypes.func,
    justView: PropTypes.bool,
    defaultOptions: PropTypes.bool,
    cardBrand: PropTypes.bool,
    cardBrandModel: PropTypes.bool,
    actionMenuItems: PropTypes.any,
}
