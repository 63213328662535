/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
 import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Button from '../../../components/atoms/Button/Button';
import GlobalFilter from '../../../components/atoms/GlobalFilter/GlobalFilter';
import Searcher from '../../../components/atoms/Searcher/Searcher';
import DataTable from '../../../components/atoms/DataTable/DataTable';
import ActionMenu from '../../../components/atoms/ActionMenu/ActionMenu';
import CustomModal from '../../../components/organisms/Modal/Modal';
import DeleteModal from '../../../components/organisms/Modal/DeleteModal';
import {
	CAR_BRAND_MODEL_MANAGEMENT,
    CAR_BRAND_FORM,
} from '../../../routes/Paths';
import {
    getAllCarsBrandAction,
    deleteCarBrandAction,
} from '../../../redux/actions/carBrandAction';

// src & styles
import './CarBrandManagement.scss';
import photo from '../../../assets/images/koopers/profilePhoto.png';
import add from '../../../assets/images/koopers/add.png';

const CarBrandManagement = (props) => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ searchCondition, setSearchCondition ] = useState({});
    const [ itemSelected, setItemSelected ] = useState(null);
    const [searchObj,  setSearchObj] = useState({});

    const dispatch = useDispatch();
    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const history = useHistory();

    const reloadInfo = () => {
        dispatch(getAllCarsBrandAction(searchCondition));
    };
    
    // useEffect(()=>{
    //     props.getAllLesseesAction(searchCondition);
    // },[/* props.isSuccess, */ searchCondition]);

    const handleDelete = (item) => {
        const dataRequest = {
            carBrandId: item
        }
        dispatch(deleteCarBrandAction(dataRequest));
        toggleModal();
        reloadInfo();
    }

    const rows = React.useMemo(
        () => [
            {
                id: 'id',
                Header: 'ID',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '20%', 
                accessor: d => ({
                    id: _.get(d, 'id', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        id,
                    },
                    row
                }) {
                    return(<span>{`${id}`}</span>)
                }
            },
            {
                id: 'name',
                Header: 'Nombre',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '20%', 
                accessor: d => ({
                    displayText: _.get(d, 'displayText', ''),
                }),
                Cell: function cell ({
                    value: {
                        displayText,
                    },
                    row
                }) {
                    return(
                        <span>
                            {
                                !_.isEmpty(displayText) && 
                                `${displayText.toUpperCase()}`
                            }
                        </span>)
                }
            },
            {
                 id: 'options.name',
                 Header: '',
                 filter: "text",
                 disableFilters: true,
                 width: '3%',
                 accessor: d => ({
                     item: d,
                 }),
                Cell: function cell ({ value: { item }, row }) {
                    return (
                        <ActionMenu
							cardBrand
                            client={true}
                            callback={(selected) => {
                            if (selected === 'EDITAR_REGISTRO') {
                                history.push(CAR_BRAND_FORM.replace(':accessMode', 'editar').replace(":carId", item.id));
                            } else if (selected === 'VER_MODELOS') {
                                history.push(CAR_BRAND_MODEL_MANAGEMENT.replace(":carId", item.id));
                            } else if (selected === 'ELIMINAR_REGISTRO') {
                                toggleModal();
                                setItemSelected(item.id);
                            }
                        }}
                        />
                    ); 
                }
             },
    ]);

    const getNewData = (condition) => {
        setSearchCondition(condition);
        dispatch(getAllCarsBrandAction(searchCondition));
    };

    return (
        <div className='CarBrandManagementGeneralContainer'>
            <div className='CarBrandManagementHeaderContainer'>
                <div className='titleContainer'>
                    <h1>GESTIÓN DE MARCAS DE VEHICULOS</h1>
                </div> 
                <div className='searcherContainer'>
                    {/* <Searcher placeholderSearch={'Busca nombre o correo arrendatario'} /> */}
                    {/* <GlobalFilter 
                        placeholderSearch={'Busca nombre o correo arrendatario'}
                        filter={searchObj}
                        setFilter={setSearchObj}
                    /> */}
                </div>
            </div>
            <div className='buttonContainer'>
                <Button
                    srcicon={add}
                    onClick={() => history.push(CAR_BRAND_FORM.replace(':accessMode', 'registrar').replace(":carId", ''))}
                >
                    NUEVO
                </Button>
            </div>
            <div className='tableContainer'>
                <DataTable
                    columns={rows}
                    data={{
                        // ...props.lessees,
                        data: _.get(props.carsBrand, 'data', []),
                        totalRecords: _.get(props.carsBrand, 'totalRecords', 0)
                    }}
                    getData={getNewData}
                    searchObj={searchObj}
                />
            </div>
            {
                openModal &&
                <CustomModal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <DeleteModal
                        OnClickCancel={toggleModal}
                        onClickAgree={() => handleDelete(itemSelected)}
                        reloadInfo={reloadInfo}
                        toggle={toggleModal}
                    />
                </CustomModal>
            }
        </div>
    )
}

CarBrandManagement.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    getAllLesseesAction: PropTypes.any,
    deleteLesseeAction: PropTypes.any,
    carsBrand: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    data: PropTypes.any,
    totalRecords: PropTypes.any,
    isSuccess: PropTypes.any,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        // data: _.get(state, 'data.data', {}),
        carsBrand: _.get(state, 'cardBrand.carsBrand', {}),
        isSuccess: _.get(state, 'cardBrand.carsBrand.isSuccess', true),
    };
}

const mapDispatchToProps = dispatch => ({
    // getAllLesseesAction: params => dispatch(getAllLesseesAction(params)),
    // deleteLesseeAction: params => dispatch(deleteLesseeAction(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(CarBrandManagement);
