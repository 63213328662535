import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './OptionsSelector.scss';
import arrow from '../../assets/images/koopers/icon-arrow.png';
import user from '../../assets/images/koopers/admin.png';
import Link from '../atoms/Link1/Link1';


const OptionsSelector = ({
    options,
    // = [
    //     {
    //         name: 'Mi perfil',
    //         path: PROFILE,
    //     },
    //     {
    //         name: 'Log out',
    //         path: '/iniciar-sesion',
    //         onClick: () => {
    //             history.push('/iniciar-sesion');
    //             props.userLogout();
    //         },
    //     },
    // ],
    admin = true,
    src = arrow,
}) => {

    const [ optionsDeployed, setOptionsDeployed ] = useState(false);
    const [ rotate, setRotate ] = useState('');

    const onClickDeployOptions = () => {
        setOptionsDeployed(!optionsDeployed);
        // setRotate(rotate == '' ? 'rotate' : '');
    };

    return (
        <div className='OptionsSelectorContainer'>
            {
                admin &&
                <div className='userIconContainer'>          
                <img
                    className='adminAccount__img'
                    src={user}
                />
                </div> 
            }
            <div className='profileLogoutButtonContainer'>
                <div className={admin ? 'buttonSelectorContainer' : 'buttonMenuContainer'}
                    onClick={() => onClickDeployOptions()}
                >
                    {
                        admin &&
                        <>
                            <p>Administrador</p>
                            <img className={`arrow ${rotate}`} src={src} />
                        </>
                    }
                </div>
                {
                    optionsDeployed &&
                    <div className='deployedOptionsContainer'>
                        {   
                            options.map(option => 
                                <div
                                    key={option.name}
                                    className='optionStyleContainer'
                                    onClick={option.onClick ? option.onClick : ''}
                                >
                                    <Link 
                                        className='optionStyleText'
                                        to={option.path}
                                    >
                                        {option.name}
                                    </Link>
                                </div>
                            )
                        }
                    </div>
                }

            </div>
        </div>
    )
};

OptionsSelector.propTypes = {
    options: PropTypes.array,
    src: PropTypes.any,
    admin: PropTypes.bool,
    menu: PropTypes.bool,
}

export default OptionsSelector;
