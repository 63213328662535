export default {
  user: {
    token: "",
    isAuthenticated: true,
    role: "company",
    request: {
      headers: {
        "Content-Type": "application/json",
      },
      body: {},
    },
    loading: false,
    error: null,
    users: {},
    user: {},
    updatePasswordRequest: {
      loading: false,
      isSuccess: false,
      error: null,
    },
    setNewPassword: {
      loading: false,
      isSuccess: false,
      error: null,
    },
    userInformation: {},
  },
  sidebar: {
    sideSelected: false,
    optionSelected: 2,
  },
  userInterface: {
    isSidebarOpened: false,
  },
  notification: {
    toastTitle: null,
    toastType: null,
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  },
  company: {
    companies: {
      loadingGetAllClients: false,
      errorGetAllClients: null,
      totalRecords: 0,
      data: [],
    },
    upsertCompany: {
      loading: false,
      isSuccess: false,
      err: null,
      data: {},
    },
    companyRecord: {
      loading: false,
      isSuccess: false,
      error: null,
      data: null,
    },
    companyAccounts: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    companyConnect: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    companyUploadFile: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
  },
  admin: {
    admins: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    adminRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    upsertAdmin: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteAdmin: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
  },

  lessor: {
    lessors: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    lessorRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    upsertLessor: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    updateLessor: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteLessor: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    getAllLessorDrivers: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    deleteOneCarDiver: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    upsertLessorDriver: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
  },
  lessee: {
    lessees: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    lesseeRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    upsertLessee: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: {},
    },
    updateLessee: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteLessee: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
  },
  car: {
    cars: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    getAllDocuments: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    carRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    upsertCar: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    updateCar: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteCar: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteOneCarDocument: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    upsertCarsDocument: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    getAllTransactionRates: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
  },
  carBrand: {
    carsBrand: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    carBrandRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    upsertCarBrand: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteCarBrand: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    carsModel: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    carModelRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    upsertCarModel: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteCarModel: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
  },
  transaction: {
    transactions: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
    transactionRecord: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
    deleteTransaction: {
      isLoading: false,
      isSuccess: false,
      err: null,
      data: null,
    },
  },
  documentCategory: {
    getAllDocumentTypes: {
      isLoading: false,
      isSuccess: false,
      err: null,
      totalRecords: 0,
      data: [],
    },
  },
  countries: {},
  cities:{},
  states:{},
  countriesFiscales: {},
  citiesFiscales:{},
  statesFiscales:{},
  countriesRepresentante: {},
  citiesRepresentante:{},
  statesRepresentante:{},
  agencias:{},
  talleres:{},
  profile:{}
};
