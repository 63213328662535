import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BG from '../../../assets/images/BG.png';
import './NotFoundPage.scss';
import Footer from '../../templates/BaseLayout/Footer/Footer';

const NotFoundPage = (props) => {
  return (
    <>
      <main className='mainNotFoundView'
          style={{backgroundImage: `url(${BG})`}}
      >
          <h2 className='mainNotFoundView__message'>
            {props.message ? props.message : 'NOT FOUND PAGE'}
          </h2>
          <img src={props.image}/>
          <Link
            className='mainNotFoundView__link'
            to="/"> 
            Go back to homepage 
          </Link>

      </main>
      <Footer />
    </>
    
  );
};

NotFoundPage.propTypes = {
message: PropTypes.string,
image: PropTypes.string,
}

export default NotFoundPage;
