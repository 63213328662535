/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
 import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import Button from '../../../../components/atoms/Button/Button';
import GlobalFilter from '../../../../components/atoms/GlobalFilter/GlobalFilter';
import Searcher from '../../../../components/atoms/Searcher/Searcher';
import DataTable from '../../../../components/atoms/DataTable/DataTable';
import ActionMenu from '../../../../components/atoms/ActionMenu/ActionMenu';
import CustomModal from '../../../../components/organisms/Modal/Modal';
import DeleteModal from '../../../../components/organisms/Modal/DeleteModal';
import {
	CAR_BRAND_VIEW_MODELS_FORM,
} from '../../../../routes/Paths';
import {
    getAllCarsBrandAction,
    deleteCarModelAction
} from '../../../../redux/actions/carBrandAction';

// src & styles
import './../CarBrandManagement.scss';
import photo from '../../../../assets/images/koopers/profilePhoto.png';
import add from '../../../../assets/images/koopers/add.png';

const CarBrandModelManagement = (props) => {

	const { carId } = useParams();


    const [ openModal, setOpenModal ] = useState(false);
    const [ searchCondition, setSearchCondition ] = useState({});
    const [ itemSelected, setItemSelected ] = useState(null);
    const [searchObj,  setSearchObj] = useState({});

    const dispatch = useDispatch();
    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const history = useHistory();

    const reloadInfo = () => {
        dispatch(getAllCarsBrandAction(searchCondition));
    };
    
    // useEffect(()=>{
    //     props.getAllLesseesAction(searchCondition);
    // },[/* props.isSuccess, */ searchCondition]);

    const handleDelete = (item) => {
        const dataRequest = {
            userId: item
        }
        //props.deleteLesseeAction(dataRequest);
        dispatch(deleteCarModelAction({ carModelId: item, }));
        toggleModal();
        reloadInfo();
    }

    const rows = React.useMemo(
        () => [
            {
                id: 'id',
                Header: 'ID',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '20%', 
                accessor: d => ({
                    id: _.get(d, 'id', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        id,
                    },
                    row
                }) {
                    return(<span>{`${id}`}</span>)
                }
            },
            {
                id: 'name',
                Header: 'Nombre',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '20%', 
                accessor: d => ({
                    displayText: _.get(d, 'displayText', ''),
                }),
                Cell: function cell ({
                    value: {
                        displayText,
                    },
                    row
                }) {
                    return(
                        <span>
                            {
                                !_.isEmpty(displayText) && 
                                `${displayText.toUpperCase()}`
                            }
                        </span>)
                }
            },
            {
                 id: 'options.name',
                 Header: '',
                 filter: "text",
                 disableFilters: true,
                 width: '3%',
                 accessor: d => ({
                     item: d,
                 }),
                Cell: function cell ({ value: { item }, row }) {
                    return (
                        <ActionMenu
                            cardBrandModel
                            client={true}
                            callback={(number) => {
                            if (number === "EDITAR_REGISTRO") { // EDITAR REGISTRO
                                history.push(CAR_BRAND_VIEW_MODELS_FORM.replace(":carId", carId).replace(':accessMode', 'editar').replace(":modelId", item.id));
                            }
                            else if (number === "ELIMINAR_REGISTRO") {
                                toggleModal();
                                setItemSelected(item.id);
                            }
                        }}
                        />
                    ); 
                }
             },
    ]);

    const getNewData = (condition) => {
        setSearchCondition(condition);
        dispatch(getAllCarsBrandAction(searchCondition));
    };

	const getBrandName = () => {
		let brandName = "VEHICULOS";
		let carBrand = _.get(props.carsBrand, 'data', []);
		if (_.isEmpty(carBrand)) return brandName;
		let filteredCarBrand = carBrand.filter(c => c.id == carId);
		brandName = _.get(filteredCarBrand, '[0].displayText', []);
		return brandName.toLocaleUpperCase();
	};

	const formatData = () => {
		let output = {
			data: [],
			totalRecords: 0,
		};
		let carBrand = _.get(props.carsBrand, 'data', []);
		if (_.isEmpty(carBrand)) return output;
		let filteredCarBrand = carBrand.filter(c => c.id == carId);
		let models = _.sortBy(_.get(filteredCarBrand, '[0].carModels', []), ['displayText']);
		return {
			data: models,
			totalRecords: models.length,
		};
	};

    return (
        <div className='CarBrandManagementGeneralContainer'>
            <div className='CarBrandManagementHeaderContainer'>
                <div className='titleContainer'>
                    <h1>GESTIÓN DE MODELOS DE {getBrandName()}</h1>
                </div> 
                <div className='searcherContainer'>
                    {/* <Searcher placeholderSearch={'Busca nombre o correo arrendatario'} /> */}
                    {/* <GlobalFilter 
                        placeholderSearch={'Busca nombre o correo arrendatario'}
                        filter={searchObj}
                        setFilter={setSearchObj}
                    /> */}
                </div>
            </div>
            <div className='buttonContainer'>
                <Button
                    srcicon={add}
                    onClick={() => history.push(CAR_BRAND_VIEW_MODELS_FORM.replace(":carId", carId).replace(':accessMode', 'crear').replace(":modelId", ''))}
                >
                    NUEVO
                </Button>
            </div>
            <div className='tableContainer'>
                <DataTable
                    columns={rows}
                    data={formatData()}
                    getData={getNewData}
                    searchObj={searchObj}
                />
            </div>
            {
                openModal &&
                <CustomModal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <DeleteModal
                        OnClickCancel={toggleModal}
                        onClickAgree={() => handleDelete(itemSelected)}
                        reloadInfo={reloadInfo}
                        toggle={toggleModal}
                    />
                </CustomModal>
            }
        </div>
    )
}

CarBrandModelManagement.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    getAllLesseesAction: PropTypes.any,
    deleteLesseeAction: PropTypes.any,
    carsBrand: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    data: PropTypes.any,
    totalRecords: PropTypes.any,
    isSuccess: PropTypes.any,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        // data: _.get(state, 'data.data', {}),
        carsBrand: _.get(state, 'cardBrand.carsBrand', {}),
        isSuccess: _.get(state, 'cardBrand.carsBrand.isSuccess', true),
    };
}

const mapDispatchToProps = dispatch => ({
    // getAllLesseesAction: params => dispatch(getAllLesseesAction(params)),
    // deleteLesseeAction: params => dispatch(deleteLesseeAction(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(CarBrandModelManagement);
