import request from "../../utils/request";
import { addNotification } from "./notificationAction";
import {
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_BEGIN,
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_SUCCESS,
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_FAILURE,
  ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_BEGIN,
  ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_SUCCESS,
  ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_FAILURE,
  ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_BEGIN,
  ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_SUCCESS,
  ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_FAILURE,
} from "./../actionTypes";

export const getAllCountriesRepresentanteBegin = () => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_BEGIN,
});

export const getAllCountriesRepresentanteSuccess = (data) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_SUCCESS,
  data,
});

export const getAllCountriesRepresentanteFailure = (err) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_FAILURE,
  err,
});

export const getAllCiudadRepresentanteBegin = () => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_BEGIN,
});

export const getAllCiudadRepresentanteSuccess = (data) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_SUCCESS,
  data,
});

export const getAllCiudadRepresentanteFailure = (err) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_FAILURE,
  err,
});

export const getAllEstadosRepresentanteBegin = () => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_BEGIN,
});

export const getAllEstadosRepresentanteSuccess = (data) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_SUCCESS,
  data,
});

export const getAllEstadosRepresentanteFailure = (err) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_FAILURE,
  err,
});




export function getAllContriesRepresentanteAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getAllCountriesRepresentanteBegin());
    return request({
      partialUrl: `/country/get-all-records`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(getAllCountriesRepresentanteSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getAllCountriesRepresentanteFailure(err));
      });
  };
}

export function getAllCiudadesRepresentanteAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getAllCiudadRepresentanteBegin());
    return request({
      partialUrl: `/city/get-all-records`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(getAllCiudadRepresentanteSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getAllCiudadRepresentanteFailure(err));
      });
  };
}

export function getAllEstadoRepresentanteAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getAllEstadosRepresentanteBegin());
    return request({
      partialUrl: `/state/get-all-records`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(getAllEstadosRepresentanteSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getAllEstadosRepresentanteFailure(err));
      });
  };
}

