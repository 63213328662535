import {
    LESSEE_MODULE_GET_ALL_LESSEE_BEGIN,
    LESSEE_MODULE_GET_ALL_LESSEE_SUCCESS,
    LESSEE_MODULE_GET_ALL_LESSEE_FAILURE,
    LESSEE_MODULE_UPSERT_LESSEE_BEGIN,
    LESSEE_MODULE_UPSERT_LESSEE_SUCCESS,
    LESSEE_MODULE_UPSERT_LESSEE_FAILURE,
    LESSEE_MODULE_UPDATE_LESSEE_BEGIN,
    LESSEE_MODULE_UPDATE_LESSEE_SUCCESS,
    LESSEE_MODULE_UPDATE_LESSEE_FAILURE,
} from '../actionTypes';

import initialState from '../initialState';

export default function lesseesReducer(state=initialState.lessee, action) {
    switch(action.type) {
        case LESSEE_MODULE_GET_ALL_LESSEE_BEGIN:
            return {
                ...state,
                lessees: {
                    ...state.lessees,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case LESSEE_MODULE_GET_ALL_LESSEE_SUCCESS:
            return {
                ...state,
                lessees: {
                    ...state.lessees,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.totalRecords,
                    data: action.data,
                },
            };
        case LESSEE_MODULE_GET_ALL_LESSEE_FAILURE:
            return {
                ...state,
                lessees: {
                    ...state.lessees,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
            case LESSEE_MODULE_UPSERT_LESSEE_BEGIN:
                return {
                    ...state,
                    upsertLessee: {
                        ...state.upsertLessee,
                        isLoading: true,
                        isSuccess: false,
                        err: null,
                        data: {},
                    },
                };
            case LESSEE_MODULE_UPSERT_LESSEE_SUCCESS:
                return {
                    ...state,
                    upsertLessee: {
                        ...state.upsertLessee,
                        isLoading: false,
                        isSuccess: true,
                        err: null,
                        data: action.admin,
                    },
                };
            case LESSEE_MODULE_UPSERT_LESSEE_FAILURE:
                return {
                    ...state,
                    upsertLessee: {
                        ...state.upsertLessee,
                        isLoading: false,
                        isSuccess: false,
                        err: action.err,
                        data: {},
                    },
                };
            case LESSEE_MODULE_UPDATE_LESSEE_BEGIN:
                return {
                    ...state,
                    updateLessee: {
                        ...state.updateLessee,
                        isLoading: true,
                        isSuccess: false,
                        err: null,
                        totalRecords: 0,
                        data: [],
                    }
                };
            case LESSEE_MODULE_UPDATE_LESSEE_SUCCESS:
                return {
                    ...state,
                    updateLessee: {
                        ...state.updateLessee,
                        isLoading: false,
                        isSuccess: true,
                        err: null,
                        totalRecords: action.totalRecords,
                        data: action.data,
                    },
                };
            case LESSEE_MODULE_UPDATE_LESSEE_FAILURE:
                return {
                    ...state,
                    updateLessee: {
                        ...state.updateLessee,
                        isLoading: false,
                        isSuccess: false,
                        err: action.err,
                        totalRecords: 0,
                        data: [],
                    },
                };
        default:
            return state;
    }
}
