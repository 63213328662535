import {
    CAR_MODULE_GET_ALL_CARS_BEGIN,
    CAR_MODULE_GET_ALL_CARS_SUCCESS,
    CAR_MODULE_GET_ALL_CARS_FAILURE,
    CAR_MODULE_UPSERT_CARS_BEGIN,
    CAR_MODULE_UPSERT_CARS_SUCCESS,
    CAR_MODULE_UPSERT_CARS_FAILURE,
    CAR_MODULE_GET_ALL_DOCUMENTS_BEGIN,
    CAR_MODULE_GET_ALL_DOCUMENTS_SUCCESS,
    CAR_MODULE_GET_ALL_DOCUMENTS_FAILURE,
    CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_BEGIN,
    CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_SUCCESS,
    CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_FAILURE,
    CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_BEGIN,
    CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_SUCCESS,
    CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_FAILURE,
    CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_BEGIN,
    CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_SUCCESS,
    CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_FAILURE,
} from '../actionTypes';

import initialState from '../initialState';

export default function carReducer(state=initialState.car, action) {
    switch(action.type) {
        case CAR_MODULE_GET_ALL_CARS_BEGIN:
            return {
                ...state,
                cars: {
                    ...state.cars,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case CAR_MODULE_GET_ALL_CARS_SUCCESS:
            return {
                ...state,
                cars: {
                    ...state.cars,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.totalRecords,
                    data: action.data,
                },
            };
        case CAR_MODULE_GET_ALL_CARS_FAILURE:
            return {
                ...state,
                cars: {
                    ...state.cars,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
            case CAR_MODULE_UPSERT_CARS_BEGIN:
                return {
                    ...state,
                    upsertCar: {
                        ...state.upsertCar,
                        isLoading: true,
                        isSuccess: false,
                        err: null,
                        data: {},
                    },
                };
            case CAR_MODULE_UPSERT_CARS_SUCCESS:
                return {
                    ...state,
                    upsertCar: {
                        ...state.upsertCar,
                        isLoading: false,
                        isSuccess: true,
                        err: null,
                        data: action.admin,
                    },
                };
            case CAR_MODULE_UPSERT_CARS_FAILURE:
                return {
                    ...state,
                    upsertCar: {
                        ...state.upsertCar,
                        isLoading: false,
                        isSuccess: false,
                        err: action.err,
                        data: {},
                    },
                };
            case CAR_MODULE_GET_ALL_DOCUMENTS_BEGIN:
                return {
                    ...state,
                    getAllDocuments: {
                        ...state.getAllDocuments,
                        isLoading: true,
                        isSuccess: false,
                        err: null,
                        totalRecords: 0,
                        data: [],
                    },
                };
            case CAR_MODULE_GET_ALL_DOCUMENTS_SUCCESS:
                return {
                    ...state,
                    getAllDocuments: {
                        ...state.getAllDocuments,
                        isLoading: false,
                        isSuccess: true,
                        err: null,
                        totalRecords: action.totalRecords,
                        data: action.data,
                    },
                };
            case CAR_MODULE_GET_ALL_DOCUMENTS_FAILURE:
                return {
                    ...state,
                    getAllDocuments: {
                        ...state.getAllDocuments,
                        isLoading: false,
                        isSuccess: false,
                        err: action.err,
                        totalRecords: 0,
                        data: [],
                    },
                };
            case CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_BEGIN:
                return {
                    ...state,
                    deleteOneCarDocument: {
                        ...state.deleteOneCarDocument,
                        isLoading: false,
                        isSuccess: false,
                        err: null,
                        data: null,
                    },
                };
            case CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_SUCCESS:
                return {
                    ...state,
                    deleteOneCarDocument: {
                        ...state.deleteOneCarDocument,
                        isLoading: false,
                        isSuccess: true,
                        err: null,
                        data: null,
                    },
                };
            case CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_FAILURE:
                return {
                    ...state,
                    deleteOneCarDocument: {
                        ...state.deleteOneCarDocument,
                        isLoading: false,
                        isSuccess: false,
                        err: null,
                        data: null,
                    },
                };
            case CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_BEGIN:
                return {
                    ...state,
                    upsertCarsDocument: {
                        ...state.upsertCarsDocument,
                        isLoading: true,
                        isSuccess: false,
                        err: null,
                        data: {},
                    },
                };
            case CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_SUCCESS:
                return {
                    ...state,
                    upsertCarsDocument: {
                        ...state.upsertCarsDocument,
                        isLoading: false,
                        isSuccess: true,
                        err: null,
                        data: action.admin,
                    },
                };
            case CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_FAILURE:
                return {
                    ...state,
                    upsertCarsDocument: {
                        ...state.upsertCarsDocument,
                        isLoading: false,
                        isSuccess: false,
                        err: action.err,
                        data: {},
                    },
                };
            case CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_BEGIN:
                return {
                    ...state,
                    getAllTransactionRates: {
                        ...state.getAllTransactionRates,
                        isLoading: true,
                        isSuccess: false,
                        err: null,
                        totalRecords: 0,
                        data: [],
                    },
                };
            case CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_SUCCESS:
                return {
                    ...state,
                    getAllTransactionRates: {
                        ...state.getAllTransactionRates,
                        isLoading: false,
                        isSuccess: true,
                        err: null,
                        totalRecords: action.totalRecords,
                        data: action.data,
                    },
                };
            case CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_FAILURE:
                return {
                    ...state,
                    getAllTransactionRates: {
                        ...state.getAllTransactionRates,
                        isLoading: false,
                        isSuccess: false,
                        err: action.err,
                        totalRecords: 0,
                        data: [],
                    },
                };
        default:
            return state;
    }
}