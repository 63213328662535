/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import InputKoopers from '../../components/molecules/InputKoopers';
import Button from '../atoms/Button/Button';
import Modal from '../../components/organisms/Modal/Modal';
import ProfileImage from '../atoms/ProfileImage/ProfileImage';

// src & styles
import './ProfileCard.scss';
import profilePhoto from '../../assets/images/koopers/defaultPhoto.png';
import plusDocs from '../../assets/images/koopers/iconCirclePlus.png'
import fileImg from '../../assets/images/koopers/document.png';

const ProfileCard = (
    props
) => {
    const [ currentProfilePhoto, setCurrentProfilePhoto ] = useState(props.photo ? props.photo : profilePhoto);
    const [ uploadedFiles, setUploadedFiles ] = useState([]);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ currentItem, setCurrentItem ] = useState();
    const [ files, setFiles ] = useState(props.files ? props.files : []);
    const [ images, setImages ] = useState(props.images ? props.images : []);
    const [ userInformation, setUserInformation ] = useState({
        firstName: '',
        firstLastName: '',
        primaryEmail: '',
        primaryPhone: '',
        password: '',
    });

    const { register, handleSubmit } = useForm();

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    let name = props.title ? props.title.toUpperCase() : '';
/*
    const onClickInputDocument = () => {
        const inputDocs = document.getElementById('docsProfileInput');
        inputDocs.click();
    }
*/
    const handleAddDocument = (event) => {
        setUploadedFiles(uploadedFiles.push((event.target.files[0])));
    }

    return (
        <div className='profileGeneralContainer'>
            <div className='profileCard'>
                    {/* <input 
                        id='inputPhoto' 
                        type={'file'} 
                        accept='image/*'
                        onChange={(e) => handleChangeImage(e)}
                        // {...register('photoProfile')}
                    /> */}
                <div className='form'>
                    {
                        props.carsProfile ?
                        <div className='profileNameContainer'>
                            <h2>{props.brand}</h2>
                            <h3>{props.model}</h3>
                        </div>
                        :
                        <>
                            {
                                /*
                                    <div 
                                        onClick={onClickInputPhoto}
                                        className='profilePhotoContainer'
                                    >
                                        <img className='profilePhotoImg' src={currentProfilePhoto} />
                                    </div> 
                                */
                            }
                            <ProfileImage
                                photo={props.photo}
                                onChangePhoto={props.onChangePhoto}
                                allowOpenSelectFile={!!props.children}
                            />
                            <div className='profileNameContainer'>
                                <h2>{name}</h2>
                            </div>
                        </>
                    }
                    {
                        !props.children &&
                        <div className='formContainer'>
                            <InputKoopers 
                                mode={'user'}
                                placeholder={'Nombre (s)'}
                                key={'firstName'}
                                {...register('firstName')}
                                onChange={(e) => setUserInformation({...userInformation, firstName: e.target.value.toLowerCase().trim()})}
                            />
                            <InputKoopers 
                                mode={'user'}
                                placeholder={'Apellido (s)'}
                                key={'firstLastName'}
                                {...register('firstLastName')}
                                onChange={(e) => setUserInformation({...userInformation, firstLastName: e.target.value.toLowerCase().trim()})}
                            />
                            <InputKoopers 
                                mode={'phone'}
                                placeholder={'Teléfono'}
                                key={'primaryPhone'}
                                {...register('primaryPhone')}
                                onChange={(e) => setUserInformation({...userInformation, primaryPhone: e.target.value.trim()})}
                            />
                            <InputKoopers 
                                mode={'mail'}
                                placeholder={'Correo electrónico'}
                                key={'3m4il'}
                                autocomplete={'new-email'}
                                {...register('email')}
                                onChange={(e) => setUserInformation({...userInformation, primaryEmail: e.target.value.toLowerCase().trim()})}
                            />
                            <InputKoopers 
                                mode={'password'}
                                placeholder={'Contraseña'}
                                key={'p4ssw0rd'}
                                autocomplete={'new-password'}
                                {...register('password')}
                                onChange={(e) => setUserInformation({...userInformation, password: e.target.value})}
                            />
                            <Button onClick={() => props.upsertFunction(userInformation)}>
                                GUARDAR
                            </Button>
                        </div>
                    }
                    <div className='formContainer'>
                        {props.children}
                        {
                            props.button &&
                            <Button
                            onClick={props.buttonsOnClick}
                            >
                                {props.button}
                            </Button>
                        }
                    </div>
                </div>
            </div>
        
        </div>
    )
}

ProfileCard.propTypes = {
    isAuthenticated: PropTypes.any,
    children: PropTypes.any,
    onSubmit: PropTypes.func,
    docsTitle: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    photo: PropTypes.any,
    upsertFunction: PropTypes.func,
    addNotification: PropTypes.any,
    carsProfile: PropTypes.bool,
    brand: PropTypes.string,
    model: PropTypes.string,
    images: PropTypes.any,
    files: PropTypes.any,
    button: PropTypes.string,
    buttonsOnClick: PropTypes.func,
    gallery: PropTypes.bool,
    docContainerClassName: PropTypes.string,
    onChangePhoto: PropTypes.func,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        admins: _.get(state, 'admin.admins', {}),
        lessors: _.get(state, 'lessor.lessors', {})
    };
}

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(ProfileCard);
