import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';
import Button from '../../../atoms/Button/Button';
import {userLogout} from '../../../../redux/actions/userActions';
import { toggleSidebar } from '../../../../redux/actions/userInterfaceAction';
import OptionsSelector from '../../../molecules/OptionsSelector';
import { PROFILE } from '../../../../routes/Paths';

// src and styles
import './Header.scss';
import backButton from '../../../../assets/images/koopers/back-arrow.png';
import historial from '../../../../assets/images/koopers/icon-historial.png';
import comercial from '../../../../assets/images/koopers/icon-comercial.png';
import usuario from '../../../../assets/images/maranello/Icon_Perfil_Bco.png';
const Header = (props) => {

    const history = useHistory();

    const handleClick = () => {
        history.push('/iniciar-sesion');
        props.userLogout();
    }

    const options = [
        {
            name: 'Mi perfil',
            path: PROFILE,
        },
        {
            name: 'Log out',
            path: '/iniciar-sesion',
            onClick: handleClick,
        },
    ]

    // const onClickToogle = () => {
    //     props.toggleSidebar();
    // }

    const onClickGoBack = () => {
        history.goBack();
    }

    // const openMenu = () => {
    //     const openButton = document.getElementById('menu');
    //     openButton.click();
    // }

    return (
        <header>
            <nav className='header container-fluid'>
                <div className='headerFirstContainer'></div>
                <div className='headerSecondContainer'>
                    {/* <img 
                        className='backButton' 
                        src={backButton} 
                        onClick={onClickGoBack}
                    /> */}
                    {/* <Button srcicon={historial}>
                        HISTORIAL
                    </Button> */}
                </div>
                <div className='headerThirdContainer'>
                    {/* <Button srcicon={comercial}>
                        COMERCIAL
                    </Button> */}
                </div>
                <div className='headerFourthContainer'>

                </div>
                <div className='headerFifthContainer'>
                    <img src={usuario} style={{width:"20px", height:"20px", marginRight:"1rem"}}/>
                    <p style={{color:"white"}}>Juan López</p>
                    {/* <OptionsSelector options={options} /> */}
                </div>
            </nav>
        </header>
    );
}

Header.propTypes = {
    title: PropTypes.string,
    userLogout: PropTypes.func,
    isSidebarOpened: PropTypes.any,
    toggleSidebar: PropTypes.func,
    userName: PropTypes.any,
}

function mapStateToProps (state) {
    return {
        isSidebarOpened: _.get(state.userInterface, 'isSidebarOpened', false),
    };
}

const mapDispatchToProps = dispatch => ({
    userLogout: () => dispatch(userLogout()),
    toggleSidebar: () => dispatch(toggleSidebar()),})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
