import React, { useEffect, useState } from "react";
import "./tabsStyle.scss";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import * as Yup from "yup";
import Modal from "react-modal";
import { upsertAgenciesAdmin } from "../../../../redux/actions/agenciesAction";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object().shape({
  telefono: Yup.string().required("Este campo es obligatorio"),
  correo: Yup.string().required("Este campo es obligatorio"),
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function DatosContacto({ datosContacto,tipoPersona,idGeneral }) {
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [paginaWeb, setPaginaWeb] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  const handleGuardarCambios = () => {
    validationSchema
      .validate({
        telefono: telefono,
        correo: correo,
        whatsapp: whatsapp,
        paginaWeb: paginaWeb,
      })
      .then(() => {
        const datosContacto = {
          data: {
            id:idGeneral,
            agencyTypeId:tipoPersona,
            agencyContactDetails: {
              primaryEmail: correo,
              primaryPhone: telefono,
              website: paginaWeb,
              whatsApp: whatsapp,
            },
          },
        };
        console.log("datosContacto",datosContacto);
        console.log("Cambios guardados");
        dispatch(upsertAgenciesAdmin(datosContacto));
      })
      .catch((errors) => {
        setValidationErrors(errors);
        setModalOpen(true);
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    console.log("datosContacto", datosContacto);
    if (_.get(datosContacto, "primaryEmail")) {
      setCorreo(datosContacto.primaryEmail);
    }
    if (_.get(datosContacto, "primaryPhone")) {
      setTelefono(datosContacto.primaryPhone);
    }
    if (_.get(datosContacto, "website")) {
      setPaginaWeb(datosContacto.website);
    }
    if (_.get(datosContacto, "whatsApp")) {
      setWhatsapp(datosContacto.whatsApp);
    }
  }, [datosContacto]);

  return (
    <div className="main-tabs">
      <div className="scrollable-container">
        <div className="main-separete-container">
          <div className="separete-container">
            <TextField
              className="textfield-stepper"
              id="telefono"
              margin="dense"
              name="telefono"
              label="Teléfono *"
              variant="filled"
              placeholder="Agregar..."
              value={telefono}
              onChange={(event) => setTelefono(event.target.value)}
              error={Boolean(validationErrors.telefono)}
              helperText={validationErrors.telefono}
            />
            <TextField
              className="textfield-stepper"
              id="whatsapp"
              margin="dense"
              value={whatsapp}
              name="whatsapp"
              onChange={(event) => setWhatsapp(event.target.value)}
              label="WhatsApp"
              variant="filled"
              placeholder="Agregar..."
            />
          </div>
          <div className="separete-container">
            <TextField
              className="textfield-stepper"
              id="correo"
              margin="dense"
              name="correo"
              label="Correo *"
              variant="filled"
              placeholder="Agregar..."
              value={correo}
              onChange={(event) => setCorreo(event.target.value)}
              error={Boolean(validationErrors.correo)}
              helperText={validationErrors.correo}
            />
            <TextField
              className="textfield-stepper"
              id="paginaWeb"
              value={paginaWeb}
              margin="dense"
              name="paginaWeb"
              onChange={(event) => setPaginaWeb(event.target.value)}
              label="Página web"
              variant="filled"
              placeholder="Agregar..."
            />
          </div>
        </div>
      </div>

      <div className="container-button">
        <Button className="btn-continuar" onClick={handleGuardarCambios}>
          GUARDAR
        </Button>
      </div>

      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="container-btns"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <p
              className="text-modal"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              No se han guardado los campos editados
            </p>
            <p
              className="text-modal"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {" "}
              ¿Seguro de querer salir?
            </p>
            <Button
              onClick={closeModal}
              className="btn-salir-sin-guardar"
              style={{
                color: "#3c3c3b",
                opacity: "1",
                textAlign: "center",
                backgroundColor: "#d6c103",
                boxShadow: "0px 3px 12px #3C3C3B33",
                borderRadius: "8px",
                fontSize: "bold",
              }}
            >
              SALIR SIN GUARDAR
            </Button>
            <Button
              onClick={closeModal}
              style={{
                color: "#3c3c3b",
                opacity: "1",
                textAlign: "center",
                backgroundColor: "white",
                boxShadow: "0px 3px 12px #3C3C3B33",
                borderRadius: "8px",
                fontSize: "bold",
                border: "1px solid #d6c103",
              }}
            >
              REGRESAR
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DatosContacto;
