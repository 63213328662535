import React, { useEffect, useState } from "react";
import { Modal, Typography, Button, Box } from "@mui/material";
import "./UsuariosInternosModal.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid } from "@material-ui/data-grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: "30rem",
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

function UsuariosInternosModal({ open, onClose, rowData }) {
  console.log("rowData", rowData);
  const [nombreAgencia, setNombreAgencia] = useState("")


  useEffect(() => {
    
  }, [])
  
  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "nombre",
      headerName: "NOMBRE",
      width: 150,
    },
    {
      field: "rol",
      headerName: "ROL",
      width: 150,
    },
    {
      field: "telefono",
      headerName: "TELÉFONO",
      width: 200,
    },
    {
      field: "correo",
      headerName: "CORREO",
      width: 200,
      description: "This column has a value getter and is not sortable.",
      sortable: false,
    },
  ];

  const rows = [
    { id: 1, nombre: "Jon Snow", rol: "Admin", telefono: 1234567890, correo: "jon.snow@example.com" },
    { id: 2, nombre: "Cersei Lannister", rol: "User", telefono: 9876543210, correo: "cersei.lannister@example.com" },
    
  ];

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="main-internos-modal">
            <div className="cancel-container">
              <CancelIcon onClick={onClose} />
            </div>
            <div className="head-container">
              <Typography variant="h6" gutterBottom style={{fontWeight:"bold"}}>
                Listado de internos
              </Typography>
              <Typography variant="h6" gutterBottom style={{fontWeight:"bold"}}> 
                Nombre de la agencia
              </Typography>
            </div>
            <div className="contianer-data-grid">
              <DataGrid
                style={{ height: "100%" }}
                rows={rows}
                columns={columns}
                disableSelectionOnClick
                hideFooterSelectedRowCount
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default UsuariosInternosModal;
