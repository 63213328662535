import {
    LESSOR_MODULE_GET_ONE_LESSOR_BEGIN,
    LESSOR_MODULE_GET_ONE_LESSOR_SUCCESS,
    LESSOR_MODULE_GET_ONE_LESSOR_FAILURE,
    LESSOR_MODULE_GET_ALL_LESSOR_BEGIN,
    LESSOR_MODULE_GET_ALL_LESSOR_SUCCESS,
    LESSOR_MODULE_GET_ALL_LESSOR_FAILURE,
    LESSOR_MODULE_UPSERT_LESSOR_BEGIN,
    LESSOR_MODULE_UPSERT_LESSOR_SUCCESS,
    LESSOR_MODULE_UPSERT_LESSOR_FAILURE,
    LESSOR_MODULE_UPDATE_LESSOR_BEGIN,
    LESSOR_MODULE_UPDATE_LESSOR_SUCCESS,
    LESSOR_MODULE_UPDATE_LESSOR_FAILURE,
    LESSOR_MODULE_DELETE_LESSOR_BEGIN,
    LESSOR_MODULE_DELETE_LESSOR_SUCCESS,
    LESSOR_MODULE_DELETE_LESSOR_FAILURE,
    LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_BEGIN,
    LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_SUCCESS,
    LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_FAILURE,
    LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_BEGIN,
    LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_SUCCESS,
    LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_FAILURE,
    LESSOR_MODULE_UPSERT_LESSOR_DRIVER_BEGIN,
    LESSOR_MODULE_UPSERT_LESSOR_DRIVER_SUCCESS,
    LESSOR_MODULE_UPSERT_LESSOR_DRIVER_FAILURE,
} from './../actionTypes';

import initialState from '../initialState';

export default function lessorsReducer(state=initialState.lessor, action) {
    switch(action.type) {
        case LESSOR_MODULE_GET_ONE_LESSOR_BEGIN:
            return {
                ...state,
                lessorRecord: {
                    ...state.lessorRecord,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: null,
                },
            };
        case LESSOR_MODULE_GET_ONE_LESSOR_SUCCESS:
            return {
                ...state,
                lessorRecord: {
                    ...state.lessorRecord,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.lessorRecord,
                },
            };
        case LESSOR_MODULE_GET_ONE_LESSOR_FAILURE:
            return {
                ...state,
                lessorRecord: {
                    ...state.lessorRecord,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                }
            };
        case LESSOR_MODULE_GET_ALL_LESSOR_BEGIN:
            return {
                ...state,
                lessors: {
                    ...state.lessors,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case LESSOR_MODULE_GET_ALL_LESSOR_SUCCESS:
            return {
                ...state,
                lessors: {
                    ...state.lessors,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.totalRecords,
                    data: action.data,
                },
            };
        case LESSOR_MODULE_GET_ALL_LESSOR_FAILURE:
            return {
                ...state,
                lessors: {
                    ...state.lessors,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
        case LESSOR_MODULE_UPSERT_LESSOR_BEGIN:
            return {
                ...state,
                upsertLessor: {
                    ...state.upsertLessor,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                },
            };
        case LESSOR_MODULE_UPSERT_LESSOR_SUCCESS:
            return {
                ...state,
                upsertLessor: {
                    ...state.upsertLessor,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.lessor,
                },
            };
        case LESSOR_MODULE_UPSERT_LESSOR_FAILURE:
            return {
                ...state,
                upsertLessor: {
                    ...state.upsertLessor,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                },
            };
        case LESSOR_MODULE_UPDATE_LESSOR_BEGIN:
            return {
                ...state,
                updateLessor: {
                    ...state.updateLessor,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                },
            };
        case LESSOR_MODULE_UPDATE_LESSOR_SUCCESS:
            return {
                ...state,
                updateLessor: {
                    ...state.updateLessor,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.lessor,
                },
            };
        case LESSOR_MODULE_UPDATE_LESSOR_FAILURE:
            return {
                ...state,
                updateLessor: {
                    ...state.updateLessor,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                },
            };
        case LESSOR_MODULE_DELETE_LESSOR_BEGIN:
            return {
                ...state,
                deleteLessor: {
                    ...state.deleteLessor,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: null,
                },
            };
        case LESSOR_MODULE_DELETE_LESSOR_SUCCESS:
            return {
                ...state,
                deleteLessor: {
                    ...state.deleteLessor,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.admin,
                },
            };
        case LESSOR_MODULE_DELETE_LESSOR_FAILURE:
            return {
                ...state,
                deleteLessor: {
                    ...state.deleteLessor,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                },
            };
        case LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_BEGIN:
            return {
                ...state,
                getAllLessorDrivers: {
                    ...state.getAllLessorDrivers,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    totalRecords: 0,
                    data: [],
                }
            };
        case LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_SUCCESS:
            return {
                ...state,
                getAllLessorDrivers: {
                    ...state.getAllLessorDrivers,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    totalRecords: action.totalRecords,
                    data: action.data,
                },
            };
        case LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_FAILURE:
            return {
                ...state,
                getAllLessorDrivers: {
                    ...state.getAllLessorDrivers,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    totalRecords: 0,
                    data: [],
                },
            };
        case LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_BEGIN:
            return {
                ...state,
                deleteOneCarDiver: {
                    ...state.deleteOneCarDiver,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: null,
                },
            };
        case LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_SUCCESS:
            return {
                ...state,
                deleteOneCarDiver: {
                    ...state.deleteOneCarDiver,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.data,
                },
            };
        case LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_FAILURE:
            return {
                ...state,
                deleteOneCarDiver: {
                    ...state.deleteOneCarDiver,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: null,
                },
            };
        case LESSOR_MODULE_UPSERT_LESSOR_DRIVER_BEGIN:
            return {
                ...state,
                upsertLessor: {
                    ...state.upsertLessor,
                    isLoading: true,
                    isSuccess: false,
                    err: null,
                    data: {},
                },
            };
        case LESSOR_MODULE_UPSERT_LESSOR_DRIVER_SUCCESS:
            return {
                ...state,
                upsertLessorDriver: {
                    ...state.upsertLessorDriver,
                    isLoading: false,
                    isSuccess: true,
                    err: null,
                    data: action.lessor,
                },
            };
        case LESSOR_MODULE_UPSERT_LESSOR_DRIVER_FAILURE:
            return {
                ...state,
                upsertLessorDriver: {
                    ...state.upsertLessorDriver,
                    isLoading: false,
                    isSuccess: false,
                    err: action.err,
                    data: {},
                },
            };
        default:
            return state;
    }
}
