import request from "../../utils/request";
import { addNotification } from './notificationAction';
import {
	DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_BEGIN,
	DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_SUCCESS,
	DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_FAILURE,
} from "../actionTypes";

// ACTIONS TO REDUCER

export const getAllDocumentCategoriesBegin = () => ({
    type: DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_BEGIN,
});

export const getAllDocumentCategoriesSuccess = ({totalRecords, data}) => ({
    type: DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_SUCCESS,
    totalRecords,
    data,
});

export const getAllDocumentCategoriesFailure = (err) => ({
    type: DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_FAILURE,
    err,
});


// DISPATCH TO ACTIONS


export function getAllDocumentCategoriesAction(data){
    return (dispatch, getState) => {
        dispatch(getAllDocumentCategoriesBegin());
        return request({
            partialUrl: `/api/v1/document-category/get-all-document-category-action`,
            method: 'POST',
            state: getState().user.request,
            body: data,
            dispatch,
        })
        .then( request => request.json())
        .then( data => {
            console.log(data);
            dispatch(getAllDocumentCategoriesSuccess(data.data));
        })
        .catch(err => {
            console.error(data);
            console.log(err);
            dispatch(addNotification({
                toastType: 'error',
                toastTitle: 'Se ha generado un error al obtener el listado, favor de intentarlo de nuevo',
            }));
            return dispatch(getAllDocumentCategoriesFailure(err));
        });
    };
}
