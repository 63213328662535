import React from 'react';
import './Login.scss';
import FormLogIn from './FormLogIn';


const Login = () => {
    return (
        <>
            <div className='login-main'>
                <div className='login-container'>
                    <FormLogIn></FormLogIn>
                </div>
            </div>
        </>
    )
}

export default Login;
