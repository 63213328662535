// COMPANY Module
export const COMPANY_MODULE_GET_ALL_COMPANIES_BEGIN = 'COMPANY_MODULE_GET_ALL_COMPANIES_BEGIN';
export const COMPANY_MODULE_GET_ALL_COMPANIES_SUCCESS = 'COMPANY_MODULE_GET_ALL_COMPANIES_SUCCESS';
export const COMPANY_MODULE_GET_ALL_COMPANIES_FAILURE = 'COMPANY_MODULE_GET_ALL_COMPANIES_FAILURE';
export const COMPANY_MODULE_UPSERT_COMPANY_BEGIN = 'COMPANY_MODULE_UPSERT_COMPANY_BEGIN';
export const COMPANY_MODULE_UPSERT_COMPANY_SUCCESS = 'COMPANY_MODULE_UPSERT_COMPANY_SUCCESS';
export const COMPANY_MODULE_UPSERT_COMPANY_FAILURE = 'COMPANY_MODULE_UPSERT_COMPANY_FAILURE';
export const COMPANY_MODULE_GET_ONE_COMPANY_RECORD_BEGIN = 'COMPANY_MODULE_GET_ONE_COMPANY_RECORD_BEGIN';
export const COMPANY_MODULE_GET_ONE_COMPANY_RECORD_SUCCESS = 'COMPANY_MODULE_GET_ONE_COMPANY_RECORD_SUCCESS';
export const COMPANY_MODULE_GET_ONE_COMPANY_RECORD_FAILURE = 'COMPANY_MODULE_GET_ONE_COMPANY_RECORD_FAILURE';
export const COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_BEGIN = 'COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_BEGIN';
export const COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_SUCCESS = 'COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_SUCCESS';
export const COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_FAILURE = 'COMPANY_MODULE_GET_ALL_COMPANY_ACCOUNTS_FAILURE';
export const COMPANY_MODULE_CONNECT_WITH_STORE_BEGIN = 'COMPANY_MODULE_CONNECT_WITH_STORE_BEGIN';
export const COMPANY_MODULE_CONNECT_WITH_STORE_SUCCESS = 'COMPANY_MODULE_CONNECT_WITH_STORE_SUCCESS';
export const COMPANY_MODULE_CONNECT_WITH_STORE_FAILURE = 'COMPANY_MODULE_CONNECT_WITH_STORE_FAILURE';
export const COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_BEGIN = 'COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_BEGIN';
export const COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_SUCCESS = 'COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_SUCCESS';
export const COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_FAILURE = 'COMPANY_MODULE_UPLOAD_COMPANY_ACCOUNT_FILE_FAILURE';
export const COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_BEGIN = 'COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_BEGIN';
export const COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_SUCCESS = 'COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_SUCCESS';
export const COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_FAILURE = 'COMPANY_MODULE_UPSERT_COMPANY_ACCOUNT_FAILURE';

// Administrator Module
export const ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_BEGIN = "ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_BEGIN";
export const ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_SUCCESS = "ADMINISTRATOR_MODULE_GET_ONE_ADMINISTARTOR_SUCCESS";
export const ADMINISTATOR_MODULE_GET_ONE_ADMINISTRATOR_FAILURE = "ADMINISTATOR_MODULE_GET_ONE_ADMINISTRATOR_FAILURE";
export const ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_BEGIN = "ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_BEGIN";
export const ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_SUCCESS = "ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_SUCCESS";
export const ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_FAILURE = "ADMINISTRATOR_MODULE_GET_ALL_ADMINISTRATORS_FAILURE";
export const ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_BEGIN = "ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_BEGIN";
export const ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_SUCCESS = "ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_SUCCESS";
export const ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_FAILURE = "ADMINISTRATOR_MODULE_UPSERT_ADMINISTRATOR_FAILURE";
export const ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_BEGIN = 'ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_BEGIN';
export const ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_SUCCESS = 'ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_SUCCESS';
export const ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_FAILURE = 'ADMINISTRATOR_MODULDE_DELETE_ADMINISTRATOR_FAILURE';
export const ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_BEGIN = 'ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_BEGIN';
export const ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_SUCCESS = 'ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_SUCCESS';
export const ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_FAILURE = 'ADMINISTRATOR_MODULE_UPDATE_ADMINISTRATOR_FAILURE';

// Lessor Module
export const LESSOR_MODULE_GET_ONE_LESSOR_BEGIN = "LESSOR_MODULE_GET_ONE_LESSOR_BEGIN";
export const LESSOR_MODULE_GET_ONE_LESSOR_SUCCESS = "LESSOR_MODULE_GET_ONE_LESSOR_SUCCESS";
export const LESSOR_MODULE_GET_ONE_LESSOR_FAILURE = "LESSOR_MODULE_GET_ONE_LESSOR_FAILURE";
export const LESSOR_MODULE_GET_ALL_LESSOR_BEGIN = "LESSOR_MODULE_GET_ALL_LESSOR_BEGIN";
export const LESSOR_MODULE_GET_ALL_LESSOR_SUCCESS = "LESSOR_MODULE_GET_ALL_LESSOR_SUCCESS";
export const LESSOR_MODULE_GET_ALL_LESSOR_FAILURE = "LESSOR_MODULE_GET_ALL_LESSOR_FAILURE";
export const LESSOR_MODULE_UPSERT_LESSOR_BEGIN = "LESSOR_MODULE_UPSERT_LESSOR_BEGIN";
export const LESSOR_MODULE_UPSERT_LESSOR_SUCCESS = "LESSOR_MODULE_UPSERT_LESSOR_SUCCESS";
export const LESSOR_MODULE_UPSERT_LESSOR_FAILURE = "LESSOR_MODULE_UPSERT_LESSOR_FAILURE";
export const LESSOR_MODULE_DELETE_LESSOR_BEGIN = 'LESSOR_MODULDE_DELETE_LESSOR_BEGIN';
export const LESSOR_MODULE_DELETE_LESSOR_SUCCESS = 'LESSOR_MODULDE_DELETE_LESSOR_SUCCESS';
export const LESSOR_MODULE_DELETE_LESSOR_FAILURE = 'LESSOR_MODULDE_DELETE_LESSOR_FAILURE';
export const LESSOR_MODULE_UPDATE_LESSOR_BEGIN = 'LESSOR_MODULE_UPDATE_LESSOR_BEGIN';
export const LESSOR_MODULE_UPDATE_LESSOR_SUCCESS = 'LESSOR_MODULE_UPDATE_LESSOR_SUCCESS';
export const LESSOR_MODULE_UPDATE_LESSOR_FAILURE = 'LESSOR_MODULE_UPDATE_LESSOR_FAILURE';

export const LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_BEGIN = "LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_BEGIN";
export const LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_SUCCESS = "LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_SUCCESS";
export const LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_FAILURE = "LESSOR_MODULE_GET_ALL_LESSOR_DRIVERS_FAILURE";
export const LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_BEGIN = "LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_BEGIN";
export const LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_SUCCESS = "LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_SUCCESS";
export const LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_FAILURE = "LESSOR_MODULE_DELETE_ONE_CAR_DRIVER_FAILURE";

export const LESSOR_MODULE_UPSERT_LESSOR_DRIVER_BEGIN = "LESSOR_MODULE_UPSERT_LESSOR_DRIVER_BEGIN";
export const LESSOR_MODULE_UPSERT_LESSOR_DRIVER_SUCCESS = "LESSOR_MODULE_UPSERT_LESSOR_DRIVER_SUCCESS";
export const LESSOR_MODULE_UPSERT_LESSOR_DRIVER_FAILURE = "LESSOR_MODULE_UPSERT_LESSOR_DRIVER_FAILURE";


// Lessee Module
export const LESSEE_MODULE_GET_ALL_LESSEE_BEGIN = "LESSEE_MODULE_GET_ALL_LESSEE_BEGIN";
export const LESSEE_MODULE_GET_ALL_LESSEE_SUCCESS = "LESSEE_MODULE_GET_ALL_LESSEE_SUCCESS";
export const LESSEE_MODULE_GET_ALL_LESSEE_FAILURE = "LESSEE_MODULE_GET_ALL_LESSEE_FAILURE";
export const LESSEE_MODULE_UPSERT_LESSEE_BEGIN = "LESSEE_MODULE_UPSERT_LESSEE_BEGIN";
export const LESSEE_MODULE_UPSERT_LESSEE_SUCCESS = "LESSEE_MODULE_UPSERT_LESSEE_SUCCESS";
export const LESSEE_MODULE_UPSERT_LESSEE_FAILURE = "LESSEE_MODULE_UPSERT_LESSEE_FAILURE";
export const LESSEE_MODULE_DELETE_LESSEE_BEGIN = 'LESSEE_MODULE_UPDATE_LESSEE_BEGIN';
export const LESSEE_MODULE_DELETE_LESSEE_SUCCESS = 'LESSEE_MODULE_UPDATE_LESSEE_SUCCESS';
export const LESSEE_MODULE_DELETE_LESSEE_FAILURE = 'LESSEE_MODULE_UPDATE_LESSEE_FAILURE';
export const LESSEE_MODULE_UPDATE_LESSEE_BEGIN = 'LESSEE_MODULE_UPDATE_LESSEE_BEGIN';   
export const LESSEE_MODULE_UPDATE_LESSEE_SUCCESS = 'LESSEE_MODULE_UPDATE_LESSEE_SUCCESS'; 
export const LESSEE_MODULE_UPDATE_LESSEE_FAILURE = 'LESSEE_MODULE_UPDATE_LESSEE_FAILURE';

// Cars Module
export const CAR_MODULE_GET_ALL_CARS_BEGIN = 'CAR_MODULE_GET_ALL_CARS_BEGIN';
export const CAR_MODULE_GET_ALL_CARS_SUCCESS = 'CAR_MODULE_GET_ALL_CARS_SUCCESS';
export const CAR_MODULE_GET_ALL_CARS_FAILURE = 'CAR_MODULE_GET_ALL_CARS_FAILURE';
export const CAR_MODULE_UPSERT_CARS_BEGIN = 'CAR_MODULE_UPSERT_CARS_BEGIN';
export const CAR_MODULE_UPSERT_CARS_SUCCESS = 'CAR_MODULE_UPSERT_CARS_SUCCESS';
export const CAR_MODULE_UPSERT_CARS_FAILURE = 'CAR_MODULE_UPSERT_CARS_FAILURE';
export const CAR_MODULE_GET_ALL_DOCUMENTS_BEGIN = 'CAR_MODULE_GET_ALL_DOCUMENTS_BEGIN';
export const CAR_MODULE_GET_ALL_DOCUMENTS_SUCCESS = 'CAR_MODULE_GET_ALL_DOCUMENTS_SUCCESS';
export const CAR_MODULE_GET_ALL_DOCUMENTS_FAILURE = 'CAR_MODULE_GET_ALL_DOCUMENTS_FAILURE';
export const CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_BEGIN = 'CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_BEGIN';
export const CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_SUCCESS = 'CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_SUCCESS';
export const CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_FAILURE = 'CAR_MODULE_DELETE_ONE_CAR_DOCUMENT_FAILURE';


// Cars Brand Module
export const CAR_BRAND_MODULE_GET_ALL_CARS_BEGIN = 'CAR_BRAND_MODULE_GET_ALL_CARS_BEGIN';
export const CAR_BRAND_MODULE_GET_ALL_CARS_SUCCESS = 'CAR_BRAND_MODULE_GET_ALL_CARS_SUCCESS';
export const CAR_BRAND_MODULE_GET_ALL_CARS_FAILULeRE = 'CAR_BRAND_MODULE_GET_ALL_CARS_FAILURE';
export const CAR_BRAND_MODULE_UPSERT_CAR_BEGIN = 'CAR_BRAND_MODULE_UPSERT_CAR_BEGIN';
export const CAR_BRAND_MODULE_UPSERT_CAR_SUCCESS = 'CAR_BRAND_MODULE_UPSERT_CAR_SUCCESS';
export const CAR_BRAND_MODULE_UPSERT_CAR_FAILURE = 'CAR_BRAND_MODULE_UPSERT_CAR_FAILURE';
export const CAR_BRAND_MODULE_DELETE_CAR_BEGIN = 'CAR_BRAND_MODULE_DELETE_CAR_BEGIN';
export const CAR_BRAND_MODULE_DELETE_CAR_SUCCESS = 'CAR_BRAND_MODULE_DELETE_CAR_SUCCESS';
export const CAR_BRAND_MODULE_DELETE_CAR_FAILURE = 'CAR_BRAND_MODULE_DELETE_CAR_FAILURE';

export const CAR_BRAND_MODULE_UPSERT_CAR_MODEL_BEGIN = 'CAR_BRAND_MODULE_UPSERT_CAR_MODEL_BEGIN';
export const CAR_BRAND_MODULE_UPSERT_CAR_MODEL_SUCCESS = 'CAR_BRAND_MODULE_UPSERT_CAR_MODEL_SUCCESS';
export const CAR_BRAND_MODULE_UPSERT_CAR_MODEL_FAILURE = 'CAR_BRAND_MODULE_UPSERT_CAR_MODEL_FAILURE';
export const CAR_BRAND_MODULE_DELETE_CAR_MODEL_BEGIN = 'CAR_BRAND_MODULE_DELETE_CAR_MODEL_BEGIN';
export const CAR_BRAND_MODULE_DELETE_CAR_MODEL_SUCCESS = 'CAR_BRAND_MODULE_DELETE_CAR_MODEL_SUCCESS';
export const CAR_BRAND_MODULE_DELETE_CAR_MODEL_FAILURE = 'CAR_BRAND_MODULE_DELETE_CAR_MODEL_FAILURE';
export const CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_BEGIN = 'CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_BEGIN';
export const CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_SUCCESS = 'CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_SUCCESS';
export const CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_FAILURE = 'CAR_BRAND_MODULE_UPSERT_CARS_DOCUMENT_FAILURE';

export const CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_BEGIN = 'CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_BEGIN';
export const CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_SUCCESS = 'CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_SUCCESS';
export const CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_FAILURE = 'CAR_BRAND_MODULE_GET_ALL_TRANSACTION_RATES_FAILURE';


// Transactions Module
export const TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_BEGIN = 'TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_BEGIN';
export const TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_SUCCESS = 'TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_SUCCESS';
export const TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_FAILURE = 'TRANSACTION_MODULE_GET_ALL_TRANSACTIONS_FAILURE';

// Notification Module
export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';

// User Interface Module
export const TOGGLE_SIDEBAR_SUCCESS = 'TOGGLE_SIDEBAR_SUCCESS';

// User Module
export const USER_MODULE_USER_LOGIN_BEGIN = 'USER_MODULE_USER_LOGIN_BEGIN';
export const USER_MODULE_USER_LOGIN_SUCCESS = 'USER_MODULE_USER_LOGIN_SUCCESS';
export const USER_MODULE_USER_LOGIN_FAILURE = 'USER_MODULE_USER_LOGIN_FAILURE';
export const USER_MODULE_USER_LOGOUT_BEGIN = 'USER_MODULE_USER_LOGOUT_BEGIN';
export const USER_MODULE_USER_LOGOUT_SUCCESS = 'USER_MODULE_USER_LOGOUT_SUCCESS';
export const USER_MODULE_USER_LOGOUT_FAILURE = 'USER_MODULE_USER_LOGOUT_FAILURE';
export const USER_MODULE_USER_REGISTER_BEGIN = 'USER_MODULE_USER_REGISTER_BEGIN';
export const USER_MODULE_USER_REGISTER_SUCCESS = 'USER_MODULE_USER_REGISTER_SUCCESS';
export const USER_MODULE_USER_REGISTER_FAILURE = 'USER_MODULE_USER_REGISTER_FAILURE';
export const USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_BEGIN = 'USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_BEGIN';
export const USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_SUCCESS = 'USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_SUCCESS';
export const USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_FAILURE = 'USER_MODULE_USER_UPDATE_PASSWORD_REQUEST_FAILURE';
export const USER_MODULE_USER_SET_NEW_PASSWORD_BEGIN = 'USER_MODULE_USER_SET_NEW_PASSWORD_BEGIN';
export const USER_MODULE_USER_SET_NEW_PASSWORD_SUCCESS = 'USER_MODULE_USER_SET_NEW_PASSWORD_SUCCESS';
export const USER_MODULE_USER_SET_NEW_PASSWORD_FAILURE = 'USER_MODULE_USER_SET_NEW_PASSWORD_FAILURE';
export const USER_MODULE_USER_GET_INFORMATION_BEGIN = 'USER_MODULE_USER_GET_INFORMATION_BEGIN';
export const USER_MODULE_USER_GET_INFORMATION_SUCCESS = 'USER_MODULE_USER_GET_INFORMATION_SUCCESS';
export const USER_MODULE_USER_GET_INFORMATION_FAILURE = 'USER_MODULE_USER_GET_INFORMATION_FAILURE';
export const USER_MODULE_USER_UPDATE_INFORMATION_BEGIN = 'USER_MODULE_USER_UPDATE_INFORMATION_BEGIN';
export const USER_MODULE_USER_UPDATE_INFORMATION_SUCCESS = 'USER_MODULE_USER_UPDATE_INFORMATION_SUCCESS';
export const USER_MODULE_USER_UPDATE_INFORMATION_FAILURE = 'USER_MODULE_USER_UPDATE_INFORMATION_FAILURE';


// Gender Module
export const GENDER_MODULE_GENDER_GET_ALL_BEGIN = 'GENDER_MODULE_GENDER_GET_ALL_BEGIN';
export const GENDER_MODULE_GENDER_GET_ALL_SUCCESS = 'GENDER_MODULE_GENDER_GET_ALL_SUCCESS';
export const GENDER_MODULE_GENDER_GET_ALL_FAILURE = 'GENDER_MODULE_GENDER_GET_ALL_FAILURE';


// Gender Document Reducer
export const DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_BEGIN = 'DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_BEGIN';
export const DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_SUCCESS = 'DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_SUCCESS';
export const DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_FAILURE = 'DOCUMENT_MODULE_DOCUMENT_CATEGORIES_GET_ALL_FAILURE';




//-------------------Maranello-----------------
//Paises 
export const ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_BEGIN = 'ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_BEGIN';
export const ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_SUCCESS = 'ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_SUCCESS';
export const ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FAILURE = 'ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FAILURE';

//Ciudad - Municipio
export const ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_BEGIN = 'ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_BEGIN';
export const ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_SUCCESS = 'ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_SUCCESS';
export const ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FAILURE = 'ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FAILURE';

//Estado
export const ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_BEGIN = 'ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_BEGIN';
export const ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_SUCCESS = 'ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_SUCCESS';
export const ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FAILURE = 'ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FAILURE';

//Paises Fiscales
export const ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_BEGIN = 'ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_BEGIN';
export const ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_SUCCESS = 'ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_SUCCESS';
export const ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_FAILURE = 'ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_FAILURE';

//Ciudad - Municipio fiscales
export const ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_BEGIN = 'ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_BEGIN';
export const ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_SUCCESS = 'ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_SUCCESS';
export const ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_FAILURE = 'ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_FAILURE';

//Estado fiscales
export const ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_BEGIN = 'ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_BEGIN';
export const ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_SUCCESS = 'ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_SUCCESS';
export const ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_FAILURE = 'ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_FAILURE';


//Paises Representante
export const ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_BEGIN = 'ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_BEGIN';
export const ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_SUCCESS = 'ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_SUCCESS';
export const ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_FAILURE = 'ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_REPRESENTANTE_FAILURE';

//Ciudad - Municipio Representante
export const ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_BEGIN = 'ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_BEGIN';
export const ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_SUCCESS = 'ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_SUCCESS';
export const ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_FAILURE = 'ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_REPRESENTANTE_FAILURE';

//Estado Representante
export const ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_BEGIN = 'ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_BEGIN';
export const ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_SUCCESS = 'ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_SUCCESS';
export const ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_FAILURE = 'ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_REPRESENTANTE_FAILURE';


export const ADMIN_MODULE_ADMIN_GET_PROFILE_BEGIN = 'ADMIN_MODULE_ADMIN_GET_PROFILE_BEGIN';
export const ADMIN_MODULE_ADMIN_GET_PROFILE_SUCCESS = 'ADMIN_MODULE_ADMIN_GET_PROFILE_SUCCESS';
export const ADMIN_MODULE_ADMIN_GET_PROFILE_FAILURE = 'ADMIN_MODULE_ADMIN_GET_PROFILE_FAILURE';

export const ADMIN_MODULE_ADMIN_UPDATE_PROFILE_BEGIN = 'ADMIN_MODULE_ADMIN_UPDATE_PROFILE_BEGIN';
export const ADMIN_MODULE_ADMIN_UPDATE_PROFILE_SUCCESS = 'ADMIN_MODULE_ADMIN_UPDATE_PROFILE_SUCCESS';
export const ADMIN_MODULE_ADMIN_UPDATE_PROFILE_FAILURE = 'ADMIN_MODULE_ADMIN_UPDATE_PROFILE_FAILURE';


export const ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_BEGIN = 'ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_BEGIN';
export const ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_SUCCESS = 'ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_SUCCESS';
export const ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_FAILURE = 'ADMIN_MODULE_ADMIN_CHANGE_PASSWORD_FAILURE';


export const ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_BEGIN = 'ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_BEGIN';
export const ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_SUCCESS = 'ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_SUCCESS';
export const ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_FAILURE = 'ADMIN_MODULE_ADMIN_UPDATE_DOCUMENTATION_FAILURE';



//Insertar - Editar  agencias
export const ADMIN_MODULE_UPSERT_AGENCIES_BEGIN = 'ADMIN_MODULE_UPSERT_AGENCIES_BEGIN';
export const ADMIN_MODULE_UPSERT_AGENCIES_SUCCESS = 'ADMIN_MODULE_UPSERT_AGENCIES_SUCCESS';
export const ADMIN_MODULE_UPSERT_AGENCIES_FAILURE = 'ADMIN_MODULE_UPSERT_AGENCIES_FAILURE';

//Listar Agencias 
export const ADMIN_MODULE_GET_ALL_AGENCIES_BEGIN = 'ADMIN_MODULE_GET_ALL_AGENCIES_BEGIN';
export const ADMIN_MODULE_GET_ALL_AGENCIES_SUCCESS = 'ADMIN_MODULE_GET_ALL_AGENCIES_SUCCESS';
export const ADMIN_MODULE_GET_ALL_AGENCIES_FAILURE = 'ADMIN_MODULE_GET_ALL_AGENCIES_FAILURE';

//ELIMINAR AGENCIAS
export const ADMIN_MODULE_DELETE_AGENCIES_BEGIN = 'ADMIN_MODULE_DELETE_AGENCIES_BEGIN';
export const ADMIN_MODULE_DELETE_AGENCIES_SUCCESS = 'ADMIN_MODULE_DELETE_AGENCIES_SUCCESS';
export const ADMIN_MODULE_DELETE_AGENCIES_FAILURE = 'ADMIN_MODULE_DELETE_AGENCIES_FAILURE';



//Insertar Taller
export const ADMIN_MODULE_UPSERT_WORKSHOP_BEGIN = 'ADMIN_MODULE_UPSERT_WORKSHOP_BEGIN';
export const ADMIN_MODULE_UPSERT_WORKSHOP_SUCCESS = 'ADMIN_MODULE_UPSERT_WORKSHOP_SUCCESS';
export const ADMIN_MODULE_UPSERT_WORKSHOP_FAILURE = 'ADMIN_MODULE_UPSERT_WORKSHOP_FAILURE';

//Editar Taller
export const ADMIN_MODULE_EDIT_WORKSHOP_BEGIN = 'ADMIN_MODULE_EDIT_WORKSHOP_BEGIN';
export const ADMIN_MODULE_EDIT_WORKSHOP_SUCCESS = 'ADMIN_MODULE_EDIT_WORKSHOP_SUCCESS';
export const ADMIN_MODULE_EDIT_WORKSHOP_FAILURE = 'ADMIN_MODULE_EDIT_WORKSHOP_FAILURE';



//Listar Taller 
export const ADMIN_MODULE_GET_ALL_WORKSHOP_BEGIN = 'ADMIN_MODULE_GET_ALL_WORKSHOP_BEGIN';
export const ADMIN_MODULE_GET_ALL_WORKSHOP_SUCCESS = 'ADMIN_MODULE_GET_ALL_WORKSHOP_SUCCESS';
export const ADMIN_MODULE_GET_ALL_WORKSHOP_FAILURE = 'ADMIN_MODULE_GET_ALL_WORKSHOP_FAILURE';

//ELIMINAR Taller
export const ADMIN_MODULE_DELETE_WORKSHOP_BEGIN = 'ADMIN_MODULE_DELETE_WORKSHOP_BEGIN';
export const ADMIN_MODULE_DELETE_WORKSHOP_SUCCESS = 'ADMIN_MODULE_DELETE_WORKSHOP_SUCCESS';
export const ADMIN_MODULE_DELETE_WORKSHOP_FAILURE = 'ADMIN_MODULE_DELETE_WORKSHOP_FAILURE';
