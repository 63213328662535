/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory,  } from 'react-router-dom';
import _, { first } from "lodash";
import InputKoopers from '../../../components/molecules/InputKoopers';
import Button from '../../../components/atoms/Button/Button';
import { 
    getUserInformationRequest,
    updateUserInformationRequest,
} from '../../../redux/actions/userActions';

// src & styles
import './Profile.scss';
import photo from '../../../assets/images/koopers/profilePhoto.png';
import pin from '../../../assets/images/koopers/locationPin.png';
import ProfileCard from '../../../components/organisms/ProfileCard';
import { upsertAdministratorAction } from '../../../redux/actions/adminAction';

const Profile = (props) => {


    const history = useHistory();
    let mainData;
    mainData = _.get(props.user, "data", null);

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [firstName, setFirstName] = useState('-')
    const [firstLastName, setFirstLastName] = useState('-')
    const [email, setEmail] = useState('-')
    const [primaryPhone, setPrimaryPhone] = useState('-')
    const [url, setUrl] = useState()
    const [dataInfo, setDataInfo] = useState()


    const getInfo = async () => {
        const item = JSON.parse(localStorage.getItem('user'))
        console.log(item,"soy item")
        if(!_.isEmpty(item)){
            const res = await dispatch(getUserInformationRequest({"administratorId": item.administrator.id})).then((data)=>{
                // console.warn(data.data, "soy data")
                setDataInfo(data.data.person);
                setFirstName(data.data.person.firstName);
                setFirstLastName(data.data.person.firstLastName);
                setEmail(data.data.person.primaryEmail);
                setPrimaryPhone(data.data.person.primaryPhone);
                setUrl(data.data.person.profileImageMedia.url)
                console.warn("url", data.data.person.profileImageMedia.url)
    
              
            } );
        }else{
            alert("Error al cargar los datos")
            history.push('/dashboard/inicio')
        }
      
        

    }

    useEffect(() => {
        getInfo().then((res) => {
                console.warn(res);
                    setData(res.data);
                })
    }, [])

    const onSubmitUpdateProfile = () => {
        const item = JSON.parse(localStorage.getItem('user'))
        if(!_.isEmpty(item)){
            const data = {
                id: item.id,
                user: {
                    email: email
                },
                person: {
                    firstName: firstName,
                    firstLastName: firstLastName,
                    primaryPhone: primaryPhone,
                    primaryEmail: email
                }
            };

            console.warn(data)
            dispatch(upsertAdministratorAction(data));
            history.push('/dashboard/inicio')

            // dispatch(upsertAdministratorAction(requestData))


        }else{
            alert("No se pudo ejectuar la accion")
        }
       
    }


    let location = 'Zapopan';

    return (
        <div className='profileCardContainer'>
            <ProfileCard
                docsTitle='DOCUMENTACIÓN'
                title={`${firstName} ${firstLastName} `}
                // location={location}
                // photo={`https://koopersapi.beesoftware.dev/${url}`}
                >
                    {/* <img
                        src={`https://koopersapi.beesoftware.dev/${url}`}
                    /> */}
                <InputKoopers 
                    mode={'user'} 
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <InputKoopers 
                    mode={'user'} 
                    value={firstLastName}
                    onChange={(e) => setFirstLastName(e.target.value)}
                />
                <InputKoopers 
                    mode={'mail'} 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <InputKoopers
                    mode={'phone'}
                    value={primaryPhone}
                    onChange={(e) => setPrimaryPhone(e.target.value)}
                />
                <Button
                    onClick={() => onSubmitUpdateProfile()}
                >
                    GUARDAR
                </Button>
            </ProfileCard>             
        </div>
    )
}

Profile.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    userRegister: PropTypes.any,
    companies: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    user: PropTypes.object,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        user: _.get(state, 'user.userInformation', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    getUserInformationRequest: params => dispatch(getUserInformationRequest(params)),
    updateUserInformationRequest: params => dispatch(updateUserInformationRequest(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(Profile);
