/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import Searcher from '../../../../components/atoms/Searcher/Searcher';
import DataTable from '../../../../components/atoms/DataTable/DataTable';
import ActionMenu from '../../../../components/atoms/ActionMenu/ActionMenu';
import CustomModal from '../../../../components/organisms/Modal/Modal';
import DeleteModal from '../../../../components/organisms/Modal/DeleteModal';
import {
    LESSOR_DRIVERS_FORM,
} from '../../../../routes/Paths';
import {
	getAllLessorDriversAction,
	deleteOneCarDriverBegin,
	deleteOneCarDriverAction,
} from '../../../../redux/actions/lessorAction';
import Button from '../../../../components/atoms/Button/Button';

// src & styles
import './LessorDriversManagement.scss';
import add from '../../../../assets/images/koopers/add.png';

const LessorDriversManagement = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ openModal, setOpenModal ] = useState(false);
    const [ searchCondition, setSearchCondition ] = useState({});
    const [ itemSelected, setItemSelected ] = useState(null);
    const [searchObj,  setSearchObj] = useState({});

    const { mode, lessorId } = useParams();


    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    
    const reloadInfo = () => {
        //dispatch(getAllLessorDriversAction(searchCondition));
		dispatch(getAllLessorDriversAction({ where: { lessorId: parseInt(lessorId) }}));
    };

    const handleDelete = (item) => {
        const dataRequest = {
            driverId: item
        }
        props.deleteOneCarDriverAction(dataRequest);
        toggleModal();
        
    }

    useEffect(()=>{
        if (props.deleteOneCarDiverSuccess) {
            reloadInfo();
            props.deleteOneCarDriverBegin();
        }
    },[props.deleteOneCarDiverSuccess]);

	const actionMenuItems = [
        {
            displayText: 'Editar registro',
            keyName: 'EDITAR_REGISTRO',
            icon: 'edit',
        },
        {
            displayText: 'Eliminar registro',
            keyName: 'ELIMINAR_REGISTRO',
            icon: 'trash',
        },
    ];

    const columns = React.useMemo(
        () => [
            {
                id: 'id',
                Header: 'ID',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '5%', 
                accessor: d => ({
                    id: _.get(d, 'id', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        id,
                    },
                    row
                }) {
                    return(<span>{`${id}`}</span>)
                }
            },
            {
                id: 'documentType',
                Header: 'Nombre',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '20%', 
                accessor: d => ({
					firstName: _.get(d, 'person.firstName', ''),
					secondName: _.get(d, 'person.secondName', ''),
					firstLastName: _.get(d, 'person.firstLastName', ''),
					secondLastName: _.get(d, 'person.secondLastName', ''),
                }),
                Cell: function cell ({ 
                    value: {
						firstName,
						secondName,
						firstLastName,
						secondLastName,
                    },
                    row
                }) {
                    return(<span>{`${firstName} ${secondName} ${firstLastName} ${secondLastName}`}</span>)
                }
            },
			{
                id: 'createdAt',
                Header: 'Fecha de creación',
                disableSortBy: true,
                disableFilters: true,
                width: '20%',
                accessor: d => ({
                    createdAt: _.get(d, 'createdAt', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        createdAt,
                    },
                    row
                }) {
                    return(<span>{createdAt}</span>)
                }
            },
             {
                 id: 'options.name',
                 Header: '',
                 filter: "text",
                 disableFilters: true,
                 width: '10%',
                 accessor: d => ({
                     item: d,
                 }),
                 Cell: function cell ({ value: { item }, row }) {
                     return (
                         <ActionMenu
                            actionMenuItems={actionMenuItems}
                            client={true}
                            callback={(selected) => {
                                if (selected === 'EDITAR_REGISTRO') {
                                    history.push(
                                        LESSOR_DRIVERS_FORM
                                            .replace(":lessorId", lessorId)
                                            .replace(":mode", 'editar')
                                            .replace(":driverId", item.id)
                                    );
                                } else if (selected === 'ELIMINAR_REGISTRO') {
                                    toggleModal();
                                    setItemSelected(item.id);
                                }}}

                                
                         />
                     ); 
                 }
             },
    ]);

    const getNewData = (condition) => {
        setSearchCondition(condition);
        // Pendiente implementar el SEARCH
		dispatch(getAllLessorDriversAction({ where: { lessorId: parseInt(lessorId) }}));
        //dispatch(getAllCarDocumentsAction(mode != 'todos' ? { where: { lessorId: parseInt(mode) }} : {}));
    };
	console.log('props.getAllLessorDrivers: ', props.getAllLessorDrivers);
    return (
        <div className='LessorManagementGeneralContainer'>
            <div className='LessorManagementHeaderContainer'>
                <div className='titleContainer'>
                    <h1>GESTIÓN DE CONDUCTORES</h1>
                </div> 
                <div className='searcherContainer'>
                    <Searcher placeholderSearch={'Buscar'} />
                </div>
            </div>
            <div className='buttonContainer'>
                    <Button
                        srcicon={add}
                        onClick={() => history.push(
                                            LESSOR_DRIVERS_FORM
                                            .replace(":lessorId", lessorId)
                                            .replace(":mode", 'registro')
                                            .replace(":driverId", '')
                        )}
                    >
                        NUEVO
                    </Button>
                </div>
            <div className='tableContainer'>
                <DataTable
                    columns={columns}
                    data={{...props.getAllLessorDrivers}}
                    getData={getNewData}
                    searchObj={searchObj}
                />
            </div>
            {
                openModal &&
                <CustomModal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <DeleteModal
                        OnClickCancel={toggleModal}
                        onClickAgree={() => handleDelete(itemSelected)}
                        reloadInfo={reloadInfo}
                        toggle={toggleModal}
                    />
                </CustomModal>
            }
        </div>
    )
}

LessorDriversManagement.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    getAllLessorDrivers: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    data: PropTypes.any,
    totalRecords: PropTypes.any,
	getAllLessorDriversAction: PropTypes.any,
    deleteOneCarDriverAction: PropTypes.func,
    deleteOneCarDriverBegin: PropTypes.func,
	deleteOneCarDiverSuccess: PropTypes.bool,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        // data: _.get(state, 'data.data', {}),
        getAllLessorDrivers: _.get(state, 'lessor.getAllLessorDrivers', {}),
        deleteOneCarDiverSuccess: _.get(state, 'lessor.deleteOneCarDiver.isSuccess', false),
    };
}

const mapDispatchToProps = dispatch => ({
    getAllLessorDriversAction: params => dispatch(getAllLessorDriversAction(params)),
    deleteOneCarDriverAction: params => dispatch(deleteOneCarDriverAction(params)),
    deleteOneCarDriverBegin: params => dispatch(deleteOneCarDriverBegin(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(LessorDriversManagement);
