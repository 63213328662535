import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";
import EliminarModal from "../../../components/ModalesTalleres/EliminarModal";
import { useDispatch } from "react-redux";
import {
  deleteWorkshopsAction,
  getAllWorkshopsAction,
} from "../../../redux/actions/workshopsAction";

const ActionsMenuTaller = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const [eliminarModalOpen, setEliminarModalOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  /*   */
  /* console.log("props.row", props.row); */

  const handleEdit = () => {
    history.push({
      pathname: `/dashboard/editar-taller/${props.row.id}`,
      state: { item: props.row },
    });
  };

  const handleEliminarClick = () => {
    setEliminarModalOpen(true);
    handleClose();
  };

  const handleUsuariosInternosClick = () => {
    props.setUsuariosInternosModalOpen(true);
    handleClose();
  };
  const handleEliminarModalClose = () => {
    setEliminarModalOpen(false);
  };

  const handleConfirmDelete = () => {
    console.log("soy taller");
    const data = {
      id: props.row.id,
    };
    console.log("soy taller", data);
    dispatch(deleteWorkshopsAction(data));
    dispatch(getAllWorkshopsAction());
    setEliminarModalOpen(false);
  };
  return (
    <div>
      <EliminarModal
        open={eliminarModalOpen}
        onClose={handleEliminarModalClose}
        rowData={props.row}
        onConfirmDelete={handleConfirmDelete}
      />
      <IconButton
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>Editar</MenuItem>
        <MenuItem onClick={handleUsuariosInternosClick}>
          Usuarios internos
        </MenuItem>
        <MenuItem onClick={handleEliminarClick}>Eliminar</MenuItem>
      </Menu>
    </div>
  );
};

export default ActionsMenuTaller;
