/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import Searcher from '../../../../components/atoms/Searcher/Searcher';
import DataTable from '../../../../components/atoms/DataTable/DataTable';
import ActionMenu from '../../../../components/atoms/ActionMenu/ActionMenu';
import CustomModal from '../../../../components/organisms/Modal/Modal';
import DeleteModal from '../../../../components/organisms/Modal/DeleteModal';
import {
    CAR_VIEW_CREATE_DOCUMENT,
} from '../../../../routes/Paths';
import {
	getAllTransactionRatesAction,
} from '../../../../redux/actions/carActions';
import Button from '../../../../components/atoms/Button/Button';

// src & styles
import './CarsCommentManagement.scss';
import add from '../../../../assets/images/koopers/add.png';

const CarsCommentManagement = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ openModal, setOpenModal ] = useState(false);
    const [ searchCondition, setSearchCondition ] = useState({});
    const [ itemSelected, setItemSelected ] = useState(null);
    const [searchObj,  setSearchObj] = useState({});

    const { mode, carInstanceId } = useParams();


    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    
    const reloadInfo = () => {
        dispatch(getAllTransactionRatesAction(searchCondition));
    };

    const handleDelete = (item) => {
        const dataRequest = {
            carInstanceDocumentId: item
        }
        //props.deleteOneCarDocumentAction(dataRequest);
        toggleModal();
        
    }
/*
    useEffect(()=>{
        if (props.deleteOneCarDocumentSuccess) {
            reloadInfo();
            props.deleteOneCarDocumentBegin();
        }
    },[props.deleteOneCarDocumentSuccess]);
*/
const actionMenuItems = [
    {
        displayText: 'Ver registro',
        keyName: 'VER_REGISTRO',
        icon: 'eye',
    },
];

    const columns = React.useMemo(
        () => [
            {
                id: 'id',
                Header: 'ID',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '5%', 
                accessor: d => ({
                    id: _.get(d, 'id', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        id,
                    },
                    row
                }) {
                    return(<span>{`${id}`}</span>)
                }
            },
            {
                id: 'lesseePerson',
                Header: 'Arendatario',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '20%', 
                accessor: d => ({
                    firstName: _.get(d, 'lessee.person.firstName', ''),
					secondName: _.get(d, 'lessee.person.secondName', ''),
					firstLastName: _.get(d, 'lessee.person.firstLastName', ''),
					secondLastName: _.get(d, 'lessee.person.secondLastName', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        firstName,
						secondName,
						firstLastName,
						secondLastName,
                    },
                    row
                }) {
                    return(<span>{`${firstName} ${secondName} ${firstLastName} ${secondLastName}`}</span>)
                }
            },
            {
                id: 'comment',
                Header: 'Comentario',
                disableSortBy: true,
                disableFilters: true,
                width: '20%',
                accessor: d => ({
                    comment: _.get(d, 'comment', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        comment,
                    },
                    row
                }) {
                    return(<span>{comment}</span>)
                }
            },
			{
                id: 'rate',
                Header: 'Calificación',
                disableSortBy: true,
                disableFilters: true,
                width: '20%',
                accessor: d => ({
                    rate: _.get(d, 'rate', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        rate,
                    },
                    row
                }) {
                    return(<span>{rate}</span>)
                }
            },
            {
                id: 'driver',
                Header: 'Conductor',
                disableSortBy: true,
                disableFilters: true,
                width: '20%',
                accessor: d => ({
                    firstName: _.get(d, 'transaction.driver.person.firstName', ''),
					secondName: _.get(d, 'transaction.driver.person.secondName', ''),
					firstLastName: _.get(d, 'transaction.driver.person.firstLastName', ''),
					secondLastName: _.get(d, 'transaction.driver.person.secondLastName', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        firstName,
						secondName,
						firstLastName,
						secondLastName,
                    },
                    row
                }) {
                    return(<span>{`${firstName} ${secondName} ${firstLastName} ${secondLastName}`}</span>)
                }
            },
			{
                id: 'createdAt',
                Header: 'Fecha de creación',
                disableSortBy: true,
                disableFilters: true,
                width: '20%',
                accessor: d => ({
                    createdAt: _.get(d, 'createdAt', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        createdAt,
                    },
                    row
                }) {
                    return(<span>{createdAt}</span>)
                }
            },
             {
                 id: 'options.name',
                 Header: '',
                 filter: "text",
                 disableFilters: true,
                 width: '10%',
                 accessor: d => ({
                     item: d,
                 }),
                 Cell: function cell ({ value: { item }, row }) {
                     return (
                         <ActionMenu
                            actionMenuItems={actionMenuItems}
                            client={true}
                            callback={(selected) => {
                                if (selected === 'VER_REGISTRO') {
                                    alert('Implementar la redireccion del detalle de transaccion');
                                    //history.push(CAR_VIEW_REGISTER.replace(":carId", item.id));
                                }
                            }}
                         />
                     ); 
                 }
             },
    ]);

    const getNewData = (condition) => {
        setSearchCondition(condition);
        // Pendiente implementar el SEARCH
		dispatch(getAllTransactionRatesAction({ where: { carInstanceId: parseInt(carInstanceId) }}));
        //dispatch(getAllCarDocumentsAction(mode != 'todos' ? { where: { lessorId: parseInt(mode) }} : {}));
    };

    return (
        <div className='LessorManagementGeneralContainer'>
            <div className='LessorManagementHeaderContainer'>
                <div className='titleContainer'>
                    <h1>Transacciones</h1>
                </div> 
                <div className='searcherContainer'>
                    <Searcher placeholderSearch={'Buscar'} />
                </div>
            </div>
			{
				/*
				<div className='buttonContainer'>
						<Button
							srcicon={add}
							onClick={() => history.push(CAR_VIEW_CREATE_DOCUMENT.replace(":carInstanceId", carInstanceId).replace(":mode", 'registro'))}
						>
							NUEVO
						</Button>
				</div>
				*/
			}
            <div className='tableContainer'>
                <DataTable
                    columns={columns}
                    data={{...props.getAllTransactionRates}}
                    getData={getNewData}
                    searchObj={searchObj}
                />
            </div>
            {
                openModal &&
                <CustomModal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <DeleteModal
                        OnClickCancel={toggleModal}
                        onClickAgree={() => handleDelete(itemSelected)}
                        reloadInfo={reloadInfo}
                        toggle={toggleModal}
                    />
                </CustomModal>
            }
        </div>
    )
}

CarsCommentManagement.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
	value: PropTypes.any,
    row: PropTypes.any,
    data: PropTypes.any,
    dispatch: PropTypes.any,
    getAllTransactionRatesAction: PropTypes.any,
	getAllTransactionRates: PropTypes.object,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        getAllTransactionRates: _.get(state, 'car.getAllTransactionRates', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    getAllTransactionRatesAction: params => dispatch(getAllTransactionRatesAction(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(CarsCommentManagement);
