import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import "./EditarFoto.scss";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { url_api } from "../../../config";
import { getProfileAction } from "../../../redux/actions/profileAction";

function EditarFoto({
  setUrlImagenBack,
  imagenBack,
  setImagenBack,
  urlimagenBack,
}) {
  // Supongamos que estos valores vienen del backend
  const profileImageUrl = "url_de_la_imagen_del_perfil";
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("Nombre del Usuario");
  const [userRole, setUserRole] = useState("Super Administrador");

  const [selectedImage, setSelectedImage] = useState(null);

  const userLoggeado = useSelector((state) => state.user.user);
  const profileLogge = useSelector((state) => state.profile.profile);

  /* const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));

    }
  }; */
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const base64Image = await convertBase64(file);
      setSelectedImage(base64Image.file);
      setUrlImagenBack(base64Image);
      setImagenBack(true);
    }
  };

  const handleEditClick = () => {
    const inputElement = document.getElementById("image-input");
    if (inputElement) {
      inputElement.click();
    }
  };

  useEffect(() => {
    console.log("userLoggeado", userLoggeado);
    if (_.get(userLoggeado, "person")) {
      console.log(userLoggeado.person);
      setUserName(
        `${userLoggeado.person.firstName}${" "}${
          userLoggeado.person.firstLastName
        }`
      );
      const data = {
        userId: userLoggeado.id,
      };
      dispatch(getProfileAction(data));
      /* userRole = userLoggeado */
      console.log(userLoggeado.roles[0]);
      setUserRole(userLoggeado.roles[0]);
      /* if (_.get(userLoggeado, "person.profileImageMedia")) { */

      /*  */
      /* } */
    }
  }, [userLoggeado]);

  useEffect(() => {
    if (_.get(profileLogge, "data")) {
      console.log("profileLogge", profileLogge.data);
      
      if (!_.isNil(profileLogge.data.person.profileImageMedia)) {
        console.log(
          "profileLogge.data.person.profileImageMedia.fileName",
          profileLogge.data.person.profileImageMedia.fileName
        );
        

        setSelectedImage(
          `${url_api}${profileLogge.data.person.profileImageMedia.fileName}`
        );
      }
    }
  }, [profileLogge]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        return resolve({
          file: fileReader.result,
          title: file.name,
        });
      };
      fileReader.onerror = (error) => reject(error);
    });
  };

  return (
    <div className="editar-foto-container-main">
      <div className="editar-foto-container">
        <div className="container-top-imagen">
          <div className="profile-image-container">
            <Avatar
              alt="Profile Image"
              src={selectedImage || profileImageUrl}
              sx={{ width: 150, height: 150 }}
            />
          </div>
          <div className="user-details">
            <h2 style={{ fontSize: "20px" }}>{userName}</h2>
            <p>{userRole}</p>
          </div>
        </div>

        <input
          id="image-input"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }} // Ocultamos el input
        />
        <Button
          variant="outlined"
          className="btn-editar-photo"
          onClick={handleEditClick}
        >
          Editar Foto
        </Button>
      </div>
    </div>
  );
}

export default EditarFoto;
