import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../atoms/Button/Button';
import './Modal.scss'


const DeleteModalBody = ({
    text = '¿Desea eliminar?',
    OnClickCancel,
    onClickAgree,
}) => {

    return (
        <div className='deleteModalContainer'>
            <h2 className='deleteModalTitle'>{text}</h2>
            <div className='deleteModalButtonsContainer'>
                <Button
                    onClick={onClickAgree}
                >
                    ACEPTAR
                </Button>
                <Button
                    onClick={OnClickCancel}
                >
                    CANCELAR
                </Button>
            </div>
        </div>
    );
}

DeleteModalBody.propTypes = {
    text: PropTypes.string,
    onClickAgree: PropTypes.func,
    OnClickCancel: PropTypes.func,
    item: PropTypes.any,
    reloadInfo: PropTypes.func,
    toggle: PropTypes.func,
}

function mapStateToProps () {
    return {};
  }
  
  const mapDispatchToProps = () => ({
    // getReportInfo: params => console.log(params),
    // test: params => console.log(params),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(DeleteModalBody);
