import React from 'react';
import SimpleFooter from '../../../organisms/Footer1/SimpleTextFooter';
import './Footer.scss';

const Footer = () => {
    return (
        <div>
          {/*   <SimpleFooter className='footer' text={'© 2023, MARANELLO'} /> */}
        </div>
    );              
}

export default Footer;
