import React from 'react';
import SetNewPasswordForm from './SetNewPasswordForm';


const SetNewPassword = () => {
    return (
        <>
            <div className='login-main'>
                <div className='login-container'>
                <SetNewPasswordForm to={'/RecoverPassword'}/>
                </div>
            </div>
        </>
    )
}

export default SetNewPassword;