import React from 'react';
import PropTypes from 'prop-types';
// import Button from '../../../components/atoms/Button';
// import InputKoopers from '../../../components/molecules/InputKoopers';
import ProfileCard from '../../../components/organisms/ProfileCard';
import './Register.scss';

const RegisterTemplate = ({
    title,
    onSubmit,
    upsertFunction,
}) => {

    return (
        <div className='newRegisterContainer'>
            {
                title &&
                <h1 className='registerTitle'>{title}</h1>
            }
            <ProfileCard
                docsTitle='SUBIR DOCUMENTACIÓN'
                onSubmit={onSubmit}
                upsertFunction={upsertFunction}
                onChangePhoto={(e) => {
                    //setUserInformation({...userInformation, profileImageMediaFile: e.target.files[0]})
                }}
            />
        </div>
    );
}

RegisterTemplate.propTypes = {
    title: PropTypes.string,
    onSubmit: PropTypes.func,
    inputs: PropTypes.array,
    upsertFunction: PropTypes.func,
}

export default RegisterTemplate;
