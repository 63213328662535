import React, { useEffect, useState } from "react";
import "./EditarFotos.scss";
import { Button, IconButton } from "@mui/material";
import { PhotoCamera, Edit } from "@mui/icons-material";
import { url_api } from "../../../config";
import ColorModal from "./modalColor/ColorModal";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

function EditarFotos({ dataAgencie, setLogo,setLogoChanged }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [colorSelected, setColorSelected] = useState();
  const [hojaMembretada, setHojaMembretada] = useState();

  const [open, setOpen] = useState(false);

  const [businessName, setBusinessName] = useState();

  const handleColorChange = (color) => {
    setColorSelected(color.hex);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        return resolve({
          file: fileReader.result,
          title: file.name,
        });
      };
      fileReader.onerror = (error) => reject(error);
    });
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("dataAgencie", dataAgencie);
    if (_.get(dataAgencie, "agencyData")) {
      setBusinessName(dataAgencie.agencyData.businessName);
      if (_.get(dataAgencie.agencyData.logo, "fileName")) {
        setSelectedImage(`${url_api}${dataAgencie.agencyData.logo.fileName}`);
      }
      if (_.get(dataAgencie.agencyData, "colorCode")) {
        setColorSelected(dataAgencie.agencyData.colorCode);
       
      }
      if (_.get(dataAgencie.agencyData.letterHead, "fileName")) {
        setHojaMembretada(dataAgencie.agencyData.letterHead.fileName);
      }
    }
  }, [dataAgencie]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setSelectedImage(e.target.result);
    };

    reader.readAsDataURL(file);
  };

  const handleEditImage = async () => {
    const inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = "image/*";
    inputElement.style.display = "none";

    inputElement.addEventListener("change", async (event) => {
      const file = event.target.files[0];
      if (file) {
        const base64Image = await convertBase64(file);
        setLogo(base64Image); // Cargar el base64 en el estado del logo
        setLogoChanged(true);
        const reader = new FileReader();
        reader.onload = (e) => {
          setSelectedImage(e.target.result); // Actualizar selectedImage con la nueva imagen
        };
        reader.readAsDataURL(file);
      }
    });

    document.body.appendChild(inputElement);
    inputElement.click();
    document.body.removeChild(inputElement);
  };

  const renderImageContainer = () => {
    if (selectedImage) {
      return (
        <div className="image-container">
          <div className="image-wrapper">
            <div className="container-imagen">
              <img
                className="uploaded-image"
                src={selectedImage}
                alt="Uploaded"
              />
            </div>
            <div className="edit-icon" onClick={handleEditImage}>
              <IconButton className="edit-button" color="primary">
                <Edit />
              </IconButton>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="image-container">
          <div className="placeholder-image">
            <IconButton
              className="upload-button"
              color="primary"
              component="label"
            >
              <PhotoCamera />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
            </IconButton>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="container-main-fotos">
      <div className="image-uploader">{renderImageContainer()}</div>
      <p className="agency-name">{businessName}</p>
      <div className="color-section">
        <div className="title-color">
          <p className="color-label-principal">Color:</p>
          <div
            className="color-circle"
            style={{ backgroundColor: colorSelected }}
          ></div>
        </div>
        <p className="color-label" onClick={handleOpenModal}>
          Cambiar color
        </p>
      </div>
      <ColorModal
        open={open}
        handleClose={handleCloseModal}
        handleColorChange={handleColorChange}
        colorSelected={colorSelected}
        dataAgencie={dataAgencie}
        setColorSelected={setColorSelected}
      />

      <div className="container-hoja-membretada">
        <Button
          className="title-hoja-membretada"
          onClick={() => {
            window.open(`${url_api}${hojaMembretada}.pdf`, "_blank");
          }}
        >
          <TextSnippetIcon />
          Hoja membretada
        </Button>
      </div>
    </div>
  );
}

export default EditarFotos;
