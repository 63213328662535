import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";
import EliminarModal from "../../../components/ModalesAgencias/EliminarModal";
import {
  deleteAgencieAction,
  getAllAgenciesAction,
} from "../../../redux/actions/agenciesAction";
import { useDispatch } from "react-redux";

const ActionsMenuAgencias = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const [eliminarModalOpen, setEliminarModalOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  /*   */
  /*  console.log("props.row", props.row); */

  const handleEdit = () => {
    history.push({
      pathname: `/dashboard/editar-agencia/${props.row.id}`,
      state: { item: props.row },
    });
  };

  const handleEliminarClick = () => {
    setEliminarModalOpen(true);
    handleClose();
  };

  const handleUsuariosInternosClick = () => {
    props.setUsuariosInternosModalOpen(true);
    handleClose();
  };
  const handleEliminarModalClose = () => {
    setEliminarModalOpen(false);
  };

  const handleConfirmDelete = () => {
    const data = {
      id: props.row.id,
    };
    dispatch(deleteAgencieAction(data));
    dispatch(getAllAgenciesAction());
    /* console.log("Eliminar:", props.row); */
    setEliminarModalOpen(false);
  };
  return (
    <div>
      <EliminarModal
        open={eliminarModalOpen}
        onClose={handleEliminarModalClose}
        rowData={props.row}
        onConfirmDelete={handleConfirmDelete}
      />
      <IconButton
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>Editar</MenuItem>
        <MenuItem onClick={handleUsuariosInternosClick}>
          Usuarios internos
        </MenuItem>
        <MenuItem onClick={handleEliminarClick}>Eliminar</MenuItem>
      </Menu>
    </div>
  );
};

export default ActionsMenuAgencias;
