/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import Searcher from '../../../components/atoms/Searcher/Searcher';
import DataTable from '../../../components/atoms/DataTable/DataTable';
import ActionMenu from '../../../components/atoms/ActionMenu/ActionMenu';
import {
    CAR_VIEW_REGISTER,
    CAR_REGISTER,
    CAR_VIEW_DOCUMENTS,
    CAR_COMMENTS_MANAGEMENT,
} from '../../../routes/Paths';
import { getAllCarsAction } from '../../../redux/actions/carActions';
import Button from '../../../components/atoms/Button/Button';

// src & styles
import './CarsManagement.scss';
import add from '../../../assets/images/koopers/add.png';

const CarsManagement = (props) => {

    const { mode } = useParams();

    const dispatch = useDispatch();

    const history = useHistory();

    // const reloadInfo = (searchCondition) => {
    //     // props.getAllAdministratorsAction(searchCondition);
    //     props.getAllCarsAction({offset: 0,limit: 5});
    // };
    
    // useEffect(()=>{
    //     props.getAllCarsAction(searchCondition);
    // },[/* props.deleteAdmin.isSuccess, */ searchCondition]);

    const actionMenuItems = [
        {
            displayText: 'Ver registro',
            keyName: 'VER_REGISTRO',
            icon: 'eye',
        },
        {
            displayText: 'Ver documentos',
            keyName: 'VER_DOCUMENTOS',
            icon: 'eye',
        },
        {
            displayText: 'Ver transacciones',
            keyName: 'VER_TRANSACCIONES',
            icon: 'eye',
        },
    ];


    const carsInfo = React.useMemo(
        () => [
            {
                id: 'id',
                Header: 'ID',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '5%', 
                accessor: d => ({
                    id: _.get(d, 'id', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        id,
                    },
                    row
                }) {
                    return(<span>{`${id}`}</span>)
                }
            },
            {
                id: 'carBrand',
                Header: 'Marca',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '10%', 
                accessor: d => ({
                    carBrand: _.get(d, 'carBrand.displayText', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        carBrand,
                    },
                    row
                }) {
                    return(<span>{`${carBrand.toUpperCase()}`}</span>)
                }
            },
            {
                id: 'carModel',
                Header: 'Modelo',
                disableSortBy: true,
                disableFilters: true,
                width: '10%',
                accessor: d => ({
                    carModel: _.get(d, 'carModel.displayText', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        carModel,
                    },
                    row
                }) {
                    return(<span>{`${carModel}`}</span>)
                }
            },
            {
                id: 'setUpOption',
                Header: 'Configuración',
                disableSortBy: true,
                disableFilters: true,
                filter: "text",
                width: '15%',
                accessor: d => ({
                    carSetupOption: _.get(d, 'carInstanceSetups[0].carSetupOption.displayText',''),
                }),
                Cell: function cell ({ value: { carSetupOption }, row }) {
                    return(<span>{`${carSetupOption}`}</span>)
                }
            },
            {
                id: 'carCost',
                Header: 'Costo',
                disableSortBy: true,
                disableFilters: true,
                filter: "text",
                width: '10%',
                accessor: d => ({
                    baseCost: _.get(d, 'baseCost', ''),
                }),
                Cell: function cell ({ value: { baseCost }, row }) {
                    return(<span>{`${baseCost}`}</span>)
                },
             },
             {
                id: 'rateAvg',
                Header: 'Calificación',
                disableSortBy: true,
                disableFilters: true,
                filter: "text",
                width: '10%',
                accessor: d => ({
                    baseCost: _.get(d, 'rateAvg', ''),
                }),
                Cell: function cell ({ value: { baseCost }, row }) {
                    return(<span>{`${baseCost}`}</span>)
                },
             },
             {
                id: 'statusAlta',
                Header: 'Status de la alta',
                disableSortBy: true,
                disableFilters: true,
                filter: "text",
                width: '10%',
                accessor: d => ({
                    baseCost: _.get(d, 'rateAvg', ''),
                }),
                Cell: function cell ({ value: { baseCost }, row }) {
                    return(<span>{`Ha implementar`}</span>)
                },
             },
             {
                id: 'statusServicio',
                Header: 'Status de servicio',
                disableSortBy: true,
                disableFilters: true,
                filter: "text",
                width: '10%',
                accessor: d => ({
                    baseCost: _.get(d, 'rateAvg', ''),
                }),
                Cell: function cell ({ value: { baseCost }, row }) {
                    return(<span>{`Ha implementar`}</span>)
                },
             },
             {
                id: 'createdAt',
                Header: 'Fecha de alta',
                disableSortBy: true,
                disableFilters: true,
                filter: "text",
                width: '10%',
                accessor: d => ({
                    createdAt: _.get(d, 'createdAt', ''),
                }),
                Cell: function cell ({ value: { createdAt }, row }) {
                    return(<span>{`${createdAt}`}</span>)
                },
             },
             {
                 id: 'options.name',
                 Header: '',
                 filter: "text",
                 disableFilters: true,
                 width: '10%',
                 accessor: d => ({
                     item: d,
                 }),
                 Cell: function cell ({ value: { item }, row }) {
                     return (
                         <ActionMenu
                            actionMenuItems={actionMenuItems}
                            client={true}
                            callback={(selected) => {
                                if (selected === 'VER_REGISTRO') {
                                    history.push(CAR_VIEW_REGISTER.replace(":carId", item.id));
                                } else if (selected === 'ELIMINAR_REGISTRO') {
                                    //toggleModal();
                                    //setItemSelected(item.user.id);
                                } else if (selected === 'VER_DOCUMENTOS') {
                                    history.push(CAR_VIEW_DOCUMENTS.replace(":carInstanceId", item.id));
                                } else if (selected === 'VER_TRANSACCIONES') {
                                    history.push(CAR_COMMENTS_MANAGEMENT.replace(":carInstanceId", item.id));
                                }
                            }}
                         />
                     ); 
                 }
             },
    ]);

    const getNewData = () => {
        dispatch(getAllCarsAction(mode != 'todos' ? { where: { lessorId: parseInt(mode) }} : {}));
    };

    return (
        <div className='LessorManagementGeneralContainer'>
            <div className='LessorManagementHeaderContainer'>
                <div className='titleContainer'>
                    <h1>GESTIÓN DE VEHÍCULOS</h1>
                </div> 
                <div className='searcherContainer'>
                    <Searcher placeholderSearch={'Busca marca o modelo'} />
                </div>
            </div>
            <div className='buttonContainer'>
                    <Button
                        srcicon={add}
                        onClick={() => history.push(CAR_REGISTER.replace(":mode", mode))}
                    >
                        NUEVO
                    </Button>
                </div>
            <div className='tableContainer'>
                <DataTable
                    columns={carsInfo}
                    data={{...props.cars}}
                    getData={getNewData}
                    searchObj={'searchObj'}
                />
            </div>
        </div>
    )
}

CarsManagement.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    getAllCarsAction: PropTypes.any,
    cars: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    data: PropTypes.any,
    totalRecords: PropTypes.any,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        // data: _.get(state, 'data.data', {}),
        cars: _.get(state, 'car.cars', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    getAllCarsAction: params => dispatch(getAllCarsAction(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(CarsManagement);
