import request from "../../utils/request";
import { addNotification } from "./notificationAction";

import {
  ADMIN_MODULE_UPSERT_AGENCIES_BEGIN,
  ADMIN_MODULE_UPSERT_AGENCIES_SUCCESS,
  ADMIN_MODULE_UPSERT_AGENCIES_FAILURE,
  ADMIN_MODULE_GET_ALL_AGENCIES_BEGIN,
  ADMIN_MODULE_GET_ALL_AGENCIES_SUCCESS,
  ADMIN_MODULE_GET_ALL_AGENCIES_FAILURE,
  ADMIN_MODULE_DELETE_AGENCIES_BEGIN,
  ADMIN_MODULE_DELETE_AGENCIES_SUCCESS,
  ADMIN_MODULE_DELETE_AGENCIES_FAILURE,
} from "./../actionTypes";

export const upsertAgenciesBegin = () => ({
  type: ADMIN_MODULE_UPSERT_AGENCIES_BEGIN,
});
export const upsertAgenciesSuccess = (data) => ({
  type: ADMIN_MODULE_UPSERT_AGENCIES_SUCCESS,
  data,
});
export const upsertAgenciesFailure = (err) => ({
  type: ADMIN_MODULE_UPSERT_AGENCIES_FAILURE,
  err,
});

export const getAllAgenciesBegin = () => ({
  type: ADMIN_MODULE_GET_ALL_AGENCIES_BEGIN,
});
export const getAllAgenciesSuccess = (data) => ({
  type: ADMIN_MODULE_GET_ALL_AGENCIES_SUCCESS,
  data,
});
export const getAllAgenciesFailure = (err) => ({
  type: ADMIN_MODULE_GET_ALL_AGENCIES_FAILURE,
  err,
});

export const deleteAgenciesBegin = () => ({
  type: ADMIN_MODULE_DELETE_AGENCIES_BEGIN,
});
export const deleteAgenciesSuccess = (data) => ({
  type: ADMIN_MODULE_DELETE_AGENCIES_SUCCESS,
  data,
});
export const deleteAgenciesFailure = (err) => ({
  type: ADMIN_MODULE_DELETE_AGENCIES_FAILURE,
  err,
});

export function upsertAgenciesAdmin(data = {}) {
  return (dispatch, getState) => {
    dispatch(upsertAgenciesBegin());
    return request({
      partialUrl: `/agency/upsert-agency-action`,
      method: "POST",
      state: getState().user.request,
      body: { ...data },
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(upsertAgenciesSuccess(data));
        dispatch(
          addNotification({
            toastType: "success",
            toastTitle: "Registro realizado con exito",
          })
        );
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
      });
  };
}

export function getAllAgenciesAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getAllAgenciesBegin());
    return request({
      partialUrl: `/agency/getAll-agency-action`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getAllAgenciesSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getAllAgenciesFailure(err));
      });
  };
}

export function deleteAgencieAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(deleteAgenciesBegin());
    console.log("Dispatch");
    return request({
      partialUrl: `/agency/delete-agency-action`,
      method: "POST",
      state: getState().user.request,
      body: { ...data },
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(deleteAgenciesSuccess(data));
        dispatch(
          addNotification({
            toastType: "success",
            toastTitle: "Eliminado con exito",
          })
        );
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
