import {
    ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_BEGIN,
    ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_SUCCESS,
    ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_FAILURE,
  } from "./../actionTypes";
  
  import initialState from "../initialState";
  
  export default function quoteReducer(state = initialState.citiesFiscales, action) {
    const dataPage = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_BEGIN:
        return {
          ...state,
          citiesFiscales: {
            ...state.citiesFiscales,
            isLoading: true,
            isSuccess: false,
            err: null,
            data: null,
          },
        };
      case ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_SUCCESS:
        return {
          ...state,
          citiesFiscales: {
            ...state.citiesFiscales,
            isLoading: false,
            isSuccess: true,
            err: null,
            data: action.data,
          },
        };
      case ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_FAILURE:
        return {
          ...state,
          citiesFiscales: {
            ...state.citiesFiscales,
            isLoading: false,
            isSuccess: false,
            err: action.err,
            data: null,
          },
        };
  
      default:
        return state;
    }
  }
  