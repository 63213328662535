import React, { useEffect, useState } from "react";
import "./TabsEditables.scss";
import { Tab, Tabs } from "@mui/material";
import DatosPrincipales from "./Tabs/DatosPrincipales";
import DatosContacto from "./Tabs/DatosContacto";
import DatosFiscales from "./Tabs/DatosFiscales";
import RepresentanteLegal from "./Tabs/RepresentanteLegal";
import Documentacion from "./Tabs/Documentacion";

function TabsEditables({ dataAgencie, logo, logoChanged, setLogo }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [type, setType] = useState(false);
  const [datosPrincipales, setDatosPrincipales] = useState();
  const [idGeneral, setIdGeneral] = useState();
  const [tipoPersona, setTipoPersona] = useState();

  const [datosContacto, setDatosContacto] = useState();
  const [datosFiscales, setDatosFiscales] = useState();
  const [representanteLegal, setRepresentanteLegal] = useState();

  const [agencyMedia, setAgencyMedia] = useState();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    console.log("dataAgencie", dataAgencie);
    if (_.get(dataAgencie, "id")) {
      setIdGeneral(dataAgencie.id);
    }
    if (_.get(dataAgencie, "workshopType.id")) {
      setTipoPersona(dataAgencie.workshopType.id);
      console.log(dataAgencie.workshopType.id);
      if (dataAgencie.workshopType.id !== 1) {
        setType(true);
      }
    }
    if (_.get(dataAgencie, "workshopData")) {
      setDatosPrincipales(dataAgencie.workshopData);
    }
    if (_.get(dataAgencie, "workshopContactDetails")) {
      setDatosContacto(dataAgencie.workshopContactDetails);
    }
    if (_.get(dataAgencie, "workshoptaxData")) {
      setDatosFiscales(dataAgencie.workshoptaxData);
      console.log("dataAgencie.workshoptaxData",dataAgencie.workshoptaxData)
      if (_.get(dataAgencie, "workshoptaxData.representativeDetails")) {
        console.log("dataAgencie.workshoptaxData.representativeDetails",dataAgencie.workshoptaxData.representativeDetails)
        setRepresentanteLegal(dataAgencie.workshoptaxData.representativeDetails);
      }
    }
    if (_.get(dataAgencie, "workshopMedia")) {
      setAgencyMedia(dataAgencie.workshopMedia);
    }
  }, [dataAgencie]);

  const tabs = [
    {
      label: "Datos Principales",
      component: (
        <DatosPrincipales
          datosPrincipales={datosPrincipales}
          type={type}
          idGeneral={idGeneral}
          logo={logo}
          tipoPersona={tipoPersona}
          logoChanged={logoChanged}
        />
      ),
    },
    {
      label: "Datos del Contacto",
      component: (
        <DatosContacto
          datosContacto={datosContacto}
          type={type}
          idGeneral={idGeneral}
          tipoPersona={tipoPersona}
        />
      ),
    },
    {
      label: "Datos Fiscales",
      component: (
        <DatosFiscales
          datosFiscales={datosFiscales}
          type={type}
          tipoPersona={tipoPersona}
          idGeneral={idGeneral}
        />
      ),
    },
    ...(type
      ? [
          {
            label: "Representante Legal",
            component: (
              <RepresentanteLegal
                type={type}
                idGeneral={idGeneral}
                tipoPersona={tipoPersona}
                representanteLegal={representanteLegal}
              />
            ),
          },
        ]
      : []),
    {
      label: "Documentación",
      component: (
        <Documentacion
          agencyMedia={agencyMedia}
          type={type}
          idGeneral={idGeneral}
          tipoPersona={tipoPersona}
        />
      ),
    },
  ];

  const renderTabContent = () => {
    return tabs[selectedTab].component;
  };

  return (
    <div className="container-main-Tabs">
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
      >
        {tabs.map((tab) => (
          <Tab key={tab.label} label={tab.label} />
        ))}
      </Tabs>

      {renderTabContent()}
    </div>
  );
}

export default TabsEditables;
