/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory,  } from 'react-router-dom';
import _ from 'lodash';
import InputKoopers from '../../../components/molecules/InputKoopers';
import Button from '../../../components/atoms/Button/Button';
import OptionsSelector from '../../../components/molecules/OptionsSelector';
import Searcher from '../../../components/atoms/Searcher/Searcher';
import DataTable from '../../../components/atoms/DataTable/DataTable';
import ActionMenu from '../../../components/atoms/ActionMenu/ActionMenu';
import CustomModal from '../../../components/organisms/Modal/Modal';
import DeleteModal from '../../../components/organisms/Modal/DeleteModal';
import { ADMIN_REGISTER, ADMIN_VIEW_REGISTER } from '../../../routes/Paths';
import {
    getAllAdministratorsAction,
    deleteAdministratorAction
} from '../../../redux/actions/adminAction';
import {
    updatePasswordRequestAction,
} from '../../../redux/actions/userActions';

// src & styles
import './AdminsManagement.scss';
import photo from '../../../assets/images/koopers/profilePhoto.png';
import add from '../../../assets/images/koopers/add.png';

const AdminsManagementHome = (props) => {

    const [ openModal, setOpenModal ] = useState(false);
    const [searchCondition, setSearchCondition] = useState({});
    const [itemSelected, setItemSelected] = useState(null);
    const [searchObj,  setSearchObj] = useState({});


    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    const history = useHistory();

    
    const reloadInfo = (searchCondition) => {
        // useEffect(()=>{
            props.getAllAdministratorsAction(searchCondition);
        // props.getAllAdministratorsAction({offset: 0,limit: 5});
    };
    
    const handleDelete = (item) => {
        const dataRequest = {
            userId: item,
        }
        props.deleteAdministratorAction(dataRequest);
        toggleModal();
        reloadInfo();
    }

    useEffect(()=>{
        props.getAllAdministratorsAction(searchCondition);
    },[/* props.deleteAdmin.isSuccess, */ searchCondition]);

    // const capitalize = (string) => {
    //     let words = string.split(' ');
    //     let newString;
    //     for (let i = 0; i < words.length; i++) {
    //         newString.push(`${words[i][0].toUpperCase() + words[i].substring(1)} `);
    //     }
    //     console.warn(newString);
    //     return newString;
    // }


    const actionMenuItems = [
        {
            displayText: 'Editar registro',
            keyName: 'EDITAR_REGISTRO',
            icon: 'edit',
        },
        {
            displayText: 'Recuperar contraseña',
            keyName: 'RECUPERAR_CONTRASEÑA',
            icon: null,
        },
        {      
            displayText: 'Eliminar registro',
            keyName: 'ELIMINAR_REGISTRO',
            icon: 'trash',
        }
    ];

    const AdminsInfo = React.useMemo(
        () => [
            {
                id: 'id',
                Header: 'ID',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '20%', 
                accessor: d => ({
                    id: _.get(d, 'id', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        id,
                    },
                    row
                }) {
                    return(<span>{`${id}`}</span>)
                }
            },
            {
                id: 'name',
                Header: 'Nombre(s)',
                disableSortBy: true,
                disableFilters: true,
                filter: 'text',
                width: '20%', 
                accessor: d => ({
                    firstName: _.get(d, 'person.firstName', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        firstName,
                    },
                    row
                }) {
                    return(<span>{`${ firstName[0].toUpperCase()}${firstName.substring(1) }`}</span>)
                }
            },
            {
                id: 'lastname',
                Header: 'Apellido(s)',
                disableSortBy: true,
                disableFilters: true,
                width: '20%',
                accessor: d => ({
                    firstLastName: _.get(d, 'person.firstLastName', ''),
                }),
                Cell: function cell ({ 
                    value: {
                        firstLastName: firstLastName,
                    },
                    row
                }) {
                    return(<span>{`${firstLastName[0].toUpperCase()}${firstLastName.substring(1)}`}</span>)
                }
            },
            {
                id: 'email',
                Header: 'Correo',
                disableSortBy: true,
                disableFilters: true,
                filter: "text",
                width: '25%',
                accessor: d => ({
                    primaryEmail:  _.get(d, 'email',  _.get(d, 'person.primaryEmail','')),
                }),
                Cell: function cell ({ value: { primaryEmail }, row }) {
                    return(<span>{`${primaryEmail}`}</span>)
                }
            },
            {
                id: 'phoneNumber',
                Header: 'Teléfono',
                disableSortBy: true,
                disableFilters: true,
                filter: "text",
                width: '20%',
                accessor: d => ({
                    primaryPhone: _.get(d, 'person.primaryPhone', ''),
                }),
                Cell: function cell ({ value: { primaryPhone }, row }) {
                    return(<span>{`${primaryPhone}`}</span>)
                },
             },
             {
                 id: 'options.name',
                 Header: '',
                 filter: "text",
                 disableFilters: true,
                 width: '3%',
                 accessor: d => ({
                     item: d,
                 }),
                 Cell: function cell ({ value: { item }, row }) {
                     return (
                         <ActionMenu
                            actionMenuItems={actionMenuItems}
                            client={true}
                            callback={(selected) => {
                                if (selected === 'EDITAR_REGISTRO') {
                                    history.push(ADMIN_VIEW_REGISTER.replace(":adminId", item.id));
                                } 
                                else if (selected === 'ELIMINAR_REGISTRO') {
                                    toggleModal();
                                    setItemSelected(item.user.id);
                                } else if (selected === 'RECUPERAR_CONTRASEÑA') {
                                    props.updatePasswordRequestAction({email: _.get(item, 'person.primaryEmail','')});
                                }
                            }}
                         />
                     ); 
                 }
             },
    ]);

    const getNewData = (searchCondition) => {
        props.getAllAdministratorsAction(searchCondition);
    };


    return (
        <div className='LessorManagementGeneralContainer'>
            <div className='LessorManagementHeaderContainer'>
                <div className='titleContainer'>
                    <h1>GESTIÓN DE ADMINISTRADORES</h1>
                </div> 
                <div className='searcherContainer'>
                    {/* <Searcher placeholderSearch={'Busca nombre o correo administrador'} /> */}
                </div>
            </div>
            <div className='buttonContainer'>
                <Button
                    srcicon={add}
                    onClick={() => history.push(ADMIN_REGISTER)}
                >
                    NUEVO
                </Button>
            </div>
            <div className='tableContainer'>
                <DataTable
                    columns={AdminsInfo}
                    data={{...props.admins}}
                    getData={getNewData}
                    searchObj={searchObj}
                />
            </div>
            {
                openModal &&
                <CustomModal
                    isOpen={openModal}
                    toggle={toggleModal}
                >
                    <DeleteModal
                        OnClickCancel={toggleModal}
                        onClickAgree={() => handleDelete(itemSelected)}
                        toggle={toggleModal}
                        // reloadInfo={reloadInfo}
                        // item={itemSelected}
                    />
                </CustomModal>
            }
        </div>
    )
}

AdminsManagementHome.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    userRegister: PropTypes.any,
    getAllAdministratorsAction: PropTypes.any,
    deleteAdministratorAction: PropTypes.any,
    deleteAdmin: PropTypes.any,
    administrators: PropTypes.any,
    admins: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    data: PropTypes.any,
    totalRecords: PropTypes.any,
    updatePasswordRequestAction: PropTypes.func,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        // data: _.get(state, 'data.data', {}),
        admins: _.get(state, 'admin.admins', {}),
        deleteAdmin: _.get(state, 'admin.deleteAdmin', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    getAllAdministratorsAction: params => dispatch(getAllAdministratorsAction(params)),
    deleteAdministratorAction: params => dispatch(deleteAdministratorAction(params)),
    updatePasswordRequestAction: params => dispatch(updatePasswordRequestAction(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(AdminsManagementHome);
