/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams,  } from 'react-router-dom';
import _ from 'lodash';
import InputKoopers from '../../../../components/molecules/InputKoopers';
import Button from '../../../../components/atoms/Button/Button';
import { 
    getAllCarsBrandAction,
    upsertCarModelAction,
    upsertCarModelBegin,
} from '../../../../redux/actions/carBrandAction';
import GenericCardContainer from '../../../../components/organisms/GenericCardContainer';

// src & styles
import '../../ProfileModule/Profile.scss';
import carImg from '../../../../assets/images/koopers/car-a.png';

const CarBranModeldForm = (props) => {
    const { accessMode, carId, modelId } = useParams();
    const [formData, setFormData] = useState({
        displayText: ''
    });
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(()=>{
        dispatch(getAllCarsBrandAction({}));
    },[]);

    useEffect(() => {
        if (!_.isNil(props.carsBrand) && accessMode === 'editar') {
            // eslint-disable-next-line no-debugger
            let car = _.get(props.carsBrand, 'data', []).filter( c => c.id == carId)[0];
            console.log('car: ', car);
            if (!_.isNil(car)) {
				let model = car.carModels.filter(c => c.id == modelId)[0];
                setFormData({
                    id: model.id,
					carBrandId: carId,
					carModelCategoryId: model.carModelCategoryId,
                    displayText: model.displayText,
                    keyName: model.keyName,
                    // logoMediaFile -> Pending
                });
            }
        }
    }, [props.carsBrand]);

    useEffect(() => {
        if (props.upsertCarModel && _.get(props.upsertCarModel , 'isSuccess', false)) {
            dispatch(upsertCarModelBegin());
            history.goBack();
        }
    }, [props.upsertCarModel]);

    const onChange = (name) => (e) => {
        let formDataCopy = _.clone(formData);
        setFormData({
            ...formDataCopy,
            [name]: e.target.value
        });
    };

    const submitForm = () => {
        let data = formData;
        if (accessMode === 'crear') {
            data = {
				carBrandId: carId,
				// carModelCategoryId: model.carModelCategoryId,
				carModelCategoryId: 1,
                displayText: formData.displayText,
                keyName: formData.displayText,
				// logoMediaFile -> Pending
            }
        }
        dispatch(upsertCarModelAction(data));
    };
    

    return (
        <div className='profileCardContainer'>
            <GenericCardContainer
                //docsTitle='DOCUMENTACIÓN'
                //photo={carImg}
                //carsProfile
                //gallery
                // brand={carInformation.carBrand}
                // model={carInformation.carModel}
                // images={carInformation.images}
                // files={carInformation.files}
                // docContainerClassName={'carsDocsGeneralContainer'}
            >
                <InputKoopers 
                    mode={'carBrand'} 
                    value={`${_.get(formData,'displayText', '')}`}
                    onChange={onChange('displayText')}
                />

                <div style={{ width: '100%', display: 'flex'}}>
                    <Button onClick={submitForm}>
                        GUARDAR
                    </Button>
                    <Button onClick={() => history.goBack()}>
                        CANCELAR
                    </Button>
                </div>
            </GenericCardContainer>
        </div>
    )
}

CarBranModeldForm.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    userRegister: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
    user: PropTypes.object,
    addNotification: PropTypes.func,
    carsBrand: PropTypes.any,
    upsertCarModel: PropTypes.bool,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
        carsBrand: _.get(state, 'cardBrand.carsBrand', {}),
        upsertCarModel: _.get(state, 'cardBrand.upsertCarModel', {}),
    };
}

const mapDispatchToProps = dispatch => ({
    //getUserInformationRequest: params => dispatch(getUserInformationRequest(params)),
    //updateUserInformationRequest: params => dispatch(updateUserInformationRequest(params)),
    // updateLessor: params => dispatch(updateUserInformationRequest(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(CarBranModeldForm);
