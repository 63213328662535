import React, { useState } from "react";
import "./Style/DatosRepresentante.scss";
import { Button, MenuItem, TextField } from "@mui/material";
import * as yup from "yup";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllContriesFiscalesAction,
  getAllEstadoFiscalesAction,
} from "../../../redux/actions/countryFiscalesAction";
import {
  getAllCiudadesRepresentanteAction,
  getAllContriesRepresentanteAction,
  getAllEstadoRepresentanteAction,
} from "../../../redux/actions/coutryRepresentanteAction";
export default function DatosRepresentante({
  setFieldValue,
  handleNext,
  handleBack,
  isMoral,
}) {
  const dispatch = useDispatch();
  const formik = useFormikContext();
  const [country, setCountry] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [state, setState] = useState("");
  const { values, errors, validateForm } = useFormikContext();

  const [countries, setCountries] = React.useState({});
  const [states, setStates] = React.useState({});
  const [ciudades, setCiudades] = React.useState({});

  const countriesRepresentanteGlobal = useSelector(
    (state) => state.countriesRepresentante.countriesRepresentante
  );
  const ciudadesRepresentanteGlobal = useSelector(
    (state) => state.citiesRepresentante.citiesRepresentante
  );
  const EstadosRepresentanteGlobal = useSelector(
    (state) => state.statesRepresentante.statesRepresentante
  );

  React.useEffect(() => {
    dispatch(getAllContriesRepresentanteAction({}));
  }, []);

  React.useEffect(() => {
    console.log("countriesRepresentanteGlobal", countriesRepresentanteGlobal);
    if (_.get(countriesRepresentanteGlobal, "data.rows[0]")) {
      setCountries(countriesRepresentanteGlobal.data.rows);
      console.log(
        "countriesRepresentanteGlobal",
        countriesRepresentanteGlobal.data.rows
      );
    }
  }, [countriesRepresentanteGlobal]);

  React.useEffect(() => {
    console.log("ciudadesRepresentanteGlobal", ciudadesRepresentanteGlobal);
    if (_.get(ciudadesRepresentanteGlobal, "data.rows[0]")) {
      setCiudades(ciudadesRepresentanteGlobal.data.rows);
      console.log(
        "ciudadesRepresentanteGlobal",
        ciudadesRepresentanteGlobal.data.rows
      );
    }
  }, [ciudadesRepresentanteGlobal]);

  React.useEffect(() => {
    console.log("EstadosRepresentanteGlobal", EstadosRepresentanteGlobal);
    if (_.get(EstadosRepresentanteGlobal, "data.rows[0]")) {
      setStates(EstadosRepresentanteGlobal.data.rows);
      console.log(
        "EstadosRepresentanteGlobal",
        EstadosRepresentanteGlobal.data.rows
      );
    }
  }, [EstadosRepresentanteGlobal]);

  const handleCountryRepresentanteChange = (event) => {
    setCountry(event.target.value);
    const data = {
      where: {
        countryId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllEstadoRepresentanteAction(data));
    setFieldValue("PaisRepresentante", event.target.value);
  };

  const handleStateRepresentanteChange = (event) => {
    setState(event.target.value);
    setFieldValue("EstadoRepresentante", event.target.value);
    const data = {
      where: {
        stateId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllCiudadesRepresentanteAction(data));
  };

  const handleCiudadRepresentanteChange = (event) => {
    setCiudad(event.target.value);
    setFieldValue("CiudadRepresentante", event.target.value);
  };

  const handleContinue = () => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        handleNext();
      }
    });
  };

  return (
    <div className="main-container-datos-representante">
      <div className="head-container-registro">
        <p className="title-head">Datos del Representante Legal</p>
      </div>
      <div className="scrollable-container">
        <div className="main-separete-container">
          <div className="separete-container">
            <TextField
              className="textfield-stepper"
              id="filled-basic"
              margin="dense"
              name="nombreRepresentante"
              label="Nombre(s) *"
              variant="filled"
              placeholder="Agregar..."
              InputLabelProps={{ shrink: true }}
              value={values.nombreRepresentante || ""}
              error={Boolean(errors.nombreRepresentante)}
              helperText={errors.nombreRepresentante}
              onChange={(e) => {
                setFieldValue("nombreRepresentante", e.target.value);
              }}
            />
            <TextField
              className="textfield-stepper"
              id="filled-basic"
              margin="dense"
              name="telefonoRepresentante"
              label="Teléfono *"
              variant="filled"
              placeholder="Agregar..."
              InputLabelProps={{ shrink: true }}
              value={values.telefonoRepresentante || ""}
              error={Boolean(errors.telefonoRepresentante)}
              helperText={errors.telefonoRepresentante}
              onChange={(e) => {
                setFieldValue("telefonoRepresentante", e.target.value);
              }}
            />
            <TextField
              className="textfield-stepper"
              id="filled-basic"
              margin="dense"
              name="curpRepresentante"
              label="CURP *"
              variant="filled"
              placeholder="Agregar..."
              InputLabelProps={{ shrink: true }}
              value={values.curpRepresentante || ""}
              error={Boolean(errors.curpRepresentante)}
              helperText={errors.curpRepresentante}
              onChange={(e) => {
                setFieldValue("curpRepresentante", e.target.value);
              }}
            />

            <TextField
              id="filled-basic"
              className="textfield-stepper"
              label="Estado *"
              variant="filled"
              name="EstadoRepresentante"
              margin="dense"
              placeholder="Seleccionar"
              select
              onChange={handleStateRepresentanteChange}
              InputLabelProps={{ shrink: Boolean(state) }}
              value={values.EstadoRepresentante}
              error={Boolean(errors.EstadoRepresentante)}
              helperText={errors.EstadoRepresentante}
            >
              {states &&
                states.length > 0 &&
                states.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              className="textfield-stepper"
              id="filled-basic"
              margin="dense"
              name="direccionRepresentate"
              label="Direccion *"
              variant="filled"
              placeholder="Agregar..."
              InputLabelProps={{ shrink: true }}
              value={values.direccionRepresentate || ""}
              error={Boolean(errors.direccionRepresentate)}
              helperText={errors.direccionRepresentate}
              onChange={(e) => {
                setFieldValue("direccionRepresentate", e.target.value);
              }}
            />
          </div>
          <div className="separete-container">
            <TextField
              className="textfield-stepper"
              id="filled-basic"
              margin="dense"
              name="apellidoRepresentante"
              label="Apellido(s) *"
              variant="filled"
              placeholder="Agregar..."
              InputLabelProps={{ shrink: true }}
              value={values.apellidoRepresentante || ""}
              error={Boolean(errors.apellidoRepresentante)}
              helperText={errors.apellidoRepresentante}
              onChange={(e) => {
                setFieldValue("apellidoRepresentante", e.target.value);
              }}
            />
            <TextField
              className="textfield-stepper"
              id="filled-basic"
              margin="dense"
              name="corrreoRepresentante"
              label="Correo *"
              variant="filled"
              placeholder="Agregar..."
              InputLabelProps={{ shrink: true }}
              value={values.corrreoRepresentante || ""}
              error={Boolean(errors.corrreoRepresentante)}
              helperText={errors.corrreoRepresentante}
              onChange={(e) => {
                setFieldValue("corrreoRepresentante", e.target.value);
              }}
            />
            <TextField
              className="textfield-stepper"
              id="filled-basic"
              name="PaisRepresentante"
              label="País *"
              variant="filled"
              margin="dense"
              placeholder="Seleccionar"
              select
              onChange={handleCountryRepresentanteChange}
              InputLabelProps={{ shrink: Boolean(country) }}
              value={values.PaisRepresentante}
              error={Boolean(errors.PaisRepresentante)}
              helperText={errors.PaisRepresentante}
            >
              {countries &&
                countries.length > 0 &&
                countries.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              id="filled-basic"
              className="textfield-stepper"
              label="Ciudad/Municipio *"
              name="CiudadRepresentante"
              margin="dense"
              variant="filled"
              placeholder="Seleccionar"
              select
              onChange={handleCiudadRepresentanteChange}
              InputLabelProps={{ shrink: Boolean(ciudad) }}
              value={values.CiudadRepresentante}
              error={Boolean(errors.CiudadRepresentante)}
              helperText={errors.CiudadRepresentante}
            >
              {ciudades &&
                ciudades.length > 0 &&
                ciudades.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </div>
      </div>
      <div className="container-button-stepper">
        <Button className="btn-continuar" onClick={handleBack}>
          ATRAS
        </Button>
        <Button className="btn-continuar" onClick={handleContinue}>
          SIGUIENTE
        </Button>
      </div>
    </div>
  );
}
DatosRepresentante.initialValues = {
  nombreRepresentante: "",
  telefonoRepresentante: "",
  curpRepresentante: "",
  PaisRepresentante: "",
  CiudadRepresentante: "",
  apellidoRepresentante: "",
  EstadoRepresentante: "",
  direccionRepresentate: "",
  corrreoRepresentante: "",
};

DatosRepresentante.validationSchema = yup.object().shape({
  corrreoRepresentante: yup
    .string()
    .required("Correo del representante legal requerido")
    .min(2, "Mínimo dos caracteres"),
  nombreRepresentante: yup
    .string()
    .required("Nombre del representante legal requerido")
    .min(2, "Mínimo dos caracteres"),
  telefonoRepresentante: yup
    .string()
    .required("Teléfono del representante legal requerido")
    .min(2, "Mínimo dos caracteres"),
  apellidoRepresentante: yup
    .string()
    .required("Apellido del representante legal requerido")
    .min(2, "Mínimo dos caracteres"),
  direccionRepresentate: yup
    .string()
    .required("Dirección del representante legal requerida")
    .min(2, "Mínimo dos caracteres"),
  curpRepresentante: yup
    .string()
    .required("CURP del representante legal requerida")
    .min(2, "Mínimo dos caracteres"),
  PaisRepresentante: yup
    .string()
    .required("País del representante legal requerido")
    .min(2, "Mínimo dos caracteres"),
  CiudadRepresentante: yup
    .string()
    .required("Ciudad/Municipio del representante legal requerido")
    .min(2, "Mínimo dos caracteres"),
  EstadoRepresentante: yup
    .string()
    .required("Estado del representante legal requerido")
    .min(2, "Mínimo dos caracteres"),
});

DatosRepresentante.label = "Datos del Representante";
