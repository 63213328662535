// HOME
import Home from "../pages/AdminDashboard/HomeModule/Home";

// PERFIL
import Profile from "../pages/AdminDashboard/ProfileModule/Profile";






// GESTIÓN ADMINISTRADORES
import AdminsManagement from "../pages/AdminDashboard/AdminsManagement/AdminsManagement";
import NewRegisterAdmin from "../pages/AdminDashboard/AdminsManagement/NewRegisterAdmin";
import ViewAdminProfile from "../pages/AdminDashboard/AdminsManagement/ViewAdminProfile";

// GESTIÓN ARRENDADOR
import LessorManagementHome from "../pages/AdminDashboard/LessorManagement/LessorManagementHome";
// - Nuevo registro:
import NewRegisterLessor from "../pages/AdminDashboard/LessorManagement/NewRegister";
import ViewLessorProfile from "../pages/AdminDashboard/LessorManagement/ViewLessorProfile";
import LessorDriversManagement from "../pages/AdminDashboard/LessorManagement/drivers/LessorDriversManagement";
import LessorDriversForm from "./../pages/AdminDashboard/LessorManagement/drivers/LessorDriversForm";

// GESTIÓN ARRENDATARIO
import LesseeManagement from "../pages/AdminDashboard/LesseeManagement/LesseeManagement";
// - Nuevo registro:
import ViewLesseeProfile from "../pages/AdminDashboard/LesseeManagement/ViewLesseeProfile";
import NewRegisterLessee from "../pages/AdminDashboard/LesseeManagement/NewRegisterLessee";

// GESTIÓN INTERNOS
import InternManagement from "../pages/AdminDashboard/InternModule/InternManagement";

// GESTIÓN VEHÍCULOS
import CarsManagement from "../pages/AdminDashboard/CarsModule/CarsManagement";
import ViewCardCar from "../pages/AdminDashboard/CarsModule/ViewCarCard";
import CarsDocumentManagement from "../pages/AdminDashboard/CarsModule/CarsDocument/CarsDocumentManagement";
import CarsDocumentForm from "./../pages/AdminDashboard/CarsModule/CarsDocument/CarsDocumentForm";
import CarsCommentManagement from "./../pages/AdminDashboard/CarsModule/CarsComment/CarsCommentManagement";
// eslint-disable-next-line import/no-duplicates
import newRegisterCar from "../pages/AdminDashboard/AdminsManagement/NewRegisterAdmin";

// GESTIÓN MARCAS Y MODELOS DE VEHÍCULOS
import CarBrandManagement from "../pages/AdminDashboard/CarBrandModule/CarBrandManagement";
import CarBrandFrom from "../pages/AdminDashboard/CarBrandModule/CarBrandForm";
//import newRegisterCarBrand from '../pages/AdminDashboard/CarBrandModule/CarBrandRegister';

// GESTIÓN MARCAS Y MODELOS DE VEHÍCULOS
import CarBrandModelManagement from "../pages/AdminDashboard/CarBrandModule/CarBrandModel/CarBrandModelManagement";
import CarBrandModelForm from "../pages/AdminDashboard/CarBrandModule/CarBrandModel/CarBrandModelForm";
/*
import ViewCarBranModel from '../pages/AdminDashboard/CarBrandModule/CarBrandModel/CarBrandModelManagement';
*/

//GESTION TRANSACCIONES
import TransactionsManagement from "../pages/AdminDashboard/TransactionsModule/TransactionsManagement";
import ViewTransactionDetails from "../pages/AdminDashboard/TransactionsModule/ViewTransactionDetails";

// GESTIÓN COLLECTIONS
import CollectionManagement from "../pages/AdminDashboard/CollectionModule/CollectionManagement";

//Maranello
//Gestion de agencias
import ManagmentAgencies from "../pages/AdminDashboard/GestionAgencias/GestionAgencias";
import AddAgencies from "../pages/Maranello/TypePersonAgency/NuevaAgencia";
import StepperAgencies from "../pages/Maranello/NewAgency/NewAgency";
import EditarAgencia from "../pages/AdminDashboard/EditarAgencias/EditarAgencias";

//Gestion de Talleres
import AddWorkshop from "../pages/Maranello/TypePersonWorkshop/TypePersonWorkshop";
import StepperWorkshop from "../pages/Maranello/NewWorkshop/NewWorkshopPage";
import EditarTaller from '../pages/Maranello/EditWorkshop/EditWorkshop'


//Mi Perfil
import miPerfilMaranello from '../pages/Maranello/MiPerfil/MiPerfilAdmin'

export let home = {
  Home,
};

export let profile = {
  Profile,
};

export let admins = {
  AdminsManagement,
  NewRegisterAdmin,
  ViewAdminProfile,
};
export let adminMaranello = {
  ManagmentAgencies,
  AddAgencies,
  StepperAgencies,
  EditarAgencia,
  EditarTaller,
  AddWorkshop,
  StepperWorkshop,
  miPerfilMaranello
};

export let lessors = {
  LessorManagementHome,
  NewRegisterLessor,
  ViewLessorProfile,
  LessorDriversManagement,
  LessorDriversForm,
};

export let lessees = {
  LesseeManagement,
  NewRegisterLessee,
  ViewLesseeProfile,
};

export let inters = {
  InternManagement,
};

export let cars = {
  CarsManagement,
  ViewCardCar,
  newRegisterCar,
  CarsDocumentManagement,
  CarsDocumentForm,
  CarsCommentManagement,
};

export let carBrand = {
  CarBrandManagement,
  CarBrandFrom,
  carBrandModel: {
    CarBrandModelManagement,
    CarBrandModelForm,
  },
};

export let transactions = {
  TransactionsManagement,
  ViewTransactionDetails,
};

export let collection = {
  CollectionManagement,
};
