import request from "../../utils/request";
import { addNotification } from "./notificationAction";
import {
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_BEGIN,
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_SUCCESS,
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_FAILURE,
  ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_BEGIN,
  ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_SUCCESS,
  ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_FAILURE,
  ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_BEGIN,
  ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_SUCCESS,
  ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_FAILURE,
} from "./../actionTypes";

export const getAllCountriesFiscalesBegin = () => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_BEGIN,
});

export const getAllCountriesFiscalesSuccess = (data) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_SUCCESS,
  data,
});

export const getAllCountriesFiscalesFailure = (err) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FISCALES_FAILURE,
  err,
});

export const getAllCiudadFiscalesBegin = () => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_BEGIN,
});

export const getAllCiudadFiscalesSuccess = (data) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_SUCCESS,
  data,
});

export const getAllCiudadFiscalesFailure = (err) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_CIUDAD_FISCALES_FAILURE,
  err,
});

export const getAllEstadosFiscalesBegin = () => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_BEGIN,
});

export const getAllEstadosFiscalesSuccess = (data) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_SUCCESS,
  data,
});

export const getAllEstadosFiscalesFailure = (err) => ({
  type: ADMIN_MODULE_ADMIN_GET_ALL_ESTADO_FISCALES_FAILURE,
  err,
});




export function getAllContriesFiscalesAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getAllCountriesFiscalesBegin());
    return request({
      partialUrl: `/country/get-all-records`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(getAllCountriesFiscalesSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getAllCountriesFiscalesFailure(err));
      });
  };
}

export function getAllCiudadesFiscalesAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getAllCiudadFiscalesBegin());
    return request({
      partialUrl: `/city/get-all-records`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(getAllCiudadFiscalesSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getAllCiudadFiscalesFailure(err));
      });
  };
}

export function getAllEstadoFiscalesAction(data = {}) {
  return (dispatch, getState) => {
    dispatch(getAllEstadosFiscalesBegin());
    return request({
      partialUrl: `/state/get-all-records`,
      method: "POST",
      state: getState().user.request,
      body: data,
      dispatch,
    })
      .then((request) => request.json())
      .then((data) => {
        console.log(data);
        dispatch(getAllEstadosFiscalesSuccess(data));
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification({
            toastType: "error",
            toastTitle:
              "Se ha generado un error al ejecutar la acción, favor de intentarlo de nuevo",
          })
        );
        return dispatch(getAllEstadosFiscalesFailure(err));
      });
  };
}

