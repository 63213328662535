import React, { useEffect, useState } from "react";
import "./tabsStyle.scss";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import * as Yup from "yup";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCiudadesAction,
  getAllContriesAction,
  getAllEstadoAction,
} from "../../../../redux/actions/countryAction";
import { getAllCiudadesFiscalesAction } from "../../../../redux/actions/countryFiscalesAction";
import { upsertAgenciesAdmin } from "../../../../redux/actions/agenciesAction";

const validationSchema = Yup.object().shape({
  razonSocial: Yup.string().required("Este campo es obligatorio"),
  numeroActa: Yup.string(),
  pais: Yup.string().required("Este campo es obligatorio"),
  ciudad: Yup.string().required("Este campo es obligatorio"),
  rfc: Yup.string().required("Este campo es obligatorio"),
  numeroPoder: Yup.string(),
  estado: Yup.string().required("Este campo es obligatorio"),
  direccionFiscal: Yup.string().required("Este campo es obligatorio"),
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  diplay: "flex",
  flexDirection: "column",
  textAlign: "center",
  height: "20rem",
};
function DatosFiscales({ datosFiscales, type, tipoPersona, idGeneral }) {
  const dispatch = useDispatch();
  const countriesGlobal = useSelector((state) => state.countries.countries);
  const ciudadesGlobal = useSelector((state) => state.cities.cities);
  const EstadosGlobal = useSelector((state) => state.states.states);
  const [razonSocial, setRazonSocial] = useState("");
  const [numeroActa, setNumeroActa] = useState("");
  const [country, setCountry] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [rfc, setRfc] = useState("");
  const [numeroPoder, setNumeroPoder] = useState("");
  const [direccionFiscal, setDireccionFiscal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [countries, setCountries] = React.useState({});
  const [states, setStates] = React.useState({});
  const [ciudades, setCiudades] = React.useState({});

  useEffect(() => {
    console.log("datosFiscales", datosFiscales);
    /* if (_.get(datosFiscales, "datosFiscales")) { */
    if (_.get(datosFiscales, "address")) {
      setDireccionFiscal(datosFiscales.address.street1);
    }
    if (_.get(datosFiscales, "rfc")) {
      setRfc(datosFiscales.rfc);
    }
    if (_.get(datosFiscales, "rfc")) {
      setRfc(datosFiscales.rfc);
    }
    if (_.get(datosFiscales, "companyName")) {
      setRazonSocial(datosFiscales.companyName);
    }
    if (_.get(datosFiscales, "address")) {
      setCountry(datosFiscales.address.country.id);
      const data = {
        where: {
          countryId: datosFiscales.address.country.id,
        },
      };
      dispatch(getAllEstadoAction(data));
    }

    if (_.get(datosFiscales, "proxyNumber")) {
      setNumeroPoder(datosFiscales.proxyNumber);
    }
    if (_.get(datosFiscales, "charterNumber")) {
      setNumeroActa(datosFiscales.charterNumber);
    }
  }, [datosFiscales]);

  useEffect(() => {
    if (_.get(datosFiscales, "address")) {
      setSelectedState(datosFiscales.address.state.id);
      console.log(datosFiscales.address.state.id);
      const data = {
        where: {
          stateId: datosFiscales.address.state.id,
        },
      };
      dispatch(getAllCiudadesAction(data));
      setCiudad(datosFiscales.address.city.id);
    }
  }, [country]);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    const data = {
      where: {
        countryId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllEstadoAction(data));
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    const data = {
      where: {
        stateId: event.target.value,
      },
    };
    dispatch(getAllCiudadesFiscalesAction(data));

    // Actualizar la variable de estado
  };

  const handleCiudadChange = (event) => {
    setCiudad(event.target.value);
  };

  const handleGuardarCambios = () => {
    console.log("si lo intento");
    validationSchema
      .validate({
        razonSocial: razonSocial,
        pais: country,
        ciudad: ciudad,
        rfc: rfc,
        /*  numeroPoder: numeroPoder, */
        estado: selectedState,
        direccionFiscal: direccionFiscal,
      })
      .then(() => {
        const taxData = {
          data: {
            id: idGeneral,
            agencyTypeId: tipoPersona,
            taxData: {
              companyName: razonSocial,
              rfc: rfc,
              address: {
                street1: direccionFiscal,
                countryId: country,
                stateId: selectedState,
                cityId: ciudad,
              },
            },
          },
        };
        const taxDataMoral = {
          data: {
            id: idGeneral,
            agencyTypeId: tipoPersona,
            taxData: {
              companyName: razonSocial,
              proxyNumber: numeroPoder,
              charterNumber: numeroActa,
              rfc: rfc,
              address: {
                street1: direccionFiscal,
                countryId: country,
                stateId: selectedState,
                cityId: ciudad,
              },
            },
          },
        };

        console.log("type", type);
        if (type === true) {
          dispatch(upsertAgenciesAdmin(taxDataMoral));
          console.log("Cambios guardados", taxDataMoral);
        } else {
          dispatch(upsertAgenciesAdmin(taxData));
          console.log("Cambios guardados", taxData);
        }

        console.log("Cambios guardados", taxDataMoral);
      })
      .catch((errors) => {
        setValidationErrors(errors);
        setModalOpen(true);
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  React.useEffect(() => {
    dispatch(getAllContriesAction({}));
  }, []);
  React.useEffect(() => {
    if (_.get(countriesGlobal, "data.rows[0]")) {
      setCountries(countriesGlobal.data.rows);
    }
  }, [countriesGlobal]);

  React.useEffect(() => {
    if (_.get(ciudadesGlobal, "data.rows[0]")) {
      setCiudades(ciudadesGlobal.data.rows);
    }
  }, [ciudadesGlobal]);

  React.useEffect(() => {
    if (_.get(EstadosGlobal, "data.rows[0]")) {
      setStates(EstadosGlobal.data.rows);
    }
  }, [EstadosGlobal]);

  return (
    <div className="main-tabs">
      <div className="scrollable-container">
        <div className="main-separete-container">
          <div className="separete-container">
            <TextField
              className="textfield-stepper"
              id="razonSocial"
              margin="dense"
              name="razonSocial"
              label="Razon Social *"
              variant="filled"
              placeholder="Agregar..."
              value={razonSocial}
              onChange={(event) => setRazonSocial(event.target.value)}
              error={Boolean(validationErrors.razonSocial)}
              helperText={validationErrors.razonSocial}
            />
            {type && (
              <TextField
                className="textfield-stepper"
                id="numeroActa"
                margin="dense"
                name="numeroActa"
                label="Numero de acta constitutiva *"
                variant="filled"
                placeholder="Agregar..."
                value={numeroActa}
                onChange={(event) => setNumeroActa(event.target.value)}
                error={Boolean(validationErrors.numeroActa)}
                helperText={validationErrors.numeroActa}
              />
            )}

            <TextField
              className="textfield-stepper"
              id="pais"
              name="pais"
              label="País *"
              variant="filled"
              margin="dense"
              placeholder="Seleccionar"
              select
              value={country}
              onChange={handleCountryChange}
              error={Boolean(validationErrors.pais)}
              helperText={validationErrors.pais}
            >
              {countries &&
                countries.length > 0 &&
                countries.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              id="ciudad"
              className="textfield-stepper"
              label="Ciudad/Municipio *"
              name="ciudad"
              margin="dense"
              variant="filled"
              placeholder="Seleccionar"
              select
              value={ciudad}
              onChange={handleCiudadChange}
              error={Boolean(validationErrors.ciudad)}
              helperText={validationErrors.ciudad}
            >
              {ciudades &&
                ciudades.length > 0 &&
                ciudades.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>
          </div>

          <div className="separete-container">
            <TextField
              className="textfield-stepper"
              id="rfc"
              margin="dense"
              name="rfc"
              label="RFC *"
              variant="filled"
              placeholder="Agregar..."
              value={rfc}
              onChange={(event) => setRfc(event.target.value)}
              error={Boolean(validationErrors.rfc)}
              helperText={validationErrors.rfc}
            />
            {type && (
              <TextField
                className="textfield-stepper"
                id="numeroPoder"
                margin="dense"
                name="numeroPoder"
                label="Número de poder *"
                variant="filled"
                placeholder="Agregar..."
                value={numeroPoder}
                onChange={(event) => setNumeroPoder(event.target.value)}
                error={Boolean(validationErrors.numeroPoder)}
                helperText={validationErrors.numeroPoder}
              />
            )}

            <TextField
              id="estado"
              className="textfield-stepper"
              label="Estado *"
              variant="filled"
              name="estado"
              margin="dense"
              placeholder="Seleccionar"
              select
              value={selectedState}
              onChange={handleStateChange}
              error={Boolean(validationErrors.estado)}
              helperText={validationErrors.estado}
            >
              {states &&
                states.length > 0 &&
                states.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              className="textfield-stepper"
              id="direccionFiscal"
              margin="dense"
              name="direccionFiscal"
              label="Dirección fiscal *"
              variant="filled"
              placeholder="Agregar..."
              value={direccionFiscal}
              onChange={(event) => setDireccionFiscal(event.target.value)}
              error={Boolean(validationErrors.direccionFiscal)}
              helperText={validationErrors.direccionFiscal}
            />
          </div>
        </div>
      </div>

      <div className="container-button">
        <Button className="btn-continuar" onClick={handleGuardarCambios}>
          GUARDAR
        </Button>
      </div>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="container-btns"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <p
              className="text-modal"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              No se han guardado los campos editados
            </p>
            <p
              className="text-modal"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {" "}
              ¿Seguro de querer salir?
            </p>
            <Button
              onClick={closeModal}
              className="btn-salir-sin-guardar"
              style={{
                color: "#3c3c3b",
                opacity: "1",
                textAlign: "center",
                backgroundColor: "#d6c103",
                boxShadow: "0px 3px 12px #3C3C3B33",
                borderRadius: "8px",
                fontSize: "bold",
              }}
            >
              SALIR SIN GUARDAR
            </Button>
            <Button
              onClick={closeModal}
              style={{
                color: "#3c3c3b",
                opacity: "1",
                textAlign: "center",
                backgroundColor: "white",
                boxShadow: "0px 3px 12px #3C3C3B33",
                borderRadius: "8px",
                fontSize: "bold",
                border: "1px solid #d6c103",
              }}
            >
              REGRESAR
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DatosFiscales;
