import React, { useEffect, useState } from "react";
import "./tabsStyle.scss";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCiudadesAction,
  getAllContriesAction,
  getAllEstadoAction,
} from "../../../../redux/actions/countryAction";
import { upsertAgenciesAdmin } from "../../../../redux/actions/agenciesAction";
import { editWorkshopsAdmin } from "../../../../redux/actions/workshopsAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  diplay: "flex",
  flexDirection: "column",
  textAlign: "center",
  height: "20rem",
};
const validationSchema = Yup.object().shape({
  nombreProveedor: Yup.string().required("Este campo es obligatorio"),
  pais: Yup.string().required("Este campo es obligatorio"),
  ciudad: Yup.string().required("Este campo es obligatorio"),
  estado: Yup.string().required("Este campo es obligatorio"),
  direccion: Yup.string().required("Este campo es obligatorio"),
  descripcionProveedor: Yup.string(),
});

function DatosPrincipales({
  datosPrincipales,
  idGeneral,
  tipoPersona,
  logo,
  logoChanged,
  type
}) {
  const [nombreProveedor, setNombreProveedor] = useState("");
  const [nombrePersona, setNombrePersona] = useState("");
  const [isStripeSelected, setIsStripeSelected] = useState(false);
  const [isPayPalSelected, setIsPayPalSelected] = useState(false);
  const [country, setCountry] = useState("");
  const [ciudad, setCiudad] = useState("");
  /* const [state, setState] = useState(""); */
  const [direccion, setDireccion] = useState("");
  const [descripcionProveedor, setDescripcionProveedor] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const countriesGlobal = useSelector((state) => state.countries.countries);
  const ciudadesGlobal = useSelector((state) => state.cities.cities);
  const EstadosGlobal = useSelector((state) => state.states.states);
  const [countries, setCountries] = React.useState({});
  const [states, setStates] = React.useState({});
  const [selectedState, setSelectedState] = useState("");
  const [ciudades, setCiudades] = React.useState({});
  const [paypal, setPaypal] = React.useState(false);
  const [stripe, setStripe] = React.useState();

  const dispatch = useDispatch();



useEffect(() => {
  console.log("type",type)
}, [type])



  useEffect(() => {
    console.log("datosPrincipales", datosPrincipales);
    if (_.get(datosPrincipales, "businessName")) {
      console.log("datosPrincipales", datosPrincipales.businessName);
      setNombreProveedor(datosPrincipales.businessName);
    }
    if (_.get(datosPrincipales, "personFisicName")) {
      setNombrePersona(datosPrincipales.personFisicName);
    }
    if (_.get(datosPrincipales, "address.country")) {
      setCountry(datosPrincipales.address.country.id);
      const data = {
        where: {
          countryId: datosPrincipales.address.country.id,
        },
      };
      dispatch(getAllEstadoAction(data));
    }

    if (_.get(datosPrincipales, "address.street1")) {
      setDireccion(datosPrincipales.address.street1);
    }
    if (_.get(datosPrincipales, "description")) {
      setDescripcionProveedor(datosPrincipales.description);
    }
    if (_.get(datosPrincipales, "gatewayPayments")) {
      console.log(
        "gatewayPayments",
        datosPrincipales.gatewayPayments.usePaypal
      );

      if (
        datosPrincipales.gatewayPayments.usePaypal == 1 ||
        datosPrincipales.gatewayPayments.usePaypal == true
      ) {
        setIsPayPalSelected(true);
        console.log("soy true paypal");
      }

      if (
        datosPrincipales.gatewayPayments.useStripe == 1 ||
        datosPrincipales.gatewayPayments.useStripe == true
      ) {
        setIsStripeSelected(true);
        console.log("soy true stripe");
      }
    }
  }, [datosPrincipales]);

  useEffect(() => {
    if (_.get(datosPrincipales, "address")) {
      setSelectedState(datosPrincipales.address.state.id);
      console.log(datosPrincipales.address.state.id);
      const data = {
        where: {
          stateId: datosPrincipales.address.state.id,
        },
      };
      dispatch(getAllCiudadesAction(data));
      setCiudad(datosPrincipales.address.city.id);
    }
  }, [country]);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    const data = {
      where: {
        countryId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllEstadoAction(data));
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
    const data = {
      where: {
        stateId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllCiudadesAction(data));
  };

  const handleCiudadChange = (event) => {
    setCiudad(event.target.value);
  };

  const handleGuardarCambios = () => {
    validationSchema
      .validate({
        nombreProveedor: nombreProveedor,
        /* nombrePersona: nombrePersona, */
        pais: country,
        ciudad: ciudad,
        estado: selectedState,
        direccion: direccion,
        descripcionProveedor: descripcionProveedor,
      })
      .then(() => {
        const agencyDataLogo = {
          data: {
            id: idGeneral,
            workshopTypeId: tipoPersona,
            workshopData: {
              businessName: nombreProveedor,
              description: descripcionProveedor,
              personFisicName: nombrePersona,
              address: {
                countryId: country,
                stateId: selectedState,
                cityId: ciudad,
                street1: direccion,
              },
              gatewayPayments: {
                useStripe: isStripeSelected ? 1 : 0,
                usePaypal: isPayPalSelected ? 1 : 0,
              },
              logo: logo,
            },
          },
        };
        const agencyData = {
          data: {
            id: idGeneral,
            workshopTypeId: tipoPersona,
            workshopData: {
              businessName: nombreProveedor,
              description: descripcionProveedor,
              personFisicName: nombrePersona,
              address: {
                countryId: country,
                stateId: selectedState,
                cityId: ciudad,
                street1: direccion,
              },
              gatewayPayments: {
                useStripe: isStripeSelected ? 1 : 0,
                usePaypal: isPayPalSelected ? 1 : 0,
              },
            },
          },
        };

        if (logoChanged == true) {
          dispatch(editWorkshopsAdmin(agencyDataLogo));
        } else {
          dispatch(editWorkshopsAdmin(agencyData));
        }
        console.log("logoChanged", logoChanged);
        console.log("Cambios guardados", agencyData);
        console.log("Nombre del proveedor:", nombreProveedor);
        console.log("Nombre de la persona:", nombrePersona);
        console.log("País:", country);
        console.log("Ciudad:", ciudad);
        console.log("Estado:", selectedState); // Cambio de la variable state a selectedState
        console.log("Dirección:", direccion);
        console.log("Descripción del proveedor:", descripcionProveedor);
      })
      .catch((errors) => {
        setValidationErrors(errors);
        console.log(errors);
        /* setModalOpen(true); */
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  React.useEffect(() => {
    dispatch(getAllContriesAction({}));
  }, []);
  React.useEffect(() => {
    if (_.get(countriesGlobal, "data.rows[0]")) {
      setCountries(countriesGlobal.data.rows);
    }
  }, [countriesGlobal]);

  React.useEffect(() => {
    if (_.get(ciudadesGlobal, "data.rows[0]")) {
      setCiudades(ciudadesGlobal.data.rows);
    }
  }, [ciudadesGlobal]);

  React.useEffect(() => {
    if (_.get(EstadosGlobal, "data.rows[0]")) {
      setStates(EstadosGlobal.data.rows);
    }
  }, [EstadosGlobal]);

  const handleStripeChange = (event) => {
    setIsStripeSelected(event.target.checked);
  };
  const handlePayPalChange = (event) => {
    setIsPayPalSelected(event.target.checked);
  };
  return (
    <div className="main-tabs">
      <div className="scrollable-container">
        <div className="main-separete-container">
          <div className="separete-container">
            <TextField
              className="textfield-stepper"
              id="nombreProveedor"
              margin="dense"
              name="nombreProveedor"
              label="Nombre del proveedor *"
              variant="filled"
              placeholder="Agregar..."
              value={nombreProveedor}
              onChange={(event) => setNombreProveedor(event.target.value)}
              error={Boolean(validationErrors.nombreProveedor)}
              helperText={validationErrors.nombreProveedor}
            />
            {!type && (
              <TextField
                className="textfield-stepper"
                id="nombrePersona"
                margin="dense"
                name="nombrePersona"
                label="Nombre persona física *"
                variant="filled"
                placeholder="Agregar..."
                value={nombrePersona}
                onChange={(event) => setNombrePersona(event.target.value)}
                error={Boolean(validationErrors.nombrePersona)}
                helperText={validationErrors.nombrePersona}
              />
            )}
            <TextField
              className="textfield-stepper"
              id="pais"
              name="pais"
              label="País *"
              variant="filled"
              margin="dense"
              placeholder="Seleccionar"
              select
              value={country}
              onChange={handleCountryChange}
              error={Boolean(validationErrors.pais)}
              helperText={validationErrors.pais}
            >
              {countries &&
                countries.length > 0 &&
                countries.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              id="estado"
              className="textfield-stepper"
              label="Estado *"
              variant="filled"
              name="estado"
              margin="dense"
              placeholder="Seleccionar"
              select
              value={selectedState}
              onChange={handleStateChange}
              error={Boolean(validationErrors.estado)}
              helperText={validationErrors.estado}
            >
              {states &&
                states.length > 0 &&
                states.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              className="textfield-stepper"
              id="direccion"
              margin="dense"
              name="direccion"
              label="Dirección *"
              variant="filled"
              placeholder="Agregar..."
              value={direccion}
              onChange={(event) => setDireccion(event.target.value)}
              error={Boolean(validationErrors.direccion)}
              helperText={validationErrors.direccion}
            />
          </div>
          <div className="separete-container">
            <TextField
              className="textfield-stepper"
              id="descripcionProveedor"
              margin="dense"
              multiline
              rows={4}
              name="descripcionProveedor"
              label="Descripción del proveedor"
              variant="filled"
              placeholder="Agregar..."
              value={descripcionProveedor}
              onChange={(event) => setDescripcionProveedor(event.target.value)}
              error={Boolean(validationErrors.descripcionProveedor)}
              helperText={validationErrors.descripcionProveedor}
            />

            <TextField
              id="ciudad"
              className="textfield-stepper"
              label="Ciudad/Municipio *"
              name="ciudad"
              margin="dense"
              variant="filled"
              placeholder="Seleccionar"
              select
              value={ciudad}
              onChange={handleCiudadChange}
              error={Boolean(validationErrors.ciudad)}
              helperText={validationErrors.ciudad}
            >
              {ciudades &&
                ciudades.length > 0 &&
                ciudades.map((elemento) => (
                  <MenuItem key={elemento.id} value={elemento.id}>
                    {elemento.name}
                  </MenuItem>
                ))}
            </TextField>

            <div className="container-checkbox-main">
              <p className="title-pasarela">Pasarela de pagos</p>
              <div className="container-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isStripeSelected}
                      onChange={handleStripeChange}
                      name="stripe"
                      className="titlecheck"
                    />
                  }
                  label="Stripe"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPayPalSelected}
                      onChange={handlePayPalChange}
                      name="paypal"
                      className="titlecheck"
                    />
                  }
                  label="PayPal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-button">
        <Button className="btn-continuar" onClick={handleGuardarCambios}>
          GUARDAR
        </Button>
      </div>

      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="container-btns"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <p
              className="text-modal"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              No se han guardado los campos editados
            </p>
            <p
              className="text-modal"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {" "}
              ¿Seguro de querer salir?
            </p>
            <Button
              onClick={closeModal}
              className="btn-salir-sin-guardar"
              style={{
                color: "#3c3c3b",
                opacity: "1",
                textAlign: "center",
                backgroundColor: "#d6c103",
                boxShadow: "0px 3px 12px #3C3C3B33",
                borderRadius: "8px",
                fontSize: "bold",
              }}
            >
              SALIR SIN GUARDAR
            </Button>
            <Button
              onClick={closeModal}
              style={{
                color: "#3c3c3b",
                opacity: "1",
                textAlign: "center",
                backgroundColor: "white",
                boxShadow: "0px 3px 12px #3C3C3B33",
                borderRadius: "8px",
                fontSize: "bold",
                border: "1px solid #d6c103",
              }}
            >
              REGRESAR
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DatosPrincipales;
