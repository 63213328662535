import {
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_BEGIN,
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_SUCCESS,
  ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FAILURE,
} from "./../actionTypes";

import initialState from "../initialState";

export default function quoteReducer(state = initialState.countries, action) {
  const dataPage = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_BEGIN:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: true,
          isSuccess: false,
          err: null,
          data: null,
        },
      };
    case ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: false,
          isSuccess: true,
          err: null,
          data: action.data,
        },
      };
    case ADMIN_MODULE_ADMIN_GET_ALL_COUNTRIES_FAILURE:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: false,
          isSuccess: false,
          err: action.err,
          data: null,
        },
      };

    default:
      return state;
  }
}
