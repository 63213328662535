import React, { useState } from "react";
import * as yup from "yup";
import { useFormikContext } from "formik";
import "./Style/DatosFiscales.scss";
import { TextField } from "@material-ui/core";
import { Button, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllCiudadesFiscalesAction,
  getAllContriesFiscalesAction,
  getAllEstadoFiscalesAction,
} from "../../../redux/actions/countryFiscalesAction";

export default function DatosFiscales({
  setFieldValue,
  handleNext,
  handleBack,
  isMoral,
}) {
  const dispatch = useDispatch();
  const { values, errors, validateForm } = useFormikContext();
  const [country, setCountry] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [state, setState] = useState("");
  const countriesFiscalGlobal = useSelector(
    (state) => state.countriesFiscales.countriesFiscales
  );
  const ciudadesFiscalGlobal = useSelector(
    (state) => state.citiesFiscales.citiesFiscales
  );
  const EstadosFiscalGlobal = useSelector(
    (state) => state.statesFiscales.statesFiscales
  );
  const [countries, setCountries] = React.useState({});
  const [states, setStates] = React.useState({});
  const [ciudades, setCiudades] = React.useState({});

  React.useEffect(() => {
    dispatch(getAllContriesFiscalesAction({}));
  }, []);

  React.useEffect(() => {
    console.log("countriesFiscalGlobal", countriesFiscalGlobal);
    if (_.get(countriesFiscalGlobal, "data.rows[0]")) {
      setCountries(countriesFiscalGlobal.data.rows);
      console.log("countriesFiscalGlobal", countriesFiscalGlobal.data.rows);
    }
  }, [countriesFiscalGlobal]);

  React.useEffect(() => {
    console.log("ciudadesFiscalGlobal", ciudadesFiscalGlobal);
    if (_.get(ciudadesFiscalGlobal, "data.rows[0]")) {
      setCiudades(ciudadesFiscalGlobal.data.rows);
      console.log("ciudadesFiscalGlobal", ciudadesFiscalGlobal.data.rows);
    }
  }, [ciudadesFiscalGlobal]);

  React.useEffect(() => {
    console.log("EstadosFiscalGlobal", EstadosFiscalGlobal);
    if (_.get(EstadosFiscalGlobal, "data.rows[0]")) {
      setStates(EstadosFiscalGlobal.data.rows);
      console.log("EstadosFiscalGlobal", EstadosFiscalGlobal.data.rows);
    }
  }, [EstadosFiscalGlobal]);

  const handleCountryFiscalChange = (event) => {
    setCountry(event.target.value);
    const data = {
      where: {
        countryId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllEstadoFiscalesAction(data));
    setFieldValue("PaisFiscal", event.target.value);
  };

  const handleStateFiscalChange = (event) => {
    setState(event.target.value);
    setFieldValue("EstadoFiscal", event.target.value);
    const data = {
      where: {
        stateId: event.target.value,
      },
    };
    console.log("data", data);
    dispatch(getAllCiudadesFiscalesAction(data));
  };

  const handleCiudadFiscalChange = (event) => {
    setCiudad(event.target.value);
    setFieldValue("CiudadFiscal", event.target.value);
  };

  const handleContinue = () => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        handleNext();
      }
    });
  };

  return (
    <div className="main-container-datos-fiscales">
      <div className="head-container-registro">
        <p className="title-head">Datos Fiscales</p>
      </div>
      <div className="container-datos-contacto">
        <div className="separete-container">
          <TextField
            className="textfield-stepper"
            id="filled-basic"
            margin="dense"
            name="razonSocial"
            label="Razon Social *"
            variant="filled"
            placeholder="Agregar..."
            InputLabelProps={{ shrink: true }}
            value={values.razonSocial || ""}
            error={Boolean(errors.razonSocial)}
            helperText={errors.razonSocial}
            onChange={(e) => {
              setFieldValue("razonSocial", e.target.value);
            }}
          />
          {isMoral && (
            <TextField
              className="textfield-stepper"
              id="filled-basic"
              margin="dense"
              name="numeroActa"
              label="Numero de acta constitutiva *"
              variant="filled"
              placeholder="Agregar..."
              InputLabelProps={{ shrink: true }}
              value={values.numeroActa || ""}
              error={Boolean(errors.numeroActa)}
              helperText={errors.numeroActa}
              onChange={(e) => {
                setFieldValue("numeroActa", e.target.value);
              }}
            />
          )}

          <TextField
            className="textfield-stepper"
            id="filled-basic"
            name="PaisFiscal"
            label="País *"
            variant="filled"
            margin="dense"
            placeholder="Seleccionar"
            select
            onChange={handleCountryFiscalChange}
            InputLabelProps={{ shrink: Boolean(country) }}
            value={values.PaisFiscal}
            error={Boolean(errors.PaisFiscal)}
            helperText={errors.PaisFiscal}
          >
            {countries &&
              countries.length > 0 &&
              countries.map((elemento) => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.name}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            id="filled-basic"
            className="textfield-stepper"
            label="Ciudad/Municipio *"
            name="CiudadFiscal"
            margin="dense"
            variant="filled"
            placeholder="Seleccionar"
            select
            onChange={handleCiudadFiscalChange}
            InputLabelProps={{ shrink: Boolean(ciudad) }}
            value={values.CiudadFiscal}
            error={Boolean(errors.CiudadFiscal)}
            helperText={errors.CiudadFiscal}
          >
            {ciudades &&
              ciudades.length > 0 &&
              ciudades.map((elemento) => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.name}
                </MenuItem>
              ))}
          </TextField>
        </div>
        <div className="separete-container">
          <TextField
            className="textfield-stepper"
            id="filled-basic"
            margin="dense"
            name="rfc"
            label="RFC *"
            variant="filled"
            placeholder="Agregar..."
            InputLabelProps={{ shrink: true }}
            value={values.rfc || ""}
            error={Boolean(errors.rfc)}
            helperText={errors.rfc}
            onChange={(e) => {
              setFieldValue("rfc", e.target.value);
            }}
          />
          {isMoral && (
            <TextField
              className="textfield-stepper"
              id="filled-basic"
              margin="dense"
              name="numeroPoder"
              label="Número de poder *"
              variant="filled"
              placeholder="Agregar..."
              InputLabelProps={{ shrink: true }}
              value={values.numeroPoder || ""}
              error={Boolean(errors.numeroPoder)}
              helperText={errors.numeroPoder}
              onChange={(e) => {
                setFieldValue("numeroPoder", e.target.value);
              }}
            />
          )}
          <TextField
            id="filled-basic"
            className="textfield-stepper"
            label="Estado *"
            variant="filled"
            name="EstadoFiscal"
            margin="dense"
            placeholder="Seleccionar"
            select
            onChange={handleStateFiscalChange}
            InputLabelProps={{ shrink: Boolean(state) }}
            value={values.EstadoFiscal}
            error={Boolean(errors.EstadoFiscal)}
            helperText={errors.EstadoFiscal}
          >
            {states &&
              states.length > 0 &&
              states.map((elemento) => (
                <MenuItem key={elemento.id} value={elemento.id}>
                  {elemento.name}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            className="textfield-stepper"
            id="filled-basic"
            name="DireccionFiscal"
            label="Dirección fiscal *"
            margin="dense"
            variant="filled"
            placeholder="Agregar..."
            InputLabelProps={{ shrink: true }}
            value={values.DireccionFiscal}
            error={Boolean(errors.DireccionFiscal)}
            helperText={errors.DireccionFiscal}
            onChange={(e) => {
              setFieldValue("DireccionFiscal", e.target.value);
            }}
          />
        </div>
      </div>
      <div className="container-button-stepper">
        <Button className="btn-continuar" onClick={handleBack}>
          ATRAS
        </Button>
        <Button className="btn-continuar" onClick={handleContinue}>
          SIGUIENTE
        </Button>
      </div>
    </div>
  );
}




DatosFiscales.initialValues = {
  razonSocial: "",
  numeroActa: "",
  numeroPoder: "",
  rfc: "",
  DireccionFiscal: "",
  PaisFiscal: "",
  CiudadFiscal: "",
  EstadoFiscal: "",
};

DatosFiscales.validationSchema = yup.object().shape({
  razonSocial: yup
    .string()
    .required("Razón Social es requerido")
    .min(2, "Mínimo dos caracteres"),
  /* numeroActa: yup.string().when("isMoral", {
    is: true,
    then: yup.string().required("Número de acta constitutiva es requerido"),
    otherwise: yup.string().min(2, "Mínimo dos caracteres"),
  }),
  numeroPoder: yup.string().when("isMoral", {
    is: true,
    then: yup.string().required("Número de poder es requerido"),
    otherwise: yup.string().min(2, "Mínimo dos caracteres"),
  }), */
  rfc: yup
    .string()
    .required("RFC es requerido")
    .min(2, "Mínimo dos caracteres"),
  DireccionFiscal: yup
    .string()
    .required("Dirección fiscal es requerida")
    .min(2, "Mínimo dos caracteres"),
  PaisFiscal: yup
    .string()
    .required("País fiscal es requerido")
    .min(2, "Mínimo dos caracteres"),
  CiudadFiscal: yup
    .string()
    .required("Ciudad/Municipio fiscal es requerido")
    .min(2, "Mínimo dos caracteres"),
  EstadoFiscal: yup
    .string()
    .required("Estado fiscal es requerido")
    .min(2, "Mínimo dos caracteres"),
});

DatosFiscales.label = "Datos Fiscales";
