/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams,  } from 'react-router-dom';
import _ from 'lodash';
import SpanKoopers from '../../../components/molecules/SpanKoopers';
import Button from '../../../components/atoms/Button/Button';
import { 
    addNotification
} from '../../../redux/actions/notificationAction';
// src & styles
import '../ProfileModule/Profile.scss';
import ProfileCard from '../../../components/organisms/ProfileCard';

const TransactionDetails = () => {
    const { transactionId } = useParams();
    const transaction = useSelector(state => state.transaction.transactions.data).find(transaction => transaction.id == transactionId);
    const history = useHistory();

    const transactionInformation = {
        transactionId: transaction.id,
        carBrand: transaction.carInstance.carBrand.displayText,
        carModel: transaction.carInstance.carModel.displayText,
        lesseeFirstName: transaction.lessee.person.firstName.toLowerCase(),
        lesseeFirstLastName: transaction.lessee.person.firstLastName.toLowerCase(),
        lessorFirstName: transaction.lessor.person.firstName.toLowerCase(),
        lessorFirstLastName: transaction.lessor.person.firstLastName.toLowerCase(),
        baseCost: transaction.baseCost,
        files: transaction.carInstance.carInstanceDocuments,
        driverFirstName: transaction.driver.person.firstName.toLowerCase(),
        driverFirstLastName: transaction.driver.person.firstLastName.toLowerCase(),
    };
    console.warn(transactionInformation.files)
    return (
            <ProfileCard
                docsTitle='DOCUMENTACIÓN'
                brand={`Transacción: ${transactionInformation.transactionId}`}
                buttonsOnClick={() => history.goBack()}
                carsProfile
                docContainerClassName='transactionDocumentsContainer'
                files={transactionInformation.files}
                >
                <SpanKoopers 
                    mode={'cost'}
                    tag={'COSTO:'}
                    info={`${transactionInformation.baseCost}`}
                />
                <SpanKoopers 
                    mode={'car'}
                    tag={'MARCA:'}
                    info={`${transactionInformation.carBrand}`}
                />
                <SpanKoopers 
                    mode={'car'}
                    tag={'MODELO:'}
                    info={`${transactionInformation.carModel}`}
                />
                <SpanKoopers
                    mode={'person'}
                    tag={'ARRENDATARIO:'}
                    info={` ${transactionInformation.lesseeFirstName[0].toUpperCase()}${transactionInformation.lesseeFirstName.substring(1)} ${transactionInformation.lesseeFirstLastName[0].toUpperCase()}${transactionInformation.lesseeFirstLastName.substring(1)}`}
                />
                <SpanKoopers 
                    mode={'person'}
                    tag={'ARRENDADOR:'}
                    info={`${transactionInformation.lessorFirstName[0].toUpperCase()}${transactionInformation.lessorFirstName.substring(1)} ${transactionInformation.lessorFirstLastName[0].toUpperCase()}${transactionInformation.lessorFirstLastName.substring(1)}`}
                />
                <SpanKoopers 
                    mode={'person'} 
                    tag={'CONDUCTOR:'}
                    info={`${transactionInformation.driverFirstName[0].toUpperCase()}${transactionInformation.driverFirstName.substring(1)} ${transactionInformation.driverFirstLastName[0].toUpperCase()}${transactionInformation.driverFirstLastName.substring(1)}`}
                />
            </ProfileCard>  
    )
}

TransactionDetails.propTypes = {
    isAuthenticated: PropTypes.any,
    history: PropTypes.any,
    role: PropTypes.any,
    dispatch: PropTypes.any,
    value: PropTypes.any,
    row: PropTypes.any,
};

function mapStateToProps (state) {
    return {
        isAuthenticated: state.user.isAuthenticated,
        role: state.user.role,
        error: state.user.error,
    };
}

const mapDispatchToProps = dispatch => ({
    addNotification: params => dispatch(addNotification(params)),
});

export default connect(mapStateToProps,mapDispatchToProps)(TransactionDetails);
