import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './FullScreenLayout.scss';
import { addNotification } from '../../../redux/actions/notificationAction';
import koala from './../../../assets/images/koala.png';
import backbutton from './../../../assets/images/backbutton.png';
import { Link } from '../../atoms';

const Layout = (props) => {
    return (
        <section>
            <div className='container-image-koala left'>
                <Link 
                    to={'/dashboard/admin'}
                    className='backbutton'
                >
                    <img src={backbutton} />
                </Link>
                <img className='koala' src={koala}/>
            </div>
            <div 
                className={'form-container right'}
                style={{backgroundImage: `url(${ props.image })`}}
            >
                {props.children}
            </div>
        </section>
    );
}

Layout.propTypes = {
    children: PropTypes.any,
    toLogin: PropTypes.any,
    toSignup: PropTypes.any,
    image: PropTypes.any,
    classNameButton1: PropTypes.string,
    classNameButton2: PropTypes.string,
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps (state) {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
    addNotification: params => dispatch(addNotification(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
