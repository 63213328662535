import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import searchicon from '../../../assets/images/searchIcon.png';
import './Searcher.scss';

const Searcher = ({
    filter={},
    setFilter,
    // showTextSearch = true,
    classNameSearch = 'inputSearch',
    placeholderSearch,
}) => {
    const onChange = (key,term) => {
        let filterCopy = _.cloneDeep(filter);
        filterCopy[key] = term;
        setFilter(filterCopy);
    };

    return (       
        <div className='inputSearchContainer'>
            <input
                name={'textSearch'}
                value={filter['textSearch'] || ''}
                onChange={e => onChange('textSearch', e.target.value)}
                className={classNameSearch}
                style={{backgroundImage: `url(${searchicon})`}}
                placeholder={placeholderSearch}
            />
        </div>
    );
}

Searcher.propTypes = {
    filter: PropTypes.any,
    setFilter: PropTypes.any,
    showTextSearch: PropTypes.any,
    classNameSearch: PropTypes.any,
    placeholderSearch: PropTypes.any,
}

export default Searcher;
