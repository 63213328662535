import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import _ from "lodash";
import Button from "../../../components/atoms/Button/Button";
import Link from "../../../components/atoms/Link1/Link1";
import "./ChangePassword.scss";
import { updatePasswordRequestAction } from "../../../redux/actions/userActions";
import logo from "../../../assets/images/maranello/LOGO_MARANELLO_NGR.png";
import { TextField } from "@material-ui/core";

const RecoverPasswordForm = (props) => {
  let history = useHistory();

  const userGeneral = useSelector((state) => state.user.user);
  const {token} = useParams();
  
  useEffect(() => {
    console.log("token",token)
    console.log("hola")
  }, [])
  console.log("token",token)


useEffect(() => {
  console.log(userGeneral,"userGeneral")
}, [userGeneral])


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onErrors = (errors, e) => console.log(errors, e);

  useEffect(() => {
    if (!_.isNil(props.updatePasswordRequest.isSuccess) &&
      props.updatePasswordRequest.isSuccess
    ) {
      history.push("/");
    }
  }, [props.updatePasswordRequest.isSuccess]);

  const onSubmitEmail = (formData) => {
    console.log("token",token)
    console.log(formData);
    props.updatePasswordRequestAction(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitEmail)} className="form-logIn">
      <div className={"log-in"}>
        <div className="form-logo-container">
          <img className="logo-forms" src={logo} alt="" />
        </div>
        <div className="container-text-olvidaste">
          <h2 className="header-form-auth-olvidaste">
            OLVIDASTE TU CONTRASEÑA
          </h2>
          <p style={{textAlign:"justify"}}>
            Ingrese su correo electrónico asociado con su cuenta y le enviaremos
            un correo electrónico con instrucciones para restablecer su
            contraseña.
          </p>
        </div>

        <div className="inputs-container-auth">
          <TextField
            type="email"
            label="Correo:"
            margin="dense"
            className="input-generic"
            {...register("email", {
              required: "Valor requerido",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Email invalido",
              },
            })}
            placeholder={"Introduce tu correo electronico"}
            variant="standard"
            color="warning"
            
          />
        </div>
        <div className="container-button">
          <Button className="btn-login-form">
          Enviar
          </Button>
        </div>
        {/* <Button>Enviar</Button> */}
        {/* <Link className={'link'} to={'/iniciar-sesion'}>¿Ya tienes cuenta? <span>Iniciar sesión</span></Link> */}
      </div>
    </form>
  );
};

RecoverPasswordForm.propTypes = {
  updatePasswordRequestAction: PropTypes.func,
  updatePasswordRequest: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    updatePasswordRequest: _.get(state.user, "updatePasswordRequest", {}),
  };
}

const mapDispatchToProps = (dispatch) => ({
  updatePasswordRequestAction: (params) =>
    dispatch(updatePasswordRequestAction(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecoverPasswordForm);
