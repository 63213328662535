import { NOTIFICATION_ADD } from '../actionTypes';
import { toast } from 'react-toastify';

import initialState from './../initialState';

export default function notificationReducer(state=initialState.notification, action){
    switch (action.type) {
        case NOTIFICATION_ADD:
            toast[action.toastType](action.toastTitle, {
                ...state,
                ...action.toastConfig,
            });
            return {
                ...state,
                toastTitle: action.toastTitle,
                toastType: action.toastType,
            }
        default:
            return state;
    }
}
